<template>
  <v-expansion-panel ref="company">
    <v-expansion-panel-header class="font-weight-bold">
      <v-card-title class="ma-0 pa-0">{{ title }}</v-card-title>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0">
      <!-- Toggle-Button zum Umschalten zwischen aktuellen und vergangenen Ferien -->
      <v-row class="d-flex justify-center mb-3">
        <v-btn-toggle v-model="showPast" mandatory>
          <v-btn :value="false">Zukünftige Ferien</v-btn>
          <v-btn :value="true">Vergangene Ferien</v-btn>
        </v-btn-toggle>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <v-list>
            <v-list-item-group>
              <!-- Verwende die gefilterte Liste -->
              <v-list-item v-for="(item, index) in filteredItems" :key="index + '-shiftIndex'" @click="$emit('editSetting', index, type)">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                    <v-chip>{{ getDuration(item) }}</v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col cols="12" class="d-flex justify-center">
          <v-btn color="primary" @click="$emit('addSetting', type)">
            <span>Hinzufügen</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "schoolHolidaysPanel",
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      title: "Schulferien",
      type: "schoolHolidays",
      // showPast: false zeigt aktuelle Ferien, true zeigt vergangene Ferien
      showPast: false,
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        if (!item.endDate) return false // Falls kein Enddatum vorhanden ist, ausschließen
        if (this.showPast) {
          return this.$moment(item.endDate).isBefore(this.$moment(), "day")
        } else {
          return !this.$moment(item.endDate).isBefore(this.$moment(), "day")
        }
      })
    },
  },
  methods: {
    getDuration(item) {
      if (item.startDate && item.endDate) {
        return this.$moment(item.endDate).diff(this.$moment(item.startDate), "days") + 1 + " Tage"
      }
      return ""
    },
  },
}
</script>

<style scoped></style>
