import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  // Downloads
  downloadContractUpgradePDF(upgradeId) {
    let url = buildUrl("dashboard/c/print/" + upgradeId)

    axios
      .get(
        url, //your url
        {
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          }, // important
        }
      )
      .then((response) => {
        if (window.flutter_inappwebview) {
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]

            window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "pdf", "vertragUpgrade")
          }
        } else {
          saveAs(response.data, "vertragUpgrade")
        }
      })
  },

  //Stats
  fetchContractStats(data) {
    let url = buildUrl("contract/stats")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  // Upgrades
  addCommentContractUpgrade(data) {
    let url = buildUrl("dashboard/c/upgrade/status/comment")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateContractUpgradeStatus(data) {
    let url = buildUrl("dashboard/c/upgrade/status/update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchUpgradeContracts(data) {
    let url = buildUrl("dashboard/c/upgrade/status")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  createContractUpgrade(contractInfo) {
    //let url = buildUrl('dasupdateContractUpgradeStatusate')
    let url = buildUrl("dashboard/c/upgrade/create")
    return axios.post(url, contractInfo, buildOptions()).then((res) => res.data)
  },
  fetchRunningTACContract(customerId, local = true) {
    if (local) {
      let url = buildUrl("dashboard/c/upgrade/taccontract")
      return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
    } else {
      let url = "https://app.wellcomefit.ch/api/dashboard/c/upgrade/taccontract"
      return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
    }
  },
  fetchPackageUpgradePossibilitesTemp(customerId, local = true) {
    if (local) {
      let url = buildUrl("dashboard/c/upgrade/possibilities/temp")
      return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
    } else {
      let url = "https://app.wellcomefit.ch/api/dashboard/c/upgrade/possibilities/temp"
      return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
    }
  },
  fetchPackageUpgradePossibilites(customerId, local = true) {
    if (local) {
      let url = buildUrl("dashboard/c/upgrade/possibilities")
      return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
    } else {
      let url = "https://app.wellcomefit.ch/api/dashboard/c/upgrade/possibilities"
      return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
    }
  },
  fetchPackageUpgradePossibilitesForOwnCustomer() {
    let url = "https://app.wellcomefit.ch/api/dashboard/c/upgrade/possibilities/for/own/user"
    return axios.post(url, { customerId }, buildOptions()).then((res) => res.data)
  },
  saveRoodyTACContractMap(data) {
    let url = buildUrl("dashboard/c/mapper")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchRoodyTACContractMap() {
    let url = buildUrl("dashboard/c/mapper")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Company Discount

  uploadCompanyDiscountList(formData, filename) {
    let url = buildUrl("dashboard/contract/companydiscountlist/" + filename)
    return axios
      .post(
        url,
        formData,
        buildOptions({
          headers: {
            "Content-Type": formData.type,
          },
        })
      )
      .then((res) => res.data)
  },

  fetchCompanyConfirmations() {
    let url = buildUrl("dashboard/company/confirmations/download")

    axios
      .get(
        url, //your url
        {
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          }, // important
        }
      )
      .then((response) => {
        if (window.flutter_inappwebview) {
          console.log("FLUTTER IOS DOWNLOAD PDF START")
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]

            window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "xlsx", "Firmenrabattliste")
          }
        } else {
          saveAs(response.data, "Firmenrabattliste")
        }
      })
  },
  // Contract Routes

  fetchRecentlyAcceptedContracts() {
    let url = buildUrl("dashboard/contracts/recently/accepted")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  fetchExternalPackages(memberships) {
    let url = buildUrl("dashboard/contract/external/packages")
    return axios.post(url, memberships, buildOptions()).then((res) => res.data)
  },
  updateContractEditMode(infos) {
    let url = buildUrl("dashboard/contract/editmode")
    return axios.post(url, infos, buildOptions()).then((res) => res.data)
  },
  changeContractPublishedStatus(contract) {
    let url = buildUrl("dashboard/contract/published")
    return axios.post(url, contract, buildOptions()).then((res) => res.data)
  },
  createContractSettings(contract) {
    let url = buildUrl("dashboard/settings/contract")
    return axios.post(url, { contract }, buildOptions()).then((res) => res.data)
  },
  fetchUserContract(info) {
    let url = buildUrl("dashboard/contract/user/fetch/" + info.userId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  downloadImage(imgUrl) {
    let title = "bild"
    let url = imgUrl
    axios({
      url, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      if (window.flutter_inappwebview) {
        console.log("FLUTTER IOS DOWNLOAD PDF START")
        var reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onloadend = function () {
          var dataUrl = reader.result
          var base64 = dataUrl.split(",")[1]
          console.log(base64)
          window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "png", title.replaceAll(" ", "_").replaceAll(".", "_"))
        }
      } else {
        saveAs(response.data, title.replaceAll(" ", "_").replaceAll(".", "_"))
      }
    })
  },
  getIframeprintUserContract(userId, index, title = "Vertrag") {
    let url = buildUrl("dashboard/contract/print/" + userId + "/" + index)
    return axios
      .get(
        url, //your url
        {
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          }, // important
        }
      )
      .then((res) => res.data)
  },
  printUserContract(userId, index, title = "Vertrag") {
    let url = buildUrl("dashboard/contract/print/" + userId + "/" + index)

    axios
      .get(
        url, //your url
        {
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          }, // important
        }
      )
      .then((response) => {
        if (window.flutter_inappwebview) {
          console.log("FLUTTER IOS DOWNLOAD PDF START")
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]
            console.log(base64)
            window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "pdf", title.replaceAll(" ", "_").replaceAll(".", "_"))
          }
        } else {
          saveAs(response.data, title.replaceAll(" ", "_").replaceAll(".", "_"))
        }
      })
  },
  createTemporaryUserContract(contract) {
    let url = buildUrl("dashboard/contract/user/create/temporary")
    return axios.post(url, { contract }, buildOptions()).then((res) => res.data)
  },
  createUserContractSelf(contract) {
    let url = buildUrl("dashboard/contract/user/create/self")
    return axios.post(url, { contract }, buildOptions()).then((res) => res.data)
  },
  createUserContract(contract) {
    let url = buildUrl("dashboard/contract/user/create")
    return axios.post(url, { contract }, buildOptions()).then((res) => res.data)
  },
  updateUserContract(data) {
    let url = buildUrl("dashboard/contract/user/update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  declineContract(data) {
    let url = buildUrl("dashboard/contract/user/status/decline")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateContractStatus(data) {
    let url = buildUrl("dashboard/contract/user/status/update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  deleteTemporaryContract(info) {
    let url = buildUrl("dashboard/contract/temporary/delete")
    return axios.post(url, info, buildOptions()).then((res) => res.data)
  },
  deleteContract(info) {
    let url = buildUrl("dashboard/contract/delete")
    return axios.post(url, info, buildOptions()).then((res) => res.data)
  },
  createContract(contract, internalTitle, eMails, promotions, enabledStudios, preConditionText, postConditionText, useStudioCity, badge) {
    let url = buildUrl("dashboard/contract/create")
    return axios
      .post(
        url,
        { contract, internalTitle, eMails, promotions, enabledStudios, preConditionText, postConditionText, useStudioCity, badge },
        buildOptions()
      )
      .then((res) => res.data)
  },
  fetchContract(contractId) {
    let url = buildUrl("dashboard/contracts/" + contractId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchContractsByStatus(status) {
    let url = buildUrl("dashboard/contracts/user/status/" + status)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchContractsPublic(id) {
    let url = buildUrl("dashboard/contractspublic/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchContracts() {
    let url = buildUrl("dashboard/contracts")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveContractSettings(data, fieldName) {
    let url = buildUrl("dashboard/settings/contract/" + fieldName)
    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },
  fetchContractSettings() {
    let url = buildUrl("dashboard/settings/contract")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
