<template>
  <v-container dark fluid>
    <v-row>
      <!-- Linke Spalte: Ordnerbaum -->
      <v-col md="4" cols="12">
        <folder-tree
          :items="folderTreeWithInbox"
          :openNodes.sync="openNodes"
          @create-folder="openCreateFolderDialog"
          @select-folder="onFolderSelect"
          @create-subfolder="openCreateSubfolderDialog"
          @edit-folder="openEditFolderDialog"
          @delete-folder="openDeleteFolderDialog"
          @folder-drop="onFolderDrop"
          @drag-enter="handleDragEnter"
          @drag-leave="handleDragLeave" />
      </v-col>

      <!-- Rechte Spalte: Dokumente -->
      <v-col md="8" cols="12">
        <document-list
          :loading="loading"
          :documents="documents"
          :headers="documentHeaders"
          :folderName="searchQuery.trim() !== '' ? 'Suche' : selectedFolder && selectedFolder._id !== 'inbox' ? selectedFolder.name : 'Eingang'"
          :searchQuery="searchQuery"
          @search-documents="searchDocuments"
          @open-document-actions="openDocumentActionsDialog"
          @document-drag-start="onDocumentDragStart"
          @update:searchQuery="searchQuery = $event" />
      </v-col>
    </v-row>

    <!-- Dialog: Create/Edit Folder -->
    <folder-dialog
      :dialog="folderDialog"
      :mode="folderDialogMode"
      :folderData="folderForm"
      :parentOptions="parentFolderOptions"
      @save-folder="saveFolder"
      @close="closeFolderDialog" />

    <!-- Dialog: Confirm Delete Folder -->
    <delete-folder-dialog
      :dialog="deleteFolderDialog"
      :folder="folderToDelete"
      @confirm-delete="deleteFolder"
      @cancel-delete="deleteFolderDialog = false" />

    <!-- Dialog: Dokument bearbeiten -->
    <document-dialog
      :dialog="documentDialog"
      :documentData="documentForm"
      :invoiceIssuerOptions="invoiceIssuerOptions"
      :selectedInvoiceIssuer="selectedInvoiceIssuer"
      :issuerSearch="issuerSearch"
      :menu="menu"
      @save-document="saveDocument"
      @close-document="closeDocumentDialog"
      @create-new-invoice-issuer="createNewInvoiceIssuer" />

    <!-- Dialog: Dokument-Vorschau & Aktionen -->
    <document-actions-dialog
      :dialog="documentActionsDialog"
      :document="currentDocument"
      :previewBlobUrl="previewBlobUrl"
      :previewHtml="previewHtml"
      :previewHeight="previewHeight"
      :isResizing="isResizing"
      :mailDetails="mailDetails"
      :activeTab="activeTab"
      :newComment="newComment"
      :newAssignedUser="newAssignedUser"
      :newAssignedUserRights="newAssignedUserRights"
      @close="closeDocumentActionsDialog"
      @download-document="downloadDocument"
      @edit-document="openEditDocumentDialog"
      @start-resize="startResize"
      @resize-preview="resizePreview"
      @stop-resize="stopResize"
      @decide-assignment="decideAssignment"
      @update-new-comment="newComment = $event"
      @update-new-assigned-user="updateNewAssignedUser"
      @add-assigned-user="addAssignedUser"
      @remove-assigned-user="removeAssignedUser"
      @update:activeTab="activeTab = $event" />
  </v-container>
</template>

<script>
import FolderTree from "./components/folderTree.vue"
import DocumentList from "./components/documentList.vue"
import FolderDialog from "./components/folderDialog.vue"
import DeleteFolderDialog from "./components/deleteFolderDialog.vue"
import DocumentDialog from "./components/documentDialog.vue"
import mammoth from "mammoth"
import DocumentActionsDialog from "./components/documentActionsDialog.vue"
import api from "@/api"

export default {
  name: "FolderTreeManager",
  components: {
    FolderTree,
    DocumentList,
    FolderDialog,
    DeleteFolderDialog,
    DocumentDialog,
    DocumentActionsDialog,
  },
  data() {
    return {
      loading: false,
      folders: [],
      folderTree: [],
      documents: [],
      selectedFolder: null,
      searchQuery: "",
      documentHeaders: [
        { text: "Datum", value: "date" },
        { text: "Status", value: "approvalStatus" },
        { text: "Titel", value: "title" },
        { text: "Rechnungssteller", value: "invoiceIssuer" },
        { text: "Aktionen", value: "actions", sortable: false },
      ],
      // Zustände und Formulare für Ordner
      folderDialog: false,
      folderDialogMode: "create",
      folderForm: {
        _id: null,
        name: "",
        parent: null,
      },
      deleteFolderDialog: false,
      folderToDelete: {},
      // Zustände und Formulare für Dokumente
      documentDialog: false,
      selectedInvoiceIssuer: null,
      invoiceIssuerOptions: [],
      documentForm: {
        _id: null,
        title: "",
        description: "",
        invoiceIssuer: null,
        amount: null,
        invoiceNumber: "",
        date: null,
        studio: null,
      },
      menu: false,
      documentActionsDialog: false,
      currentDocument: {},
      newComment: "",
      previewBlobUrl: null,
      previewHtml: null,
      previewHeight: Math.round(window.innerHeight * 0.2),
      isResizing: false,
      startY: 0,
      startHeight: 600,
      mailDetails: null,
      activeTab: 0,
      // Für Assigned Users
      newAssignedUser: null,
      newAssignedUserRights: "manage",
      // Variablen für v-treeview
      openNodes: [],
      dragOverTimeout: null,
      issuerSearch: "",
    }
  },
  computed: {
    folderTreeWithInbox() {
      return [
        { _id: "inbox", name: "Eingang", children: [] },
        { _id: "notSigned", name: "Noch nicht visiert", children: [] },
        { _id: "signed", name: "Visiert", children: [] },
        { _id: "declined", name: "Abgelehnt", children: [] },
        ...this.folderTree,
      ]
    },
    parentFolderOptions() {
      return this.folders.filter((f) => !this.folderForm._id || f._id !== this.folderForm._id)
    },
    isApp() {
      return window.flutter_inappwebview || false
    },
  },
  methods: {
    async decideAssignment(status) {
      try {
        const payload = { status: status.status, comment: status.comment }
        const updatedDocument = await api.documents.decideDocument(this.currentDocument._id, payload)
        this.currentDocument = updatedDocument
        this.newComment = ""
      } catch (error) {
        console.error("Fehler bei der Entscheidung:", error)
      }
    },
    async fetchInvoiceIssuers() {
      try {
        const data = await api.invoiceIssuers.fetch()
        this.invoiceIssuerOptions = data
      } catch (err) {
        console.error("Fehler beim Abrufen der Rechnungssteller:", err)
      }
    },
    async fetchFolders() {
      try {
        const data = await api.documents.fetchFolders()
        this.folders = data
        this.buildFolderTree()
      } catch (error) {
        console.error("Fehler beim Abrufen der Ordner:", error)
      }
    },
    buildFolderTree() {
      const map = {}
      this.folders.forEach((folder) => {
        folder.children = []
        map[folder._id] = folder
      })
      const tree = []
      this.folders.forEach((folder) => {
        if (folder.parent) {
          if (map[folder.parent]) {
            map[folder.parent].children.push(folder)
          }
        } else {
          tree.push(folder)
        }
      })
      this.folderTree = tree
    },
    sortData(data) {
      return data.sort((a, b) => {
        // Berechne für beide Elemente, ob die Bedingung erfüllt ist
        const aCondition = a.userIsAssigned && a.approvalStatus !== "signed"
        const bCondition = b.userIsAssigned && b.approvalStatus !== "signed"

        // Wenn beide denselben Wahrheitswert haben, bleibt die Reihenfolge unverändert
        if (aCondition === bCondition) return 0

        // Wenn a true ist, soll a vor b stehen, ansonsten umgekehrt
        return aCondition ? -1 : 1
      })
    },
    async fetchDocuments(folderId = null) {
      this.loading = true
      try {
        const params = {}
        if (this.searchQuery.trim() !== "") {
          // Bei Suche global über alle Ordner hinweg

          params.q = this.searchQuery

          const data = await api.documents.searchDocuments(params)

          this.documents = this.sortData(data)
        } else {
          // Ohne Suchbegriff wird der Ordnerfilter angewendet
          if (folderId && folderId !== "inbox") {
            params.folderId = folderId
          } else {
            params.folderId = "inbox"
          }
          const data = await api.documents.fetchDocuments(params)
          this.documents = this.sortData(data)
        }
      } catch (error) {
        console.log("Fehler beim Abrufen der Dokumente:", error)
      } finally {
        this.loading = false
      }
    },
    findFolderById(folders, id) {
      for (const folder of folders) {
        if (folder._id === id) {
          return folder
        }
        if (folder.children && folder.children.length > 0) {
          const found = this.findFolderById(folder.children, id)
          if (found) {
            return found
          }
        }
      }
      return null
    },
    onFolderSelect(folderId) {
      this.searchQuery = ""
      if (folderId) {
        const folder = this.findFolderById(this.folderTreeWithInbox, folderId)
        if (folder) {
          this.selectFolder(folder)
        }
      } else {
        this.selectedFolder = null
        this.fetchDocuments("inbox")
      }
    },
    selectFolder(folder) {
      this.selectedFolder = folder
      this.fetchDocuments(folder._id)
    },
    openCreateFolderDialog() {
      this.folderDialogMode = "create"
      this.folderForm = { _id: null, name: "", parent: null }
      this.folderDialog = true
    },
    openCreateSubfolderDialog(folder) {
      this.folderDialogMode = "create"
      this.folderForm = { _id: null, name: "", parent: folder._id }
      this.folderDialog = true
    },
    openEditFolderDialog(folder) {
      this.folderDialogMode = "edit"
      this.folderForm = { _id: folder._id, name: folder.name, parent: folder.parent }
      this.folderDialog = true
    },
    closeFolderDialog() {
      this.folderDialog = false
    },
    async saveFolder(folderData) {
      try {
        const payload = { name: folderData.name, parent: folderData.parent }
        if (this.folderDialogMode === "create") {
          await api.documents.createFolder(payload)
          await this.fetchFolders()
          if (folderData.parent && !this.openNodes.includes(folderData.parent)) {
            this.openNodes.push(folderData.parent)
          }
        } else {
          const updatedFolder = await api.documents.updateFolder(folderData._id, payload)
          const index = this.folders.findIndex((f) => f._id === folderData._id)
          if (index !== -1) {
            this.folders.splice(index, 1, updatedFolder)
          }
          this.buildFolderTree()
        }
        this.folderDialog = false
      } catch (error) {
        console.error("Fehler beim Speichern des Ordners:", error)
      }
    },
    openDeleteFolderDialog(folder) {
      this.folderToDelete = folder
      this.deleteFolderDialog = true
    },
    async deleteFolder() {
      try {
        await api.documents.deleteFolder(this.folderToDelete._id)
        await this.fetchFolders()
        this.deleteFolderDialog = false
        if (this.selectedFolder && this.selectedFolder._id === this.folderToDelete._id) {
          this.selectedFolder = { _id: "inbox", name: "Eingang" }
          this.fetchDocuments("inbox")
        }
      } catch (error) {
        console.error("Fehler beim Löschen des Ordners:", error)
      }
    },
    searchDocuments() {
      console.log("SEARCHING")
      // Bei aktiver Suche wird kein Ordner selektiert – die Suche erfolgt global
      this.fetchDocuments() // Ruft fetchDocuments ohne folderId-Parameter auf
    },
    downloadDocument(document) {
      let title = document.title || document.originalFileName || "Dokument"
      let invoiceNumber = document.invoiceNumber || ""
      let fileName = title
      if (invoiceNumber.length > 0) fileName += "_" + invoiceNumber
      api.documents.downloadDocument(document._id, fileName)
    },
    openEditDocumentDialog(document) {
      this.documentForm = {
        _id: document._id,
        title: document.title,
        description: document.description,
        studio: document.studio || null,
        invoiceIssuer: document.invoiceIssuer ? document.invoiceIssuer._id : null,
        amount: document.amount,
        invoiceNumber: document.invoiceNumber,
        date: document.date ? this.$moment(document.date).format("YYYY-MM-DD") : null,
      }
      this.selectedInvoiceIssuer = document.invoiceIssuer || null
      this.documentDialog = true
    },
    closeDocumentDialog() {
      this.documentDialog = false
    },
    async saveDocument(documentData, invoiceIssuerObj) {
      try {
        const invoiceIssuerId = invoiceIssuerObj ? invoiceIssuerObj._id : null
        const payload = {
          title: documentData.title,
          description: documentData.description,
          invoiceIssuer: invoiceIssuerId,
          amount: documentData.amount,
          invoiceNumber: documentData.invoiceNumber,
          date: documentData.date,
          studio: documentData.studio,
        }
        const updatedDocument = await api.documents.updateDocument(documentData._id, payload)
        const index = this.documents.findIndex((d) => d._id === documentData._id)
        if (index !== -1) {
          this.documents.splice(index, 1, updatedDocument)
        }
        // Aktualisiere das aktuell geöffnete Dokument
        this.currentDocument.title = documentData.title
        this.currentDocument.description = documentData.description
        this.currentDocument.invoiceIssuer = this.invoiceIssuerOptions.find((issuer) => issuer._id === invoiceIssuerId)
        this.currentDocument.amount = documentData.amount
        this.currentDocument.invoiceNumber = documentData.invoiceNumber
        this.currentDocument.date = documentData.date
        this.currentDocument.studio = documentData.studio
        this.documentDialog = false
      } catch (error) {
        console.error("Fehler beim Speichern des Dokuments:", error)
      }
    },
    onDocumentDragStart(e, document) {
      e.dataTransfer.setData("documentId", document._id)
    },
    onFolderDrop({ folder, documentId }) {
      this.moveDocumentToFolder(documentId, folder._id)
    },
    moveDocumentToFolder(documentId, folderId) {
      if (folderId === "inbox" || folderId === "signed" || folderId === "notSigned" || folderId === "declined") folderId = null

      api.documents
        .moveDocument(documentId, folderId)
        .then(() => {
          const currentFolderId = this.selectedFolder ? this.selectedFolder._id : "inbox"
          this.fetchDocuments(currentFolderId)
        })
        .catch((error) => {
          console.error("Fehler beim Verschieben des Dokuments:", error)
        })
    },
    openDocumentActionsDialog(document) {
      this.currentDocument = document
      this.documentActionsDialog = true
      if (!this.isApp) this.loadDocumentPreview(document)
      if (document.mailRef) {
        this.loadDocumentMail(document)
      } else {
        this.mailDetails = null
      }
      this.activeTab = 0
    },
    loadDocumentPreview(document) {
      api.documents
        .previewDocument(document._id)
        .then((blob) => {
          if (blob.type === "application/pdf") {
            // Bei PDF: Blob URL erzeugen
            this.previewBlobUrl = URL.createObjectURL(blob)
            this.previewHtml = null
          } else if (blob.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            // Bei DOCX: Blob in ArrayBuffer umwandeln und mit Mammoth in HTML konvertieren
            blob.arrayBuffer().then((arrayBuffer) => {
              mammoth
                .convertToHtml({ arrayBuffer })
                .then((result) => {
                  this.previewHtml = result.value
                  this.previewBlobUrl = null
                })
                .catch((error) => {
                  console.error("Fehler bei der Konvertierung des DOCX:", error)
                  this.previewHtml = `<p>Fehler bei der Anzeige des Dokuments.</p>`
                  this.previewBlobUrl = null
                })
            })
          } else {
            // Falls das Format nicht unterstutzt wird
            console.warn("Vorschau für dieses Dateiformat wird nicht unterstuetzt.")
            this.previewBlobUrl = null
            this.previewHtml = `<p>Vorschau fuer dieses Dateiformat wird nicht unterstuetzt.</p>`
          }
        })
        .catch((error) => {
          console.error("Fehler beim Laden der Dokument-Vorschau:", error)
          this.previewBlobUrl = null
          this.previewHtml = null
        })
    },
    loadDocumentMail(document) {
      api.documents
        .fetchDocumentMail(document._id)
        .then((mail) => {
          this.mailDetails = mail
        })
        .catch((error) => {
          console.error("Fehler beim Laden der Mail-Daten:", error)
          this.mailDetails = null
        })
    },
    closeDocumentActionsDialog() {
      this.documentActionsDialog = false
      if (this.previewBlobUrl) {
        URL.revokeObjectURL(this.previewBlobUrl)
        this.previewBlobUrl = null
      }
      this.mailDetails = null
      const folderId = this.selectedFolder ? this.selectedFolder._id : "inbox"
      this.fetchDocuments(folderId)
    },
    startResize(event) {
      this.isResizing = true
      this.startY = event.clientY
      this.startHeight = this.previewHeight
      document.body.style.userSelect = "none"
    },
    resizePreview(event) {
      if (!this.isResizing) return
      const diff = event.clientY - this.startY
      this.previewHeight = Math.max(100, this.startHeight + diff)
    },
    stopResize() {
      this.isResizing = false
      document.body.style.userSelect = ""
    },
    updateNewAssignedUser(id) {
      this.newAssignedUser = id
    },

    addAssignedUser() {
      if (!this.newAssignedUser) return

      const newAssignment = {
        user: this.newAssignedUser,
        rights: this.newAssignedUserRights,
      }

      // Sende nur das neue Assignment ans Backend
      api.documents
        .assignDocument(this.currentDocument._id, { assignment: newAssignment })
        .then((doc) => {
          this.currentDocument = doc
          this.newAssignedUser = null
          this.newAssignedUserRights = "manage"
        })
        .catch((error) => {
          console.error("Fehler beim Hinzufügen des zugewiesenen Benutzers:", error)
        })
    },
    removeAssignedUser(index) {
      // Ermittle die ID des zu entfernenden Benutzers anhand des Indexes
      const userId = this.currentDocument.assignedUsers[index].user._id

      // Rufe den neuen API-Endpunkt auf, um den Benutzer zu entfernen
      api.documents
        .unassignDocument(this.currentDocument._id, [userId])
        .then((doc) => {
          this.currentDocument = doc
        })
        .catch((error) => {
          console.error("Fehler beim Entfernen des Assigned Users:", error)
        })
    },
    handleDragEnter(item) {
      if (item.children && item.children.length > 0 && !this.openNodes.includes(item._id)) {
        this.dragOverTimeout = setTimeout(() => {
          this.openNodes.push(item._id)
        }, 500)
      }
    },
    handleDragLeave(item) {
      if (this.dragOverTimeout) {
        clearTimeout(this.dragOverTimeout)
        this.dragOverTimeout = null
      }
    },
    createNewInvoiceIssuer(title) {
      api.invoiceIssuers
        .create({ title })
        .then((createdIssuer) => {
          this.invoiceIssuerOptions.push(createdIssuer)
          this.selectedInvoiceIssuer = createdIssuer
          this.documentForm.invoiceIssuer = createdIssuer._id
          if (this.currentDocument) {
            this.currentDocument.invoiceIssuer = createdIssuer
          }
          this.issuerSearch = ""
        })
        .catch((err) => {
          console.error("Fehler beim Erstellen des neuen Rechnungsstellers:", err)
        })
    },
    init() {
      this.selectedFolder = null
      this.fetchFolders()
      this.fetchDocuments("inbox")
      this.fetchInvoiceIssuers()
    },
  },
  created() {
    this.init()
  },
}
</script>
