<template>
  <div class="oaff" style="text-align: center">
    <v-img src="/img/einmalig/oaff.jpeg"></v-img>
    <v-card-title> Ab Montag, 27. Juni können ab 12 Uhr unter diesem Link 50 early bird tickets gebucht werden. </v-card-title>

    <vue-countdown :time="getAngebotCD()" v-slot="{ days, hours, minutes, seconds }" class="countdown cd mb-2">
      <div class="tage">
        <span class="up">
          {{ days }}
        </span>
        <span class="down">TAGE</span>
      </div>
      <div class="stunden">
        <span class="up">
          {{ hours }}
        </span>
        <span class="down">STUNDEN</span>
      </div>
      <div class="minuten">
        <span class="up">
          {{ minutes }}
        </span>
        <span class="down">MINUTEN</span>
      </div>
      <div class="sekunden">
        <span class="up">
          {{ seconds }}
        </span>
        <span class="down">SEKUNDEN</span>
      </div>
    </vue-countdown>

    <p v-if="getAngebotCD() < 1"><number :delay="1" :duration="2" class="h4 mt-3" :from="50" :to="counter"></number><span> verfügbar</span></p>

    <v-btn v-if="getAngebotCD() > 1" x-large disabled color="#feef00" class="font-weight-bold my-5"> Ticket reservieren </v-btn>
    <v-btn v-else x-large :disabled="hasReserved || counter <= 0" color="#feef00" class="font-weight-bold my-5" @click="reserve()">
      {{ hasReserved ? "reserviert" : "Ticket reservieren" }}
    </v-btn>

    <v-card-text class="mb-5" v-if="hasReserved"
      >Vielen Dank für Deine Reservierung. Du wirst umgehend einen Code per Mail zugestellt bekommen, mit dem Du auf der Seite:
      <a>http://www.ticketmaster.ch/event/9507?CL_ORIGIN=ORIGIN8 </a> unter "early bird friends" ein Ticket erwerben kannst.</v-card-text
    >
    <!-- <v-img></v-img> -->
  </div>
</template>

<script>
import api from "../../../api"
import VueCountdown from "@chenfengyuan/vue-countdown"

export default {
  components: { VueCountdown },
  data() {
    return {
      hasReserved: false,
      listName: "openair-frauenfeld",
      counter: 50,
    }
  },
  async created() {
    let allEntries = await api.downloadListCategoryPublic(this.listName)
    allEntries.forEach((entry) => {
      if (entry.createdBy) this.hasReserved = true
      this.counter = this.counter - 1
    })
    console.log(this.hasReserved, allEntries)
  },
  methods: {
    async reserve() {
      let newListEntry = {
        category: this.listName,
        content: {
          reserved: true,
          payed: false,
        },
      }
      let response = await api.createListEntry(newListEntry)
      if (response && response.content) {
        this.hasReserved = response.content.reserved
        if (this.hasReserved) this.counter = this.counter - 1
      }
    },
    getAngebotCD() {
      let startDate = this.$moment("2022-06-27 12:00:00")

      let diff = startDate.diff(this.$moment())
      console.log(diff)
      return diff
    },
  },
}
</script>

<style lang="scss" scoped>
.countdown {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #feef00;
  color: black;
  font-weight: bold;
  text-align: center;
  padding-top: 6px;
  justify-content: space-evenly;
  padding-bottom: 5px;

  &.cd {
    margin-top: auto;
    padding-top: 10px;
  }
  > div {
    margin: 5px 10px;
  }
  span.up {
    font-size: 25px;
    line-height: 20px;
    display: block;
  }
  span.down {
    font-size: 12px;
  }
}
.oaff {
}
</style>
