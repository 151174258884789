import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  getWorkoutFeedback() {
    let url = buildUrl("workout/feedback")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getWorkoutFeedbackLatest() {
    let url = buildUrl("workout/feedback/latest")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Exercises

  fetchExerciseTags() {
    let url = buildUrl("exercise/tags")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  fixTags() {
    let url = buildUrl("/tags/fix/per/studio")
    return axios.get(url, buildOptions()).then((res) => console.log(res.data))
  },

  copyTrainingPlan(plan_id) {
    let url = buildUrl(`plan/copy/${plan_id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  updateTrainingsPlan(data) {
    let url = buildUrl("plan")

    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },

  updateTrainingsPlanSets(data) {
    let url = buildUrl("plan/update/sets")

    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },

  // Deprecated?

  gewichtshistory(id) {
    let url = buildUrl(`trainingsplan/gewichtshistory/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  lookupWorkouts(search, signal = null) {
    let url = buildUrl(`trainingsplanstudio`)

    return axios.post(url, { search }, buildOptions({}, signal)).then((res) => res.data)
  },
  importGeraet(data) {
    let url = buildUrl("geraete/import")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  checkGeraetbeforeImport(data) {
    let url = buildUrl("geraete/check/import")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  saveGeraet(data) {
    let url = buildUrl("geraete/save")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  getGeraetebyId(id) {
    let url = buildUrl(`geraete/get/id/${id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getGeraetebyStudioId(studio_id) {
    let url = buildUrl(`geraete/get/${studio_id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getUebungbyStudioId(id) {
    let url = buildUrl(`uebungen/get/${id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  renderUebungen(stud_id) {
    let url = buildUrl(`uebungen/${stud_id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  editTraining(trainingsplan) {
    let url = buildUrl("trainingsplan/edit")
    return axios.post(url, trainingsplan, buildOptions()).then((res) => res.data)
  },
  createTraining(trainingsplan) {
    let url = buildUrl(`trainingsplan/create`)

    return axios.post(url, trainingsplan, buildOptions()).then((res) => res.data)
  },
  fetchStudioWorkouts(signal = null) {
    let url = buildUrl(`trainingsplanstudio`)

    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
  loadTrainerWorkouts(signal = null) {
    let url = buildUrl(`workouts/studio`)

    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
  loadWorkouts() {
    let url = buildUrl(`trainingsplan/user`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getExercisesForStudio() {
    let url = buildUrl("exercises")

    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getInformationForExercise(id) {
    let url = buildUrl(`exercises/studio/${id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  createTrainingsPlan(data) {
    let url = buildUrl("plan")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  loadPlanExercisesforEdit(plan_id) {
    let url = buildUrl(`trainingsplan/edit/load/exercises/${plan_id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  loadPlanforEdit(plan_id) {
    let url = buildUrl(`trainingsplan/edit/load/plan/${plan_id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  loadSignupsByUser() {
    let url = buildUrl(`kurse/user/signups`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  loadLSUserSignups(timeline_id) {
    let url = buildUrl(`kurse/timelines/generate/get/lssignups/${timeline_id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  loadSignups(timeline_id) {
    let url = buildUrl(`kurse/timelines/generate/get/signups/${timeline_id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  kursTeilgenommen(data) {
    let url = buildUrl(`kurse/signup/teilgenommen`)

    return axios
      .post(
        url,
        {
          ben_id: data.anmeldung.ben_id,
          course_generate_id: data.anmeldung.course_generate_id,
          teilgenommen: data.anmeldung.teilgenommen,
        },
        buildOptions()
      )
      .then((res) => res.data)
  },
  editGeneratedTimeline(data) {
    let url = buildUrl(`kurse/timelines/generate/edit`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
    plan
  },

  // Trainingsplan Routen
  getWorkoutsForStatistics() {
    let url = buildUrl(`workouts/statistics`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  assingWorkout(data) {
    let url = buildUrl(`trainingsplan/assign`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  deleteWorkout(data) {
    let url = buildUrl(`trainingsplan/delete`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  deleteWorkoutById(data) {
    let url = buildUrl(`workout/delete`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  createWorkoutSet(data) {
    let url = buildUrl(`trainingsplan/sets`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  createWorkoutSetOld(data) {
    let url = buildUrl(`trainingsplan/sets/old`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  getWorkoutHistory(plan_id, exercise_id) {
    let url = buildUrl(`trainingsplan/sets/${plan_id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getWorkoutSet(plan_id, exercise_id) {
    let url = buildUrl(`trainingsplan/sets/${plan_id}/${exercise_id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getWorkoutExecutions(plan_id, exercise_id) {
    let url = buildUrl(`workout/executions/${plan_id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  workingOut(workoutid) {
    let url = buildUrl(`trainingsplan/workout/${workoutid}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getWorkoutNew(workoutid) {
    let url = buildUrl(`trainingsplan/workoutneu/${workoutid}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getTrainingPlan(workout_id) {
    let url = buildUrl(`workout/start/${workout_id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getWorkout(workout_id) {
    let url = buildUrl(`plan/run/${workout_id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getOldPlans(signal = null) {
    let url = buildUrl(`plan`)

    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
  getTrainingPlans(signal = null) {
    let url = buildUrl(`workouts`)

    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
  editWorkoutSettings(settings) {
    let url = buildUrl(`trainingsplan/edit/settings`)

    return axios.post(url, settings, buildOptions()).then((res) => res.data)
  },
  finishWorkout(data) {
    let url = buildUrl(`trainingsplan/done`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  getStudioCompanies(signal = null) {
    let url = buildUrl(`studios/fetch/company`)

    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
}
