<template>
  <v-card dark class="mx-auto" color="transparent" elevation="0">
    <v-row>
      <v-col class="mt-5" cols="12">
        <v-alert type="info">Membercards ab dem 09.10.2024</v-alert>
      </v-col>
      <v-col cols="12">
        <v-select v-model="durationSelect" label="Zeitraum" outlined hide-details :items="durationItems"></v-select>
      </v-col>

      <!-- CSV Button -->
      <v-col cols="12" class="d-flex justify-space-between mt-3">
        <v-btn outlined @click="getStats(true)">Seit Anfang Jahr</v-btn>
        <v-btn text @click="downloadCSV">CSV Herunterladen</v-btn>
      </v-col>

      <!-- Info-Kacheln -->
      <v-col cols="12" class="mt-3">
        <v-row>
          <v-col cols="6">
            <v-card class="pa-3" outlined>
              <div>Gesamtabschlussquote:</div>
              <div v-if="!loading" class="text-h6">
                <strong>{{ globalQuote }}</strong>
              </div>
              <div v-else>
                <v-progress-circular indeterminate color="white"></v-progress-circular>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card class="pa-3" outlined>
              <div>Bestes Studio:</div>
              <div v-if="!loading" class="text-h6">
                <strong>{{ bestStudio }} ({{ bestActivated }} | {{ bestRate }}%)</strong>
              </div>
              <div v-else>
                <v-progress-circular indeterminate color="white"></v-progress-circular>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- Tabelle -->
      <v-col cols="12" class="mt-5">
        <v-data-table
          :loading="loading"
          :items-per-page="-1"
          :headers="statsHeaders"
          :items="loading ? [] : stats"
          :custom-sort="customSort"
          class="elevation-1">
          <!-- Tooltip in der Spalte 'accepted' -->
          <template v-slot:item.accepted="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ item.accepted }}</span>
              </template>
              <div v-for="(count, studio) in item.acceptedPerStudio" :key="studio">
                <span>{{ studio }}: {{ count }}</span
                ><br />
              </div>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import api from "@/api"

export default {
  data() {
    return {
      stats: [],
      loading: false,
      statsHeaders: [
        { text: "Trainer", value: "trainer" },
        { text: "Studio", value: "studio" },
        { align: "center", text: "Anz. 11 Tage Aktiviert", value: "activated" },
        { align: "center", text: "Abgeschlossen", value: "accepted" },
        { align: "center", text: "Kein Abschluss", value: "declined" },
        { align: "center", text: "Quote Mitarbeiter", value: "quote" },
        { align: "center", text: "Quote Studio", value: "studioQuote" },
      ],
      durationSelect: "letzter Monat",
      durationItems: [
        "letzter Monat",
        "letzten 2 Monate",
        "letzten 3 Monate",
        "letzten 4 Monate",
        "letzten 5 Monate",
        "letzten 6 Monate",
        "letzten 7 Monate",
        "letzten 8 Monate",
        "letzten 9 Monate",
        "letzten 10 Monate",
        "letzten 11 Monate",
        "letzten 12 Monate",
      ],
      globalQuote: "",
      bestActivated: "",
      bestStudio: "",
      bestRate: "",
    }
  },
  created() {
    this.getStats()
  },
  methods: {
    // Numerischer Sortiermechanismus für Prozentwerte
    customSort(items, sortBy, sortDesc) {
      if (!sortBy.length) return items
      const sortKey = sortBy[0]
      const descending = sortDesc[0] || false
      return items.sort((a, b) => {
        let aValue = a[sortKey]
        let bValue = b[sortKey]
        if (sortKey === "quote" || sortKey === "studioQuote") {
          aValue = parseFloat(aValue.replace("%", "")) || 0
          bValue = parseFloat(bValue.replace("%", "")) || 0
        }
        if (aValue < bValue) return descending ? 1 : -1
        if (aValue > bValue) return descending ? -1 : 1
        return 0
      })
    },
    async getStats(startOfYear = false) {
      let amount = (this.durationItems.indexOf(this.durationSelect) + 1) * 30
      if (startOfYear) {
        // Beispiel: Berechnung der Tage vom 01.01.2025 bis heute
        const startDate = this.$moment().startOf("year")
        const today = this.$moment()
        amount = today.diff(startDate, "days")
      }
      try {
        this.loading = true
        const data = await api.fetchMembercardStatisticAdmin(amount)
        // Direktes Setzen der vom Backend gelieferten Werte
        this.stats = data.stats
        this.globalQuote = data.globalQuote
        this.bestStudio = data.bestStudio
        this.bestActivated = data.bestActivated
        this.bestRate = data.bestRate
      } catch (e) {
        this.$toast.error("Fehler beim Laden der Statistik")
      } finally {
        this.loading = false
      }
    },
    downloadCSV() {
      // Gruppiere die Statistik nach Studio (eine Zeile pro Studio)
      const grouped = {}
      this.stats.forEach((item) => {
        const studio = item.studio
        if (!grouped[studio]) {
          grouped[studio] = { activated: 0, accepted: 0, declined: 0 }
        }
        grouped[studio].activated += item.activated
        grouped[studio].accepted += item.accepted
        grouped[studio].declined += item.declined
      })

      // CSV Header definieren
      let csvContent = "Studio,Activated,Accepted,Declined,Studio Quote\n"
      // Zeilen pro Studio erstellen
      for (const studio in grouped) {
        const data = grouped[studio]
        const quote = data.activated > 0 ? ((data.accepted / data.activated) * 100).toFixed(2) + "%" : "0%"
        csvContent += `${studio},${data.activated},${data.accepted},${data.declined},${quote}\n`
      }

      // Blob erstellen und Download triggern
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
      const url = URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.setAttribute("href", url)
      link.setAttribute("download", "membercard_stats.csv")
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
  watch: {
    durationSelect() {
      this.getStats()
    },
  },
}
</script>

<style lang="scss" scoped></style>
