<template>
  <v-row no-gutters>
    <v-progress-circular v-if="!user" :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    <v-btn v-show="false" @click="resetPW(user)">Reset PW</v-btn>
    <v-col :cols="isMobile ? 12 : 6" v-if="user">
      <v-row no-gutters>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-text-field hide-details @change="saveEditing()" filled rounded label="Vorname" type="text" v-model="user.vorname"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-text-field @change="saveEditing()" hide-details filled rounded label="Nachname" type="text" v-model="user.nachname"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-text-field @change="saveEditing()" hide-details filled rounded label="Geburtstag" type="text" v-model="user.geburtstag"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-text-field hide-details @change="saveEditing()" rounded filled label="E-Mail" type="email" v-model="user.email"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-text-field hide-details filled rounded @change="saveEditing()" label="Telefon" type="tel" v-model="user.telefon"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-text-field hide-details filled rounded label="Adresse" @change="saveEditing()" type="text" v-model="user.adresse_1"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-text-field rounded hide-details filled @change="saveEditing()" label="PLZ" type="number" v-model="user.plz"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-text-field hide-details @change="saveEditing()" filled rounded label="Stadt" type="text" v-model="user.stadt"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2" v-if="user.tendays_date && isAdmin">
          <v-text-field
            hide-details
            @change="saveEditing()"
            filled
            rounded
            label="10 Tage Startdatum"
            type="number"
            v-model="user.tendays_date"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-autocomplete
            hide-details
            filled
            @change="saveEditing()"
            rounded
            :key="'search' + user._id"
            v-show="userService.hasRight('g_manageRights')"
            v-model="selected.groups"
            :items="roles"
            no-data-text="Es sind keine Gruppenrechte verfügbar"
            label="Gruppenrecht zuweisen"
            chips
            clearable
            deletable-chips
            multiple
            item-value="_id"
            item-text="title"
            small-chips></v-autocomplete>
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-autocomplete
            hide-details
            filled
            rounded
            :key="user._id"
            @change="saveEditing()"
            v-show="userService.hasRight('g_manageRights')"
            v-model="selected.specialRights"
            :items="specialRightsAutocomplete"
            label="Rechte einzeln zuweisen"
            no-data-text="Dir sind bereits alle verfügbaren Rechte zugewiesen worden."
            chips
            clearable
            deletable-chips
            multiple
            item-value="_id"
            :item-text="getSpecialRightItemText"
            small-chips></v-autocomplete>
        </v-col>

        <v-col :cols="isMobile ? 12 : 6" class="mb-1 pa-2">
          <v-text-field
            label="Anzahl Kinderhort"
            filled
            @change="saveEditing()"
            rounded
            hide-details
            class="mt-5"
            type="number"
            v-model="user.daycareAmount"></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="!isMobile" cols="6">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-avatar color="primary" size="150">
            <img
              style="object-fit: cover"
              :src="user.bild ? '/img/profilbilder' + user.bild : 'https://app.wellcomefit.ch/api/checkin/pool/images/' + user._id" />
          </v-avatar>
        </v-col>
        <v-col class="d-flex justify-center mx-auto flex-column">
          <v-date-picker locale="de-CH" color="#303030" no-title :value="$moment().format('YYYY-MM-DD')" :events="getEvents"></v-date-picker>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import api from "../../../api"
import userService from "../../../services/userService"

export default {
  props: {
    user: {
      required: true,
    },
    isMobile: {
      required: true,
      default: false,
    },
  },
  data() {
    return {
      userService,
      search: "",
      showRegisterDialog: false,
      userSearchValue: null,
      users: {},
      roles: [],
      specialRights: [],
      selected: {
        groups: [],
        specialRights: [],
      },
    }
  },
  created() {
    if (!this.userService.hasRight("g_manageRights", "u_edit", "u_search")) {
      alert("Sie haben nicht genügend Rechte, um diesen Benutzer zu bearbeiten..")
    } else {
      api.fetchAllRoles().then((data) => {
        this.roles = data
        api.fetchAllRights().then((allRights) => {
          this.specialRights = allRights
          api.fetchRolesForUser(this.user._id).then((userRoles) => {
            this.roles.forEach((right) => {
              if (userRoles.specialRights.length > 0) {
                userRoles.specialRights.forEach((specialRight) => {
                  this.selected.specialRights.indexOf(specialRight._id) === -1 && this.selected.specialRights.push(specialRight._id)
                })
              }
              userRoles.userRoles.forEach((role) => {
                if (right._id === role._id) this.selected.groups.push(right._id)
              })
            })
          })
        })
      })
    }
  },
  methods: {
    resetPW(user) {
      api.resetPWAdmin(user.email)
    },
    getSpecialRightItemText(right) {
      return right.title + " (" + right.topic + ")"
    },

    saveEditing() {
      this.user.userRoles = this.selected.groups
      this.user.specialRights = this.selected.specialRights
      console.log(this.user)
      api.editRolle(this.user).catch((error) => {
        console.log(error)
      })
      this.$toast.success("Benutzer erfolgreich bearbeitet")
    },
  },
  computed: {
    getEvents() {
      if (this.user && this.user.attendance) {
        return Object.keys(this.user.attendance)
      }
    },
    isLocal() {
      return location.host.includes("local")
    },

    isLocal() {
      return location.host.includes("local")
    },

    isAdmin: function () {
      return localStorage.getItem("admin")
    },
    specialRightsAutocomplete: function () {
      let filteredSpecialRights = []
      let ignoreRights = []

      let allActiveGroups = this.roles.filter((group) => this.selected.groups.includes(group._id))

      if (allActiveGroups.length) {
        allActiveGroups.forEach((group) => {
          group.rights.forEach((right) => {
            let existingRight = filteredSpecialRights.find((x) => x._id === right._id)
            if (existingRight) {
              if (!existingRight.active && right.active) {
                filteredSpecialRights = filteredSpecialRights.filter((filteredRight) => filteredRight._id !== right._id)
              }
            } else if (!right.active && !ignoreRights.includes(right._id)) {
              filteredSpecialRights.push(right)
            }

            if (right.active) {
              ignoreRights.push(right._id)
            }
          })
        })
        return filteredSpecialRights
      } else {
        this.roles.forEach((group) => {
          group.rights.forEach((right) => {
            let existingRight = filteredSpecialRights.find((x) => x._id === right._id)
            if (!existingRight) {
              filteredSpecialRights.push(right)
            }
          })
        })
        return filteredSpecialRights
      }
    },
  },
}
</script>
