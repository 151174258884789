<template>
  <v-dialog persistent max-width="700px" :value="visible" @click:outside="$emit('closedDialog')">
    <component :is="componentByType" :existingValue="existingValue" @input="$emit('input', $event)"></component>
  </v-dialog>
</template>

<script>
import api from "@/api"
import shiftDialog from "./shiftDialog.vue"
import schoolHolidaysDialog from "./schoolHolidaysDialog.vue"

export default {
  components: {
    shiftDialog,
    schoolHolidaysDialog,
  },
  name: "ShiftManagementSettings",
  props: {
    visible: Boolean,
    type: String,
    existingValue: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      components: {
        shifts: "shiftDialog",
        schoolHolidays: "schoolHolidaysDialog",
      },
      shifts: [],
    }
  },
  computed: {
    componentByType() {
      return this.components[this.type]
    },
  },
}
</script>

<style scoped></style>
