import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchAccountTransactionsForUser() {
    let url = "https://app.wellcomefit.ch/api/tac/api/fetch/payments/for/user"
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAccountTransactionsForUserTESTING() {
    let url = "https://app.wellcomefit.ch/api/tac/api/fetch/payments/for/test"
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACPaymentsForEmail(email) {
    let url = "https://app.wellcomefit.ch/api/tac/api/fetch/payments/for/admin/" + email
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchUserTACContractDetails() {
    let url = buildUrl("dashboard/contract/tac/user/self")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACContractDetails(userId) {
    let url = "https://app.wellcomefit.ch/api/dashboard/contract/tac/user/fetch/" + userId
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAccessDevices() {
    let url = buildUrl("internal/tac/accessdevices")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAccountTransactionsForID(accountID, from = "test", to = "testii") {
    let url = "https://app.wellcomefit.ch/api/tac/api/account/transactions/" + accountID + "/" + from + "/" + to
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAccountTransactionsForIDTESTING(accountID, from = "test", to = "testii") {
    let url = "https://app.wellcomefit.ch/api/tactest/api/account/transactions/" + accountID + "/" + from + "/" + to
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAccountTransactionsForIDAdminTESTING(accountID, from = "test", to = "testii") {
    let url = "https://app.wellcomefit.ch/api/tactestadmin/api/account/transactions/" + accountID + "/" + from + "/" + to
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACMemberGroupByEmail(email) {
    let url = "https://app.wellcomefit.ch/api/tac/api/membergroup/" + email
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateTACUserProfile(data) {
    let url = "https://app.wellcomefit.ch/api/tac/api/profile"
    return axios.post(url, { data }, buildOptions()).then((res) => res.data)
  },
  createTACContract(contract) {
    let url = "https://app.wellcomefit.ch/api/tac/api/contract/create"
    return axios.post(url, { contract }, buildOptions()).then((res) => res.data)
  },
  fetchCheckinsPerTAC(data) {
    let url = "https://app.wellcomefit.ch/api/tac/api/fetch/checkin/statistic"
    return axios.post(url, { data }, buildOptions()).then((res) => res.data)
  },
  getTACReasons(id, reason = "IDLEPERIOD") {
    let url = "https://app.wellcomefit.ch/api/tac/api/reasons/for/resort/" + id + "/" + reason
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveTACMembercategoriesSeperate(data) {
    let url = buildUrl("tac/api/membercategoryseperate")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  saveTACMembercategories(data) {
    let url = buildUrl("tac/api/membercategory")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchTACProfileByTACId(userId) {
    let url = "https://app.wellcomefit.ch/api/tac/api/profile/id/" + userId
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACMembercategories() {
    let url = "https://app.wellcomefit.ch/api/tac/api/membercategory"
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACMemberDetailsByTACId(tacId) {
    let url = "https://app.wellcomefit.ch/api/tac/api/membership/id/" + tacId
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchCurrentTACMemberDetailsByTACId(tacId) {
    let url = "https://app.wellcomefit.ch/api/tac/api/membership/current/id/" + tacId
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACAllMembershipsByEmail(email) {
    let url = "https://app.wellcomefit.ch/api/tac/api/membership/all/by/mail/" + email
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACAllMemberships() {
    let url = "https://app.wellcomefit.ch/api/tac/api/membership/all/by/for/customer"
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACProfileByEmail(email) {
    let url = "https://app.wellcomefit.ch/api/tac/api/profile/email/" + email
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  searchTACProfiles(data) {
    let url = "https://app.wellcomefit.ch/api/tac/api/profile/search"
    return axios.post(url, { searchValues: data }, buildOptions()).then((res) => res.data)
  },
  fetchTACMembershipByEmail(email) {
    let url = "https://app.wellcomefit.ch/api/tac/api/membership/" + email
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  fetchTACMemberHasExistingContractByEmail() {
    let url = "https://app.wellcomefit.ch/api/tac/api/has/contract/membership"
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACMemberOwnContract() {
    let url = "https://app.wellcomefit.ch/api/tac/api/own/membership"
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACMembershipByEmailTESTING(email) {
    let url = "https://app.wellcomefit.ch/api/tactest/api/membership/" + email
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACMembershipByEmailAdminTESTING(email) {
    let url = "https://app.wellcomefit.ch/api/tactestadmin/api/membership/" + email
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTACContractMapInfos(contract) {
    let url = buildUrl("tac/api/contract/map/infos")
    return axios.post(url, contract, buildOptions()).then((res) => res.data)
  },
  createCacheUpdateEntriesPackages(cache) {
    let url = buildUrl("tac/api/contract/map/cache/packages")
    return axios.post(url, cache, buildOptions()).then((res) => res.data)
  },
  createCacheUpdateEntryMembership(cache) {
    let url = buildUrl("tac/api/contract/map/cache/membership")
    return axios.post(url, cache, buildOptions()).then((res) => res.data)
  },
  testTacApi(data) {
    let url = "https://app.wellcomefit.ch/api/tac/api/"
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
}
