<template>
  <v-card dark>
    <v-card-title class="d-flex justify-space-between">
      <span>{{ folderName }}</span>
      <v-text-field
        v-model="localSearch"
        placeholder="Dokumente suchen"
        dense
        hide-details
        solo
        @keyup.enter="$emit('search-documents')"></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :loading="loading"
        :items-per-page="20"
        :disable-sort="!$vuetify.breakpoint.mdAndUp"
        :headers="headers"
        :items="documents"
        item-key="_id">
        <template v-slot:item="{ item }">
          <tr
            :style="item.userIsAssigned && item.approvalStatus !== 'signed' ? 'background-color: rgb(52, 53, 65, 0.6);' : ''"
            @click.stop="$emit('open-document-actions', item)"
            style="min-width: 500px"
            draggable="true"
            @dragstart="onDragStart($event, item)">
            <td>
              <span :style="'color:' + getColorByDate(item)">{{ formatDate(item.date) }}</span>
            </td>
            <td>
              <v-icon :color="getIconColor(item)">
                {{ iconByStatus(item.approvalStatus) }}
              </v-icon>
            </td>
            <td>{{ item.title || "Kein Titel" }}</td>
            <td>{{ item.invoiceIssuer ? item.invoiceIssuer.title : "Kein Rechnungssteller" }}</td>
            <td>
              <v-btn icon @click.stop="$emit('open-document-actions', item)">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DocumentList",
  props: {
    documents: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    folderName: {
      type: String,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localSearch: this.searchQuery,
    }
  },
  watch: {
    searchQuery(newVal) {
      this.localSearch = newVal
    },
    localSearch(newVal) {
      this.$emit("update:searchQuery", newVal)
    },
  },
  methods: {
    iconByStatus(status) {
      if (status === "signed") return "mdi-check-circle"
      if (status === "declined") return "mdi-close-circle"
      return "mdi-help-circle"
    },
    getColorByDate(item) {
      let status = item.approvalStatus
      if (status === "signed") return "white"
      if (status === "declined") return "white"
      const diffDays = this.$moment().diff(this.$moment(item.date), "days")
      if (diffDays > 6) {
        return "#ff9e9e"
      } else if (diffDays > 3) {
        return "#ffd17c"
      } else {
        return "white"
      }
    },
    getIconColor(item) {
      // Wenn das Dokument visiert (signed) ist, soll das Icon grün sein
      let status = item.approvalStatus
      if (status === "signed") return "green"
      if (status === "declined") return "red"
      return "orange"
    },
    onDragStart(e, item) {
      e.dataTransfer.setData("documentId", item._id)
      this.$emit("document-drag-start", e, item)
    },
    formatDate(date) {
      return this.$moment(date).format("DD.MM.YY ")
    },
  },
}
</script>
