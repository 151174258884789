<template>
  <v-card>
    <v-card-title class="primary white--text">
      {{ shift._id ? "Schicht bearbeiten" : "Schicht erstellen" }}
    </v-card-title>

    <v-card-text>
      <v-container>
        <!-- Grunddaten der Schicht -->
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="shift.name" label="Titel" required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="shift.shortName" label="Kurzbezeichnung" required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-color-picker
              v-model="shift.color"
              label="Farbe wählen"
              hide-canvas
              hide-inputs
              hide-mode-switch
              hide-sliders
              show-swatches></v-color-picker>
          </v-col>
        </v-row>

        <!-- Zeiteinträge -->
        <v-row v-for="(time, index) in shift.times" :key="index" align="center">
          <v-col cols="4">
            <v-menu v-model="time.startMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field hide-details v-model="time.start" label="Startzeit" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-time-picker format="24hr" v-model="time.start" @input="time.startMenu = false"></v-time-picker>
            </v-menu>
          </v-col>

          <v-col cols="4">
            <v-menu v-model="time.endMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field hide-details v-model="time.end" label="Endzeit" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-time-picker format="24hr" v-model="time.end" @input="time.endMenu = false"></v-time-picker>
            </v-menu>
          </v-col>

          <v-col cols="3">
            <v-select hide-details v-model="time.type" :items="['Arbeitszeit', 'Pause']" label="Typ"></v-select>
          </v-col>

          <v-col cols="1" class="text-right">
            <v-btn icon @click="removeTimeSlot(index)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- Anzeige der Gesamtzeiten -->
        <v-row class="mt-4">
          <v-col cols="6">
            <div><strong>Arbeitszeit:</strong> {{ formatTime(totalArbeitszeit) }}</div>
          </v-col>
          <v-col cols="6">
            <div><strong>Pausenzeit:</strong> {{ formatTime(totalPausenzeit) }}</div>
          </v-col>
        </v-row>

        <v-card-actions class="justify-content-center">
          <v-btn color="primary" @click="addTimeSlot">+ Zeit hinzufügen</v-btn>
        </v-card-actions>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="saveShift">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "shiftDialog",
  props: {
    existingValue: {
      type: Object,
      default: () => ({
        _id: null,
      }),
    },
  },
  data() {
    return {
      shift: {
        name: "",
        shortName: "",
        times: [],
        color: "",
      },
    }
  },
  created() {
    if (this.existingValue._id) {
      this.shift = this.$clone(this.existingValue)
    }
  },
  computed: {
    // Berechnet die Gesamtarbeitszeit in Minuten
    totalArbeitszeit() {
      let total = 0
      this.shift.times.forEach((slot) => {
        if (slot.type === "Arbeitszeit" && slot.start && slot.end) {
          total += this.calculateDuration(slot.start, slot.end)
        }
      })
      return total
    },
    // Berechnet die Gesamtpausenzeit in Minuten
    totalPausenzeit() {
      let total = 0
      this.shift.times.forEach((slot) => {
        if (slot.type === "Pause" && slot.start && slot.end) {
          total += this.calculateDuration(slot.start, slot.end)
        }
      })
      return total
    },
  },
  methods: {
    addTimeSlot() {
      this.shift.times.push({
        start: "",
        end: "",
        type: "Arbeitszeit",
        startMenu: false,
        endMenu: false,
      })
    },
    removeTimeSlot(index) {
      this.shift.times.splice(index, 1)
    },
    // Berechnet die Dauer eines Zeiteintrags in Minuten (Annahme: Format "HH:mm")
    calculateDuration(start, end) {
      const [startHour, startMinute] = start.split(":").map(Number)
      const [endHour, endMinute] = end.split(":").map(Number)
      const startTotal = startHour * 60 + startMinute
      const endTotal = endHour * 60 + endMinute
      let diff = endTotal - startTotal
      // Falls die Endzeit vor der Startzeit liegt (z. B. bei Über Mitternacht), wird 24h addiert
      if (diff < 0) {
        diff += 24 * 60
      }
      return diff
    },
    // Formatiert Minuten in "Xh Ym"
    formatTime(minutes) {
      const hrs = Math.floor(minutes / 60)
      const mins = minutes % 60
      return `${hrs}h ${mins}m`
    },
    async saveShift() {
      this.$emit("input", this.shift)
    },
  },
}
</script>

<style scoped></style>
