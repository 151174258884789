import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export default {
  downloadA4PDFGF(id) {
    let url = buildUrl("gf/post/pdf/" + id)
    axios
      .get(
        url, //your url
        {
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          }, // important
        }
      )
      .then((response) => {
        if (window.flutter_inappwebview) {
          console.log("FLUTTER IOS DOWNLOAD PDF START")
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]

            window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "pdf", "GroupFitness")
          }
        } else {
          saveAs(response.data, "GroupFitness")
        }
      })
  },
  fetchActiveCourseIds() {
    let url = buildUrl("gffetchactivecourses")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  //Recommendations
  updateGFRecommendations(userId, recommendations) {
    let url = buildUrl("gfrecommendations/update")
    return axios.post(url, { userId, recommendations }, buildOptions()).then((res) => res.data)
  },
  fetchGFRecommendations(userId) {
    let url = buildUrl("gfrecommendations")
    return axios.post(url, { userId }, buildOptions()).then((res) => res.data)
  },
  fetchGFRecommendationsSelf() {
    let url = buildUrl("gfrecommendations")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Claim
  claimCourseExecutionHours(id) {
    let url = buildUrl("gf/courses/claim/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  claimCourseExecutionHoursForInstructor(courseId, instructorId) {
    let url = buildUrl("gf/courses/claimfor/" + courseId + "/" + instructorId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getGFInstructorListExcel() {
    let url = buildUrl("gfdownloadinstructorlist")
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then((res) => res.data)
  },

  async downloadGFPivotExcel(datePickerRange) {
    return new Promise(async (resolve, reject) => {
      let url = buildUrl("tools/gf/pivot")
      try {
        const response = await axios({
          url,
          method: "POST",
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          data: {
            datePickerRange,
            // Other data properties if needed
          },
        })

        if (window.flutter_inappwebview && localStorage.getItem("isIOS")) {
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]
            resolve(base64.toString())
          }
        } else {
          saveAs(response.data, "GF_Pivot")
          resolve() // Resolve without a value since the download is not async
        }
      } catch (error) {
        console.log(error, "FEHLER")
        reject(error)
      }
    })
  },

  async downloadGFStatisticsExcel(datePickerRange) {
    return new Promise(async (resolve, reject) => {
      let url = buildUrl("tools/gf/statistik")
      try {
        const response = await axios({
          url,
          method: "POST",
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          data: {
            datePickerRange,
            // Other data properties if needed
          },
        })

        if (window.flutter_inappwebview && localStorage.getItem("isIOS")) {
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]
            resolve(base64.toString())
          }
        } else {
          saveAs(response.data, "GF_Statistik")
          resolve() // Resolve without a value since the download is not async
        }
      } catch (error) {
        console.log(error, "FEHLER")
        reject(error)
      }
    })
  },

  downloadWeeklyExcel(studioCode) {
    let url = buildUrl("ex/weeklyexcel/" + studioCode)
    axios({
      url, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      if (window.flutter_inappwebview && localStorage.getItem("isIOS")) {
        var reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onloadend = function () {
          var dataUrl = reader.result
          var base64 = dataUrl.split(",")[1]
          console.log(base64)
          window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "xlsx", "Wochenansicht_" + studioCode)
        }
      } else {
        saveAs(response.data, "Wochenansicht_" + studioCode)
      }
    })
  },

  fetchGFWeeklyPDF(id) {
    let url = buildUrl("ex/weeklypdf/" + id)
    axios({
      url, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      if (window.flutter_inappwebview && localStorage.getItem("isIOS")) {
        console.log("FLUTTER IOS DOWNLOAD PDF START")
        var reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onloadend = function () {
          var dataUrl = reader.result
          var base64 = dataUrl.split(",")[1]
          console.log(base64)
          window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "pdf", "Wochenansicht")
        }
      } else {
        saveAs(response.data, "Wochenansicht")
      }
    })
  },

  fetchPlanPDF(plan) {
    let url = buildUrl("plan/pdf/" + plan._id)

    axios({
      url, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      if (window.flutter_inappwebview) {
        console.log("FLUTTER IOS DOWNLOAD PDF START")
        var reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onloadend = function () {
          var dataUrl = reader.result
          var base64 = dataUrl.split(",")[1]
          console.log(base64)
          window.flutter_inappwebview.callHandler(
            "blobToBase64Handler",
            base64.toString(),
            "pdf",
            plan.form.title.replaceAll(" ", "_").replaceAll(".", "_")
          )
        }
      } else {
        saveAs(response.data, plan.form.title.replaceAll(" ", "_").replaceAll(".", "_"))
      }
    })
  },

  fetchWeeklyCourseList() {
    let url = buildUrl("ex/gf")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchExCourseList(code) {
    let url = buildUrl("ex/gf/" + code)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  deleteGFRoom(id) {
    let url = buildUrl("gf/rooms/" + id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  updateGFRoom(obj) {
    let url = buildUrl("gf/rooms")
    return axios.put(url, obj, buildOptions()).then((res) => res.data)
  },
  createGFRoom(obj) {
    let url = buildUrl("gf/rooms")
    return axios.post(url, obj, buildOptions()).then((res) => res.data)
  },
  fetchGFRooms() {
    let url = buildUrl("gf/rooms")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  deleteGFLabel(id) {
    let url = buildUrl("gf/labels/" + id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  createGFLabel(obj) {
    let url = buildUrl("gf/labels")
    return axios.post(url, obj, buildOptions()).then((res) => res.data)
  },
  updateGFLabel(obj) {
    let url = buildUrl("gf/labels")
    return axios.put(url, obj, buildOptions()).then((res) => res.data)
  },
  fetchGFLabels() {
    let url = buildUrl("gf/labels")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  manageSignupVisited(id, status) {
    let url = buildUrl("gf/courses/visited/" + id + "/" + status)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateGFCourseSettings(settings) {
    let url = buildUrl("gf/courses/settings")
    return axios.post(url, { settings }, buildOptions()).then((res) => res.data)
  },
  fetchGFCourseSettings() {
    let url = buildUrl("gf/courses/settings")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  checkGFCourseUsage(id) {
    let url = buildUrl("gf/courses/usage/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  uploadGFCourseImage(data) {
    let url = buildUrl("gf/courses/upload")
    return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
  },
  deleteGFCourse(id) {
    let url = buildUrl("gf/courses/" + id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  updateGFCourse(obj) {
    let url = buildUrl("gf/courses")
    return axios.put(url, obj, buildOptions()).then((res) => res.data)
  },
  createGFCourse(obj) {
    let url = buildUrl("gf/courses")
    return axios.post(url, obj, buildOptions()).then((res) => res.data)
  },
  fetchGFCourses() {
    let url = buildUrl("gf/courses")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  fetchCourseRatings(ts) {
    let url = buildUrl("gf/rating/" + ts)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  sendGFRating(rating) {
    let url = buildUrl("gf/rating")
    return axios.post(url, rating, buildOptions()).then((res) => res.data)
  },
  fetchAllGFIcons() {
    let url = buildUrl("gf/icons")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchGFIcon(icon) {
    let url = buildUrl("gf/icons/" + icon)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  downloadMarketingImageGF(format, color, courseId) {
    let url = buildUrl("gf/post/" + format + "/" + color + "/" + courseId)
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then((res) => res.data)
  },

  manageUserCourseSignup(id, status, userid) {
    let url = buildUrl("gf/courses/signup/" + id + "/" + status + "/" + userid)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  manageCourseSignup(id, status) {
    let url = buildUrl("gf/courses/signup/" + id + "/" + status)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  courseSignupWithoutContract() {
    let url = buildUrl("gf/courses/without/contract/signup")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateCourseExecution(updatedCourse) {
    let url = buildUrl("gf/courses/edit/" + updatedCourse._id)
    return axios.post(url, { updatedCourse }, buildOptions()).then((res) => res.data)
  },
  lookupCoursesStudio(search) {
    let url = buildUrl("gf/courses/search/studio")
    return axios.post(url, { search }, buildOptions()).then((res) => res.data)
  },
  lookupCourses(search) {
    let url = buildUrl("gf/courses/search")
    return axios.post(url, { search }, buildOptions()).then((res) => res.data)
  },
  fetchCourseCalendar(signal = null) {
    let url = buildUrl("gf/courses/calendar")
    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
  updateGFParticipantAmount(data) {
    let url = buildUrl("gf/courses/participant/amount")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchCourseTimeline(id) {
    let url = buildUrl("gf/courses/timeline/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateCourseTimeline(timeline) {
    let url = buildUrl("gf/courses/timeline")
    return axios.put(url, { timeline }, buildOptions()).then((res) => res.data)
  },
  createCourseTimeline(timeline) {
    let url = buildUrl("gf/courses/timeline")
    return axios.post(url, { timeline }, buildOptions()).then((res) => res.data)
  },
  deleteCourseTimeline(id) {
    let url = buildUrl("gf/courses/timeline/" + id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  fetchCourseEdit(id) {
    let url = buildUrl("gf/courses/timelines/" + id + "/edit")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchCourseView(id, ts) {
    let url = buildUrl("gf/courses/view/" + id + "/" + ts)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchCourseListEdit(day) {
    let url = buildUrl("gf/courses/timelines/" + day)
    return axios.get(url, buildOptions({})).then((res) => res.data)
  },
  fetchCourseList(signal, day) {
    let url = buildUrl("gf/courses/list/" + day)
    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
  fetchLSList(signal, day) {
    let url = buildUrl("gf/courses/lslist/" + day)
    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
  fetchCourseListSettings() {
    let url = buildUrl("gf/courses/settings")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getGFHoursForSelf(startDate, endDate) {
    let url = buildUrl("intern/gf/hourlistself/" + startDate + "/" + endDate)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getGFHours(month) {
    let url = buildUrl(month ? "intern/gf/hourlist/" + month : "intern/gf/hourlist")
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then((res) => res.data)
  },
  fetchGFHours() {
    let url = buildUrl("intern/gf/hours")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchSingleCourse(id) {
    let url = buildUrl("kurse/timelines/get/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  registerForEventCourse(eventId) {
    let url = buildUrl("kurse/event/signup/" + eventId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  deRegisterForEventCourse(eventId) {
    let url = buildUrl("kurse/event/signup/" + eventId)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  fetchEventCourseSignupsForUser(eventId) {
    let url = buildUrl("kurse/event/signups/" + eventId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getKursStatistik(filter) {
    let url = buildUrl(`kursstatistik/${filter.kurs_id}/${filter.ts}/${filter.ts_end}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Livestreams

  addLSBlacklist(kurs) {
    let url = buildUrl(`livestream/blacklist/add/${kurs._id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  removeLSBlacklist(kurs) {
    let url = buildUrl(`livestream/blacklist/remove/${kurs._id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getLSBlacklist() {
    let url = buildUrl(`livestream/blacklist`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  ls_signup(kurs) {
    let url = buildUrl(`livestream/signup/${kurs._id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  ls_signoff(kurs) {
    let url = buildUrl(`livestream/signoff/${kurs._id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  loadLivestreamsignups() {
    let url = buildUrl("livestream/signups")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  loadLSSignups(kurs_id) {
    let url = buildUrl(`livestream/signups/${kurs_id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getVideoArchiv() {
    let url = buildUrl(`videoarchiv`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getVideoArchivAdmin() {
    let url = buildUrl(`videoarchivadmin`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  editVideoArchiv(va) {
    let url = buildUrl("videoarchiv")
    return axios.post(url, va, buildOptions()).then((res) => res.data)
  },
  editZoomLink(zoom) {
    let url = buildUrl("zoom")
    return axios.post(url, zoom, buildOptions()).then((res) => res.data)
  },
  getZoomLink() {
    let url = buildUrl(`zoom`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Deprecated?

  generateCourses() {
    let url = buildUrl("kurse/timelines/generate")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  checkCourseUsage(course) {
    let url = buildUrl(`kurse/usage/${course}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getCourseSettings() {
    let url = buildUrl("kurseinstellungen")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveCourseSettings(data) {
    let url = buildUrl("kurseinstellungen")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  getAllExcersiceSettings(studio_id) {
    let url = buildUrl(`uebungen/einstellungen/all/${studio_id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getPlanExerciseSettings(plan_id) {
    let url = buildUrl(`uebungen/plansettings/${plan_id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  SaveExerciseSteps(data) {
    let url = buildUrl("uebungen/exercisesteps/save")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  toggleExcerciseVisibility(data) {
    let url = buildUrl("uebungen/visibility/change")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  getExerciseSteps(data) {
    let url = buildUrl("uebungen/exercisesteps/get")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  getTags() {
    let url = buildUrl("uebungen/tags")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getExerciseSettings(data) {
    let url = buildUrl(`uebungen/einstellungen/get/${data}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveExerciseSettings(data) {
    let url = buildUrl("uebungen/einstellungen/create")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  changeLabels(data) {
    let url = buildUrl("kurse/timelines/labelchange/")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  lookupInstructors(search, signal = null) {
    //let url = buildUrl(`fetch/users/instructors`)
    let url = "https://app.wellcomefit.ch/api/fetch/users/instructors"
    return axios.post(url, { search }, buildOptions({}, signal)).then((res) => res.data)
  },
  fetchInstructors() {
    //let url = buildUrl(`fetch/users/instructors`)
    let url = "https://app.wellcomefit.ch/api/fetch/users/instructors"

    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  signUpUserForKurs(data) {
    let url = buildUrl(`kurse/user/signup`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  getLabelsByGenTimelines(gentimelines) {
    let url = buildUrl(`kurse/timelines/labels`)

    return axios.post(url, gentimelines, buildOptions()).then((res) => res.data)
  },
  loadLabels(id) {
    let url = buildUrl(`kurse/label/${id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getLabels(id) {
    let url = buildUrl(`label/${id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createLabel(label) {
    let url = buildUrl(`label`)

    return axios.post(url, label, buildOptions()).then((res) => res.data)
  },
  createRoom(room) {
    let url = buildUrl(`room`)

    return axios.post(url, room, buildOptions()).then((res) => res.data)
  },

  getKurse() {
    let url = buildUrl(`kurse`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getKursById(kursid) {
    let url = buildUrl(`kurse/get/${kursid}`)

    return axios.put(url, kursid, buildOptions()).then((res) => res.data)
  },
  createKurs(kurs) {
    let url = buildUrl(`kurse`)

    return axios.post(url, kurs, buildOptions()).then((res) => res.data)
  },
  cancelKurs(id) {
    let url = buildUrl(`timelines/cancel/${id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  editKurs(kurs) {
    let url = buildUrl(`kurse/${kurs._id}`)

    return axios.put(url, kurs, buildOptions()).then((res) => res.data)
  },
  deleteKurstimeline(id) {
    let url = buildUrl(`kurse/timeline/delete/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  deleteKurs(kurs) {
    let url = buildUrl(`kurse/delete`)
    return axios.post(url, kurs, buildOptions()).then((res) => res.data)
  },
  getInstructors() {
    let url = buildUrl(`instructors`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getRooms(id) {
    let url = buildUrl(`rooms/${id}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getKurstimelineByStudio(zeitplaene) {
    let url = buildUrl(`kurse/timeline/studio`)

    return axios.post(url, zeitplaene, buildOptions()).then((res) => res.data)
  },
  getKurstimelineById(timeline_id) {
    let url = buildUrl(`kurse/timeline/id/`)

    return axios.post(url, { timeline_id: timeline_id }, buildOptions()).then((res) => res.data)
  },
  getKurstimeline(studioid) {
    let url = buildUrl(`kurse/timeline/${studioid}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveKurstimeline(kurs) {
    let url = buildUrl(`kurse/timeline`)

    return axios.post(url, kurs, buildOptions()).then((res) => res.data)
  },
  editKurstimeline(kurs) {
    let url = buildUrl(`kurse/timeline/edit`)
    return axios.post(url, kurs, buildOptions()).then((res) => res.data)
  },
  getKurstimelines() {
    let url = buildUrl(`kurse/timelines`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getUserid() {
    let url = buildUrl(`userid`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getKursetimelinesGeneratedbyDate(date) {
    let url = buildUrl(`kurse/timelines/generate/bydate/`)

    return axios.post(url, date, buildOptions()).then((res) => res.data)
  },

  getKursetimelinesGenerated(studioid) {
    let url = buildUrl(`kurse/timelines/generate/get/${studioid}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getKursetimelinesGeneratedEx(studioid) {
    let url = buildUrl(`kurse/timelines/generate/get/${studioid}`)

    return axios.post(url, { kursliste: true }, buildOptions()).then((res) => res.data)
  },

  generateTimelines() {
    let url = buildUrl(`kurse/timelines/generate/`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getNextKursetimelinesGenerated(studioid) {
    let url = buildUrl(`kurse/timelines/generate/get/next/${studioid}`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  signUpKurs(data) {
    let url = buildUrl(`kurse/signup`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  signUpKursStatus(data) {
    let url = buildUrl(`kurse/signup/status`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  signOffKursStatus(data) {
    let url = buildUrl(`kurse/signoff`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  signOffUserKursStatus(data) {
    let url = buildUrl(`kurse/user/signoff`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
}
