<template>
  <v-dialog v-model="fetching" persistent max-width="400">
    <v-card class="pa-4">
      <v-card-text>
        <div class="text-center mt-4">
          <v-progress-circular indeterminate color="primary" size="70" width="7"></v-progress-circular>
        </div>
        <div class="text-center mt-4">Bitte warten, während neue Mails vom Mailserver geladen und verarbeitet werden.</div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api"
export default {
  name: "DocumentEmailFetcher",
  data() {
    return {
      fetching: false,
    }
  },
  methods: {
    async fetchMails() {
      // Setze fetching auf true, um den Ladevorgang anzuzeigen
      this.fetching = true
      try {
        // Die API-Funktion sollte eine JSON-Antwort mit den Zählern zurückgeben
        const response = await api.documents.fetchDocumentEmails()
        // Zum Beispiel: { message: "...", newMails: 3, newAttachments: 5 }
        this.$toast.success(`${response.message} Neue Mails: ${response.newMails}, Neue Anhänge: ${response.newAttachments}`)
        if (parseInt(response.newAttachments) > 0 || parseInt(response.newMails) > 0) this.$emit("newAttachements")
      } catch (e) {
        this.$toast.error("Fehler beim Laden der E-Mails")
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>

<style scoped>
/* Weitere Styles nach Bedarf */
</style>
