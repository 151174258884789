import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  updateLead(lead) {
    let url = buildUrl("leads")
    return axios.put(url, lead, buildOptions()).then((res) => res.data)
  },
  fetchAllLeads(status) {
    let url = buildUrl("leads/all")
    return axios.put(url, { status }, buildOptions()).then((res) => res.data)
  },
  updateLeadCost(id, newWeeklyCost) {
    let url = buildUrl("leads/costs/settings/update/" + id)
    return axios.put(url, newWeeklyCost, buildOptions()).then((res) => res.data)
  },
  fetchLeadCostsSettings() {
    let url = buildUrl("leads/costs/settings")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchLeads(id, status) {
    let url = buildUrl("leads/" + id)
    return axios.put(url, { status: status }, buildOptions()).then((res) => res.data)
  },
  fetchReferralSettingsPublic() {
    let url = buildUrl("rleads/public")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  submitLeadLP(leadEntry) {
    let url = buildUrl("websitelead")
    return axios.post(url, leadEntry, buildOptions()).then((res) => res.data)
  },
  fetchLeadSettingsLP(link) {
    let url = buildUrl("websitelead/form/" + link)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchLeadSettingsPublic(link) {
    let url = buildUrl("leads/public/" + link)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createReferralLeadPublic(leadSettingsId, lead, studio, referralEmail) {
    let url = buildUrl("rleads/public")
    return axios.post(url, { leadSettingsId, lead, studio, referralEmail }, buildOptions()).then((res) => res.data)
  },
  createWalkin(lead) {
    let url = buildUrl("rleads/walkin/create")
    return axios.post(url, lead, buildOptions()).then((res) => res.data)
  },
  createLeadPublic(link, data) {
    let url = buildUrl("leads/public/" + link)
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  saveReferralSetting(studio) {
    let url = buildUrl("leads/referral/settings")
    return axios.post(url, studio, buildOptions()).then((res) => res.data)
  },
  saveLeadSetting(leadSetting) {
    let url = buildUrl("leads/settings")
    return axios.post(url, leadSetting, buildOptions()).then((res) => res.data)
  },
  fetchLeadSettings() {
    let url = buildUrl("leads/settings")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchLeadsByCategory(id) {
    let url = buildUrl("leads/category/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveLeadCategory(category) {
    let url = buildUrl("leads/categories")
    return axios.post(url, category, buildOptions()).then((res) => res.data)
  },
  fetchLeadCategories() {
    let url = buildUrl("leads/categories")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchLeadOverview() {
    let url = buildUrl("leads/overview")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchReferralOverview(status) {
    let url = buildUrl("rleads/overview")
    return axios.put(url, { status }, buildOptions()).then((res) => res.data)
  },

  // Statistics
  getLeadStatistics(data) {
    let url = buildUrl("leads/statistic")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  // Downloads
  async fetchLeadExcel(data) {
    return new Promise(async (resolve, reject) => {
      let url = buildUrl("leads/download/excel")
      try {
        const response = await axios({
          url,
          method: "POST",
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          data: {
            data,
            // Other data properties if needed
          },
        })

        if (window.flutter_inappwebview && localStorage.getItem("isIOS")) {
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]
            resolve(base64.toString())
          }
        } else {
          saveAs(response.data, "leadStatistik")
          resolve() // Resolve without a value since the download is not async
        }
      } catch (error) {
        console.log(error, "FEHLER")
        reject(error)
      }
    })
  },
  async downloadBLSList() {
    return new Promise(async (resolve, reject) => {
      let url = buildUrl("employee/bls")
      try {
        const response = await axios({
          url,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })

        if (window.flutter_inappwebview && localStorage.getItem("isIOS")) {
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]
            resolve(base64.toString())
          }
        } else {
          saveAs(response.data, "BLS_AED")
          resolve() // Resolve without a value since the download is not async
        }
      } catch (error) {
        console.log(error, "FEHLER")
        reject(error)
      }
    })
  },
  async fetchLeadCostExcel() {
    return new Promise(async (resolve, reject) => {
      let url = buildUrl("leads/costs/excel")
      try {
        const response = await axios({
          url,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })

        if (window.flutter_inappwebview && localStorage.getItem("isIOS")) {
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]
            resolve(base64.toString())
          }
        } else {
          saveAs(response.data, "leadCosts")
          resolve() // Resolve without a value since the download is not async
        }
      } catch (error) {
        console.log(error, "FEHLER")
        reject(error)
      }
    })
  },
}
