<template>
  <v-card dark elevation="0" max-width="700px" class="mx-auto mb-15 mt-5">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col class="d-flex justify-center" cols="12">
            <v-card-title class="font-weight-bold"
              ><span class="mr-2">Einstellungen für</span>
              <v-select
                style="font-size: 1.25rem; font-weight: 700; margin: 0 !important"
                v-model="selectedStudio"
                @change="fetchSettings()"
                :items="availableStudios"
                item-text="name"
                item-value="_id"
                hide-details
                dense
                :loading="loading" />
            </v-card-title>
          </v-col>
        </v-row>

        <settings-panels :key="settingsPanelKey" @editSetting="editSetting" @addSetting="addSetting" :data="data"></settings-panels>
      </v-col>
    </v-row>
    <settingsDialog
      @closedDialog="closeSettingsDialog()"
      @input="settingsChanged"
      :visible="settingsDialog"
      :existingValue="settingsDialogValue"
      :type="settingsDialogType" />
  </v-card>
</template>

<script>
import api from "@/api"
import settingsDialog from "./shiftManagementSettingsDialogs/settingsDialog.vue"
import settingsPanels from "./shiftManagementSettingsPanels/settingsPanels.vue"

export default {
  components: {
    settingsDialog,
    settingsPanels,
  },
  name: "ShiftManagementSettings",
  data() {
    return {
      loading: false,
      data: {},
      settingsDialog: false,
      settingsDialogType: "",
      settingsDialogValue: {},
      settingsPanelKey: 0,
      availableStudios: [],
      selectedStudio: null,
    }
  },
  async created() {
    this.availableStudios = await api.fetchAllowedAdminStudios()
    this.selectedStudio = this.availableStudios[0]._id
    this.fetchSettings()
  },
  methods: {
    async fetchSettings() {
      this.loading = true
      this.data = await api.shiftManagement.fetchSettings(this.selectedStudio)
      this.loading = false
    },
    async settingsChanged(createorUpdatedSetting) {
      createorUpdatedSetting = { type: this.settingsDialogType, studio: this.selectedStudio, ...createorUpdatedSetting }
      let response = await api.shiftManagement.createOrUpdateSettings(createorUpdatedSetting)

      if (createorUpdatedSetting._id) {
        const index = this.data[this.settingsDialogType].findIndex((setting) => setting._id === response._id)
        this.$set(this.data[this.settingsDialogType][index], response)
      } else {
        this.data[this.settingsDialogType].push(response)
      }
      this.closeSettingsDialog()
    },
    editSetting(setting, type) {
      this.settingsDialogValue = this.data[type][setting]
      this.settingsDialogType = type
      this.settingsDialog = true
    },
    closeSettingsDialog() {
      this.settingsDialog = false
      this.settingsDialogType = ""
      this.settingsDialogValue = {}
    },
    addSetting(type) {
      this.settingsDialogType = type
      this.settingsDialog = true
    },
  },
}
</script>

<style scoped></style>
