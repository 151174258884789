<template>
  <v-row no-gutters>
    <v-col cols="12" v-if="runningTACContracts && runningTACContracts.find((cont) => cont.NAME.includes('PREMIUM'))">
      <v-card-title class="font-weight-bold">
        Premium Kunde Gast Eintritte seit
        {{ this.$moment(runningTACContracts.find((cont) => cont.NAME.includes("PREMIUM")).TERM_STARTDATE).format("DD.MM.YYYY") }}
      </v-card-title>
      <v-row no-gutters class="px-4">
        <v-col v-for="n of 10" :key="'guestentries' + n" cols="auto">
          <v-chip
            @click="filteredGuestEntries.length === n - 1 ? (createNewGuestEntry = true) : showGuestEntry(n)"
            class="mr-2"
            :class="{ 'font-weight-bold clickable': filteredGuestEntries.length === n - 1 }"
            :color="n > filteredGuestEntries.length ? 'grey' : 'primary'">
            {{ filteredGuestEntries.length === n - 1 ? "+" : n }}
          </v-chip>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col align="center" cols="12">
          <v-tabs class="mt-5" v-model="tab" centered dark>
            <v-tab v-for="(tab, index) of tabs" :key="index">{{ tab.text }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row v-if="userService.hasRight('c_create_customer')" no-gutters>
        <v-col cols="12">
          <v-tabs-items dark v-model="tab">
            <v-tab-item>
              <v-row no-gutters>
                <v-col class="px-5 mt-5" cols="12" v-if="!contracts || contracts.length === 0">
                  <v-alert type="info">Keine Verträge gefunden</v-alert>
                </v-col>
                <v-col v-for="(contract, index) of contracts" :key="index + '-roodyContract'" :cols="isMobile ? '12' : '6'">
                  <v-card v-show="contract.status !== 'deleted'" class="mt-8 mx-5 fill-height" color="grey darken-4" align="center">
                    <v-chip style="margin-top: -20px" :color="mapContractState(contract.status).color">
                      {{ mapContractState(contract.status).text }}
                    </v-chip>
                    <v-card-title v-if="contract.membership && contract.membership.selection" class="justify-center pt-0">
                      {{ contract.membership.selection.title }}
                    </v-card-title>
                    <v-card-subtitle v-else>Fehler bei Mitgliedschaft</v-card-subtitle>
                    <v-card-subtitle v-if="contract.category && contract.category.selection" class="justify-center pb-0">
                      {{ contract.category.selection.title }}
                    </v-card-subtitle>
                    <v-card-subtitle v-else>Fehler bei Kategorie</v-card-subtitle>
                    <v-card-text class="pb-0">
                      <div>
                        {{ $moment(contract.startDate).format("DD.MM.YYYY") }} -
                        {{ $moment(contract.endDate).format("DD.MM.YYYY") }}
                      </div>
                    </v-card-text>
                    <v-card-text v-if="contract.hasPurchasedPersonalTraining">
                      <v-icon>mdi-information</v-icon>
                      Personal Training gekauft
                    </v-card-text>
                    <v-card-text class="text-center">
                      <v-chip
                        v-for="(packagee, index2) of contract.packages"
                        class="primary accent-4 white--text mx-1"
                        :key="index2 + '-runningTACAdditionalContract'">
                        {{ packagee.title }}
                      </v-chip>
                    </v-card-text>
                    <v-card-actions>
                      <v-row no-gutters>
                        <v-col align="center" cols="12">
                          <v-btn v-if="contract.status !== 'accepted'" @click="showPaymentDialog = index + '-contractPaymentIndex'" icon>
                            <v-icon>mdi-credit-card-settings-outline</v-icon>
                          </v-btn>
                          <v-btn v-if="contract.status !== 'accepted'" @click="showAttachmentDialog = user._id + '-' + index" icon>
                            <v-icon>mdi-file-image-plus</v-icon>
                          </v-btn>
                          <v-btn v-if="contract.status === 'accepted'" @click="declineContract(index)" icon>
                            <v-icon>mdi-block-helper</v-icon>
                          </v-btn>
                          <v-btn v-if="contract.status !== 'accepted'" @click="showEditDialog = index + '-contractEditIndex'" icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn @click="$router.push('/dashboard/contract/view/' + user._id + '/' + index)" icon>
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>

                          <v-btn icon @click="printUserContract(index)">
                            <v-icon>mdi-printer</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                  <basic-dialog
                    ref="paymentDialog"
                    dialogTitle="Zahlungsbedingungen bearbeiten"
                    @onCancel="showPaymentDialog = null"
                    :dialogVisible="showPaymentDialog === index + '-contractPaymentIndex'"
                    @onSave="editPaymentContract(index, contract.paymentOptions)"
                    :dialogSaveButtonText="'Speichern'">
                    <v-row no-gutters>
                      <v-col class="d-flex justify-center mb-5" v-if="!tempContract" cols="12">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </v-col>
                      <v-col v-else cols="12" class="mb-5">
                        <v-row
                          no-gutters
                          v-for="(paymentOption, i) of tempContract.formFields
                            .find((e) => e.inputType === 'paymentOptions')
                            .inputValue.filter((e) => e.studios.includes(contract.studio._id))"
                          :key="i + '-indexPaymentOptions'">
                          <v-col
                            cols="8"
                            sm="12"
                            class="d-flex flex-row justify-space-between flex-wrap my-0 py-0"
                            v-if="
                              contract.paymentOptions.selection === null ||
                              (contract.paymentOptions.selection && contract.paymentOptions.selection.title === paymentOption.title)
                            ">
                            <v-row no-gutters>
                              <v-col cols="3" class="py-0 px-5 d-flex align-center">
                                <div
                                  v-if="
                                    contract.paymentOptions.selection &&
                                    contract.paymentOptions.selection.title &&
                                    contract.paymentOptions.selection.title === paymentOption.title
                                  ">
                                  <v-btn
                                    @click="
                                      contract.paymentOptions = {
                                        selection: null,
                                        selectedDate: null,
                                        additionalInfo: contract.paymentOptions.additionalInfo ? contract.paymentOptions.additionalInfo : '',
                                      }
                                    "
                                    icon
                                    x-large>
                                    <v-icon color="primary"> mdi-check-circle </v-icon>
                                  </v-btn>
                                </div>
                                <div v-else>
                                  <v-btn
                                    x-large
                                    icon
                                    @click="
                                      contract.paymentOptions = {
                                        selection: paymentOption,
                                        selectedDate: null,
                                        additionalInfo: contract.paymentOptions.additionalInfo ? contract.paymentOptions.additionalInfo : '',
                                      }
                                    ">
                                    <v-icon color="primary"> mdi-plus-circle-outline </v-icon>
                                  </v-btn>
                                </div>

                                <!---<v-text-field type="number" v-if="fieldEntries.packages.selection.amountSelectable" label="Anzahl"></v-text-field>-->
                              </v-col>
                              <v-col cols="9" class="py-auto d-flex align-center">
                                <span style="font-size: 1rem" class="option">
                                  {{ paymentOption.title }}
                                </span>

                                <!---<v-text-field type="number" v-if="fieldEntries.packages.selection.amountSelectable" label="Anzahl"></v-text-field>-->
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col v-if="tempContract && contract.paymentOptions.selection && contract.paymentOptions.selection.additionalInfo" cols="12">
                        <v-card-text>
                          {{ contract.paymentOptions.selection.additionalInfo }}
                        </v-card-text>
                      </v-col>

                      <v-col class="d-flex justify-center px-5 mb-5" v-if="tempContract" cols="12">
                        <v-textarea
                          label="zusätzliche Informationen"
                          counter
                          :rules="[
                            (v) => (v || '').length <= 150 || 'Bitte nur maximal 150 Zeichen eingeben',
                            (v) => !v || v.indexOf('\n') < 0 || 'Bitte keine Zeilenumbrüche eingeben',
                          ]"
                          v-model="contract.paymentOptions.additionalInfo"></v-textarea>
                      </v-col>
                    </v-row>
                  </basic-dialog>
                  <basic-dialog
                    ref="editDialog"
                    dialogTitle="Vertrag bearbeiten"
                    @onCancel="showEditDialog = null"
                    :dialogVisible="showEditDialog === index + '-contractEditIndex'"
                    @onSave="$router.push('/dashboard/contract/edit/' + user._id + '/' + index)"
                    :dialogSaveButtonText="'Fortfahren'">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-alert type="info" class="mx-5">
                          Anhänge kannst du über den Button
                          <v-icon @click="showEditDialog = null">mdi-file-image-plus</v-icon>
                          ohne extra Unterschrift hinzufügen.
                        </v-alert>
                        <v-card-text class="py-0 mt-0 mb-5">
                          Wenn du den Vertrag bearbeitest, musst du die Unterschrift des Kunden erneut einholen. Möchtest du fortfahren?
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </basic-dialog>
                  <basic-dialog
                    ref="attachmentDialog"
                    @onCancel="showAttachmentDialog = null"
                    @onSave="addFileToContract(index)"
                    dialogTitle="Anhänge bearbeiten"
                    :dialogVisible="showAttachmentDialog === user._id + '-' + index">
                    <v-row>
                      <v-col cols="12">
                        <v-row class="upload-section">
                          <v-col cols="12" class="mb-0">
                            <v-file-input multiple @change="uploadImagesContract($event, contract)" label="Bilder auswählen"></v-file-input>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row v-if="contract.customer && contract.customer.additionalImages">
                      <v-col cols="12">
                        <v-window v-model="activeIndexContract">
                          <v-window-item v-for="(image, imageIndex) in contract.customer.additionalImages" :key="`card-${imageIndex}`">
                            <v-card color="transparent" height="200">
                              <v-row class="fill-height" align="center" justify="center">
                                <v-col align="center" cols="12">
                                  <v-img
                                    contain
                                    class="mx-auto"
                                    v-if="image.dataUrl"
                                    height="150px"
                                    max-width="200px"
                                    :src="image.dataUrl"
                                    alt="Uploaded Image"></v-img>
                                  <v-btn v-else class="mx-auto" @click="fetchImageDataSource(imageIndex, contract)" color="primary">
                                    <v-icon color="white" class="pr-1"> mdi-eye </v-icon>
                                    Bild laden
                                  </v-btn>
                                  <v-btn class="mx-auto" @click="deleteImageContract(imageIndex, contract)" color="error"> Löschen </v-btn>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-window-item>
                        </v-window>
                        <v-card-actions class="justify-center">
                          <v-item-group v-model="activeIndexContract" class="text-center" mandatory>
                            <v-item v-for="n in contract.customer.additionalImages.length" :key="`btn-${n}`" v-slot="{ active, toggle }">
                              <v-btn :input-value="active" icon @click="toggle">
                                <v-icon>mdi-record</v-icon>
                              </v-btn>
                            </v-item>
                          </v-item-group>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                    <v-row v-if="contract.customer && contract.customer.additionalImages" no-gutters>
                      <v-col
                        v-for="(additionalImage, index) of contract.customer.additionalImage"
                        :key="index + '-additionalImageIndex'"
                        cols="12"
                        class="text-center">
                        <v-btn @click="removeImage(contract, index)" text>Löschen</v-btn>
                        <v-img
                          :key="imageSources[additionalImage] + 'imgsourceKey'"
                          v-if="imageSources[additionalImage]"
                          :src="imageSources[additionalImage]"
                          max-width="200px"
                          max-height="100%"></v-img>
                        <v-card-text v-else>Kein Anhang vorhanden</v-card-text>
                      </v-col>
                    </v-row>
                  </basic-dialog>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row no-gutters>
                <v-col cols="12" class="justify-center d-flex">
                  <v-switch v-model="tacContractsSwitch" label="Abgelaufene Verträge anzeigen"></v-switch>
                </v-col>
                <v-col cols="12" class="px-5 mt-5" v-if="!runningTACContracts.length > 0 && !oldTACContracts.length > 0">
                  <v-alert type="info">Keine Verträge gefunden</v-alert>
                </v-col>
                <v-col cols="12">
                  <v-row v-if="!tacContractsSwitch" no-gutters>
                    <v-col
                      v-for="(contract, index) of runningTACContracts.filter(
                        (e) => e.CONTRACTTYP === 'Hauptvertrag' || (e.CONTRACTTYP === 'Bonusvertrag' && e.GROUP_NAME !== 'Zusatzpakete')
                      )"
                      :key="index + '-runningTACContract'"
                      :cols="isMobile ? '12' : '6'">
                      <v-card color="grey darken-4" align="center" class="mx-5 mb-8">
                        <v-card-title class="justify-center">{{ contract.NAME }}</v-card-title>
                        <v-card-subtitle v-if="contract.TERM_TYPE" class="justify-center pb-0">
                          {{ contract.TERM_TYPE }}
                        </v-card-subtitle>
                        <v-card-text class="pb-0">
                          <div>
                            {{ $moment(contract.TERM_STARTDATE).format("DD.MM.YYYY") }} -
                            {{ $moment(contract.TERM_ENDDATE).format("DD.MM.YYYY") }}
                          </div>
                        </v-card-text>
                        <v-card-text class="text-center">
                          <v-chip
                            v-for="(additionalContract, index2) of runningTACContracts.filter(
                              (e) => e.CONTRACTTYP === 'Zusatzvertrag' || (e.CONTRACTTYP === 'Bonusvertrag' && e.GROUP_NAME === 'Zusatzpakete')
                            )"
                            v-show="
                              additionalContract.TERM_STARTDATE === contract.TERM_STARTDATE &&
                              additionalContract.TERM_ENDDATE === contract.TERM_ENDDATE
                            "
                            class="primary accent-4 white--text mx-1"
                            :key="index2 + '-runningTACAdditionalContract'">
                            {{ additionalContract.NAME }}
                          </v-chip>
                        </v-card-text>
                        <v-row v-if="userService.hasRight('d_c_healthcare')" no-gutters>
                          <v-col
                            cols="12"
                            v-for="year of $moment(contract.TERM_ENDDATE).diff($moment(contract.TERM_STARTDATE), 'years') + 1"
                            :key="year + 'contractYear'"
                            class="d-flex justify-center"
                            align="center">
                            <v-row no-gutters>
                              <v-col class="d-flex justify-center" cols="12">
                                <v-switch
                                  :label="year + ' Jahr ' + 'vollständig bezahlt'"
                                  @change="updateContractPayment($event, contract.CONTRACTIDSEQUENCEID, 0, year)"
                                  :input-value="
                                    user.paidTACContractIDs &&
                                    user.paidTACContractIDs.find(
                                      (e) => e.id.toString() === contract.CONTRACTIDSEQUENCEID.toString() && e.year === year
                                    )
                                  "></v-switch>
                              </v-col>
                              <v-col
                                v-if="
                                  user.paidTACContractIDs &&
                                  user.paidTACContractIDs.find((e) => e.id.toString() === contract.CONTRACTIDSEQUENCEID.toString() && e.year === year)
                                "
                                cols="12">
                                <v-text-field
                                  type="number"
                                  class="mx-5"
                                  :value="
                                    user.paidTACContractIDs &&
                                    user.paidTACContractIDs.find(
                                      (e) => e.id.toString() === contract.CONTRACTIDSEQUENCEID.toString() && e.year === year
                                    )
                                      ? user.paidTACContractIDs.find(
                                          (e) => e.id.toString() === contract.CONTRACTIDSEQUENCEID.toString() && e.year === year
                                        ).amount
                                      : 0
                                  "
                                  @blur="updateContractPayment(true, contract.CONTRACTIDSEQUENCEID, $event.target.value, year)"
                                  label="Kosten des Abos (KK-Bestätigung)"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-else no-gutters>
                    <v-col
                      v-for="(contract, index) of oldTACContracts.filter(
                        (e) => e.CONTRACTTYP === 'Hauptvertrag' || (e.CONTRACTTYP === 'Bonusvertrag' && e.GROUP_NAME !== 'Zusatzpakete')
                      )"
                      :key="index + '-runningTACContract'"
                      :cols="isMobile ? '12' : '6'">
                      <v-card color="grey darken-4" align="center" class="mx-5 mb-8">
                        <v-card-title class="justify-center">
                          {{ contract.NAME }}
                          <br />
                          (abgelaufen)
                        </v-card-title>
                        <v-card-subtitle v-if="contract.TERM_TYPE" class="justify-center pb-0">
                          {{ contract.TERM_TYPE }}
                        </v-card-subtitle>
                        <v-card-text class="pb-0">
                          <div>
                            {{ $moment(contract.TERM_STARTDATE).format("DD.MM.YYYY") }} -
                            {{ $moment(contract.TERM_ENDDATE).format("DD.MM.YYYY") }}
                          </div>
                        </v-card-text>
                        <v-card-text class="text-center">
                          <v-chip
                            v-for="(additionalContract, index2) of oldTACContracts.filter(
                              (e) => e.CONTRACTTYP === 'Zusatzvertrag' || (e.CONTRACTTYP === 'Bonusvertrag' && e.GROUP_NAME === 'Zusatzpakete')
                            )"
                            v-show="
                              additionalContract.TERM_STARTDATE === contract.TERM_STARTDATE &&
                              additionalContract.TERM_ENDDATE === contract.TERM_ENDDATE
                            "
                            class="primary accent-4 white--text mx-1"
                            :key="index2 + '-runningTACAdditionalContract'">
                            {{ additionalContract.NAME }}
                          </v-chip>
                        </v-card-text>
                        <v-row v-if="userService.hasRight('d_c_healthcare')" no-gutters>
                          <v-col
                            cols="12"
                            v-for="year of $moment(contract.TERM_ENDDATE).diff($moment(contract.TERM_STARTDATE), 'years') + 1"
                            :key="year + 'contractYear'"
                            class="d-flex justify-center"
                            align="center">
                            <v-row no-gutters>
                              <v-col class="d-flex justify-center" cols="12">
                                <v-switch
                                  :label="year + ' Jahr ' + 'vollständig bezahlt'"
                                  @change="updateContractPayment($event, contract.CONTRACTIDSEQUENCEID, 0, year)"
                                  :input-value="
                                    user.paidTACContractIDs &&
                                    user.paidTACContractIDs.find(
                                      (e) => e.id.toString() === contract.CONTRACTIDSEQUENCEID.toString() && e.year === year
                                    )
                                  "></v-switch>
                              </v-col>
                              <v-col
                                v-if="
                                  user.paidTACContractIDs &&
                                  user.paidTACContractIDs.find((e) => e.id.toString() === contract.CONTRACTIDSEQUENCEID.toString() && e.year === year)
                                "
                                cols="12">
                                <v-text-field
                                  type="number"
                                  class="mx-5"
                                  :value="
                                    user.paidTACContractIDs &&
                                    user.paidTACContractIDs.find(
                                      (e) => e.id.toString() === contract.CONTRACTIDSEQUENCEID.toString() && e.year === year
                                    )
                                      ? user.paidTACContractIDs.find(
                                          (e) => e.id.toString() === contract.CONTRACTIDSEQUENCEID.toString() && e.year === year
                                        ).amount
                                      : 0
                                  "
                                  @blur="updateContractPayment(true, contract.CONTRACTIDSEQUENCEID, $event.target.value, year)"
                                  label="Kosten des Abos (KK-Bestätigung)"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="px-5 mt-5">
          <v-alert type="info">Funktion nicht freigeschaltet</v-alert>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog max-width="500" dark v-model="createNewGuestEntry">
      <v-card>
        <v-card-title>Gast Eintritt hinzufügen für {{ newGuestEntry.date.format("DD.MM.YYYY") }}</v-card-title>
        <v-col cols="12" class="mt-5 px-4 mx-auto">
          <user-search
            :key="newGuestEntry.user"
            style="max-width: 80%"
            class="mx-auto"
            ignoreRights
            :value="newGuestEntry.user"
            label="Gast"
            @update="setGuestUser($event)"></user-search>
        </v-col>
        <v-card-actions v-if="newGuestEntry.user && existingDocs" class="justify-content-center">
          <v-btn :loading="loadingExistingDocs" rounded color="primary" v-if="newGuestEntry.user" @click="createOrEditAnamnese()">
            {{ existingDocs && existingDocs.medicalHistory && existingDocs.medicalHistory.length ? "Anamnese bearbeiten" : "Anamnese erstellen" }}
          </v-btn>
        </v-card-actions>
        <v-card-actions class="justify-content-center">
          <v-btn color="primary" v-if="!newGuestEntry.user" @click="showRegisterDialog = true"> Neuen Account erstellen </v-btn>

          <v-btn
            rounded
            v-if="newGuestEntry.user"
            :disabled="!existingDocs || !existingDocs.medicalHistory || !existingDocs.medicalHistory.length"
            class="mx-0"
            :loading="loadingExistingDocs"
            color="primary"
            @click="updateUserGuestEntries()">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <register
      @register="newAccountRegistered()"
      :auto-login="false"
      :requirePassword="false"
      @close="showRegisterDialog = false"
      :active="showRegisterDialog"></register>
    <anamnese-bogen-create @submitted="fetchExistingGuestDocs(newGuestEntry.user)" ref="anamneseBogenRef"></anamnese-bogen-create>

    <v-dialog max-width="500" persistent :value="showPremiumDialog">
      <v-card dark v-if="showExistingGuestEntry">
        <v-card-title
          >{{ $moment(showExistingGuestEntry.date).format("DD.MM.YYYY") }}
          <v-btn class="ml-auto" icon @click="deletePremiumGuest"><v-icon class="red--text">mdi-delete</v-icon></v-btn></v-card-title
        >
        <v-card-actions class="justify-content-center d-flex flex-column">
          <v-btn rounded color="primary" @click="$router.push('/dashboard/user/search/' + showExistingGuestEntry.user)">
            Profil des Gastes öffnen
          </v-btn>
          <v-btn @click="showExistingGuestEntry = null" text>Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from "@/api"
import anamneseBogenCreate from "../../../components/anamneseBogenCreate"

import userService from "@/services/userService"
import register from "@/views/launchScreen/register.vue"
import userSearch from "@/components/userSearch.vue"
import basicDialog from "@/components/tools/basicDialog"
export default {
  components: { basicDialog, register, userSearch, anamneseBogenCreate },
  props: {
    user: {
      required: true,
    },
    isMobile: {
      required: true,
    },
  },
  data() {
    return {
      showRegisterDialog: false,
      guestEntries: [],
      createNewGuestEntry: false,
      newGuestEntry: { user: null, date: this.$moment() },
      userService,
      activeIndexContract: 0,
      tempContract: null,
      showEditDialog: null,
      showAttachmentDialog: null,
      showPaymentDialog: null,
      tacContractsSwitch: false,
      imageSources: {},
      tab: null,
      tabs: [
        {
          text: "roody Verträge",
        },
        {
          text: "TAC Verträge",
        },
      ],
      contracts: null,
      tacContractInfos: null,
      loading: false,
      existingDocs: null,
      loadingExistingDocs: false,
      showExistingGuestEntry: null,
    }
  },
  async created() {
    this.fetchTACContractInfos()
    this.fetchContracts()

    if (this.user.guestEntries) {
      this.guestEntries = JSON.parse(JSON.stringify(this.user.guestEntries))
    }
  },
  methods: {
    deletePremiumGuest() {
      if (confirm("Möchtest du die Einträge vom " + this.$moment(this.showExistingGuestEntry.date).format("DD.MM.YYYY") + " wirklich löschen?")) {
        api.updateUserGuestEntries({
          userId: this.user._id,
          guestEntries: this.guestEntries.filter((e) => e.date.toString() !== this.showExistingGuestEntry.date.toString()),
        })
        this.guestEntries = this.guestEntries.filter((e) => e.date.toString() !== this.showExistingGuestEntry.date.toString())
        this.showExistingGuestEntry = null

        this.$emit("refetchUser")
      }
    },
    showGuestEntry(index) {
      console.log("SHOW")
      this.showExistingGuestEntry = this.filteredGuestEntries[index - 1]
      console.log(this.showExistingGuestEntry)
    },
    createOrEditAnamnese() {
      if (this.existingDocs?.medicalHistory?.length) {
        this.$refs.anamneseBogenRef.setVisible(this.existingDocs.medicalHistory[0]._id, true)
      } else {
        this.$refs.anamneseBogenRef.setVisible(null, true, false, this.newGuestEntry.user, userService.getUserId())
      }
    },
    async setGuestUser(id) {
      this.newGuestEntry.user = id
      this.fetchExistingGuestDocs(id)
    },
    async fetchExistingGuestDocs(id) {
      this.loadingExistingDocs = true
      this.existingDocs = await api.getCustomerDocuments(id)
      this.loadingExistingDocs = false
    },
    newAccountRegistered() {
      this.showRegisterDialog = false
      this.$toast.success("Neuer Account erfolgreich erstellt. Du kannst den Kunden nun auswählen")
    },
    async updateUserGuestEntries() {
      this.guestEntries.push(this.newGuestEntry)
      await api.updateUserGuestEntries({ userId: this.user._id, guestEntries: this.guestEntries })

      this.$emit("refetchUser")
      this.createNewGuestEntry = false
      this.newGuestEntry = { user: null, date: this.$moment() }
    },
    async updateContractPayment(value, contractId, amount = 0, year) {
      let updateRequestData = {
        amount,
        value,
        year,
        contractIdSequenceId: contractId.toString(),
        userId: this.user._id,
      }
      try {
        let status = await api.updateTACContractPaidStatus(updateRequestData)
        console.log(status)
        this.$toast.success("Status aktualisiert")
        this.$emit("refetchUser")
      } catch (e) {
        this.$toast.error("Fehler beim aktualisieren")
      }
    },
    async fetchImageDataSource(imageIndex, contract) {
      let image = contract.customer.additionalImages[imageIndex]
      let src = image.src

      try {
        const response = await api.fetchContractImageData(src)
        contract.customer.additionalImages[imageIndex].dataUrl = response
        this.$forceUpdate()
      } catch (error) {
        this.$toast.error("Fehler beim laden des Bildes")
      }
    },

    deleteImageContract(index, contract) {
      contract.customer.additionalImages.splice(index, 1)
      this.$forceUpdate()
    },
    uploadImagesContract(files, contract) {
      for (let file of files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const formData = new FormData()
          formData.append("file", file)

          api
            .uploadContractImage(formData)
            .then((response) => {
              const uploadedImageName = response.name

              if (!contract.customer.additionalImages) contract.customer.additionalImages = []
              contract.customer.additionalImages.push({
                title: "",
                src: uploadedImageName,
                dataUrl: e.target.result,
              })
              this.$forceUpdate()
            })
            .catch((error) => {
              console.log("Upload failed", error)
            })
        }
        reader.readAsDataURL(file)
      }
    },
    onFileSelected(event) {
      this.uploadStatus = ""
      this.selectedFile = event.target.files[0]
    },
    async checkImageSources(imagePaths) {
      this.imageSources = []
      for (let image of imagePaths) {
        if (!this.imageSources[image]) {
          this.imageSources[image] = await api.getContractImage(image)
        }
      }
      this.$forceUpdate()
    },
    async uploadPictureNew() {
      if (!this.selectedFile) {
        this.uploadStatus = "Please select an image to upload."
        return
      }

      const formData = new FormData()
      formData.append("file", this.selectedFile)

      let uploadImg = {
        art: "contract",
        formData: formData,
      }

      this.uploadStatus = "Uploading..."

      try {
        const data = await api.uploadImage(uploadImg)

        if (data.status) {
          let editingId = this.showAttachmentDialog.split("-")[0]
          let index = this.showAttachmentDialog.split("-")[1]
          index = parseInt(index)

          this.editing.contracts[index].customer.additionalImage.push(data.name)
          this.checkImageSources(this.editing.contracts[index].customer.additionalImage)
          this.uploadStatus = "Upload successful!"
        } else {
          this.uploadStatus = "Error uploading: " + data.message
        }
      } catch (error) {
        this.uploadStatus = `Error uploading: ${error.message}`
      } finally {
        this.$refs.fileInput.value = ""
        this.selectedFile = null
      }
    },
    uploadPicture(formdata, progress, success, failure, slim) {
      const formData = new FormData()
      formData.append("file", formdata[0])
      let uploadimg = {}
      uploadimg.art = "contract"
      uploadimg.formData = formData

      api
        .uploadImage(uploadimg)
        .then((data) => {
          if (data.status) {
            //this.fieldEntries.customer.additionalImage.push(data.name)
            let editingId = this.showAttachmentDialog.split("-")[0]
            let index = this.showAttachmentDialog.split("-")[1]

            index = parseInt(index)

            this.editing.contracts[index].customer.additionalImage.push(data.name)
            this.$forceUpdate()
            success(data)
          }
        })
        .catch((error) => {
          //this.fieldEntries.customer.additionalImage.push(data.name)
          this.$forceUpdate()
          failure(error)
        })
    },
    async addFileToContract(index) {
      // delete dataUrl from this.editing.contracts[index].customer.additionalImages
      if (this.contracts[index].customer.additionalImages) {
        this.contracts[index].customer.additionalImages.forEach((e) => {
          delete e.dataUrl
        })
      }
      let newAdditionalImages = this.contracts[index].customer.additionalImages
      let status = await api.addAttachmentToContract({
        additionalImages: this.contracts[index].customer.additionalImage,
        newAdditionalImages,
        userId: this.user._id,
        index,
      })
      this.showAttachmentDialog = null
      this.$toast.success("Erfolgreich gespeichert")
    },
    async editPaymentContract(index, paymentOptions) {
      let status = await api
        .updatePaymentOptionsContract({ userId: this.user._id, index, paymentOptions })
        .catch((e) => this.$toast.error("Fehler beim speichern, bitte versuche es erneut"))
      if (status && status.nModified) {
        this.$toast.success("Erfolgreich gespeichert")
        this.showPaymentDialog = null
        this.fetchContracts()
      }
    },
    async fetchContracts() {
      try {
        let dataTemp = await api.getCustomerContracts(this.user._id)
        this.contracts = dataTemp
        this.$emit("loaded")
      } catch (e) {
        this.$toast.error("Fehler beim laden der Dokumente")
      }
    },
    async declineContract(contractIndex) {
      if (confirm("Soll der Vertrag wirklich abgelehnt werden?")) {
        try {
          let status = await api.declineContract({ userId: this.user._id, contractIndex })
          this.fetchContracts()
          this.$toast.success("Vertrag erfolgreich abgelehnt")
        } catch (e) {
          this.$toast.error("Vertrag konnte nicht abgelehnt werden")
        }
      }
    },
    printUserContract(index) {
      api.printUserContract(this.user._id, index, "Mitgliedschaftsvertrag")
    },

    mapContractState(state) {
      let states = {
        rejected: { text: "Abgelehnt", color: "red" },
        created: { text: "Offen", color: "orange" },
        temporary: { text: "Temporär", color: "orange" },
        accepted: { text: "Akzeptiert", color: "green" },
        deleted: { text: "Gelöscht", color: "red" },
      }
      return states[state]
    },
    async fetchTACContractInfos() {
      try {
        let tacContractInfos = null
        if (this.isLocal && false) {
          tacContractInfos = [
            {
              CONTRACTID: 72102,
              CONTRACTIDSEQUENCEID: "72102_1",
              CONTRACTTYP: "Hauptvertrag",
              GROUP_NAME: "Mitarbeiter Abos",
              NAME: "Kombi Card Mitarbeiter",
              STARTDATE: "2020-07-27T00:00:00.000Z",
              ENDDATE: "2024-07-26T00:00:00.000Z",
              USE_STARTDATE: "2020-07-27T00:00:00.000Z",
              USE_ENDDATE: "2024-07-26T00:00:00.000Z",
              TERM_STARTDATE: "2020-07-27T00:00:00.000Z",
              TERM_ENDDATE: "2021-07-26T00:00:00.000Z",
              ACTUAL: "NEIN",
              BONUSPERIODID: null,
              BONUSPERIOD_COMMENT: null,
              TERM_TYPE: "Standard",
              CONTRACTSTATE: "Laufend",
              TERMINATE_REASON: null,
              RESSORTGROUPID: 1,
              FIRST_NAME: "Dominic",
              LAST_NAME: "Ammann",
              STREET: "Hauptstrasse 138",
              ZIP_CODE: "8274",
              CITY: "Tägerwilen",
              BIRTHDAY: "1999-12-28T00:00:00.000Z",
            },
            {
              CONTRACTID: 72102,
              CONTRACTIDSEQUENCEID: "72102_2",
              CONTRACTTYP: "Hauptvertrag",
              GROUP_NAME: "Mitarbeiter Abos",
              NAME: "Kombi Card Mitarbeiter",
              STARTDATE: "2020-07-27T00:00:00.000Z",
              ENDDATE: "2024-07-26T00:00:00.000Z",
              USE_STARTDATE: "2020-07-27T00:00:00.000Z",
              USE_ENDDATE: "2024-07-26T00:00:00.000Z",
              TERM_STARTDATE: "2021-07-27T00:00:00.000Z",
              TERM_ENDDATE: "2022-07-26T00:00:00.000Z",
              ACTUAL: "NEIN",
              BONUSPERIODID: null,
              BONUSPERIOD_COMMENT: null,
              TERM_TYPE: "Standard",
              CONTRACTSTATE: "Laufend",
              TERMINATE_REASON: null,
              RESSORTGROUPID: 1,
              FIRST_NAME: "Dominic",
              LAST_NAME: "Ammann",
              STREET: "Hauptstrasse 138",
              ZIP_CODE: "8274",
              CITY: "Tägerwilen",
              BIRTHDAY: "1999-12-28T00:00:00.000Z",
            },
            {
              CONTRACTID: 72102,
              CONTRACTIDSEQUENCEID: "72102_3",
              CONTRACTTYP: "Hauptvertrag",
              GROUP_NAME: "Mitarbeiter Abos",
              NAME: "Kombi Card Mitarbeiter",
              STARTDATE: "2020-07-27T00:00:00.000Z",
              ENDDATE: "2024-07-26T00:00:00.000Z",
              USE_STARTDATE: "2020-07-27T00:00:00.000Z",
              USE_ENDDATE: "2024-07-26T00:00:00.000Z",
              TERM_STARTDATE: "2022-07-27T00:00:00.000Z",
              TERM_ENDDATE: "2023-07-26T00:00:00.000Z",
              ACTUAL: "NEIN",
              BONUSPERIODID: null,
              BONUSPERIOD_COMMENT: null,
              TERM_TYPE: "Standard",
              CONTRACTSTATE: "Laufend",
              TERMINATE_REASON: null,
              RESSORTGROUPID: 1,
              FIRST_NAME: "Dominic",
              LAST_NAME: "Ammann",
              STREET: "Hauptstrasse 138",
              ZIP_CODE: "8274",
              CITY: "Tägerwilen",
              BIRTHDAY: "1999-12-28T00:00:00.000Z",
            },
            {
              CONTRACTID: 72102,
              CONTRACTIDSEQUENCEID: "72102_4",
              CONTRACTTYP: "Hauptvertrag",
              GROUP_NAME: "Mitarbeiter Abos",
              NAME: "Kombi Card Mitarbeiter",
              STARTDATE: "2020-07-27T00:00:00.000Z",
              ENDDATE: "2024-07-26T00:00:00.000Z",
              USE_STARTDATE: "2020-07-27T00:00:00.000Z",
              USE_ENDDATE: "2024-07-26T00:00:00.000Z",
              TERM_STARTDATE: "2023-07-27T00:00:00.000Z",
              TERM_ENDDATE: "2024-07-26T00:00:00.000Z",
              ACTUAL: "JA",
              BONUSPERIODID: null,
              BONUSPERIOD_COMMENT: null,
              TERM_TYPE: "Standard",
              CONTRACTSTATE: "Laufend",
              TERMINATE_REASON: null,
              RESSORTGROUPID: 1,
              FIRST_NAME: "Dominic",
              LAST_NAME: "Ammann",
              STREET: "Hauptstrasse 138",
              ZIP_CODE: "8274",
              CITY: "Tägerwilen",
              BIRTHDAY: "1999-12-28T00:00:00.000Z",
            },
            {
              CONTRACTID: 151278,
              CONTRACTIDSEQUENCEID: "151278_1",
              CONTRACTTYP: "Hauptvertrag",
              GROUP_NAME: "Kombi Card Abos",
              NAME: "3 Jahres Abonnement Kombi",
              STARTDATE: "2024-07-27T00:00:00.000Z",
              ENDDATE: "2027-07-26T00:00:00.000Z",
              USE_STARTDATE: "2024-07-27T00:00:00.000Z",
              USE_ENDDATE: "2027-07-26T00:00:00.000Z",
              TERM_STARTDATE: "2024-07-27T00:00:00.000Z",
              TERM_ENDDATE: "2027-07-26T00:00:00.000Z",
              ACTUAL: "NEIN",
              BONUSPERIODID: null,
              BONUSPERIOD_COMMENT: null,
              TERM_TYPE: "Standard",
              CONTRACTSTATE: "Aktiviert",
              TERMINATE_REASON: null,
              RESSORTGROUPID: 1,
              FIRST_NAME: "Dominic",
              LAST_NAME: "Ammann",
              STREET: "Hauptstrasse 138",
              ZIP_CODE: "8274",
              CITY: "Tägerwilen",
              BIRTHDAY: "1999-12-28T00:00:00.000Z",
            },
          ]
        } else {
          tacContractInfos = await api.fetchTACContractDetails(this.user._id)
        }
        this.tacContractInfos = tacContractInfos.sort((a, b) => {
          // Zuerst nach CONTRACTSTATE sortieren (Laufend zuoberst)
          if (a.CONTRACTSTATE === "Laufend" && b.CONTRACTSTATE !== "Laufend") {
            return -1
          }
          if (a.CONTRACTSTATE !== "Laufend" && b.CONTRACTSTATE === "Laufend") {
            return 1
          }

          // Dann nach TERM_ENDDATE sortieren (neueste zuoberst)
          const momentA = this.$moment(a.TERM_ENDDATE)
          const momentB = this.$moment(b.TERM_ENDDATE)

          if (momentA.isAfter(momentB)) {
            return -1
          }
          if (momentA.isBefore(momentB)) {
            return 1
          }

          return 0 // Keine Änderung in der Reihenfolge
        })
        console.log(this.tacContractInfos)
      } catch (e) {
        console.log("Fehler beim laden der TAC infos")
      }
    },
  },
  computed: {
    showPremiumDialog() {
      return this.showExistingGuestEntry !== null
    },
    filteredGuestEntries() {
      return this.guestEntries.filter((entry) => {
        return this.$moment(this.$moment(entry.date)).isAfter(this.runningTACContracts.find((cont) => cont.NAME.includes("PREMIUM")).TERM_STARTDATE)
      })
    },
    runningTACContracts() {
      if (!this.tacContractInfos) return []

      return this.tacContractInfos.filter(
        (contract) =>
          contract.CONTRACTSTATE === "Laufend" &&
          !this.$moment().isAfter(this.$moment(contract.TERM_ENDDATE)) &&
          this.$moment().isAfter(this.$moment(contract.TERM_STARTDATE))
      )
    },
    oldTACContracts() {
      if (!this.tacContractInfos) return []
      return this.tacContractInfos.filter(
        (contract) => !(contract.CONTRACTSTATE === "Laufend" && !this.$moment().isAfter(this.$moment(contract.TERM_ENDDATE)))
      )
    },
    isLocal() {
      return location.host.includes("local")
    },
  },
  watch: {
    showAttachmentDialog: function (val) {
      if (val) this.checkImageSources(this.contracts[this.showAttachmentDialog.split("-")[1]].customer.additionalImage)
    },
    showPaymentDialog: async function (val) {
      if (val) {
        let index = val.replace("-contractPaymentIndex", "")

        let contractId = this.contracts[parseInt(index)].contractId
        console.log(contractId)
        let contract = await api.fetchContract(contractId)
        this.tempContract = contract
      } else {
        this.tempContract = null
      }
    },
  },
}
</script>
<style scoped lang="scss">
.clickable {
  cursor: pointer;
}
</style>
