<template>
  <v-container fluid style="max-width: 90vw !important">
    <v-row>
      <v-col cols="3">
        <v-row class="mb-1">
          <v-card outlined style="width: 100%" class="rounded-lg pa-1">
            <v-card-title>Mitarbeiter hinzufügen</v-card-title>
            <v-list>
              <v-list-item :key="employee._id" v-for="employee of employees">
                <v-list-item-avatar>
                  <v-img :src="'img' + employee.picture"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="employee.fullName"></v-list-item-title>
                  <v-list-item-subtitle v-html="employee.studio[0].title"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" transition="scale-transition" :origin="dialogOrigin" max-width="500">
      <v-card>
        <v-card-title>Neuer Mitarbeiter</v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="newEmployee.name"></v-text-field>
          <v-text-field label="Position" v-model="newEmployee.position"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Abbrechen</v-btn>
          <v-btn color="primary" text @click="addEmployee">Hinzufügen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/api"

export default {
  name: "ShiftManagementMainDialogCreateEmployee",
  data() {
    return {
      dialog: false,
      employees: [],
      newEmployee: {
        name: "",
        position: "",
      },
      settings: null,
      // Passe den Origin-Wert an, um das Erscheinen des Dialogs zu steuern.
      // Zum Beispiel 'top center' lässt es so wirken, als käme es vom Button.
      dialogOrigin: "top center",
    }
  },
  async created() {
    this.employees = await api.shiftManagement.fetchShiftManagementEmployee()
  },
  methods: {
    addEmployee() {
      if (this.newEmployee.name.trim() !== "") {
        this.employees.push({ ...this.newEmployee })
        // Formular zurücksetzen
        this.newEmployee.name = ""
        this.newEmployee.position = ""
        this.dialog = false
      }
    },
  },
}
</script>

<style scoped>
/* Weitere Styles nach Bedarf */
</style>
