<template>
  <v-card dark min-height="80vh" style="border-radius: 50px; padding: -10px">
    <v-row no-gutters>
      <v-col :cols="isMobile ? 3 : 1">
        <v-btn fab class="ml-6 mt-6" small color="white" @click="$router.push('/dashboard/user/search')">
          <v-icon color="black">mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col :cols="isMobile ? 7 : 10">
        <v-card-title class="h3 pl-0 mt-3 ml-1" :class="{ 4: isMobile }" style="font-weight: bold; word-break: break-word">
          {{ user.vorname ? user.vorname + " " + user.nachname : "Wird geladen..." }}
        </v-card-title>
      </v-col>
      <v-col cols="1">
        <v-btn v-if="activeGroup === 'infos' && user" class="mt-5" color="error" icon large @click="deleteUser()">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center" v-if="isMobile">
        <v-avatar color="primary" size="100">
          <img
            style="object-fit: cover"
            :src="user.bild ? '/img/profilbilder' + user.bild : 'https://app.wellcomefit.ch/api/checkin/pool/images/' + user._id" />
        </v-avatar>
      </v-col>
    </v-row>
    <v-row no-gutters class="justify-space-between">
      <v-chip-group class="ml-2" mandatory v-model="activeGroup" @change="categoryChanged" active-class="primary--text">
        <v-chip :key="'chip' + index" class="pa-1" :value="category.value" v-for="(category, index) of categories">
          {{ category.text }}
        </v-chip>
      </v-chip-group>
      <v-btn v-if="false" class="mr-2" icon color="grey"><v-icon>mdi-cog</v-icon></v-btn>
    </v-row>
    <v-row no-gutters>
      <v-col v-if="loading" cols="12" class="text-center align-items-center d-flex flex-column justify-content-center" style="height: 40vh">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-col>
      <user-dashboard-customer-infos :isMobile="isMobile" v-show="activeGroup === 'infos'" :user="user"></user-dashboard-customer-infos>
      <user-dashboard-customer-training
        v-if="activeGroup === 'training'"
        v-show="!loading"
        :user="user"
        @loaded="loading = false"></user-dashboard-customer-training>
      <user-dashboard-contracts
        @refetchUser="refetchUser"
        v-if="activeGroup === 'contracts'"
        v-show="!loading"
        :isMobile="isMobile"
        @loaded="loading = false"
        :user="user"></user-dashboard-contracts>
      <user-dashboard-documents
        v-if="activeGroup === 'documents'"
        v-show="!loading"
        @loaded="loading = false"
        :user="user"></user-dashboard-documents>
      <user-dashboard-membercard
        v-if="activeGroup === 'membercard'"
        v-show="!loading"
        @loaded="loading = false"
        :user="user"></user-dashboard-membercard>
      <user-dashboard-nutrition
        v-if="activeGroup === 'nutrition'"
        v-show="!loading"
        @reFetchUser="refetchUser()"
        @loaded="loading = false"
        :user="user"></user-dashboard-nutrition>
      <user-dashboard-interactions
        v-if="activeGroup === 'interactions'"
        v-show="!loading"
        @loaded="loading = false"
        :user="user"></user-dashboard-interactions>
    </v-row>
  </v-card>
</template>
<script>
import basicDialog from "../../../components/tools/basicDialog"
import Slim from "../../../plugins/slimImage/slim/slim.vue"
import api from "../../../api"
import $ from "jquery"
import userService from "../../../services/userService"

import userDashboardCustomerInfos from "./userDashboardCustomerInfos"
import userDashboardCustomerTraining from "./userDashboardCustomerTraining"
import userDashboardDocuments from "./userDashboardDocuments"
import userDashboardMembercard from "./userDashboardMembercard"
import userDashboardContracts from "./userDashboardContracts"
import userDashboardNutrition from "./userDashboardNutrition"
import userDashboardInteractions from "./userDashboardInteractions"

// import style (<= Swiper 5.x)
import "swiper/css/swiper.css"
export default {
  components: {
    userDashboardCustomerInfos,
    userDashboardCustomerTraining,
    userDashboardDocuments,
    userDashboardMembercard,
    userDashboardContracts,
    userDashboardNutrition,
    userDashboardInteractions,
  },
  data() {
    return {
      categories: [
        { text: "Infos", value: "infos" },
        { text: "Training", value: "training" },
        { text: "Verträge", value: "contracts" },
        { text: "Ernährung", value: "nutrition" },
        { text: "Interaktionen", value: "interactions" },
        { text: "11-Tage-Mitgliedskarte", value: "membercard" },
        { text: "Dokumente", value: "documents" },
      ],
      activeGroup: "infos",
      userService,
      search: "",
      showRegisterDialog: false,
      userSearchValue: null,
      user: {},
      roles: [],
      specialRights: [],
      selected: [],
      loading: false,
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.fetchUser({ _id: this.$route.params.id })
    }
  },
  methods: {
    refetchUser() {
      if (this.$route.params.id) {
        this.fetchUser({ _id: this.$route.params.id })
      }
    },
    categoryChanged(value) {
      this.loading = this.activeGroup !== "infos"
    },
    deleteUser() {
      if (
        confirm(
          "Wollen Sie den Benutzer " +
            this.user.vorname +
            " " +
            this.user.nachname +
            " wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden"
        )
      ) {
        if (confirm("Ganz sicher?")) {
          api
            .deleteUser({ benutzer_id: this.user._id })
            .then((data) => {
              if (data.status) {
                alert("Benutzer gelöscht")
                this.$router.push("/dashboard/user/search")
              }
            })
            .catch((error) => {
              alert("fehler")
            })
        }
      }
    },
    async fetchUser(user) {
      console.log(user)
      let tempuser = await api.getCustomerDashboard(user._id)

      this.user = tempuser
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    },
    isLocal() {
      return location.host.includes("local")
    },

    isAdmin: function () {
      return localStorage.getItem("admin")
    },
  },
}
</script>
