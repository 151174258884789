import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchOffers() {
    const url = buildUrl("website/offers")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchServices() {
    const url = buildUrl("website/services")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  toggleOfferService(id, action) {
    const url = buildUrl(`website/offers-and-services/${id}/${action}`)
    return axios.put(url, {}, buildOptions()).then((res) => res.data)
  },
  fetchOffersAndServices() {
    const url = buildUrl("website/offers-services")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createOffer(data) {
    const url = buildUrl("website/offers")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateOffer(id, data) {
    const url = buildUrl(`website/offers/${id}`)
    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },
  deleteOffer(id) {
    const url = buildUrl(`website/offers/${id}/delete`)
    return axios.put(url, {}, buildOptions()).then((res) => res.data)
  },
  fetchJobOffers() {
    const url = buildUrl("website/joboffers")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createJobOffer(data) {
    const url = buildUrl("website/joboffers")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateJobOffer(id, data) {
    const url = buildUrl(`website/joboffers/${id}`)
    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },
  fetchJobTypes() {
    let url = buildUrl("website/jobtypes")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllJobTypes() {
    let url = buildUrl("website/jobtypes/all")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveJobType(data) {
    let url = buildUrl("website/jobtypes")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateJobType(id, data) {
    let url = buildUrl(`website/jobtypes/${id}`)
    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },
  deleteJobType(id) {
    let url = buildUrl(`website/jobtypes/${id}/delete`)
    return axios.put(url, {}, buildOptions()).then((res) => res.data)
  },
  restoreJobType(id) {
    let url = buildUrl(`website/jobtypes/${id}/restore`)
    return axios.put(url, {}, buildOptions()).then((res) => res.data)
  },

  fetchWebsiteContent(code) {
    let url = buildUrl("website/content/" + code)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateWebsiteContent(content) {
    let url = buildUrl("website/content")
    return axios.post(url, { content }, buildOptions()).then((res) => res.data)
  },
  updateJob(job) {
    let url = buildUrl("website/job")
    return axios.post(url, job, buildOptions()).then((res) => res.data)
  },
  fetchJobOffers() {
    let url = buildUrl("website/joboffers")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchJobs() {
    let url = buildUrl("website/jobs")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
