import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  submitTimePayloadForAdmin(payload) {
    let url = buildUrl("timetracker/faker")
    return axios.post(url, payload, buildOptions()).then((res) => res.data)
  },

  fetchAllTimeEntriesEmployee(date) {
    let url = buildUrl("timetracker/faker/" + date)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  submitTimeTrackerMonth(data) {
    let url = buildUrl("timetracker/month/submit")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  submitTimeTrackerDailyNote(data) {
    let url = buildUrl("timetracker/update/dailyNotes")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  selfSubmitTimetrackerMonth(month) {
    let url = buildUrl("timetracker/month/selfsubmit")
    return axios.post(url, month, buildOptions()).then((res) => res.data)
  },
  savePreSavedAttendancesToDB(attendances, user) {
    let url = buildUrl("timetracker/save/presaved/attendances/to/db")
    return axios.post(url, { attendances: attendances, user }, buildOptions()).then((res) => res.data)
  },
  updateTimeTrackerMonth(data) {
    let url = buildUrl("timetracker/month/update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  fetchVacationDaysLeft() {
    let url = buildUrl("timetracker/vacation/days/left")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchHolidayEvents(data) {
    let url = buildUrl("timetracker/overview/management/events")
    return axios
      .post(url, { range: data.datePickerRange, studio: data.selectedStudioSingle, studioManager: data.studioManager }, buildOptions())
      .then((res) => res.data)
  },
  changeHolidayStatus(data) {
    let url = buildUrl("timetracker/holiday/management/change/" + data._id + "/" + data.status)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTimeTrackerDemandCorrection(selectedDate) {
    let url = buildUrl(`timetracker/demand/correction/entries/${selectedDate}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchManagementTimeEntries(selectedDate) {
    let url = buildUrl(`timetracker/management/entries/${selectedDate}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchOfficeTimeEntries(selectedDate, studio) {
    let url = buildUrl(`timetracker/office/entries/${selectedDate}/${studio}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchOfficeTimeEntriesForStudio(selectedDate, studio) {
    let url = buildUrl(`timetracker/office/entries/for/studio`)
    return axios.post(url, { selectedDate, studio }, buildOptions()).then((res) => res.data)
  },
  fetchTimetrackerCalendarEntries(dates, showCorrection) {
    let url = buildUrl(`timetracker/entries/calendar/${dates.start}/${dates.end}/${showCorrection}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTechnicTeamEvents(data) {
    let url = buildUrl(`timetracker/overview/technic/${data.dates.start}/${data.dates.end}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllTimetrackerCalendarEntries() {
    let url = buildUrl("timetracker/entries/calendar")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  deleteTimetrackerEntryEmployee(id) {
    let url = buildUrl("timetracker/entry/employee/delete/" + id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  deleteTimetrackerEntryOffice(id) {
    let url = buildUrl("timetracker/office/employee/delete/" + id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  addTimetrackerEntryOffice(data) {
    let url = buildUrl("timetracker/office/employee/create")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateTimetrackerEntryOffice(data) {
    let url = buildUrl("timetracker/office/employee/update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateTimetrackerEntryEmployee(data) {
    let url = buildUrl("timetracker/entry/employee/update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  /*deleteSickEntryManagement(id) {
		let url = buildUrl('timetracker/management/sick/' + id)
		return axios.delete(url, buildOptions()).then((res) => res.data)
	}, */
  createSickEntryManagement(data) {
    let url = buildUrl("timetracker/management/sick")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  deleteSickEntry(data) {
    let url = buildUrl("timetracker/sick/delete")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  createFreeWishEntryEmployee(data) {
    let url = buildUrl("timetracker/freewish/employee/create")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchFreeWishEntriesEmployee() {
    let url = buildUrl("timetracker/freewish/employee/list")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  changeFreeWishStatus(data) {
    let url = buildUrl("timetracker/freewish/management/change/" + data._id + "/" + data.status)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createHolidayEntryEmployee(data) {
    let url = buildUrl("timetracker/holiday/employee/create")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchHolidayEntryEmployee() {
    let url = buildUrl("timetracker/holiday/employee/list")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchHolidayEntryEmployeeStudioManagement(studio, status) {
    let url = buildUrl(`timetracker/holiday/employee/management/${studio}/${status}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createTripControlEntryEmployee(data) {
    let url = buildUrl("timetracker/trip/employee/create")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  createTimetrackerEntryEmployee(data) {
    let url = buildUrl("timetracker/entry/employee/create")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  getTimetrackerEntriesAdmin(day) {
    let url = buildUrl(`timetracker/entry/employee/admin/fetch/${day}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
