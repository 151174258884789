<template>
  <v-dialog fullscreen v-model="dialog">
    <v-card dark>
      <v-card-actions class="py-5">
        <v-btn color="white" outlined @click="$emit('close')">
          <v-icon left>mdi-chevron-left</v-icon>
          Zurück
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="orange" @click="$emit('download-document', document)">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn color="primary" @click="$emit('edit-document', document)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-actions>

      <!-- PDF-Vorschau -->
      <div v-if="!isApp">
        <div v-if="previewBlobUrl" class="preview-wrapper" :style="{ height: previewHeight + 'px' }">
          <iframe :src="previewBlobUrl" style="width: 100%; height: 100%" frameborder="0"></iframe>
          <div class="resizer" @mousedown="onStartResize"></div>
          <div v-if="isResizing" class="resize-overlay" @mousemove="onResize" @mouseup="onStopResize"></div>
        </div>

        <!-- HTML-Vorschau (z.B. aus einem DOCX) -->
        <div v-else-if="previewHtml" class="preview-html-wrapper" :style="{ height: previewHeight + 'px' }">
          <div class="preview-html-content">
            <p class="preview-label">Vorschau aus Word-Dokument</p>
            <div v-html="previewHtml"></div>
          </div>
          <div class="resizer" @mousedown="onStartResize"></div>
          <div v-if="isResizing" class="resize-overlay" @mousemove="onResize" @mouseup="onStopResize"></div>
        </div>

        <!-- Fallback: Skeleton Loader -->
        <div v-else>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </div>
      </div>
      <div v-else>
        <v-alert class="mx-5" type="info">Vorschau in der App nicht möglich, lade das PDF herunter um es anzusehen</v-alert>
      </div>

      <v-card-text>
        <v-tabs v-model="localActiveTab" centered dark>
          <v-tab>Details</v-tab>
          <v-tab>Kommentare</v-tab>
          <v-tab>Zugriffsverlauf</v-tab>
          <v-tab>Zugewiesene Benutzer</v-tab>
        </v-tabs>
        <v-tabs-items dark v-model="localActiveTab">
          <!-- Tab: Details -->
          <v-tab-item>
            <div style="max-width: 1500px; margin: auto">
              <v-alert class="mb-0 mt-5" :type="alertTypeByStatus(document.approvalStatus)">
                <div class="d-flex align-center justify-space-between">
                  <span class="mr-2">
                    {{ alertTextByStatus(document.approvalStatus) }}
                  </span>
                  <v-btn v-if="document.approvalStatus === 'notSigned'" outlined @click="switchTab(1)" small> Jetzt visieren </v-btn>
                </div>
              </v-alert>
              <v-row class="mt-5">
                <!-- Card 1: Allgemeine Informationen -->
                <v-col cols="12" md="4">
                  <v-card max-width="500px" class="mb-4 mx-auto fill-height" color="secondary darken-2" elevation="3">
                    <v-card-title class="d-flex align-center pb-0">
                      <v-icon class="mr-2">mdi-information</v-icon>
                      <span>Allgemeine Informationen</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row align="center">
                        <v-col cols="4" class="font-weight-bold">Titel:</v-col>
                        <v-col cols="8">
                          {{ document.title || "Kein Titel" }}
                        </v-col>
                      </v-row>
                      <v-divider class="my-2"></v-divider>
                      <v-row>
                        <v-col cols="12" class="font-weight-bold pb-1"> Beschreibung: </v-col>
                        <v-col cols="12" class="pt-0">
                          {{ document.description || "Keine Beschreibung" }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- Card 2: Rechnungsdetails -->
                <v-col cols="12" md="4">
                  <v-card max-width="500px" class="mb-4 mx-auto fill-height" color="secondary darken-2" elevation="3">
                    <v-card-title class="d-flex align-center pb-0">
                      <v-icon class="mr-2">mdi-file-document-outline</v-icon>
                      <span>Rechnungsdetails</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row align="center">
                        <v-col cols="4" class="font-weight-bold">Datum:</v-col>
                        <v-col cols="8">
                          <span v-if="document.date">
                            {{ formatDate(document.date) }}
                          </span>
                          <span v-else>Kein Datum</span>
                        </v-col>
                      </v-row>
                      <v-divider class="my-2"></v-divider>
                      <v-row align="center">
                        <v-col cols="4" class="font-weight-bold"> Rechnungssteller: </v-col>
                        <v-col cols="8">
                          <span v-if="document.invoiceIssuer">
                            {{ document.invoiceIssuer.title }}
                          </span>
                          <span v-else>Kein Rechnungssteller</span>
                        </v-col>
                      </v-row>
                      <v-divider class="my-2"></v-divider>
                      <v-row align="center">
                        <v-col cols="4" class="font-weight-bold"> Rechnungs Nr: </v-col>
                        <v-col cols="8">
                          <span v-if="document.invoiceNumber">
                            {{ document.invoiceNumber }}
                          </span>
                          <span v-else>Keine Rechnungsnummer</span>
                        </v-col>
                      </v-row>
                      <v-divider class="my-2"></v-divider>
                      <v-row align="center">
                        <v-col cols="4" class="font-weight-bold">Betrag:</v-col>
                        <v-col cols="8">
                          <span v-if="document.amount !== null">
                            {{ document.amount }}
                          </span>
                          <span v-else>Kein Betrag</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- Card 3: Mail-Informationen -->
                <v-col cols="12" md="4">
                  <v-card max-width="500px" class="mb-4 mx-auto fill-height" color="secondary darken-2" elevation="3">
                    <v-card-title class="d-flex align-center pb-0">
                      <v-icon class="mr-2">mdi-email</v-icon>
                      <span>Mail-Informationen</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <div v-if="mailDetails">
                        <v-row align="center">
                          <v-col cols="4" class="font-weight-bold">Datum:</v-col>
                          <v-col cols="8"> {{ formatDateTime(mailDetails.date) }} Uhr </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row align="center">
                          <v-col cols="4" class="font-weight-bold"> Mail Betreff: </v-col>
                          <v-col cols="8">{{ mailDetails.subject }}</v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row align="center">
                          <v-col cols="4" class="font-weight-bold">Von:</v-col>
                          <v-col cols="8">
                            {{ mailDetails.from.join(", ") }}
                          </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row align="center">
                          <v-col cols="4" class="font-weight-bold">An:</v-col>
                          <v-col cols="8">
                            {{ mailDetails.to.join(", ") }}
                          </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row align="center">
                          <v-col cols="4" class="font-weight-bold"> Dateiname: </v-col>
                          <v-col cols="8">
                            {{ document.originalFileName }}
                          </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row>
                          <v-col cols="12" class="font-weight-bold pb-1"> Text: </v-col>
                          <v-col cols="12" class="pt-0">
                            {{ mailDetails.text || "Kein Mail Text vorhanden" }}
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else>
                        <p>Keine Mail-Informationen vorhanden.</p>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <!-- Tab: Kommentare -->
          <v-tab-item>
            <v-card max-width="500px" class="mx-auto mt-2" dark flat>
              <v-list dense>
                <v-list-item v-for="(comment, index) in document.comments" :key="index">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{ comment.user.vorname }} {{ comment.user.nachname }}
                      <v-chip small :color="comment.status === 'accepted' ? 'green' : 'red'">
                        {{ comment.status === "accepted" ? "akzeptiert" : "abgelehnt" }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle> {{ formatDateTime(comment.createdAt) }} Uhr </v-list-item-subtitle>
                    <span>{{ comment.comment }}</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-textarea v-model="newComment" label="Neuer Kommentar" auto-grow outlined></v-textarea>
              <v-btn color="primary" text @click="decideAssignment('accepted')"> Akzeptieren </v-btn>
              <v-btn color="error" text @click="decideAssignment('rejected')"> Ablehnen </v-btn>
            </v-card>
          </v-tab-item>
          <!-- Tab: Zugriffsverlauf -->
          <v-tab-item>
            <v-card max-width="500px" class="mx-auto mt-2" dark flat>
              <v-list dense v-if="document.accessHistory && document.accessHistory.length > 0">
                <v-list-item v-for="(entry, index) in document.accessHistory" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ entry.user.vorname ? entry.user.vorname + " " + entry.user.nachname : entry.user }}
                      {{ entry.action === "removed" ? "entzogen von" : "erhalten von" }}
                      {{ entry.grantedBy.vorname }} {{ entry.grantedBy.nachname }}
                    </v-list-item-title>
                    <v-list-item-subtitle> {{ formatDateTime(entry.grantedAt) }} Uhr </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-alert v-else type="info" class="mt-5"> Keine Zuweisungen gefunden </v-alert>
            </v-card>
          </v-tab-item>
          <!-- Tab: Zugewiesene Benutzer -->
          <v-tab-item>
            <v-card max-width="500px" class="mx-auto mt-2 pt-5" dark flat>
              <user-search
                class="mx-auto"
                :value="newAssignedUser"
                ignoreRights
                instructor
                :disabled="false"
                :key="newAssignedUser"
                label="Benutzer suchen"
                @update="updateNewAssignedUser"></user-search>
              <v-select v-show="false" v-model="newAssignedUserRights" :items="['read', 'comment', 'manage']" label="Rechte"></v-select>
              <v-row>
                <v-col cols="12" class="my-2" align="center">
                  <v-btn color="primary" @click="addAssignedUser"> Hinzufügen </v-btn>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-card-title class="pb-0">Zugewiesene Benutzer</v-card-title>
              <v-alert v-if="!(document.assignedUsers && document.assignedUsers.length > 0)" class="mt-5" type="info">
                Keine Benutzer zugewiesen
              </v-alert>
              <v-list dense>
                <v-list-item v-for="(assignment, index) in document.assignedUsers" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ assignment.user.vorname ? assignment.user.vorname + " " + assignment.user.nachname : assignment.user }}
                    </v-list-item-title>
                    <v-list-item-subtitle> Rechte: {{ assignment.rights }} </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon small @click="removeAssignedUser(index)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import userSearch from "@/components/userSearch.vue"

export default {
  name: "DocumentActionsDialog",
  components: { userSearch },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    previewBlobUrl: {
      type: String,
      default: null,
    },
    previewHtml: {
      type: String,
      default: null,
    },
    previewHeight: {
      type: Number,
      default: 200,
    },
    isResizing: {
      type: Boolean,
      default: false,
    },
    mailDetails: {
      type: Object,
      default: null,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    newComment: {
      type: String,
      default: "",
    },
    newAssignedUser: {
      type: [String, Object],
      default: null,
    },
    newAssignedUserRights: {
      type: String,
      default: "manage",
    },
  },
  data() {
    return {
      localActiveTab: this.activeTab,
    }
  },
  watch: {
    activeTab(newVal) {
      this.localActiveTab = newVal
    },
    localActiveTab(newVal) {
      this.$emit("update:activeTab", newVal)
    },
  },
  computed: {
    isApp() {
      return window.flutter_inappwebview || false
    },
  },
  methods: {
    alertTextByStatus(status) {
      if (status === "signed") return "Dokument ist visiert"
      if (status === "notSigned") return "Dokument ist noch nicht visiert"
      if (status === "declined") return "Dokument wurde abgelehnt"
      return "Fehler"
    },
    alertTypeByStatus(status) {
      if (status === "signed") return "success"
      if (status === "notSigned") return "info"
      if (status === "declined") return "error"
      return "info"
    },
    formatDate(date) {
      return this.$moment(date).format("DD.MM.YYYY") // chasch rasch nümm speichere mue katrin gschwind zeige
    },
    formatDateTime(date) {
      return this.$moment(date).format("DD.MM.YY HH:mm")
    },
    onStartResize(e) {
      this.$emit("start-resize", e)
    },
    onResize(e) {
      this.$emit("resize-preview", e)
    },
    onStopResize(e) {
      this.$emit("stop-resize", e)
    },
    switchTab(tabIndex) {
      this.$emit("update:activeTab", tabIndex)
    },
    decideAssignment(status) {
      if (status === "rejected" && !(this.newComment && this.newComment.length > 0)) {
        this.$toast.error("Gib bitte eine Begründung an")
        return
      }
      this.$emit("decide-assignment", { status, comment: this.newComment })
      this.newComment = ""
    },
    removeAssignedUser(index) {
      this.$emit("remove-assigned-user", index)
    },
    addAssignedUser() {
      this.$emit("add-assigned-user")
    },
    updateNewAssignedUser(value) {
      this.$emit("update-new-assigned-user", value)
    },
  },
}
</script>

<style scoped>
.preview-wrapper {
  position: relative;
  width: 100%;
  overflow: auto;
  border: 1px solid #444;
}

.preview-html-wrapper {
  position: relative;
  width: 80%;
  max-width: 800px;
  min-width: 300px;
  margin: auto;
  overflow: auto;
  border: 1px solid #444;
  background-color: white;
  scrollbar-width: none;
}

.preview-html-content {
  padding: 60px 16px 16px 16px;
  color: black;
  position: relative;
}

.preview-label {
  top: 0px;
  right: 0px;
  width: 100%;
  position: absolute;
  font-weight: bold;
  margin-bottom: 8px;
  background-color: #171717;
  color: white;
  padding: 10px;
  text-align: center;
}

.resizer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  cursor: ns-resize;
  background-color: rgba(255, 255, 255, 0.2);
}

.resize-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  cursor: ns-resize;
  background: transparent;
}
</style>
