<template>
  <v-card class="fill-height d-flex flex-column justify-space-between" style="background-color: #303030">
    <v-card-title>Notizen</v-card-title>
    <v-card-text style="overflow: auto">
      <v-textarea
        v-model="localNotes"
        @blur="saveNotes"
        outlined
        :rows="enlarge ? 10 : 5"
        placeholder="Noch keine Notizen hinzgefügt..."
        style="width: 100%"></v-textarea>
    </v-card-text>
    <v-card-actions style="justify-content: right">
      <v-btn text x-small @click="toggleEnlarge">vergrössern</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import api from "../../../api"
import userService from "../../../services/userService"
import userSearch from "../../../components/userSearch.vue"

export default {
  props: {
    enlarge: {
      required: true,
      default: false,
    },
    notes: {
      required: true,
      default: "",
    },
    userId: {
      required: true,
      default: "",
    },
  },
  data() {
    return {
      localNotes: {},
    }
  },
  created() {
    this.localNotes = JSON.parse(JSON.stringify(this.notes))
  },
  methods: {
    toggleEnlarge() {
      this.$emit("toggleEnlarge")
    },
    async saveNotes() {
      try {
        await api.updateCoachNote({
          userId: this.userId,
          notes: this.localNotes,
        })
        this.$toast.success("Notizen gespeichert")
      } catch (error) {
        console.log(error)
        this.$toast.error("Fehler beim Speichern der Notizen")
      }
    },
  },
}
</script>
