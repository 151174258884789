import WorkoutCreate from "@/views/workouts/WorkoutCreate.vue"
import WorkoutCreateNew from "@/views/workouts/WorkoutCreateNew.vue"
import WorkingoutCompleted from "@/views/workouts/WorkingoutCompleted.vue"
import WorkingoutCompletedOld from "@/views/workouts/WorkingoutCompletedOld.vue"
import WorkoutEdit from "@/views/workouts/WorkoutEdit.vue"
import DWorkouts from "@/views/workouts/Workouts.vue"
import WorkoutRun from "@/views/workouts/Workingout.vue"
import PlanRun from "@/views/workouts/PlanRun.vue"
import WorkoutStatistik from "@/views/workouts/Statistik.vue"

export default [
  {
    path: "/workouts",
    name: "Workouts",
    component: DWorkouts,
    meta: { navbarTitle: "Deine Workouts" },
    children: [
      { path: "create", name: "WorkoutCreate", component: WorkoutCreate },
      {
        path: "createnew",
        name: "WorkoutCreateNew",
        component: WorkoutCreateNew,
        meta: { hideBottomNavigation: true },
      },
      { path: "edit/:id", name: "WorkoutEdit", component: WorkoutCreate },
    ],
  },
  {
    path: "/workouts/completed",
    name: "WorkoutCompleted",
    component: WorkingoutCompleted,
  },
  {
    path: "/workouts/completed/pre",
    name: "WorkoutCompletedOld",
    component: WorkingoutCompletedOld,
  },
  {
    path: "/workouts/run/:id",
    name: "WorkoutRun",
    component: WorkoutRun,
  },
  {
    path: "/plan/run/:id",
    name: "planRun",
    component: PlanRun,
  },
]
