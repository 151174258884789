<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card dark>
      <v-card-title>
        <span class="headline">
          {{ mode === "create" ? "Neuen Ordner erstellen" : "Ordner bearbeiten" }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="localFolder.name" label="Name"></v-text-field>
        <v-select
          v-if="mode !== 'create'"
          v-model="localFolder.parent"
          :items="parentOptions"
          label="Elternordner"
          item-text="name"
          item-value="_id"
          clearable></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onSave">Speichern</v-btn>
        <v-btn color="secondary" text @click="$emit('close')">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FolderDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    folderData: {
      type: Object,
      required: true,
    },
    parentOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localFolder: { ...this.folderData },
    }
  },
  watch: {
    folderData: {
      immediate: true,
      handler(newVal) {
        this.localFolder = { ...newVal }
      },
    },
  },
  methods: {
    onSave() {
      this.$emit("save-folder", this.localFolder)
    },
  },
}
</script>
