<template>
  <div>
    <router-view @finishedloading="finishedloading()"></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    finishedloading() {
      this.$emit("finishedloading")
    },
  },
}
</script>
