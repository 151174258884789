<template>
  <v-container>
    <v-row style="height: 60vh; align-content: center">
      <!-- Editieren Card -->
      <v-col cols="6">
        <v-card dark ref="editCard" class="ma-3 pa-3 rounded-xl" hover outlined @click="openDialog('edit', $event)">
          <v-card-title>Editieren</v-card-title>
          <v-card-text>Klicke hier, um einen bestehenden Eintrag zu bearbeiten.</v-card-text>
        </v-card>
      </v-col>

      <!-- Erstellen Card -->
      <v-col cols="6">
        <v-card dark ref="createCard" class="ma-3 pa-3 rounded-xl" hover outlined @click="openDialog('create', $event)">
          <v-card-title>Erstellen</v-card-title>
          <v-card-text>Klicke hier, um einen neuen Eintrag zu erstellen.</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog-Komponente -->
    <shift-management-main-dialog v-model="dialog" :mode="dialogMode" :origin="dialogOrigin" />
  </v-container>
</template>

<script>
import shiftManagementMainDialog from "./shiftManagementMainDialog.vue"

export default {
  name: "shiftManagementMain",
  components: {
    shiftManagementMainDialog,
  },
  data() {
    return {
      dialog: false,
      dialogMode: "create", // 'create' oder 'edit'
      dialogOrigin: "center center",
    }
  },
  methods: {
    openDialog(mode, event) {
      this.dialogMode = mode
      // Berechne den Ursprung (origin) anhand der Position der geklickten Card
      const rect = event.currentTarget.getBoundingClientRect()
      // Errechne den Mittelpunkt der Card
      const centerX = rect.left + rect.width / 2
      const centerY = rect.top + rect.height / 2
      // Setze den origin-Wert als Pixelangabe; dieser wird vom v-dialog als Transform-Origin genutzt.
      this.dialogOrigin = `${centerX}px ${centerY}px`
      // Öffne den Dialog
      this.dialog = true
    },
  },
}
</script>

<style scoped>
.v-card {
  transition: transform 0.3s ease;
}
.v-card:hover {
  transform: scale(1.05);
}
/* Optional: weitere Styles für die Cards */
</style>
