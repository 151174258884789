<template>
  <v-dialog persistent v-model="dialog" max-width="600px">
    <v-card dark>
      <v-card-title>
        <span class="headline">Dokument bearbeiten</span>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="localDocument.title" label="Titel"></v-text-field>
        <v-textarea v-model="localDocument.description" label="Beschreibung"></v-textarea>

        <v-autocomplete v-model="localDocument.studio" :items="mappedStudios" label="Studio" item-text="name" item-value="_id" clearable>
        </v-autocomplete>
        <v-autocomplete
          return-object
          v-model="localSelectedInvoiceIssuer"
          :items="invoiceIssuerOptions"
          :filter="customFilter"
          label="Rechnungssteller"
          item-text="title"
          item-value="_id"
          clearable
          :search-input.sync="localIssuerSearch"
          @keydown.enter.prevent>
          <template v-slot:no-data>
            <v-list-item @click="onCreateInvoiceIssuer(localIssuerSearch)" style="cursor: pointer">
              <v-list-item-content>
                <v-list-item-title>Rechnungssteller "{{ localIssuerSearch }}" hinzufuegen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-text-field v-model="localDocument.amount" label="Betrag" type="number" prepend-icon="mdi-cash"></v-text-field>
        <v-text-field v-model="localDocument.invoiceNumber" label="Rechnungsnummer"></v-text-field>
        <v-menu ref="menu" v-model="menuLocal" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="localDocument.date" label="Datum" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"> </v-text-field>
          </template>
          <v-date-picker v-model="localDocument.date" @input="onDateSelect"></v-date-picker>
        </v-menu>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onSave">Speichern</v-btn>
        <v-btn color="secondary" text @click="$emit('close-document')">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
export default {
  name: "DocumentDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    documentData: {
      type: Object,
      required: true,
    },
    invoiceIssuerOptions: {
      type: Array,
      required: true,
    },
    selectedInvoiceIssuer: {
      type: Object,
      default: null,
    },
    issuerSearch: {
      type: String,
      default: "",
    },
    menu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDocument: { ...this.documentData },
      menuLocal: this.menu,
      localSelectedInvoiceIssuer: this.selectedInvoiceIssuer,
      localIssuerSearch: this.issuerSearch,
    }
  },
  watch: {
    documentData: {
      immediate: true,
      handler(newVal) {
        this.localDocument = { ...newVal }
      },
    },
    selectedInvoiceIssuer(newVal) {
      this.localSelectedInvoiceIssuer = newVal
    },
    issuerSearch(newVal) {
      this.localIssuerSearch = newVal
    },
  },
  methods: {
    customFilter(item, queryText, itemText) {
      return itemText.toString().toLowerCase().includes(queryText.toLowerCase())
    },
    onDateSelect(date) {
      this.$refs.menu.save(date)
    },
    onSave() {
      // Setze den Rechnungsteller anhand des ausgewählten Objekts
      this.localDocument.invoiceIssuer = this.localSelectedInvoiceIssuer ? this.localSelectedInvoiceIssuer._id : null
      this.$emit("save-document", this.localDocument, this.localSelectedInvoiceIssuer)
    },
    onCreateInvoiceIssuer(title) {
      this.$emit("create-new-invoice-issuer", title)
    },
  },
  computed: {
    ...mapState("studioStore", ["storeStudios"]),
    mappedStudios() {
      return this.storeStudios
        .filter((studio) => studio.code && studio.code !== "ot" && studio.code !== "alibey")
        .map((studio) => {
          return { _id: studio._id, name: studio.name }
        })
    },
  },
}
</script>
