import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  // Slides
  fetchSlides() {
    let url = buildUrl("slides")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchMainSlider() {
    let url = buildUrl("main/slider")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveSlide(slide) {
    let url = buildUrl("slide")
    return axios.post(url, { slide }, buildOptions()).then((res) => res.data)
  },

  // Popups
  savePopup(popup) {
    let url = buildUrl("notifications/popup")
    return axios.post(url, { popup }, buildOptions()).then((res) => res.data)
  },
  followLinkPopup(id) {
    let url = buildUrl("notifications/popup/lead/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  readPopup(id) {
    let url = buildUrl("notifications/popup/read/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchPopups() {
    let url = buildUrl("notifications/popups")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchPopup() {
    let url = buildUrl("notifications/popup")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // QR-Code
  fetchQRCode(link) {
    let url = buildUrl("qrcode/image?data=" + link)
    axios({
      url, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      if (window.flutter_inappwebview) {
        var reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onloadend = function () {
          var dataUrl = reader.result
          var base64 = dataUrl.split(",")[1]
          console.log(base64)
          window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "png", "qrCode")
        }
      } else {
        saveAs(response.data, "qrCode")
      }
    })
  },

  // News

  getNews(studio) {
    let url = buildUrl(`news`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  markNewsAsRead(newsId) {
    let url = buildUrl("news/" + newsId + "/read")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getNewsCount() {
    let url = buildUrl(`news/count`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getAllNews(studio) {
    let url = buildUrl(`allnews`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  saveNews(news) {
    let url = buildUrl(`news`)
    return axios.put(url, { news }, buildOptions()).then((res) => res.data)
  },
  createNews(news) {
    let url = buildUrl(`news`)
    return axios.post(url, news, buildOptions()).then((res) => res.data)
  },

  // Campaigns

  submitAdventsverlosung(fd) {
    let url = buildUrl("adventsverlosung")
    return axios.post(url, fd).then((res) => res.data)
  },

  listAdventsverlosung() {
    let url = buildUrl("adventsverlosung")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  createCampaign(campaign) {
    let url = buildUrl(`campaign`)
    return axios.post(url, campaign, buildOptions()).then((res) => res.data)
  },
  signupCampaign(campaign_id, userid, studio_id) {
    let url = buildUrl(`campaignsignup/${campaign_id}`)
    return axios.post(url, { user_id: userid, studio_id: studio_id }, buildOptions()).then((res) => res.data)
  },
  signoffCampaign(campaign_id, userid) {
    let url = buildUrl(`campaignsignoff/${campaign_id}`)
    return axios.post(url, { user_id: userid }, buildOptions()).then((res) => res.data)
  },
  copyCampaign(campaign) {
    let url = buildUrl(`copycampaign`)
    return axios.post(url, { campaign: campaign }, buildOptions()).then((res) => res.data)
  },
  deleteCampaign(campaign) {
    let url = buildUrl(`deletecampaign`)
    return axios.post(url, { campaign: campaign }, buildOptions()).then((res) => res.data)
  },
  getKampagne(id) {
    let url = buildUrl(`kampagne/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getCampaign(studio) {
    let url = buildUrl(`campaign/${studio}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getCampaignList(id) {
    let url = buildUrl(`campaignlist/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getAllCampaigns() {
    let url = buildUrl(`allcampaigns/`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Invites

  deleteWeiterempfehlung(id) {
    let url = buildUrl(`invites/delete/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateInvite(data) {
    let url = buildUrl("invites/update")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  getInvites(data) {
    let url = buildUrl(`invites/studio/${data.filter}/${data.studio}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  searchInvites(data) {
    let url = buildUrl("invites/search")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  sendSchnuppermail(data) {
    let url = buildUrl("schnuppermail")
    return axios.post(url, data).then((res) => res.data)
  },
}
