import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchPartySignups() {
    let url = "https://app.wellcomefit.ch/api/internal/party/list"
    //let url = buildUrl('internal/party/list')
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchRemainingPartySlots() {
    let url = buildUrl("internal/party/slots")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  managePartySignup(data) {
    let url = buildUrl("internal/party/signup")
    return axios.post(url, { data }, buildOptions()).then((res) => res.data)
  },
  fetchSignupAvailabilityParty() {
    let url = buildUrl("internal/party/check")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
