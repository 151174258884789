import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchTayPayPaymentConfig(data) {
    let url = "https://app.wellcomefit.ch/api/tac/pay/payment/configuration/paypage"
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  tacPayTestEmail() {
    let url = buildUrl("email/for/tac/testing")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchPaymentSubmit(data, isDetail, transactionData, merchant, resortID, email, profileID) {
    data.transactionData = transactionData
    data.merchantAccount = merchant
    data.resortID = resortID
    data.email = email
    data.profileID = profileID
    let url = "https://app.wellcomefit.ch/api/tac/pay/payment/submit/" + isDetail
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  verifyAdyenWithDetails(transactionID, redirectResult, retryCounts) {
    //let url = 'https://app.wellcomefit.ch/api/tac/pay/payment/verify/details'
    let url = buildUrl("tac/pay/payment/verify/details")
    return axios.post(url, { transactionID, redirectResult, retryCounts }, buildOptions()).then((res) => res.data)
  },

  fetchPaymentsOverview(date) {
    let url = buildUrl("payment/overview/" + date)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  deletePaymentSetting(id) {
    let url = buildUrl("payment/settings/delete/" + id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  createPaymentSetting(paymentSetting) {
    let url = buildUrl("payment/settings/create")
    return axios.post(url, paymentSetting, buildOptions()).then((res) => res.data)
  },
  fetchAllPaymentSettings() {
    let url = buildUrl("payment/settings/all")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  deletePayment(paymentId) {
    let url = buildUrl("payment/cancel/" + paymentId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  recreatePayment(paymentId) {
    let url = buildUrl("payment/recreate/" + paymentId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getPaymentsForUserSelf() {
    let url = buildUrl("payment/user")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getPaymentsForUser(userId) {
    let url = buildUrl("payment/user/" + userId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  checkPaymentSetting() {
    let url = buildUrl("payment/settings/check")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchPaymentStatus(transactionId) {
    let url = buildUrl("payment/status/" + transactionId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  initPayment(initData) {
    let url = buildUrl("payment/init")
    return axios.post(url, initData, buildOptions()).then((res) => res.data)
  },
  createPayment(paymentData) {
    let url = buildUrl("payment/create")
    return axios.post(url, paymentData, buildOptions()).then((res) => res.data)
  },
}
