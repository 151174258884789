import HomeNew from "@/views/HomeNew.vue"
import consentthing from "@/components/consentDeclaration/consentDeclarationCard.vue"

export default [
  {
    path: "/",
    name: "Home",
    meta: { navbarTitle: "roody" },
    component: HomeNew,
    children: [
      {
        path: "panel",
        name: "employeePanel",
        meta: { hideBottomNavigation: true },
        component: () => import("@/views/employee/employeePanel.vue"),
        children: [
          {
            path: "membercard",
            name: "MembercardView",
            meta: { hideBottomNavigation: true },
            component: () => import("@/views/employee/MembercardView.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    name: "catchAll",
    component: HomeNew,
  },
  { path: "/consent", name: "consent", component: consentthing },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("@/views/launchScreen/launchScreen.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/launchScreen/launchScreen.vue"),
  },
  {
    path: "/register",
    name: "Registrieren",
    component: () => import("@/views/launchScreen/launchScreen.vue"),
  },
]
