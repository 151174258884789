<template>
  <v-card dark elevation="0" max-width="700px" class="mx-auto mb-15 mt-5">
    <v-row class="d-flex justify-center mb-5">
      <v-col cols="12">
        <v-list rounded>
          <v-row>
            <v-col cols="12">
              <v-subheader class="px-5">Übersicht</v-subheader>
            </v-col>
          </v-row>
          <v-row v-if="loading" class="d-flex justify-center">
            <v-col cols="12" class="px-5">
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
          </v-row>
          <v-row v-if="!loading" class="d-flex justify-center">
            <v-col cols="12">
              <v-select class="px-5" :items="studios" label="Studio auswählen" v-model="studioSelect" item-text="name" item-value="_id"></v-select>
            </v-col>
          </v-row>
          <v-row v-if="!loading && userService.hasRight('c_create')">
            <v-col cols="12">
              <v-switch class="mx-5" label="ausgeblendete Verträge anzeigen" v-model="showHiddenContracts"></v-switch>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="!loading">
            <v-col cols="12">
              <v-list-item-group class="mx-0 px-0">
                <v-list-item
                  class="mx-0 px-0"
                  v-for="contract of contracts.filter((c) => c.enabledStudios.some((e) => studioSelect.includes(e)))"
                  v-show="contract.published || (userService.hasRight('c_create') && showHiddenContracts)"
                  :key="contract._id">
                  <v-row no-gutters>
                    <v-col cols="7" class="d-flex align-center px-2">
                      <v-btn v-if="userService.hasRight('c_create')" @click="updatePublishedStatus(contract._id)" icon>
                        <v-icon v-if="contract.published">mdi-eye-check-outline</v-icon>
                        <v-icon v-else>mdi-eye-off-outline</v-icon>
                      </v-btn>
                      <div>{{ contract.internalTitle }}</div>
                    </v-col>
                    <v-col cols="5" class="d-flex flex-row justify-end px-2">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="white" dark v-bind="attrs" v-on="on">
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          <span>{{ getContractTitle(contract) }}</span>
                          <br />
                          <span>Erstellt am:</span>
                          <br />
                          <span> {{ $moment(contract.createdAt).format("DD.MM.YY HH:mm") }} Uhr </span>
                          <br />
                          <br />
                          <span>Wird in folgenden Studios angezeigt:</span>
                          <br />
                          <div v-for="studioId in contract.enabledStudios" :key="studioId + '-studioIndex' + contract._id">
                            <span>{{ getNameOfStudio(studioId) }}</span>
                            <br />
                          </div>
                        </span>
                      </v-tooltip>
                      <v-btn v-if="userService.hasRight('c_create')" @click="copyMode(contract._id)" icon>
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>

                      <v-btn @click="editCreateMode(contract._id)" icon>
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <!--- <v-list-item-icon>
                                        <v-icon>mdi-folder-plus-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="
                                                $moment(contract.createdAt).format('DD.MM.YY') +
                                                ' ' +
                                                getContractTitle(contract) +
                                                ' - ' +
                                                getStudioTags(contract.enabledStudios)
                                            "></v-list-item-title>
                                    </v-list-item-content> --->
                </v-list-item>
              </v-list-item-group>
            </v-col>
            <v-col cols="12" v-if="userService.hasRight('c_create_customer')">
              <v-alert class="mx-5 mt-4" type="info"> Möchtest du ein Zusatzpaket Upgrade erfassen? </v-alert>
            </v-col>
            <v-col cols="12" align="center" v-if="userService.hasRight('c_create_customer')">
              <v-btn @click="$router.push('/dashboard/contract/upgrade/created')" color="orange"> zu den Upgrades </v-btn>
            </v-col>
            <v-col v-if="userService.hasRight('c_create_customer')" class="d-flex justify-center align-center" cols="12">
              <v-btn color="primary" class="mt-5" @click="fetchCompanyConfirmations"> Firmenrabattliste herunterladen </v-btn>
            </v-col>
            <v-col v-if="userService.hasRight('c_create')" class="justify-center d-flex" cols="12">
              <v-btn class="mt-5" text @click="showExcelImport = !showExcelImport">
                <span v-if="!showExcelImport">Neue Liste hochladen</span>
                <span v-else>ausblenden</span>
              </v-btn>
            </v-col>
            <v-col v-if="showExcelImport" cols="12" class="d-flex justify-center mt-5">
              <div class="input-wrapper" @dragover.prevent="handleDragOver" @drop.prevent="handleDrop">
                <label for="jsonFile" class="input-label"> Ziehe deine neue Firmenliste.xlsx (Dateiname ist wichtig) hier rein </label>
                <input id="jsonFile" type="file" class="input-hidden" ref="fileInput" @change="importExcel" />
              </div>
            </v-col>
          </v-row>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import api from "../../../api"
import contractSettings from "./contractSettings.vue"
import contractCreator from "./contractCreator.vue"
import Payment from "../payment/Payment.vue"
import userService from "../../../services/userService"

export default {
  components: { contractSettings, contractCreator, Payment },
  data() {
    return {
      showExcelImport: false,
      searchTimeout: null,
      companyConfirmationsDialog: false,
      htmlContent: "",
      originalHtmlContent: "",
      currentMarkIndex: 0,
      totalMarks: 0,
      searchQuery: "",
      showHiddenContracts: false,
      userService,
      loading: true,
      disableStudioSelect: false,
      contracts: [],
      studios: [],
      userStudio: null,
      studioSelect: null,
    }
  },
  async created() {
    let studios = await api.fetchStudios()

    this.studios = studios

    let userStudio = await api.getUserStudio()
    this.userStudio = userStudio.studio._id.toString()
    this.studioSelect = userStudio.studio._id

    this.fetchContracts()
  },
  methods: {
    async importExcel(event) {
      const file = event.target.files[0]

      if (file.name.toString() !== "Firmenrabattliste.xlsx") return alert("Dateiname muss 'Firmenrabattliste.xlsx' sein")

      try {
        const response = await api.uploadCompanyDiscountList(file, file.name)
        console.log("Upload erfolgreich:", response)
        this.$toast.success("Datei erfolgreich hochgeladen")
      } catch (error) {
        this.$toast.error("Datei konnte nicht hochgeladen werden")
        console.log("Upload fehlgeschlagen:", error)
      }
    },
    handleDrop(event) {
      const files = event.dataTransfer.files
      if (files.length > 0) {
        const file = files[0]
        this.readFile(file)
      }
    },
    async fetchCompanyConfirmations() {
      try {
        await api.fetchCompanyConfirmations()
      } catch (error) {
        console.error(error)
      }
    },
    async updatePublishedStatus(contractId) {
      console.log(contractId)
      let contract = this.contracts.find((c) => c._id === contractId)
      if (!contract) return this.$toast.error("Vertrag nicht gefunden")
      contract.published = !contract.published
      let status = await api.changeContractPublishedStatus({ contract }).catch((e) => this.$toast.error("Fehler beim Aktualisieren des Vertrags"))
      this.$toast.success("Vertrag erfolgreich aktualisiert")
    },
    getNameOfStudio(studioId) {
      let studio = this.studios.find((s) => s._id === studioId)
      return studio.name
    },
    copyMode(id) {
      this.$router.push("/dashboard/contract/settings/" + id)
    },
    getStudioTags(enabledStudios) {
      let tags = ""
      enabledStudios.forEach((e) => {
        let studio = this.studios.find((s) => s._id === e)
        tags += studio.name + ", "
      })
      return tags
    },
    getContractTitle(contract) {
      let title = contract.internalTitle ? contract.internalTitle : contract.formFields.find((e) => e.inputType === "title").inputValue
      return title
    },

    async fetchContracts() {
      let contracts = await api.fetchContracts()
      this.contracts = contracts
      this.loading = false
    },
    editCreateMode(id) {
      if (this.studioSelect.toString() === this.userStudio.toString()) {
        this.$router.push("/dashboard/contract/create/" + id)
      } else {
        this.$toast.error("Bitte wechsel in das richtige Studio, um den Vertrag zu erstellen")
        window.app.$root.$children[0].showStudioSelection = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.input-wrapper {
  width: 300px;
  height: 150px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border-color: #333;
  }
}

.input-hidden {
  display: none;
}

.input-label {
  text-align: center;
}
.contractSettings {
  &__dialog {
    position: relative;
    &-title {
      position: sticky;
      top: 0;
      background-color: white;
      border-bottom: 2px solid var(--v-primary);
      z-index: 9;
    }
    &-actions {
      background-color: #fff;
      border-top: 2px solid var(--v-primary);
      width: 100%;
    }
  }
}
</style>
