<template>
  <v-card dark>
    <v-card-title class="d-flex justify-space-between">
      Ordner
      <v-btn icon color="white" @click="$emit('create-folder')">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-treeview
        activatable
        :items="items"
        item-key="_id"
        item-text="name"
        item-children="children"
        :open.sync="openNodes"
        @update:active="onActive">
        <template v-slot:label="{ item }">
          <div
            class="d-flex align-center"
            @dragenter="handleDragEnter(item)"
            @dragleave="handleDragLeave(item)"
            @dragover.prevent
            @drop="onDrop($event, item)">
            <span>{{ item.name }}</span>
            <v-spacer></v-spacer>
            <template v-if="item._id !== 'inbox' && item._id !== 'signed' && item._id !== 'notSigned' && item._id !== 'declined'">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" @click.stop>
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click.stop="$emit('create-subfolder', item)">
                    <v-list-item-title>Unterordner erstellen</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click.stop="$emit('edit-folder', item)">
                    <v-list-item-title>Umbenennen</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click.stop="$emit('delete-folder', item)">
                    <v-list-item-title>Löschen</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </div>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FolderTree",
  props: {
    items: {
      type: Array,
      required: true,
    },
    openNodes: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onActive(active) {
      if (active && active.length > 0) {
        const folderId = active[0]
        this.$emit("select-folder", folderId)
      } else {
        this.$emit("select-folder", null)
      }
    },
    onDrop(e, item) {
      if (item._id === "signed" || item._id === "notSigned" || item._id === "declined") return
      const documentId = e.dataTransfer.getData("documentId")
      if (documentId) {
        this.$emit("folder-drop", { folder: item, documentId })
      }
    },
    handleDragEnter(item) {
      this.$emit("drag-enter", item)
    },
    handleDragLeave(item) {
      this.$emit("drag-leave", item)
    },
  },
}
</script>
