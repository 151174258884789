import EventView from "@/views/event/eventView.vue"
import EventHome from "@/views/event/eventHome.vue"
import EventForm from "@/views/event/eventForm.vue"

export default [
  {
    path: "/event/create",
    name: "EventCreate",
    component: EventForm,
  },
  {
    path: "/event/edit/:id",
    name: "EventEdit",
    component: EventForm,
  },
  {
    path: "/event/:id",
    name: "EventView",
    component: EventView,
  },
  {
    path: "/event",
    name: "EventHome",
    component: EventHome,
  },
]
