import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchEmployeeListComplete() {
    //let url = buildUrl('internal/employee/list')
    let url = "https://app.wellcomefit.ch/api/internal/employee/list/complete"
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchCurrentEmployeeList() {
    //let url = buildUrl('internal/employee/list')
    let url = "https://app.wellcomefit.ch/api/internal/employee/list"
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchEmployeeStatistics() {
    let url = buildUrl("internal/employee/statistic")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Team

  deleteTeam(id) {
    let url = buildUrl("team/delete/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createTeam(data) {
    let url = buildUrl("team/create")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  // Interactions

  fetchMeScanEntries() {
    //let url = buildUrl("employee/interactionsmescan")
    let url = "https://app.wellcomefit.ch/api/employee/interactionsmescan"
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchCustomerInteractions(userId) {
    let url = buildUrl("employee/interactions/" + userId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveCustomerInteraction(entry) {
    let url = buildUrl("employee/interaction")
    return axios.post(url, { entry }, buildOptions()).then((res) => res.data)
  },

  // Employee Crud

  createEmployee(user) {
    let url = buildUrl("employee/website")
    return axios.post(url, { user }, buildOptions()).then((res) => res.data)
  },

  deleteEmployee(id, studio) {
    let url = buildUrl("employee/website/" + id + "/" + studio)

    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  updateEmployee(user) {
    let url = buildUrl("employee/website")
    return axios.post(url, { user }, buildOptions()).then((res) => res.data)
  },
  fetchAllEmployee(studios) {
    let url = buildUrl("employee/fetch/all")
    return axios.post(url, studios, buildOptions()).then((res) => res.data)
  },
  updateEmployeeSorting(data) {
    let url = buildUrl("employee/update/sorting")
    return axios.put(url, { data }, buildOptions()).then((res) => res.data)
  },
  updateEmployee(data) {
    let url = buildUrl("employee")
    return axios.put(url, { data }, buildOptions()).then((res) => res.data)
  },
}
