<template>
  <div class="bewertung">
    <div v-show="submitted" class="end">
      <h1>
        VIELEN DANK FÜR DEIN
        <span class="title-fb">FEEDBACK</span>
      </h1>
      <div class="end-text">
        Wir versuchen uns stetig zu verbessern.
        <br />
        <br />

        Mit Deiner Rückmeldung unterstützt Du uns auf diesem Weg.
        <br />
        <br />

        Dein
        <span class="title-fb">{{ getStudioArt() }}</span>
        Team
      </div>
    </div>
    <div v-show="!submitted" class="bewertung-wrapper">
      <h1>
        GIB UNS DEIN
        <span class="title-fb">FEEDBACK</span>
      </h1>
      <div class="bewertung-frage">
        <span class="frage">Warst Du mit Deinem Trainer zufrieden?</span>
        <div class="sterne rating">
          <div data-option="1" class="rating frage_1" role="optgroup">
            <!-- in Rails just use 1.upto(5) -->
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-1"
              data-rating="1"
              tabindex="0"
              aria-label="Rate as one out of 5 stars"
              role="radio"></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-2"
              data-rating="2"
              tabindex="0"
              aria-label="Rate as two out of 5 stars"
              role="radio"></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-3"
              data-rating="3"
              tabindex="0"
              aria-label="Rate as three out of 5 stars"
              role="radio"></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-4"
              data-rating="4"
              tabindex="0"
              aria-label="Rate as four out of 5 stars"
              role="radio"></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-5"
              data-rating="5"
              tabindex="0"
              aria-label="Rate as five out of 5 stars"
              role="radio"></i>
          </div>
          <div v-show="error_1" class="fehler">Wieviel Sterne möchstest Du vergeben?</div>
        </div>
        <div class="message">
          <textarea v-model="feedback.frage_1.kommentar" placeholder="Deine Rückmeldung für uns..."></textarea>
        </div>
      </div>
      <div class="bewertung-frage">
        <span class="frage">Wie hat es Dir im {{ getStudioArt() }} gefallen?</span>
        <div class="sterne rating">
          <div data-option="2" class="rating frage_2" role="optgroup">
            <!-- in Rails just use 1.upto(5) -->
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-1"
              data-rating="1"
              tabindex="0"
              aria-label="Rate as one out of 5 stars"
              role="radio"></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-2"
              data-rating="2"
              tabindex="0"
              aria-label="Rate as two out of 5 stars"
              role="radio"></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-3"
              data-rating="3"
              tabindex="0"
              aria-label="Rate as three out of 5 stars"
              role="radio"></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-4"
              data-rating="4"
              tabindex="0"
              aria-label="Rate as four out of 5 stars"
              role="radio"></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-5"
              data-rating="5"
              tabindex="0"
              aria-label="Rate as five out of 5 stars"
              role="radio"></i>
          </div>
          <div v-show="error_2" class="fehler">Wieviel Sterne möchstest Du vergeben?</div>
        </div>
        <div class="message">
          <textarea v-model="feedback.frage_2.kommentar" placeholder="Deine Rückmeldung für uns..."></textarea>
        </div>
      </div>

      <div v-show="error_2" class="fehler">Bitte überprüfe Frage 2, um die Bewertung zu versenden</div>
      <div v-show="error_1" class="fehler">Bitte überprüfe Frage 1, um die Bewertung zu versenden</div>

      <div @click="submit" class="send">
        <span>ABSENDEN</span>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery"
import api from "../../api"
export default {
  data() {
    return {
      error_1: false,
      error_2: false,
      submitted: false,
      feedback: {
        anamnese_id: "",
        frage_1: {
          sterne: 0,
          kommentar: "",
        },
        frage_2: {
          sterne: 0,
          kommentar: "",
        },
      },
    }
  },
  methods: {
    getStudioArt() {
      if (location.hostname.includes("wellcomefit") || location.hostname.includes("localhost")) {
        return "well come FIT"
      } else if (location.hostname.includes("wintifit")) {
        return "winti FIT"
      } else if (lcoation.hostname.includes("fzw")) {
        return "Fitness Zentrum Wetzikon"
      } else {
        return "Fitnesscenter"
      }
    },
    submit() {
      if (this.feedback.frage_1.sterne == 0) {
        this.error_1 = true
      } else {
        if (this.feedback.frage_2.sterne == 0) {
          this.error_2 = true
        } else {
          api.submitAnamneseRating(this.feedback).then((status) => {
            if (status.status == 1) {
              this.submitted = true
            }
          })
        }
      }
    },
    setRating(rating, option) {
      if (option == 1) {
        this.error_1 = false
      } else {
        this.error_2 = false
      }
      this.feedback["frage_" + option].sterne = rating
      // fill all the stars assigning the '.selected' class
      $(".frage_" + option + " " + ".rating-star")
        .removeClass("fa-star-o")
        .addClass("selected")
      // empty all the stars to the right of the mouse
      $(".frage_" + option + " " + ".rating-star#rating-" + rating + " ~ .rating-star")
        .removeClass("selected")
        .addClass("fa-star-o")
    },
    initStars() {
      let ref = this
      $(".rating-star").removeClass("selected").addClass("fa-star-o")

      $(".rating-star")
        .on("mouseover", function (e) {
          var rating = $(e.target).data("rating")
          var option = $(e.target).parent().data("option")
          // fill all the stars

          $(".frage_" + option + " " + ".rating-star")
            .removeClass("fa-star-o")
            .addClass("fa-star")
            .removeClass("selected")
          // empty all the stars to the right of the mouse
          $(".frage_" + option + " " + ".rating-star#rating-" + rating + " ~ .rating-star")
            .removeClass("fa-star")
            .addClass("fa-star-o")
        })
        .on("mouseleave", function (e) {
          var option = $(e.target).parent().data("option")
          // empty all the stars except those with class .selected
          $(".frage_" + option + " " + ".rating-star")
            .removeClass("fa-star")
            .addClass("fa-star-o")
        })
        .on("click", function (e) {
          var rating = $(e.target).data("rating")
          var option = $(e.target).parent().data("option")
          ref.setRating(rating, option)
        })
        .on("keyup", function (e) {
          // if spacebar is pressed while selecting a star
          if (e.keyCode === 32) {
            // set rating (same as clicking on the star)
            var rating = $(e.target).data("rating")
            var option = $(e.target).parent().data("option")
            ref.setRating(rating, option)
          }
        })
    },
  },
  created() {
    this.feedback.anamnese_id = this.$route.params.anamnese_id
    this.$nextTick(function () {
      this.initStars()
    })
  },
}
</script>

<style lang="scss" scoped>
.end {
  h1 {
    max-width: 300px;
    margin: 20px auto;
    text-align: center;
  }

  .end-text {
    font-weight: bold;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
  }
}
.back-navigation {
  background-color: var(--v-primary);
  color: white;
  padding: 0px 10px;
  font-weight: 500;
  display: flex;

  span {
    margin-top: auto;
    margin-bottom: auto;
  }
  a {
    display: flex;
    padding: 5px;
    color: white;

    span {
      font-size: 34px;
    }
  }
}

.fehler {
  color: red;
  padding: 10px 15px;
  display: block;
}

h1 {
  font-size: 23px;
  font-weight: bold;
  margin-top: 10px;
}

.title-fb {
  color: var(--v-primary);
}

.bewertung-wrapper {
  width: 80%;
  margin: auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 200px;

  textarea {
    margin-top: 15px;
    min-height: 130px;
    background-color: #eff0f0 !important;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
  }

  .frage {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
  }
}

.rating {
  cursor: pointer;
  margin-bottom: 10px;

  .rating {
    display: flex;
    justify-content: space-evenly;
  }
  i {
    padding: 10px;

    &:focus {
      outline: 0px transparent !important;
    }
  }
  textarea {
    width: 100%;
    height: 100px;

    padding: 10px;
    margin-top: 20px;
  }
}

.rating .rating-star {
  display: inline-block;
  position: relative;
  padding-right: 5px;
  margin-left: -5px;
  color: #e3cf7a;
}
.rating .selected:before {
  content: "\f005";
}
.fa-2x {
  font-size: 3em !important;
}

.send {
  cursor: pointer;
  text-align: center;
  line-height: 60px;
  background-color: var(--v-primary);
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 24px;
}
</style>
