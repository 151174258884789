import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  loadProblems() {
    let url = buildUrl("intern/forms/problem")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  submitProblemForm(formData) {
    let url = buildUrl("intern/forms/problem")
    return axios.post(url, formData, buildOptions()).then((res) => res.data)
  },
  downloadListCategory(category) {
    let url = buildUrl("intern/lists/" + category + "/entries")

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  downloadListCategoryPublic(category) {
    let url = buildUrl("intern/lists/" + category + "/entries/public")

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  checkListEntry(category) {
    let url = buildUrl("intern/lists/" + category + "/entries/check")

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  downloadLeadCategory(category) {
    let url = buildUrl("intern/leads/" + category + "/entries")

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createLeadEntry(data) {
    if (!data.category) data.category = "undefined"

    let url = buildUrl("intern/leads/" + data.category + "/entries")
    console.log("posting")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  createListEntry(data) {
    if (!data.category) data.category = "undefined"

    let url = buildUrl("intern/lists/" + data.category + "/entries")
    console.log("posting")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  deleteListEntry(data) {
    if (!data.category) data.category = "undefined"

    let url = buildUrl("intern/lists/" + data.category + "/" + data._id)

    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
}
