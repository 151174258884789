<template>
  <v-expansion-panels flat>
    <template v-for="(component, key) in components">
      <component :is="component" :items="data[key]" @editSetting="handleChildEditSetting" @addSetting="$emit('addSetting', $event)" />
    </template>
  </v-expansion-panels>
</template>

<script>
import shiftPanel from "./shiftPanel.vue"
import schoolHolidaysPanel from "./schoolHolidaysPanel.vue"

export default {
  name: "settingsPanels",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      components: {
        shifts: shiftPanel,
        schoolHolidays: schoolHolidaysPanel,
      },
    }
  },
  methods: {
    handleChildEditSetting(index, type) {
      this.$emit("editSetting", index, type)
    },
  },
}
</script>

<style scoped>
/* Deine Styles */
</style>
