import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

export default {
  addBike(data) {
    const url = buildUrl("bikes")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  // Bike bearbeiten
  updateBike(bikeId, data) {
    const url = buildUrl(`bikes/${bikeId}`)
    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },
  // Alle Bikes laden
  fetchBikes() {
    const url = buildUrl("bikes")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Bestellung erstellen
  createOrder(bikeId, amount) {
    const url = buildUrl("bikes/order")
    const data = { bikeId, amount }
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  // Eigene Bestellungen laden
  fetchMyOrders() {
    const url = buildUrl("bikes/my-orders")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Bestellung stornieren
  cancelOrder(orderId) {
    const url = buildUrl(`bikes/order/${orderId}`)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },

  // Übersicht aller Bestellungen (Admin)
  fetchAllOrders() {
    const url = buildUrl("bikes/admin/orders")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Bestellung bearbeiten (Admin)
  updateOrder(orderId, data) {
    const url = buildUrl(`bikes/admin/order/${orderId}`)
    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },
}
