import habitHome from "@/views/habit/habitHome.vue"
import nutritionRoute from "@/views/nutrition/nutritionRoute.vue"
import nutritionMainpage from "@/views/nutrition/nutritionMainpage.vue"
import nutritionRecipes from "@/views/nutrition/nutritionRecipes.vue"
import nutritionShoppinList from "@/views/nutrition/nutritionShoppinList.vue"

export default [
  {
    path: "/nutrition",
    name: "nutritionMainpage",
    component: nutritionRoute,
    children: [
      {
        path: "",
        name: "nutritionMainpage",
        component: nutritionMainpage,
        meta: {
          hideBottomNavigation: true,
          navbarTitle: "Ernährungsplan",
        },
      },
      {
        path: "recipes",
        name: "nutritionRecipes",
        props: true,
        component: nutritionRecipes,
        meta: {
          hideBottomNavigation: true,
          navbarTitle: "Rezepte",
        },
      },
      {
        path: "shopping-list",
        name: "nutritionShoppingList",
        props: true,
        component: nutritionShoppinList,
        meta: {
          hideBottomNavigation: true,
          navbarTitle: "Shopping List",
        },
      },
    ],
  },
  // Good old habit :(
  {
    path: "/habits",
    name: "HabitHome",
    component: habitHome,
  },
]
