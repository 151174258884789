<template>
  <div class="text-center">
    <v-dialog max-width="800px" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="white" text v-bind="attrs" v-on="on">
          <v-icon class="pr-2" color="white">mdi-calendar-heart</v-icon>
          <span v-if="!$vuetify.breakpoint.smAndDown">Freiwünsche</span>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 primary white--text mb-5">Freiwünsche</v-card-title>
        <v-tabs v-model="tab">
          <v-tab>Übersicht</v-tab>
          <v-tab>Erfassen</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row v-if="freeWishes.length === 0">
              <v-col cols="12">
                <v-alert class="ma-5" type="info">Du hast noch keine Freiwünsche eingegeben</v-alert>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr style="text-wrap: nowrap">
                        <th class="text-left">Tag</th>
                        <th class="text-left">Zeitraum</th>
                        <th class="text-left">Bemerkung</th>
                        <th class="text-left">Status</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="text-wrap: nowrap" v-for="freeWish of freeWishes" :key="freeWish._id">
                        <td>{{ $moment(freeWish.day).format("DD.MM.YY") }}</td>
                        <td>
                          {{ freeWish.period === "full" ? "Ganzer Tag" : freeWish.period === "morning" ? "Vormittag" : "Nachmittag" }}
                        </td>
                        <td>
                          {{ freeWish.comment }}
                        </td>
                        <td>
                          <v-chip
                            class="white--text"
                            :color="freeWish.status === 'open' ? 'orange' : freeWish.status === 'accepted' ? 'green' : 'red'">
                            {{ mapHolidayStatus[freeWish.status] }}
                          </v-chip>
                        </td>
                        <td>
                          <v-btn v-if="freeWish.status === 'open'" text @click="selfCancelFreeWish(freeWish._id)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-stepper v-model="stepperModel" vertical>
                <v-stepper-step :complete="stepperModel > 1" step="1">
                  Tag auswählen <span v-if="stepperModel > 1" class="primary--text">{{ "(" + $moment(day).format("DD.MM.YYYY") + ")" }}</span>
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-row>
                    <v-col cols="12">
                      <v-date-picker first-day-of-week="1" locale="de-CH" v-model="day"></v-date-picker>
                    </v-col>
                  </v-row>

                  <v-btn color="primary" @click="stepperModel = 2"> Weiter </v-btn>
                </v-stepper-content>
                <v-stepper-step :complete="stepperModel > 2" step="2"> Zeitraum </v-stepper-step>

                <v-stepper-content step="2">
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="period"
                        :items="[
                          { value: 'full', text: 'Ganzer Tag' },
                          { value: 'morning', text: 'Vormittag' },
                          { value: 'afternoon', text: 'Nachmittag' },
                        ]"
                        label="Zeitraum"></v-select>
                    </v-col>
                  </v-row>
                  <v-btn text @click="stepperModel -= 1">Zurück</v-btn>

                  <v-btn color="primary" @click="stepperModel = 3"> Weiter </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="stepperModel > 3" step="3"> Bemerkung hinzufügen </v-stepper-step>

                <v-stepper-content step="3">
                  <v-row v-if="startDate !== null">
                    <v-col cols="12">
                      <v-text-field label="Bemerkung" v-model="comment"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-btn text @click="stepperModel -= 1">Zurück</v-btn>
                  <v-btn :loading="!buttonEnabled" color="primary" text @click="saveFreeWish"> beantragen </v-btn>
                </v-stepper-content>
              </v-stepper>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/api"
import { mapState } from "vuex"
export default {
  name: "EnterHolidays",
  data() {
    return {
      buttonEnabled: true,
      freeWishes: [],
      mapHolidayStatus: {
        open: "Offen",
        accepted: "Akzeptiert",
        rejected: "Abgelehnt",
      },
      tab: null,
      stepperModel: 1,
      day: this.$moment().format("YYYY-MM-DD"),
      dialog: false,
      dates: [],
      period: "",
      comment: "",
    }
  },
  async created() {
    this.freeWishes = await api.fetchFreeWishEntriesEmployee()
  },
  methods: {
    async selfCancelFreeWish(id) {
      if (!confirm("Bist du sicher, dass du diese Anfrage selbst ablehnen möchtest?")) return
      try {
        await api.changeFreeWishStatus({ _id: id, status: "rejected" })
        this.freeWishes = await api.fetchFreeWishEntriesEmployee()
      } catch (e) {
        this.$toast.error("Anfrage konnte nicht abgelehnt werden")
      }
    },
    async saveFreeWish() {
      this.buttonEnabled = false
      try {
        let newEntry = {
          day: this.day,
          status: "open",
          period: this.period,
          comment: this.comment,
        }
        let status = await api.createFreeWishEntryEmployee(newEntry)
        this.$toast.success("Freiwunsch erfolgreich eingetragen")
        this.tab = null
        this.startDate = this.$moment().format("YYYY-MM-DD")
        this.endDate = this.$moment().add(1, "days").format("YYYY-MM-DD")
        this.comment = ""
        this.period = null
        this.stepperModel = 1
        this.freeWishes = await api.fetchFreeWishEntriesEmployee()
      } catch (e) {
        console.log(e)
        this.$toast.error("Fehler beim Eintragen der Ferien")
      }
    },
  },
  computed: {
    ...mapState("studioStore", ["storeStudios"]),
  },
}
</script>

<style scoped></style>
