import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchHealthcareRequests() {
    let url = buildUrl(`healthcare/requests`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchHealthcareRequestsAdmin(status) {
    let url = buildUrl(`healthcare/requests/admin/` + status)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getHealthcareFormsForUser(id) {
    let url = buildUrl(`healthcare/requests/for/user/` + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  updateHealthcareRequest(data) {
    let url = buildUrl("healthcare/request/update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  healthcareFetchUserContracts() {
    let url = buildUrl(`healthcare/options`)
    if (location.host.includes("local")) {
      url = `https://app.wellcomefit.ch/api/healthcare/options`
    }
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  requestHealthcareUnlock(data) {
    let url = buildUrl("healthcare/request/contract")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchHealthcareUnlockRequests() {
    let url = buildUrl("healthcare/requests")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateTACContractPaidStatus(data) {
    let url = buildUrl("healthcare/update/contract/status")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  saveHealthcareFormEntry(entry) {
    let url = buildUrl("healthcare")
    return axios.post(url, { entry }, buildOptions()).then((res) => res.data)
  },
  // Healthcare Downloads
  createHealthcarePDF(data) {
    let url = buildUrl("healthcare/pdf")
    axios
      .post(
        url, //your url
        data,
        {
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          }, // important
        }
      )
      .then((response) => {
        if (window.flutter_inappwebview) {
          console.log("FLUTTER IOS DOWNLOAD PDF START")
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]

            window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "pdf", "HealthCareConfirmation")
          }
        } else {
          saveAs(response.data, "HealthCareConfirmation")
        }
      })
  },
  downloadHealthcarePDF(healthcareId) {
    let url = buildUrl("healthcare/pdf/" + healthcareId)
    axios
      .get(
        url, //your url
        {
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          }, // important
        }
      )
      .then((response) => {
        if (window.flutter_inappwebview) {
          console.log("FLUTTER IOS DOWNLOAD PDF START")
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]

            window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "pdf", "HealthCareConfirmation")
          }
        } else {
          saveAs(response.data, "HealthCareConfirmation")
        }
      })
  },
}
