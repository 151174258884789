import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  sendNutritionPushToUserNewRecipes(userId) {
    let url = buildUrl("send/nutrition/push/to/user/new/recipes/" + userId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchOwnNutritionContract() {
    let url = buildUrl("nutrition/contract")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateNutritionSale(_id, data) {
    let url = buildUrl("nutrition/sale")
    return axios.put(url, { _id, data }, buildOptions()).then((res) => res.data)
  },
  fetchOpenNutritionSales(studios) {
    let url = buildUrl("nutrition/sales/list/open")
    return axios.post(url, studios, buildOptions()).then((res) => res.data)
  },
  fetchNutritionSales(studios) {
    let url = buildUrl("nutrition/sales/list")
    return axios.post(url, studios, buildOptions()).then((res) => res.data)
  },
  submitNutritionSaleForm(formData) {
    let url = buildUrl("nutrition/sale")
    return axios.post(url, { data: formData }, buildOptions()).then((res) => res.data)
  },

  updateNutritionShoppingList(data) {
    let url = buildUrl("nutrition/update/shoppinglist")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateNutritionShoppingListFullTableItems(data) {
    let url = buildUrl("nutrition/update/shoppinglist/fullTableItems")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchNutritionUserShoppingList() {
    let url = buildUrl("nutrition/fetch/shoppingList")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchWeekNutritionStatistics(date) {
    let url = buildUrl("nutrition/fetch/weekly/stats/" + date)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchOwnNutritionAnamnese() {
    let url = buildUrl("anamnesebogen/nutrition/existing")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllAvailableFormUpdateForUser(habit) {
    let url = buildUrl("fetch/available/form/update/for/" + habit)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  setNutritionDayChoice(data) {
    let url = buildUrl("nutrition/set/day/choice")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  saveNutritionPortionSizes(data) {
    let url = buildUrl("nutrition/add/portion/sizes")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchNutritionDataForDay(day) {
    let url = buildUrl("nutrition/data/for/day/" + day)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  deleteNutritionEntry(entry) {
    let url = buildUrl("nutrition/data/delete/entry")
    return axios.post(url, entry, buildOptions()).then((res) => res.data)
  },
  fetchAllRecipesForDate(day) {
    let url = buildUrl("nutrition/fetch/all/recipes/for/" + day)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllRecipesShoppingList(day) {
    let url = buildUrl("nutrition/fetch/all/recipes/shopping-list")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  nutritionForUserExists() {
    let url = buildUrl("nutrition/for/user/exists")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  searchNutritionApi(data) {
    let url = buildUrl("nutrition/search")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  getUserNutritionFavorites() {
    let url = buildUrl("fetch/user/nutrition/favorites")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  addRecipeToNutritionFavorites(recipe) {
    let url = buildUrl("add/recipe/to/favorites")
    return axios.post(url, recipe, buildOptions()).then((res) => res.data)
  },
  getReceiptNutritionApi(id, calories = 0) {
    let url = buildUrl("nutrition/search/" + id + "/" + calories)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getReceiptByFilter(data) {
    let url = buildUrl("nutrition/receipt/by/filter")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  getNutritionSearchFilters() {
    let url = buildUrl("nutrition/get/search/filters")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createNutritionDashboard(data) {
    let url = buildUrl("create/new/dashboard/for/user")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  saveMealPlanToNutritionUser(data) {
    let url = buildUrl("/save/mealplan")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateMealPlanAllergens(data) {
    let url = buildUrl("/update/mealplan/allergens")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateMealPlanDiet(data) {
    let url = buildUrl("/update/mealplan/diet")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateCoachNote(data) {
    let url = buildUrl("/update/coach-note")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  deleteMealPlan(id) {
    let url = buildUrl("/delete/mealplan/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  loadCustomerDashboardCoach(id) {
    let url = buildUrl("load/existing/dashboard/for/user/coach/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  loadNutritionUser() {
    let url = buildUrl("load/nutrition/user")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  toggleLaunchNutritionStatus() {
    let url = buildUrl("toggle/launch/nutrition/status")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveSelfCheckinEntryEditTODB(data) {
    let url = buildUrl("save/self/checkin/entry/edit/to/db")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  addNewNutritionEntry(data) {
    let url = buildUrl("nutrition/add/user/entry")
    return axios.post(url, { data }, buildOptions()).then((res) => res.data)
  },
  askNutritionAi(message) {
    let url = buildUrl("nutrition/ai")
    return axios.post(url, { message }, buildOptions()).then((res) => res.data)
  },
  searchOpenDatabase(search, page) {
    let url = buildUrl("search/openfood/for/products/" + search + "/" + page)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchOpenDatabaseBarcode(code) {
    let url = buildUrl("search/openfood/by/barcode/" + code)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createOpenFoodProduct(body) {
    let url = buildUrl("/create/openfood/new/prodct")

    let options = buildOptions()
    options.headers["Content-Type"] = "multipart/form/data"

    return axios.post(url, body, buildOptions()).then((res) => res.data)
  },
}
