import Friends from "@/views/friends/Friends.vue"

export default [
  {
    path: "/friends",
    meta: { navbarTitle: "Freunde" },
    name: "Friends",
    component: Friends,
  },
]
