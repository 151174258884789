import slimMeView from "@/views/einmalig/scams/slimMeView.vue"
import oaff from "@/views/einmalig/randomDeprecatedStuff/oaff.vue"
import viewProblem from "@/views/einmalig/reportProblemForm/viewProblem.vue"
import reportProblem from "@/views/einmalig/reportProblemForm/reportProblem.vue"
import aliBey from "@/views/aliBey/aliBey.vue"
import aliBeyEditor from "@/views/aliBey/aliBeyEditor.vue"
import aliBeyBooking from "@/views/aliBey/aliBeyBooking.vue"
import fitnessCenterDisplay from "@/views/einmalig/displaysDeprecated/fitnessCenterDisplay.vue"
import Covid_check from "@/views/admin/Covid_check.vue"
import Covid_archive from "@/views/admin/Covid_archive.vue"
import Timestop from "@/views/einmalig/covid19/Timestop.vue"
import Helsana from "@/views/einmalig/competitions/helsana.vue"
import Sensordaten from "@/views/einmalig/randomDeprecatedStuff/Sensordaten.vue"
import RateWorkouts from "@/views/einmalig/RateWorkouts.vue"
import RateAnamnese from "@/views/einmalig/RateAnamnese.vue"
import Playground from "@/views/admin/Playground.vue"
import timetrackerAdmin from "@/views/admin/timetracker/timetracker.vue"
import redirection from "@/views/redirection/redirection.vue"
import Impressum from "@/views/einmalig/Impressum.vue"
import Datenschutz from "@/views/einmalig/Datenschutz.vue"
import Rollenverwaltung from "@/views/admin/Rollenverwaltung.vue"
import rights from "@/views/admin/rights.vue"

import DeviceList from "@/views/deviceManagement/deviceList.vue"
import DeviceForm from "@/views/deviceManagement/deviceForm.vue"
import DeviceExerciseForm from "@/views/deviceManagement/deviceExerciseForm.vue"

import einsteinSaunaContest from "@/views/einmalig/competitions/einsteinSaunaContest.vue"
import einsteinSaunaContestAdminList from "@/views/einmalig/competitions/einsteinSaunaContestAdminList.vue"

export default [
  // Web conform routes
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: Datenschutz,
  },

  // Rollenverwaltung
  {
    path: "/rollenverwaltung",
    name: "rollenverwaltung",
    component: Rollenverwaltung,
  },
  {
    path: "/tools/sysadmin/rights",
    name: "rights",
    component: rights,
  },

  // REDIRECT EVERYTTHING
  { path: "/redirect/:routeName", name: "redirection", component: redirection },

  // PLAYGROUND
  {
    path: "/playground",
    name: "Playground",
    component: Playground,
  },

  // TIMETRACKER LIFESAVER <3
  {
    path: "/timetrackeradmin",
    name: "timetrackerAdmin",
    component: timetrackerAdmin,
  },

  // Contest Routes
  {
    path: "/wheel",
    name: "spinningWheel",
    meta: { hideBottomNavigation: true, hideAppBar: true, fullWidth: true },
    component: () => import("@/views/einmalig/competitions/spinningWheel/spinningWheel.vue"),
  },
  {
    path: "/olma/:video",
    name: "olmaVideo",
    meta: { hideBottomNavigation: true, hideAppBar: true, fullWidth: true },
    component: () => import("@/views/einmalig/olma/video.vue"),
  },
  {
    path: "/allianz",
    meta: { navbarTitle: "Allianz" },
    name: "allianzAssociation",
    component: () => import("@/views/einmalig/randomDeprecatedStuff/allianzAssociation.vue"),
  },
  {
    path: "/einstein/contest",
    name: "EinsteinSaunaContest",
    component: einsteinSaunaContest,
  },
  {
    path: "/einstein/contest/admin/list",
    name: "EinsteinSaunaContestAdminList",
    component: einsteinSaunaContestAdminList,
  },
  // WCF PARTY
  {
    path: "/party",
    name: "partyForm",
    meta: {
      hideBottomNavigation: true,
      hideAppBar: true,
    },
    component: () => import("@/views/event/partyForm.vue"),
  },

  // ALIBEY ROUTES
  {
    path: "/alibey",
    name: "AliBey",
    component: aliBey,
  },
  {
    path: "/alibey/booking",
    name: "AliBeyBooking",
    component: aliBeyBooking,
  },
  {
    path: "/alibey/edit",
    name: "AlibeyEditor",
    component: aliBeyEditor,
  },

  // BIKE SHOP
  /*{
    name: "BikeShop",
    path: "/bikes",
    meta: { hideBottomNavigation: true, hideAppBar: true },
    component: () => import("../views/singleUseViews/Bike/BikeShop.vue"),
    children: [
      {
        name: "BikeOwnOrders",
        path: "orders",
        meta: { hideBottomNavigation: true, hideAppBar: true },
        component: () => import("../views/singleUseViews/Bike/BikeOwnOrders.vue"),
        childen: [],
      },
    ],
  },

  {
    name: "BikeAdminOrders",
    path: "/bikes/orders/admin",
    meta: { hideBottomNavigation: true, hideAppBar: true },
    component: () => import("../views/singleUseViews/Bike/BikeAdminOrders.vue"),
    childen: [],
  },*/

  // SLIM ME
  {
    path: "/slimme/dashboard",
    component: slimMeView,
  },

  // OAFF - Openair Frauenfeld
  {
    path: "/oaff",
    name: "oaff",
    component: oaff,
  },

  // Report Problem Form
  {
    path: "/intern/report/dashboard",
    name: "viewProblem",
    component: viewProblem,
  },
  {
    path: "/intern/report",
    name: "reportProblem",
    component: reportProblem,
  },

  // Displays managed by Roody (CENTERDISPLAYS)
  {
    path: "/centerdisplay/view/:id",
    name: "fitnessCenterDisplay",
    component: fitnessCenterDisplay,
  },
  {
    path: "/centerdisplay",
    name: "fitnessCenterDisplay",
    component: fitnessCenterDisplay,
  },

  // COVID 19 ROUTES
  {
    path: "/covidcerts/archive",
    name: "Covid_archive",
    component: Covid_archive,
  },
  {
    path: "/covidcerts",
    name: "Covid_check",
    component: Covid_check,
  },
  // COVID TIMESTOP NOT ACTUAL TIMESTOPS!
  {
    path: "/timestopcovid-19",
    name: "Timestop",
    component: Timestop,
  },

  // HELSANA CAMPAIGN
  {
    path: "/helsana",
    name: "Helsana",
    component: Helsana,
  },

  // Raspberry Pi Sensor
  {
    path: "/sensordaten",
    name: "Sensordaten",
    component: Sensordaten,
  },

  // ANAMNESE AND WORKOUT RATINGS (REFACTOR THIS PLEASE)
  {
    path: "/bewerten/trainingsplan/:plan_id",
    name: "RateWorkouts",
    component: RateWorkouts,
  },
  {
    path: "/bewerten/anamnese/:anamnese_id",
    name: "RateAnamnese",
    component: RateAnamnese,
  },
  {
    path: "/devices",

    name: "DeviceList",

    component: DeviceList,

    children: [
      {
        path: "create",

        name: "DeviceCreate",

        component: DeviceForm,

        children: [
          {
            path: "exercise/create",

            name: "DeviceExerciseCreate",

            component: DeviceExerciseForm,
          },
        ],
      },

      {
        path: ":id",

        name: "DeviceEdit",

        component: DeviceForm,

        children: [
          {
            path: "exercise/create",

            name: "DeviceExerciseCreate",

            component: DeviceExerciseForm,
          },

          {
            path: "exercise/:eid",

            name: "DeviceExerciseEdit",

            component: DeviceExerciseForm,
          },
        ],
      },
    ],
  },
]
