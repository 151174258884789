<template>
  <div class="zeitgutschrift">
    <div class="zeitgutschrift">
      <div class="zeitgutschrift-titel">TIMESTOP</div>
      <div class="zeitgutschrift-text" v-if="wellcomefit" v-show="!success">
        Liebes well come FIT Mitglied. <br />Vielleicht gibt es ja das ein oder andere Mitglied unter euch, welches im Moment aufgrund der bestehenden
        Zertifikats-Pflicht auf ein Training bei uns verzichten möchte. Hierfür bieten wir die Möglichkeit eines kostenlosen Timestops bis max. 24.
        Januar 2022.
      </div>
      <div class="zeitgutschrift-text" v-else v-show="!success">
        Liebes winti FIT Mitglied. <br />Vielleicht gibt es ja das ein oder andere Mitglied unter euch, welches im Moment aufgrund der bestehenden
        Zertifikats-Pflicht auf ein Training bei uns verzichten möchte. Hierfür bieten wir die Möglichkeit eines kostenlosen Timestops bis max. 24.
        Januar 2022.
      </div>

      <div v-show="!success" class="zeitgutschrift-form">
        <div class="zeitgutschrift-wrapper">
          <label>Vorname</label>
          <input type="text" placeholder="Dein Vorname" v-model="formData.vorname" />
        </div>
        <div class="zeitgutschrift-wrapper">
          <label>Nachname</label>
          <input type="text" placeholder="Dein Nachname" v-model="formData.nachname" />
        </div>
        <div class="zeitgutschrift-wrapper">
          <label>E-Mail</label>
          <input type="email" placeholder="Deine E-Mail" v-model="formData.email" />
        </div>
        <div class="zeitgutschrift-wrapper">
          <v-col class="d-flex p-0 mt-5">
            <v-select
              v-model="formData.studio"
              v-if="wellcomefit"
              full-width
              solo
              :items="studios.wellcomefit"
              item-text="studio_name"
              item-value="studio_id"
              label="Fitnesscenter auswählen"></v-select>
            <v-select
              v-model="formData.studio"
              v-else
              full-width
              solo
              :items="studios.wintifit"
              item-text="studio_name"
              item-value="studio_id"
              label="Fitnesscenter auswählen"></v-select>
          </v-col>

          <div class="zeitgutschrift-wrapper">
            <v-container class="px-0" fluid>
              <v-radio-group v-model="formData.timestop_auswahl">
                <v-radio
                  label="Ich wähle den Timestop bis max. 24. Januar 2022 (Dein Abonnement erneuert sich auch während des Timestops. Die Zeit wird Deinem Folgeabonnement gutgeschrieben.)"
                  value="A"></v-radio>
              </v-radio-group>
            </v-container>
            <v-row v-show="formData.timestop_auswahl == 'A'" justify="center" class="flex-column p-2">
              <span>Timestop bis:</span>
              <v-date-picker v-model="formData.date" class="mt-4" locale="de-CH" color="primary" min="2021-09-13" max="2022-01-24"></v-date-picker>
            </v-row>
          </div>
        </div>
        <div class="error-zeitgutschrift" v-show="fehlermeldung">
          {{ fehlermeldung }}
        </div>

        <div class="absenden" @click="submitForm">Absenden</div>
      </div>
      <div class="success-zeitgutschrift" v-show="success">Der Timestop wurde erfolgreich erfasst</div>
    </div>
  </div>
</template>
<script>
import api from "@/api"

export default {
  data() {
    return {
      wellcomefit: true,
      studios: {
        wellcomefit: [
          { studio_name: "Frauenfeld", studio_id: "5f326a24ae8863640c859e75" },
          {
            studio_name: "Netstal",
            studio_id: "5f3c1241d91ddb464c2648b0",
          },
          { studio_name: "Bülach", studio_id: "5f3c30afd91ddb464c2648b1" },
          {
            studio_name: "St. Gallen West",
            studio_id: "5f3c30bfd91ddb464c2648b2",
          },
          { studio_name: "St. Gallen Ost", studio_id: "5f3c30d0d91ddb464c2648b3" },
          {
            studio_name: "St. Gallen Einstein",
            studio_id: "5f3c30e1d91ddb464c2648b4",
          },
          { studio_name: "Dietlikon", studio_id: "5f3c30f2d91ddb464c2648b5" },
        ],
        wintifit: [
          { studio_name: "Winterthur", studio_id: "5f3d482d5dd7955ad48de5e4" },
          { studio_name: "Pfungen", studio_id: "5f3d48195dd7955ad48de5e3" },
        ],
      },
      formData: {
        vorname: "",
        nachname: "",
        email: "",
        studio: "",
        timestop_auswahl: "A",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      },
      success: false,
      fehlermeldung: null,
    }
  },
  created() {
    if (!location.host.includes("wellcomefit")) {
      this.wellcomefit = false
    }
  },
  methods: {
    submitForm() {
      this.fehlermeldung = null

      if (
        this.formData.vorname.length > 2 &&
        this.formData.nachname.length > 2 &&
        this.formData.email.includes("@") &&
        this.formData.email.length > 3
      ) {
        if (
          this.studios.wellcomefit.map((s) => s.studio_id).includes(this.formData.studio) ||
          this.studios.wintifit.map((s) => s.studio_id).includes(this.formData.studio)
        ) {
          if (
            this.formData.timestop_auswahl == "B" ||
            (this.formData.timestop_auswahl == "A" &&
              this.formData.date !== new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10))
          ) {
            let fd = this.formData

            api.submitTimestop(fd).then((data) => {
              if (data.status == 1) {
                this.success = true
              } else {
                this.fehlermeldung = "Diese E-Mail Adresse ist bereits hinterlegt"
              }
            })
          } else {
            this.fehlermeldung = "Bitte wähle ein Datum für den Timestamp aus"
          }
        } else {
          this.fehlermeldung = "Bitte wähle ein Fitnesscenter aus"
        }
      } else {
        this.fehlermeldung = "Bitte fülle alle persönlichen Daten aus"
      }
      console.log(this.fehlermeldung)
    },
  },
}
</script>

<style lang="scss" scoped>
.error-zeitgutschrift {
  max-width: 700px;
  margin: auto;
  padding: 10px 15px;
  text-align: center;
  color: red;
}

.success-zeitgutschrift {
  max-width: 700px;
  margin: auto;
  padding: 10px 15px;
  text-align: center;
  color: var(--v-primary);
}

.zeitgutschrift {
  .titel {
    width: 100%;
    background-color: var(--v-primary);
    padding: 5px 10px;
    color: white;
  }

  .zeitgutschrift {
    max-width: 700px;
    margin: auto;

    .form-check {
      width: 50%;
      padding: 0px;
    }

    .form-check-label {
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      color: #676767;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding: 0 15px;
      height: 42px;
      border: none;
      line-height: 1.86;
      letter-spacing: 0.35px;
      box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.24);
      background: #f5f5f5;
      width: 100%;
    }

    .gender-input:checked ~ label {
      background-color: var(--v-primary);
      color: white;
    }

    &-logo {
      width: 240px;
      margin: 20px auto 15px;
      display: flex;
    }

    select {
      padding: 10px 15px;
    }

    &-header {
      width: 100%;
      max-height: 300px;
      object-fit: contain;
    }

    &-titel,
    &-text,
    &-form-wrapper {
      padding: 5px 15px;
    }

    &-titel {
      text-align: left;
      font-weight: bold;
      font-size: 16px;
      max-width: 700px;
      margin: 15px auto 0px auto;
    }

    &-text {
      max-width: 700px;
      text-align: left;
      margin: auto;
    }

    &-form {
      max-width: 700px;
      margin: auto;
      padding: 10px 15px;

      input:not([type="radio"]) {
        padding: 0 15px;
        width: 100%;
        height: 42px;
        border: none;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.24);

        font-size: 16px;
        background-color: rgb(245, 245, 245);
        margin-bottom: 15px;
        background: #f5f5f5 !important;
      }

      label {
        font-weight: bold;
        font-size: 14px;
      }

      .zeitgutschrift-wrapper {
        display: flex;
        flex-direction: column;
      }
    }

    .absenden {
      background-color: var(--v-primary);
      color: white;
      margin: 25px auto 170px auto;
      padding: 10px;
      display: block;
      width: 300px;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
