<template>
  <v-dialog max-width="500px" persistent v-model="showUploadDialog">
    <v-card v-if="allowed" class="mx-auto pa-4" outlined>
      <div class="drop-area" @dragover.prevent @dragenter.prevent @drop.prevent="handleDrop">
        <v-icon size="48" color="primary">mdi-cloud-upload</v-icon>
        <div>Datei per Drag &amp; Drop hier ablegen</div>
      </div>
      <v-alert v-if="uploadSuccess" type="success" dismissible> Datei erfolgreich hochgeladen! </v-alert>
      <v-alert v-if="uploadError" type="error" dismissible>
        {{ uploadError }}
      </v-alert>
      <v-card-actions class="justify-center pt-5">
        <v-btn color="secondary" @click="hideUploadDialog">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else class="mx-auto pa-4">
      <v-alert type="info"> Um ein Dokument einzureichen, sende einfach eine Mail an kreditoren@wellcomefit.ch </v-alert>
      <v-card-actions class="justify-center pt-5">
        <v-btn color="secondary" @click="hideUploadDialog">Alles klar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api"

export default {
  name: "FileDropUpload",
  props: {
    showUploadDialog: {
      type: Boolean,
      required: true,
    },
    allowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadSuccess: false,
      uploadError: "",
    }
  },
  methods: {
    hideUploadDialog() {
      this.$emit("hideDialog")
    },
    async handleDrop(event) {
      const files = event.dataTransfer.files
      if (files.length > 0) {
        const file = files[0]
        try {
          await this.uploadFile(file)
          // this.uploadSuccess = true
          this.uploadError = ""
          this.$emit("fileUpload")
        } catch (error) {
          this.uploadSuccess = false
          this.uploadError = "Fehler beim Hochladen der Datei."
          console.error("Upload error:", error)
        }
      }
    },
    uploadFile(file) {
      const formData = new FormData()
      formData.append("file", file)
      return api.documents.uploadDocument(formData)
    },
  },
}
</script>

<style scoped>
.drop-area {
  border: 2px dashed #1976d2;
  padding: 50px;
  text-align: center;
  cursor: pointer;
}
</style>
