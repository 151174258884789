import contractCreator from "@/views/dashboard/contract/contractCreator.vue"
import contractCreatorExtern from "@/views/dashboard/contract/contractCreatorExtern.vue"

export default [
  {
    name: "contractCreatorExtern",
    path: "/contract",
    component: contractCreatorExtern,
  },
  {
    name: "contractCreatorExternForm",
    path: "/contract/create/:id/:studioId",
    component: contractCreator,
    meta: { displayedTitle: "Vertrag Erstellen" },
  },
  {
    name: "contractCreatorExternForm",
    path: "/contract/create/:id/:studioId/:skipPresale",
    component: contractCreator,
    meta: { displayedTitle: "Vertrag Erstellen" },
  },
]
