<template>
  <v-expansion-panel ref="company">
    <v-expansion-panel-header class="font-weight-bold">
      <v-card-title class="ma-0 pa-0">{{ title }} <v-chip class="ml-2" small>Alle Center</v-chip></v-card-title>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0">
      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <v-list>
            <v-list-item-group>
              <v-list-item @click="$emit('editSetting', index, type)" v-for="(item, index) of items" :key="index + '-shiftIndex'">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                    <v-chip>{{ item.shortName }}</v-chip>
                    <v-badge class="ml-2" :color="item.color"></v-badge>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col cols="12" class="d-flex justify-center">
          <v-btn color="primary" @click="$emit('addSetting', type)"><span>Hinzufügen</span></v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "shiftPanel",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      title: "Schichten",
      type: "shifts",
    }
  },
}
</script>

<style scoped></style>
