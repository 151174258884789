<template>
  <v-card dark elevation="0" max-width="700px" class="mx-auto mb-15 mt-5">
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-select class="px-5" multiple :items="studios" label="Studio auswählen" v-model="studioSelect" item-text="name" item-value="_id"></v-select>
      </v-col>
      <v-col cols="12" v-if="!userService.hasRight('c_open_contracts')">
        <v-alert class="mx-5" type="info"> Die Verträge in dieser Liste sind noch in Bearbeitung / noch nicht im TAC hinterlegt </v-alert>
      </v-col>
      <v-col class="mt-0 pt-0 d-flex justify-center" v-if="userService.hasRight('c_create') || userService.hasRight('c_open_contracts')" cols="12">
        <v-btn
          class="mt-0 pt-0 mx-auto"
          @click="
            () => {
              if (studioSelect.length === studios.length) {
                studioSelect = []
              } else {
                studioSelect = studios.map((e) => e._id)
              }
            }
          "
          text>
          <span class="pr-1">Alle Studios</span>
          <span v-if="studioSelect.length === studios.length">abwählen</span>
          <span v-else>anwählen</span>
        </v-btn>
      </v-col>
      <v-col v-if="showNewContractAlert" cols="12">
        <v-alert class="mx-5" ref="alert" type="success">
          <span>Ein neuer Vertrag wurde erstellt! Bitte lade die Seite neu, um die Änderungen zu sehen.</span>
          <v-btn
            @click="
              () => {
                fetchContractsByStatus('created')
                showNewContractAlert = false
              }
            ">
            Neu laden
          </v-btn>
        </v-alert>
      </v-col>
      <v-col class="justify-center align-center d-flex flex-column" cols="12">
        <v-btn @click="showRecentlyAddedContracts = !showRecentlyAddedContracts" color="primary">
          <span v-if="showRecentlyAddedContracts">Zurück</span>
          <span v-else>
            Zuletzt akzeptiert
            <v-icon color="white">mdi-history</v-icon>
          </span>
        </v-btn>

        <v-switch class="mt-5" v-model="sortByStartDate" label="Sortieren nach Vertragsstart"></v-switch>

        <v-btn v-if="false" @click="sortByStartDate = !sortByStartDate" class="mt-5" text>
          <span v-if="sortByStartDate">Sortieren nach Erstellungsdatum</span>
          <span v-else>Sortieren nach Startdatum</span>
        </v-btn>

        <v-simple-table v-if="showRecentlyAddedContracts">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Datum</th>
                <th class="text-left">Studio</th>
                <th class="text-left">Name</th>
                <th class="text-left">Vertrag</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="contract of recentlyAcceptedContractsFiltered" :key="contract._id">
                <td>{{ contract.latestAcceptedContractCreatedAt }} Uhr</td>
                <td>{{ getNameOfStudio(contract.studio) }}</td>
                <td>{{ contract.name }}</td>
                <td>{{ contract.latestAcceptedContractTitle }}</td>
                <td>
                  <v-btn icon @click="pushToUser(contract._id)">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!--- <v-expansion-panel
                                    v-for="contract of recentlyAcceptedContractsFiltered"
                                    :key="contract.user">
                                    <v-expansion-panel-header
                                        >{{ contract.latestAcceptedContractCreatedAt }} Uhr | {{ contract.name }} |
                                        {{ contract.latestAcceptedContractTitle }}</v-expansion-panel-header
                                    >
                             
                           -->
      </v-col>
      <v-col v-if="!showRecentlyAddedContracts" cols="12" class="px-5">
        <v-col cols="12" v-if="userService.hasRight('c_open_contracts')">
          <v-card-title>Heute: {{ getTodayContractsLength }} Verträge</v-card-title>
          <v-card-title>Gestern: {{ getYesterdayContractsLength }} Verträge</v-card-title>
        </v-col>
        <v-expansion-panels v-model="userContractPanel" dark v-if="openContracts.length > 0">
          <v-expansion-panel v-for="(user, i) of sortedOpenContracts" :key="i + '-contractIndex'">
            <v-expansion-panel-header :color="user.publicContract ? 'orange' : 'default'">
              ({{ i + 1 }}) {{ $moment(user.newestCreatedAt).format("DD.MM.YY HH:mm") }} Uhr | {{ getStudiosOfContracts(user.contracts) }} |
              {{ user.vorname }}
              {{ user.nachname }}
              <span class="white--text ml-2">
                {{ $moment(user.contracts[user.contracts.length - 1].startDate).format("DD.MM.YYYY") }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels v-model="openContractPanel">
                <v-expansion-panel
                  :disabled="contract.status.toString() !== 'created'"
                  v-for="(contract, j) of user.contracts"
                  :key="j + '-contractUserIndex'">
                  <v-expansion-panel-header>
                    {{ $moment(contract.createdAt).format("DD.MM.YY HH:mm") }} Uhr |

                    {{ getNameOfContract(contract.contractId) }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col v-if="userService.hasRight('c_open_contracts') && contract.editingBy && contract.editingBy !== null" cols="12">
                        <v-btn
                          v-if="contract.editingBy.toString() === userId.toString()"
                          @click="takeEditAction(false, contract.index, contract.customer.selection)"
                          color="red">
                          Bearbeitung stoppen
                        </v-btn>

                        <v-alert v-else type="error" border="left" outlined> Vertrag ist in Bearbeitung </v-alert>
                      </v-col>
                      <v-col v-else cols="12">
                        <v-btn
                          v-if="userService.hasRight('c_open_contracts')"
                          @click="takeEditAction(true, contract.index, contract.customer.selection)"
                          color="orange">
                          Bearbeitung übernehmen
                        </v-btn>
                      </v-col>
                      <v-col v-if="contract.customer.contractCreatorInfos" cols="12">
                        <v-card-title class="h4 mx-0 px-0">Vertragersteller:</v-card-title>
                        <span>
                          {{ contract.customer.contractCreatorInfos.firstName }}
                          {{ contract.customer.contractCreatorInfos.lastName }}
                        </span>
                        <br />
                        <span>{{ contract.customer.contractCreatorInfos.email }}</span>
                        <br />
                      </v-col>
                      <v-col v-if="contract.category.isTakeover && contract.category.takeover" cols="12">
                        <v-card-title class="h4 mx-0 px-0">Abonnementübernahme:</v-card-title>
                        <span>
                          {{ contract.category.takeover.firstName }}
                          <br />
                          {{ contract.category.takeover.lastName }}
                          <br />
                          {{ contract.category.takeover.email }}
                        </span>
                        <br />
                        <v-card-text class="pl-0"> Hiermit bestätige ich, dass ich mein Abonnement übergebe. </v-card-text>
                        <br />
                        <v-img style="background-color: white" max-width="200px" :src="contract.category.takeover.signature"></v-img>
                      </v-col>
                      <v-col v-if="contract.category.isAffiliate && contract.category.affiliateInfos" cols="12">
                        <v-card-title class="h4 mx-0 px-0">Weiterempfehlung:</v-card-title>
                        <span>
                          {{ contract.category.affiliateInfos.firstName }}
                          <br />
                          {{ contract.category.affiliateInfos.lastName }}
                          <br />
                          {{ contract.category.affiliateInfos.email }}
                        </span>
                        <br />
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="
                          Array.isArray(contract.customer.additionalImage) &&
                          contract.customer.additionalImage &&
                          contract.customer.additionalImage.length > 0
                        ">
                        <v-row>
                          <v-col cols="12">
                            <v-card-title class="h4 mx-0 px-0">Anhänge:</v-card-title>
                            <v-card-subtitle class="mx-0 px-0"> Bild anklicken, um Download zu starten </v-card-subtitle>
                          </v-col>

                          <v-col v-for="additionalImage of contract.customer.additionalImage" :key="additionalImage + '-additionalImage'" cols="12">
                            <v-img
                              :key="imageSources[additionalImage] + 'imgsourceKey'"
                              v-if="imageSources[additionalImage]"
                              :src="imageSources[additionalImage]"
                              @click="downloadContractImage(additionalImage)"
                              max-width="200px"
                              max-height="100%"></v-img>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="
                          contract.customer.additionalImages &&
                          Array.isArray(contract.customer.additionalImages) &&
                          contract.customer.additionalImages.length > 0
                        ">
                        <v-row>
                          <v-col cols="12">
                            <v-card-title class="h4 mx-0 px-0">Anhänge:</v-card-title>
                            <v-card-subtitle class="mx-0 px-0"> Bild anklicken, um Download zu starten </v-card-subtitle>
                          </v-col>

                          <v-col v-for="(additionalImage, index) of contract.customer.additionalImages" :key="index + '-additionalImage'" cols="12">
                            <v-btn @click="downloadNewContractImage(additionalImage.src, index + '_Bild')" color="primary">
                              <v-icon class="pr-1">mdi-download</v-icon>
                              {{ index + 1 }}. Bild
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" v-if="contract.hasPurchasedPersonalTraining">
                        <v-alert type="info">Personal Training gekauft</v-alert>
                      </v-col>
                      <v-col cols="12">
                        <v-card-title class="h4 mx-0 px-0">Kommentare</v-card-title>
                        <div v-for="comment of contract.comments" :key="comment.date + '-comment'">
                          <div v-if="comment.date && (comment.comment || comment.status)">
                            <span> {{ $moment(comment.date).format("DD.MM.YY HH:mm") }} Uhr </span>
                            <v-card-text v-if="comment.status"> Grund: {{ comment.status }} </v-card-text>
                            <v-card-text v-if="comment.comment">
                              {{ comment.comment }}
                            </v-card-text>
                          </div>
                        </div>
                      </v-col>
                      <v-col v-if="userService.hasRight('c_open_contracts')" cols="12">
                        <v-select v-model="contract.commentStatus" label="Grund" :items="reasons"></v-select>
                      </v-col>
                      <v-col v-if="userService.hasRight('c_open_contracts')" cols="12">
                        <v-textarea v-model="contract.comment" rows="3" label="Kommentar"></v-textarea>
                      </v-col>

                      <v-col v-if="userService.hasRight('c_open_contracts')" class="mb-0 pb-0" cols="12">
                        <v-switch class="mb-0 pb-0" v-model="contract.externallySaved" label="im TAC abgespeichert"></v-switch>
                      </v-col>
                      <v-col class="mt-0 pt-0" v-if="userService.hasRight('c_open_contracts') && contract.category.isAffiliate" cols="12">
                        <v-switch class="mt-0 pt-0" v-model="contract.affiliateSaved" label="Weiterempfehlung abgespeichert"></v-switch>
                      </v-col>

                      <v-col class="mt-0 pt-0" v-if="getEmailsOfContract(contract.contractId, contract.studio._id).length > 0" cols="12">
                        <v-card-text>
                          E-Mails werden beim Zurückweisen an:
                          {{ getEmailsOfContract(contract.contractId, contract.studio._id).join(", ") }}
                          verschickt.
                        </v-card-text>
                      </v-col>
                      <v-col v-if="userService.hasRight('c_open_contracts')" cols="8">
                        <v-btn
                          @click="
                            updateContract(
                              contract.customer.selection,
                              contract.index,
                              contract.comment,
                              contract.commentStatus,
                              'accepted',
                              contract.contractId,
                              contract.studio._id,
                              contract.externallySaved,
                              contract.affiliateSaved
                            )
                          "
                          color="primary"
                          class="white--text mr-3">
                          Bestätigen
                        </v-btn>
                        <v-btn
                          color="red"
                          class="white--text"
                          @click="
                            updateContract(
                              contract.customer.selection,
                              contract.index,
                              contract.comment,
                              contract.commentStatus,
                              'rejected',
                              contract.contractId,
                              contract.studio._id,
                              contract.externallySaved,
                              contract.affiliateSaved
                            )
                          ">
                          Zurück an Trainer
                        </v-btn>
                        <v-btn
                          color="orange"
                          v-if="contract.category.selection.title.includes('Neu') || contract.category.selection.title.includes('Ehe')"
                          @click="copyToTAC(contract)"
                          class="white--text mt-5">
                          ins TAC übertragen
                        </v-btn>
                      </v-col>
                      <v-col v-if="userService.hasRight('c_open_contracts')" cols="4" class="d-flex justify-end">
                        <v-btn
                          v-if="userService.hasRight('c_create')"
                          @click="visibleDeleteDialogIndex = contract.customer.selection + '-' + contract.index"
                          icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn @click="$router.push('/dashboard/contract/view/' + contract.customer.selection + '/' + contract.index)" icon>
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn icon @click="printUserContract(contract.customer.selection, contract.index, getNameOfContract(contract.contractId))">
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                      </v-col>

                      <v-col cols="12">
                        <object v-if="!isApp" :key="openContractPanel" width="100%" height="300px" :data="contractSauce"></object>
                        <v-btn
                          color="blue"
                          v-else
                          @click="printUserContract(contract.customer.selection, contract.index, getNameOfContract(contract.contractId))">
                          Vertrag öffnen
                        </v-btn>
                      </v-col>

                      <delete-dialog
                        @onDelete="deleteContract(contract.customer.selection, contract.index)"
                        @onCancel="visibleDeleteDialogIndex = null"
                        :dialog-title="'Vertrag löschen'"
                        :dialog-visible="visibleDeleteDialogIndex === contract.customer.selection + '-' + contract.index"
                        :itemName="'Vertrag'"></delete-dialog>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-text v-else>Keine Verträge gefunden</v-card-text>
      </v-col>
    </v-row>
    <v-row v-else class="justify-center d-flex">
      <v-col cols="11">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <open-nutrition-sales :studios="studioSelect"></open-nutrition-sales>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import api from "../../../api"
import userService from "../../../services/userService"
import contractCreator from "./contractCreator.vue"
import deleteDialog from "../../../components/tools/deleteDialog.vue"
import openNutritionSales from "./components/openNutritionSales.vue"

export default {
  components: { contractCreator, deleteDialog, openNutritionSales },
  data() {
    return {
      reasons: [
        "Firmenbestätigung fehlt",
        "Unterschrift gesetzlicher Vertreter fehlt",
        "Geburtsdatum falsch",
        "Falsche Laufzeit",
        "Aktivierungs-Tarif falsch gewählt",
        "Betreibung in der Vergangenheit",
        "Unterschriften unklar",
        "Weitere",
      ],
      showRecentlyAddedContracts: false,
      imageSources: {},
      contractSauce: null,
      openContractPanel: null,
      userContractPanel: null,
      showNewContractAlert: false,
      visibleDeleteDialogIndex: null,
      recentlyAcceptedContracts: [],
      userService,
      userId: null,
      loading: true,
      showDialog: { active: false, infos: { userId: null, index: null, text: "" } },
      contracts: [],
      studios: [],
      disableStudioSelect: false,
      studioSelect: [],
      openContracts: [],
      contractsBackup: [],
      sortByStartDate: false,
    }
  },

  async created() {
    this.userId = JSON.parse(localStorage.getItem("user")).user._id

    this.fetchContracts()
    this.fetchContractsByStatus("created")
    this.fetchRecentlyAcceptedContracts()
    let studios = await api.fetchStudios()

    this.studios = studios
    let userStudio = await api.getUserStudio()
    this.studioSelect.push(userStudio.studio._id)
  },
  beforeDestroy() {
    this.abortController.abort("not needed anymore")
  },
  methods: {
    copyToTAC(contract) {
      let http = location.host.includes("local") ? "http://" : "https://"
      window.open(http + location.host + "/dashboard/contract/manager/map/" + contract.customer.selection + "/" + contract.index, "_blank")
    },
    async downloadNewContractImage(src, title) {
      await api.downloadContractImageData(src, title)
    },

    async fetchContractImage(src) {
      let data = null
      try {
        const response = await api.fetchContractImageData(src)
        data = response
      } catch (error) {
        this.$toast.error("Fehler beim laden des Bildes")
      }
      console.log(data)
      return data
    },
    getStudiosOfContracts(contracts) {
      let studios = contracts.map((e) => e.studio._id)
      studios = studios.map((e) => this.getNameOfStudio(e))
      studios = [...new Set(studios)]
      return studios.join(", ")
    },
    pushToUser(userId) {
      if (confirm("Benutzerkonto aufrufen")) {
        this.$router.push("/dashboard/user/search/" + userId)
      }
    },
    getNameOfStudio(studioId) {
      let studio = this.studios.find((e) => e._id === studioId)
      return studio && studio.name ? studio.name : "Studio nicht gefunden"
    },
    async fetchRecentlyAcceptedContracts() {
      try {
        let contracts = await api.fetchRecentlyAcceptedContracts()
        this.recentlyAcceptedContracts = contracts
      } catch (e) {
        console.log(e)
      }
    },
    downloadContractImage(additionalImage) {
      api.downloadContractImage(additionalImage)
    },
    deleteContract(customerId, contractIndex) {
      api
        .deleteContract({ customerId, contractIndex })
        .then((res) => {
          this.fetchContractsByStatus("created")
          this.$toast.success("Vertrag gelöscht")
        })
        .catch((e) => {
          this.$toast.error("Fehler beim Löschen des Vertrags")
        })
      this.visibleDeleteDialogIndex = null
    },
    downloadImage(url) {
      api.downloadImage(url)
    },
    handleMessage(message) {
      /*
topic: 'contractEditMode',
        type: 'contractTakeAction',
        info: { active: req.body.active, index: req.body.index, user: req.body.user, editingBy: res.locals.user._id },
            */
      if (message && message.topic == "contractEditMode" && message.type === "contractTakeAction") {
        if (message.info.active) {
          let user = this.openContracts.find((e) => e._id === message.info.user)
          user.contracts[message.info.index].editingBy = message.info.editingBy
        } else {
          let user = this.openContracts.find((e) => e._id === message.info.user)
          user.contracts[message.info.index].editingBy = null
        }
      }
    },
    /* websocketHandler(data) {
            if (data && data.topic.toString() === 'contractEditMode') {
                if (data.info.active) {
                    let user = this.openContracts.find((e) => e._id === data.info.user)
                    user.contracts[data.info.index].editingBy = data.info.editingBy
                } else {
                    let user = this.openContracts.find((e) => e._id === data.info.user)
                    user.contracts[data.info.index].editingBy = null
                }
            }

            if (data && data.topic.toString() === 'newContractCreated') {
                this.showNewContractAlert = true
            }
        },*/
    async takeEditAction(takeAction = true, index, user) {
      let status = await api.updateContractEditMode({
        active: takeAction,
        index,
        user,
      })

      let contractUserId = user.toString()

      if (takeAction) {
        let userContract = this.openContracts.find((e) => e._id.toString() === contractUserId)
        userContract.contracts[index].editingBy = this.userId
      } else {
        let userContract = this.openContracts.find((e) => e._id.toString() === contractUserId)
        userContract.contracts[index].editingBy = null
      }
    },
    filterContracts() {
      this.openContracts = this.contractsBackup.filter((c) => {
        for (let contract of c.contracts) {
          let studioId = contract.studio._id.toString()

          if (this.studioSelect.includes(studioId)) return true
        }
      })
    },
    updateContract(
      userId,
      index,
      comment,
      commentStatus,
      status = "rejected",
      contractId = null,
      studioId = null,
      externallySaved = false,
      affiliateSaved = false
    ) {
      if (confirm(status === "rejected" ? "Vertrag Zurückweisen?" : "Vertrag bestätigen?")) {
        api
          .updateContractStatus({
            status,
            userId,
            index,
            comment,
            commentStatus,
            externallySaved,
            affiliateSaved,
            sendMailTo: this.getEmailsOfContract(contractId, studioId),
          })
          .then((e) => {
            this.$toast.success("Vertrag aktualisiert")
            this.fetchContractsByStatus("created")
          })
          .catch((e) => {
            this.$toast.error("Fehler bei Aktualisierung")
          })
      } else {
        this.$toast.info("Vertrag nicht bestätigt")
      }
    },

    printUserContract(userId, index, title) {
      api.printUserContract(userId, index, title)
    },
    async checkImageSources(imagePaths) {
      this.imageSources = []
      for (let image of imagePaths) {
        if (!this.imageSources[image]) {
          this.imageSources[image] = await api.getContractImage(image)
        }
      }
      this.$forceUpdate()
    },
    getNameOfContract(contractId) {
      try {
        let foundContract = this.contracts.find((c) => c._id.toString() === contractId.toString())

        let title = foundContract.formFields.find((f) => f.inputType === "title").inputValue
        return title
      } catch (e) {
        return "Vertrag nicht gefunden"
      }
    },
    getEmailsOfContract(contractId, studioId) {
      try {
        let foundContract = this.contracts.find((c) => c._id.toString() === contractId.toString())

        let eMails = foundContract.eMails

        return studioId.toString() in eMails ? eMails[studioId.toString()] : null
      } catch (e) {
        return []
      }
    },
    async fetchContractsByStatus(status) {
      this.openContracts = []
      let contracts = await api.fetchContractsByStatus(status)

      //contracts = contracts.filter((e) => e.customer && e.customer.selection)
      contracts.forEach((c) => {
        c.newestCreatedAt = c.contracts.findLast((c) => c.status === "created").createdAt
        c.contracts.forEach((contract) => {
          contract.comment = ""
          contract.commentStatus = null
        })
      })
      // sort contracts by contracts.newestCreatedAt
      contracts = contracts.sort((a, b) => {
        return new Date(b.newestCreatedAt) - new Date(a.newestCreatedAt)
      })
      this.openContracts = contracts
      this.loading = false
      this.contractsBackup = JSON.parse(JSON.stringify(contracts))
      this.filterContracts()
    },
    async fetchContracts() {
      let contracts = await api.fetchContracts()
      this.contracts = contracts
    },
  },
  computed: {
    sortedOpenContracts() {
      if (this.sortByStartDate) {
        this.openContracts.sort((a, b) => {
          const dateA = this.$moment(a.contracts[a.contracts.length - 1].startDate)
          const dateB = this.$moment(b.contracts[b.contracts.length - 1].startDate)

          return dateA - dateB
        })
      } else {
        this.openContracts.sort((a, b) => {
          const dateA = this.$moment(a.newestCreatedAt)
          const dateB = this.$moment(b.newestCreatedAt)

          return dateA - dateB
        })
      }
      return this.openContracts

      // filtern nach start Datum
    },
    getTodayContractsLength() {
      return this.openContracts.filter((c) => {
        return this.$moment(c.newestCreatedAt).isSame(this.$moment(), "day")
      }).length
    },
    getYesterdayContractsLength() {
      return this.openContracts.filter((c) => {
        return this.$moment(c.newestCreatedAt).isSame(this.$moment().subtract(1, "days"), "day")
      }).length
    },
    recentlyAcceptedContractsFiltered() {
      return this.recentlyAcceptedContracts.filter((c) => {
        let studioId = c.studio

        if (this.studioSelect.includes(studioId)) return true
      })
    },
    isApp() {
      return window.flutter_inappwebview || false
    },
  },
  watch: {
    studioSelect() {
      this.filterContracts()
    },
    async openContractPanel(val) {
      if (val !== undefined) {
        let user = this.openContracts[this.userContractPanel]
        let contract = user.contracts[val]

        if (contract && contract.customer && contract.customer.additionalImage) {
          this.checkImageSources(contract.customer.additionalImage)
        }
        try {
          let blob = await api.getIframeprintUserContract(contract.customer.selection, val, this.getNameOfContract(contract.contractId))
          this.contractSauce = URL.createObjectURL(blob)
        } catch (e) {
          this.$toast.error("Vertrag konnte nicht gedruckt werden")
        }
      } else {
        this.contractSauce = null
      }
    },
    userContractPanel(val) {
      this.openContractPanel = undefined
    },
  },
}
</script>
<style lang="scss" scoped></style>
