import { render, staticRenderFns } from "./upgradeContract.vue?vue&type=template&id=a2ff2a92&scoped=true"
import script from "./upgradeContract.vue?vue&type=script&lang=js"
export * from "./upgradeContract.vue?vue&type=script&lang=js"
import style0 from "./upgradeContract.vue?vue&type=style&index=0&id=a2ff2a92&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2ff2a92",
  null
  
)

export default component.exports