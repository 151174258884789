<template>
  <v-container v-if="userService.hasRight('todo_fetchAndEdit')" fluid>
    <v-row>
      <v-col cols="12" class="mt-5">
        <v-select
          v-if="userService.hasRight('todo_changeStudio')"
          :disabled="onlyUserTodos"
          hide-details
          @change="reloadTodos"
          :items="studios"
          label="Studio auswählen"
          v-model="selectedStudio"
          item-text="name"
          item-value="_id"></v-select>
      </v-col>

      <v-col cols="12" class="d-flex justify-center my-0 py-0">
        <v-switch hide-details color="primary" v-model="onlyUserTodos" @change="reloadTodos" label="meine ToDos"> </v-switch>
      </v-col>
      <v-col cols="12">
        <v-card-text align="center"> Falls du deine ToDos (Centerübergreifend) sehen möchtest, aktiviere den Schalter. <br /> </v-card-text>
      </v-col>
      <v-col cols="12" class="mb-5" align="center">
        <v-btn color="secondary" @click="openCreateDialog">ToDo hinzufügen<v-icon class="ml-1">mdi-plus</v-icon></v-btn>
      </v-col>
    </v-row>

    <!-- Tabs: Offene und Erledigte Todos -->
    <v-tabs centered dark v-model="activeTab">
      <v-tab key="open">Offene Todos</v-tab>
      <v-tab key="resolved">Erledigte Todos</v-tab>
    </v-tabs>

    <v-tabs-items dark v-model="activeTab">
      <!-- Offene Todos -->
      <v-tab-item key="open">
        <v-row align="center" class="mb-2">
          <v-col cols="12" class="d-flex justify-end">
            <v-slide-x-transition>
              <div v-if="openSearchActive">
                <v-text-field
                  hide-details
                  v-model="openSearchQuery"
                  label="Suche in offenen Todos"
                  clearable
                  @click:clear="openSearchQuery = ''"
                  append-icon="mdi-close"
                  @click:append="openSearchActive = false"></v-text-field>
              </div>
            </v-slide-x-transition>
            <v-btn class="mt-4" icon v-if="!openSearchActive" @click="openSearchActive = true">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :items-per-page="-1"
          :headers="headers"
          :items="filteredOpenTodos"
          class="elevation-1"
          :sort-by="['priorityValue', 'createdAt']"
          :sort-desc="[true, true]"
          clickable
          @click:row="openDetailDialog">
          <template v-slot:item.priority="{ item }">
            <v-chip :color="getPriorityColor(item.priority)" dark>{{ mapPriorityName(item.priority) }}</v-chip>
          </template>
          <template v-slot:item.createdBy="{ item }">
            {{ item.createdBy.vorname[0].toUpperCase() }}{{ item.createdBy.nachname[0].toUpperCase() }}
          </template>
          <template v-slot:item.assignedUsers="{ item }">
            <span v-if="item.assignedUsers && item.assignedUsers.length > 0">
              <span v-for="(assignedUser, index) of item.assignedUsers" :key="item._id + '-' + assignedUser._id"
                >{{ assignedUser.user.vorname[0].toUpperCase() }}{{ assignedUser.user.nachname[0].toUpperCase()
                }}{{ index + 1 !== item.assignedUsers.length ? ", " : "" }}</span
              >
            </span>
            <span v-else>- </span>
          </template>
          <template v-slot:item.studio="{ item }">
            {{ item.studio ? item.studio.name : "Kein Studio" }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            <span>{{ $moment(item.createdAt).format("DD.MM.YY HH:mm") }}</span>
          </template>
        </v-data-table>
      </v-tab-item>

      <!-- Erledigte Todos -->
      <v-tab-item v-if="false" key="resolved">
        <v-data-table
          :headers="headers"
          :items="resolvedTodos"
          class="elevation-1"
          :sort-by="['priorityValue', 'createdAt']"
          :sort-desc="[true, true]"
          clickable
          @click:row="openDetailDialog">
          <template v-slot:item.priority="{ item }">
            <v-chip :color="getPriorityColor(item.priority)" dark>{{ mapPriorityName(item.priority) }}</v-chip>
          </template>
          <template v-slot:item.createdBy="{ item }">
            {{ item.createdBy.vorname[0].toUpperCase() }}{{ item.createdBy.nachname[0].toUpperCase() }}
          </template>
          <template v-slot:item.studio="{ item }">
            {{ item.studio ? item.studio.name : "Kein Studio" }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            <span>{{ $moment(item.createdAt).format("DD.MM.YY HH:mm") }}</span>
          </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item key="resolved">
        <v-row align="center" class="mb-2">
          <v-col cols="12" class="d-flex justify-end">
            <v-slide-x-transition>
              <div v-if="resolvedSearchActive">
                <v-text-field
                  hide-details
                  v-model="resolvedSearchQuery"
                  label="Suche in erledigten Todos"
                  clearable
                  @click:clear="resolvedSearchQuery = ''"
                  append-icon="mdi-close"
                  @click:append="resolvedSearchActive = false"></v-text-field>
              </div>
            </v-slide-x-transition>
            <v-btn class="mt-4" icon v-if="!resolvedSearchActive" @click="resolvedSearchActive = true">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="Object.keys(groupedResolvedTodosFiltered).length === 0">
          <v-col cols="12" class="mt-5">
            <v-alert type="info">Keine erledigte ToDo's gefunden</v-alert>
          </v-col>
        </v-row>
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="(group, index) in groupedResolvedTodosFiltered" :key="group.year">
            <v-expansion-panel-header>
              {{ group.year }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="(monthGroup, idx) in group.months" :key="monthGroup.month">
                  <v-expansion-panel-header> {{ monthGroup.monthName }} ({{ monthGroup.todos.length }}) </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item v-for="todo in monthGroup.todos" :key="todo._id" @click="openDetailDialog(todo)" style="cursor: pointer">
                        <v-list-item-content>
                          <v-list-item-title>{{ todo.title }}</v-list-item-title>
                          <v-list-item-subtitle>{{ formatDate(todo.createdAt) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
    </v-tabs-items>

    <!-- Detail Dialog mit integriertem Kommentar-, Zuweisungs- und Verlauf-Bereich -->
    <v-dialog fullscreen v-model="detailDialog">
      <v-card class="mx-auto" dark>
        <!-- Header mit Titel, Erstellungsdatum, Ersteller und Loeschen-Button -->

        <div style="max-width: 500px" class="d-flex flex-row align-center justify-space-between mx-auto px-5 pt-5">
          <div>
            <v-btn icon class="white" color="black" @click="closeDetailDialog"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <v-chip color="white" class="mx-2 black--text">{{ formatDate(currentTodo?.createdAt) }}</v-chip>
            <v-chip small class="mx-2" :color="getPriorityColor(currentTodo?.priority)">{{ mapPriorityName(currentTodo?.priority) }}</v-chip>
          </div>
          <div>
            <v-btn icon class="orange" color="white" @click="toggleEditMode"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn class="red ml-1" color="white" icon @click="deleteCurrentTodo">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>

        <div style="max-width: 500px" class="mx-auto">
          <v-card-title>
            <div class="d-flex flex-column w-100">
              <v-card-subtitle class="px-0">
                Erstellt von: {{ currentTodo?.createdBy?.vorname }} {{ currentTodo?.createdBy?.nachname }} |
                {{ currentTodo?.studio?.name }}</v-card-subtitle
              >
              <div v-if="!editMode" class="w-100">
                <v-chip small :color="currentTodo?.resolved ? 'green' : 'orange'">{{ currentTodo?.resolved ? "Erledigt" : "Offen" }}</v-chip>
              </div>
              <div v-if="!editMode">
                <span class="headline">{{ currentTodo?.title }}</span>
              </div>
              <div v-else>
                <v-text-field width="100%" v-model="editedTodo.title" label="Titel" :rules="[(v) => !!v || 'Pflichtfeld']"></v-text-field>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <!-- Beschreibung und Editiermodus -->
            <div v-if="editMode">
              <v-textarea v-model="editedTodo.description" label="Beschreibung" auto-grow dense></v-textarea>
              <v-select
                v-if="userService.hasRight('todo_changeStudio')"
                :items="studios"
                label="Studio auswählen"
                v-model="editedTodo.studio"
                item-text="name"
                item-value="_id"></v-select>
              <v-select v-model="editedTodo.priority" :items="priorityItems" label="Priorität" :rules="[(v) => !!v || 'Pflichtfeld']">
                <template v-slot:selection="{ item }">
                  <v-chip :color="getPriorityColor(item)" dark>{{ mapPriorityName(item) }}</v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-chip :color="getPriorityColor(item)" dark>{{ mapPriorityName(item) }}</v-chip>
                </template>
              </v-select>
              <v-card-actions class="justify-center">
                <v-btn text color="white" @click="toggleEditMode">Abbrechen</v-btn>
                <v-btn text color="primary" @click="saveEdit">Speichern</v-btn>
              </v-card-actions>
            </div>
            <div v-else>
              <p>{{ currentTodo?.description }}</p>

              <v-divider class="my-3"></v-divider>

              <!-- Neuer Kommentar -->
              <div class="mt-2">
                <v-switch hide-details v-model="isResolved" label="Erledigt"></v-switch>
                <v-textarea class="mt-2" hide-details v-model="newComment" label="Kommentar" auto-grow outlined></v-textarea>

                <v-card-actions class="d-flex justify-center mb-5 mt-2">
                  <v-btn large color="primary" @click="submitComment"> ToDo aktualisieren </v-btn>
                </v-card-actions>
              </div>
              <!-- Kommentarverlauf -->
              <div v-if="currentTodo && currentTodo.comments && currentTodo.comments.filter((e) => e.comment.length > 0).length">
                <h3 class="headline">
                  Kommentare <v-chip>{{ currentTodo.comments.filter((e) => e.comment.length > 0).length }}</v-chip>
                </h3>
                <v-list dense>
                  <v-list-item v-for="(comment, index) in reverse(currentTodo.comments.filter((e) => e.comment.length > 0))" :key="index">
                    <v-list-item-content>
                      <div class="subtitle-2">{{ formatDate(comment.createdAt) }} {{ comment.user.vorname }} {{ comment.user.nachname }}</div>
                      <div>{{ comment.comment }}</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>

              <v-divider class="my-3"></v-divider>

              <!-- Assigned Users Bereich -->
              <div v-if="currentTodo">
                <h3 class="headline d-flex align-center justify-space-between">
                  Zugewiesene Benutzer
                  <v-btn v-if="userService.hasRight('todo_manageUsers')" icon class="primary" color="white" small @click="toggleAssignment">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </h3>
                <v-expand-transition>
                  <div class="mt-5" v-show="showAssignment">
                    <div class="justify-center d-flex flex-column">
                      <user-search
                        style="width: 100%"
                        class="mx-auto mb-2"
                        :value="newAssignedUser"
                        :key="assignedUserKey"
                        ignoreRights
                        :disabled="false"
                        instructor
                        label="Benutzer suchen"
                        @update="updateNewAssignedUser"></user-search>
                      <v-select
                        v-show="false"
                        v-model="newAssignmentRights"
                        :items="['read', 'comment', 'manage']"
                        label="Rechte"
                        outlined
                        dense></v-select>
                      <v-btn color="primary" class="mx-auto" @click="submitAssignment" :disabled="!newAssignedUser || !newAssignmentRights">
                        Zuweisen
                      </v-btn>
                    </div>
                  </div>
                </v-expand-transition>
                <v-list dense>
                  <v-list-item v-for="(user, index) in currentTodo.assignedUsers || []" :key="index">
                    <v-list-item-content> {{ user.user.vorname }} {{ user.user.nachname }} </v-list-item-content>
                    <v-list-item-actions v-if="userService.hasRight('todo_manageUsers')"
                      ><v-btn @click="removeUser(user._id)" icon><v-icon>mdi-account-minus</v-icon></v-btn></v-list-item-actions
                    >
                  </v-list-item>
                  <v-list-item v-if="!currentTodo.assignedUsers || currentTodo.assignedUsers.length === 0">
                    <v-list-item-content> Keine Benutzer zugewiesen. </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>

              <v-divider class="my-3"></v-divider>
            </div>
          </v-card-text>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: Erstellen von Todo Eintraegen -->
    <v-dialog overlay-opacity="0.9" v-model="todoDialog" max-width="600px">
      <v-card dark>
        <v-card-title class="primary white--text">
          <span class="headline">{{ todoDialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="todoForm" v-model="formValid">
            <user-search
              style="width: 100%"
              class="mx-auto mt-5"
              :value="createdByUser"
              :key="assignedUserKey"
              ignoreRights
              :disabled="false"
              instructor
              label="Wer hat das ToDo erstellt?"
              @update="updateCreatedByUser"></user-search>
            <v-text-field v-model="editedTodo.title" label="Titel" :rules="[(v) => !!v || 'Pflichtfeld']"></v-text-field>
            <v-textarea v-model="editedTodo.description" label="Beschreibung"></v-textarea>

            <v-select v-model="editedTodo.priority" :items="priorityItems" label="Priorität" :rules="[(v) => !!v || 'Pflichtfeld']">
              <template v-slot:selection="{ item }">
                <v-chip :color="getPriorityColor(item)" dark>{{ mapPriorityName(item) }}</v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-chip :color="getPriorityColor(item)" dark>{{ mapPriorityName(item) }}</v-chip>
              </template>
            </v-select>
            <v-select
              v-if="userService.hasRight('todo_changeStudio')"
              :items="studios"
              label="Studio auswählen"
              v-model="editedTodo.studio"
              item-text="name"
              item-value="_id"></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue darken-1" @click="closeTodoDialog">Abbrechen</v-btn>
          <v-btn text color="blue darken-1" @click="saveTodo" :disabled="!formValid">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <v-container v-else fluid>
    <v-alert type="info">Dieser Bereich ist nicht freigeschaltet</v-alert>
  </v-container>
</template>

<script>
import api from "@/api"
import userSearch from "@/components/userSearch.vue"
import { mapState, mapActions, mapGetters } from "vuex"
import userService from "@/services/userService"

export default {
  name: "toDoListMain",
  components: { userSearch },
  data() {
    return {
      userService,
      onlyUserTodos: false,
      activeTab: 0, // 0 = offene Todos, 1 = erledigte Todos
      openTodos: [],
      resolvedTodos: [],
      headers: [
        { text: "Titel", value: "title" },
        { text: "Ersteller", value: "createdBy" },
        { text: "Zugewiesen", value: "assignedUsers" },
        { text: "Studio", value: "studio" },
        { text: "Priorität", value: "priority" },
        { text: "Erstellt am", value: "createdAt" },
      ],
      priorityItems: ["low", "medium", "high"],
      todoDialog: false,
      todoDialogTitle: "",
      // editedTodo wird sowohl für Create als auch für Edit genutzt
      editedTodo: {
        _id: null,
        title: "",
        description: "",
        priority: "medium",
        studio: null,
      },
      formValid: false,
      // Detail Dialog bezogene Daten
      detailDialog: false,
      currentTodo: null, // Todo für Detailanzeige
      editMode: false,
      // Kommentar und Status
      newComment: "",
      isResolved: false,

      // Zuweisungsbezogene Daten
      newAssignedUser: null,
      newAssignmentRights: "manage",
      showAssignment: false,
      selectedStudio: null,
      openSearchActive: false,
      openSearchQuery: "",
      resolvedSearchActive: false,
      resolvedSearchQuery: "",
      assignedUserKey: 0,
      createdByUser: null,
      studios: [],
    }
  },
  async created() {
    let studios = await api.fetchStudios()

    this.studios = studios
      .filter((studio) => studio.code && studio.code !== "ot" && studio.code !== "alibey")
      .map((studio) => {
        return { _id: studio._id, name: studio.name }
      })
    this.selectedStudio = this.userStudio
    this.fetchOpenTodos()
  },
  watch: {
    // Beim Wechseln des Tabs werden jeweils die entsprechenden Todos geladen
    activeTab(newVal) {
      if (newVal === 1) {
        this.fetchResolvedTodos()
      } else {
        this.fetchOpenTodos()
      }
    },
  },
  computed: {
    ...mapState("studioStore", ["storeStudios"]),
    filteredOpenTodos() {
      if (!this.openSearchQuery) return this.openTodos
      const query = this.openSearchQuery.toLowerCase()
      return this.openTodos.filter((todo) => this.matchesTodo(todo, query))
    },
    filteredResolvedTodos() {
      if (!this.resolvedSearchQuery) return this.resolvedTodos
      const query = this.resolvedSearchQuery.toLowerCase()
      return this.resolvedTodos.filter((todo) => this.matchesTodo(todo, query))
    },
    // Gruppierung der erledigten Todos anhand der gefilterten Liste
    groupedResolvedTodosFiltered() {
      const groups = {}
      this.filteredResolvedTodos.forEach((todo) => {
        const date = new Date(todo.createdAt)
        const year = date.getFullYear()
        const month = date.getMonth() // 0-basiert
        if (!groups[year]) groups[year] = {}
        if (!groups[year][month]) groups[year][month] = []
        groups[year][month].push(todo)
      })
      return Object.keys(groups)
        .sort((a, b) => b - a)
        .map((year) => {
          const months = Object.keys(groups[year])
            .sort((a, b) => b - a)
            .map((month) => ({
              month: month,
              monthName: new Date(2020, month).toLocaleString("default", { month: "long" }),
              todos: groups[year][month].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
            }))
          return { year, months }
        })
    },
    userStudio() {
      return this.userService.getStudio()?._id || null
    },
    studiosOld() {
      // Standardmäßig werden nur offene Todos geladen
      return this.storeStudios
        .filter((studio) => studio.code && studio.code !== "ot" && studio.code !== "alibey")
        .map((studio) => {
          return { _id: studio._id, name: studio.name }
        })
    },
    groupedResolvedTodos() {
      const groups = {}
      this.resolvedTodos.forEach((todo) => {
        const date = new Date(todo.createdAt)
        const year = date.getFullYear()
        const month = date.getMonth() // 0-basiert
        if (!groups[year]) groups[year] = {}
        if (!groups[year][month]) groups[year][month] = []
        groups[year][month].push(todo)
      })

      // Transformiere die gruppierten Daten in ein Array, sortiert nach Jahr und Monat (absteigend)
      return Object.keys(groups)
        .sort((a, b) => b - a)
        .map((year) => {
          const months = Object.keys(groups[year])
            .sort((a, b) => b - a)
            .map((month) => ({
              month: month,
              monthName: new Date(2020, month).toLocaleString("default", { month: "long" }),
              todos: groups[year][month].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
            }))
          return { year, months }
        })
    },
  },
  methods: {
    matchesTodo(todo, query) {
      // Prüfe Titel und Beschreibung
      if (todo.title && todo.title.toLowerCase().includes(query)) return true
      if (todo.description && todo.description.toLowerCase().includes(query)) return true
      // Prüfe Studio (Name)
      if (todo.studio && todo.studio.name && todo.studio.name.toLowerCase().includes(query)) return true
      // Prüfe Ersteller (vorname und nachname)
      if (todo.createdBy) {
        if (todo.createdBy.vorname && todo.createdBy.vorname.toLowerCase().includes(query)) return true
        if (todo.createdBy.nachname && todo.createdBy.nachname.toLowerCase().includes(query)) return true
      }
      // Prüfe zugewiesene Benutzer
      if (todo.assignedUsers && Array.isArray(todo.assignedUsers)) {
        for (const assigned of todo.assignedUsers) {
          if (assigned.user) {
            if (assigned.user.vorname && assigned.user.vorname.toLowerCase().includes(query)) return true
            if (assigned.user.nachname && assigned.user.nachname.toLowerCase().includes(query)) return true
          }
        }
      }
      return false
    },
    mapPriorityName(priority) {
      if (priority === "high") return "Hoch"
      if (priority === "medium") return "Mittel"
      if (priority === "low") return "Niedrig"
      return "Unbekannt"
    },
    reverse(array) {
      return array.slice().reverse()
    },
    getPriorityColor(priority) {
      if (priority === "high") return "red"
      if (priority === "medium") return "orange"
      if (priority === "low") return "green"
      return "grey"
    },
    formatDate(date) {
      return date ? this.$moment(date).format("DD.MM.YY HH:mm") : ""
    },
    fetchOpenTodos() {
      api.toDoList
        .fetchAll({ status: "open", studio: this.selectedStudio, onlyUserTodos: this.onlyUserTodos })
        .then((data) => {
          this.openTodos = data.map((todo) => {
            let priorityValue = 0
            if (todo.priority === "high") priorityValue = 3
            else if (todo.priority === "medium") priorityValue = 2
            else if (todo.priority === "low") priorityValue = 1
            return { ...todo, priorityValue }
          })
          if (this.currentTodo && this.currentTodo._id) {
            // Wenn ein Todo geöffnet ist, wird es neu geladen
            this.currentTodo = this.openTodos.find((todo) => todo._id === this.currentTodo._id)
          }
        })
        .catch((err) => {
          console.error("Fehler beim Laden offener Todos", err)
        })
    },
    fetchResolvedTodos() {
      api.toDoList
        .fetchAll({ status: "resolved", studio: this.selectedStudio, onlyUserTodos: this.onlyUserTodos })
        .then((data) => {
          this.resolvedTodos = data.map((todo) => {
            let priorityValue = 0
            if (todo.priority === "high") priorityValue = 3
            else if (todo.priority === "medium") priorityValue = 2
            else if (todo.priority === "low") priorityValue = 1
            return { ...todo, priorityValue }
          })
          if (this.currentTodo && this.currentTodo._id) {
            // Wenn ein Todo geöffnet ist, wird es neu geladen
            this.currentTodo = this.resolvedTodos.find((todo) => todo._id === this.currentTodo._id)
          }
        })
        .catch((err) => {
          console.error("Fehler beim Laden erledigter Todos", err)
        })
    },
    reloadTodos() {
      if (this.activeTab === 0) {
        this.fetchOpenTodos()
      } else {
        this.fetchResolvedTodos()
      }
    },
    openCreateDialog() {
      this.todoDialogTitle = "Neuer ToDo Eintrag"
      this.createdByUser = null
      this.assignedUserKey += 1
      this.editedTodo = {
        _id: null,
        title: "",
        studio: this.userStudio,
        createdBy: null,
        description: "",
        priority: "low",
      }
      this.todoDialog = true
    },
    closeTodoDialog() {
      this.todoDialog = false
    },
    saveTodo() {
      if (this.editedTodo._id) {
        // Update
        api.toDoList
          .update(this.editedTodo._id, this.editedTodo)
          .then(() => {
            if (this.editedTodo.studio !== this.selectedStudio) {
              this.selectedStudio = this.editedTodo.studio
              let studioName = this.studios.find((s) => s._id === this.selectedStudio)?.name || "Fehler "
              this.$toast.info("Das Studio wurde auf " + studioName + " gesetzt")
            }
            this.reloadTodos()
            this.todoDialog = false
          })
          .catch((err) => {
            console.error("Fehler beim Aktualisieren", err)
          })
      } else {
        // Neuer Eintrag
        if (!this.createdByUser) {
          this.$toast.error("Bitte einen Benutzer auswählen")
          return
        }

        this.editedTodo.createdBy = this.createdByUser

        api.toDoList
          .create(this.editedTodo)
          .then((e) => {
            if (this.editedTodo.studio !== this.selectedStudio) {
              this.selectedStudio = this.editedTodo.studio
              let studioName = this.studios.find((s) => s._id === this.selectedStudio)?.name || "Fehler "
              this.$toast.info("Das Studio wurde auf " + studioName + " gesetzt")
            }

            this.reloadTodos()
            this.openDetailDialog(e)
            this.todoDialog = false
          })
          .catch((err) => {
            console.error("Fehler beim Erstellen", err)
          })
      }
    },
    // Detail Dialog öffnen und initialisieren
    openDetailDialog(item) {
      this.currentTodo = item
      this.editMode = false
      this.newComment = ""
      this.showAssignment = false
      // Checkbox automatisch setzen, falls Todo resolved ist
      this.isResolved = item.resolved
      this.detailDialog = true
    },
    closeDetailDialog() {
      this.detailDialog = false
    },
    toggleEditMode() {
      this.editMode = !this.editMode
      if (this.editMode && this.currentTodo) {
        // Vor dem Bearbeiten die aktuellen Werte kopieren
        this.editedTodo = {
          _id: this.currentTodo._id,
          title: this.currentTodo.title,
          description: this.currentTodo.description,
          studio: this.currentTodo.studio?._id || this.currentTodo.studio || null,
          priority: this.currentTodo.priority,
        }
      }
    },
    saveEdit() {
      const payload = {
        title: this.editedTodo.title,
        description: this.editedTodo.description,
        priority: this.editedTodo.priority,
        studio: this.editedTodo.studio?._id || this.editedTodo.studio || null,
      }
      api.toDoList
        .update(this.currentTodo._id, payload)
        .then(() => {
          if (this.currentTodo.studio._id !== payload.studio) {
            this.selectedStudio = payload.studio
            let studioName = this.studios.find((s) => s._id === payload.studio)?.name || "Fehler "
            this.$toast.info("Das Studio wurde auf " + studioName + " gesetzt")
          }

          this.editMode = false
          this.reloadTodos()
        })
        .catch((err) => {
          console.error("Fehler beim Aktualisieren", err)
        })
    },
    deleteCurrentTodo() {
      if (confirm("Möchtest du diesen Eintrag wirklich löschen?")) {
        api.toDoList
          .delete(this.currentTodo._id)
          .then(() => {
            this.closeDetailDialog()
            this.reloadTodos()
          })
          .catch((err) => {
            console.error("Fehler beim Loeschen", err)
          })
      }
    },
    submitComment() {
      if (!(this.currentTodo && this.currentTodo.resolved && !this.isResolved) || confirm("Möchtest du das Todo wieder öffnen?")) {
        const payload = {
          comment: this.newComment,
          status: this.isResolved ? "resolved" : "opened",
        }
        api.toDoList
          .addComment(this.currentTodo._id, payload)
          .then(() => {
            this.newComment = ""

            let correctTab = this.activeTab === Number(this.isResolved)
            if (correctTab) {
              this.reloadTodos()
            } else {
              this.activeTab = Number(this.isResolved)
            }
            this.$toast.success("ToDo erfolgreich aktualisiert")
          })
          .catch((err) => {
            console.error("Fehler beim Hinzufuegen des Kommentars", err)
          })
      }
    },
    updateNewAssignedUser(newUser) {
      this.newAssignedUser = newUser
    },
    updateCreatedByUser(newUser) {
      this.createdByUser = newUser
    },
    removeUser(userId) {
      api.toDoList
        .removeAssignment(this.currentTodo._id, userId)
        .then(() => {
          this.currentTodo.assignedUsers = this.currentTodo.assignedUsers.filter((user) => user._id !== userId)
          this.reloadTodos()
        })
        .catch((err) => {
          console.error("Fehler beim Entfernen des Benutzers", err)
        })
    },
    submitAssignment() {
      const payload = {
        assignment: { user: this.newAssignedUser, rights: this.newAssignmentRights },
      }
      api.toDoList
        .assign(this.currentTodo._id, payload)
        .then(() => {
          if (this.currentTodo) {
            if (!this.currentTodo.assignedUsers) {
              this.$set(this.currentTodo, "assignedUsers", [])
            }
            this.currentTodo.assignedUsers.push({
              user: this.newAssignedUser.username,
              rights: this.newAssignmentRights,
            })
          }
          this.newAssignedUser = null
          this.assignedUserKey += 1
          this.newAssignmentRights = "manage"
          this.showAssignment = false
          this.reloadTodos()
        })
        .catch((err) => {
          console.error("Fehler beim Zuweisen des Benutzers", err)
        })
    },
    toggleAssignment() {
      this.showAssignment = !this.showAssignment
    },
  },
}
</script>

<style scoped>
/* Eigene Styles koennen hier hinzugefuegt werden */
</style>
