import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  createTACTimestop(data) {
    let url = "https://app.wellcomefit.ch/api/tac/api/timestop/create"
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  fetchTimestopFormText() {
    let url = buildUrl("timestopform/text/fetch")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTimestopGalleryItemDataUrl(id, index) {
    let url = buildUrl("timestopform/gallery/item/data/url/" + id + "/" + index)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTimestopPDF(id) {
    let url = buildUrl("timestopform/generate/" + id)

    axios
      .get(
        url, //your url
        {
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          }, // important
        }
      )
      .then((response) => {
        if (window.flutter_inappwebview) {
          console.log("FLUTTER IOS DOWNLOAD PDF START")
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]

            window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "pdf", "Timestop")
          }
        } else {
          saveAs(response.data, "Timestop")
        }
      })
  },
  changeTimestopStatus(id, status) {
    let url = buildUrl("timestopform/change/status")
    return axios.post(url, { id, status }, buildOptions()).then((res) => res.data)
  },
  updateTimestopOfficeComment(id, officeComment) {
    let url = buildUrl("timestopform/update/office/comment")
    return axios.post(url, { id, officeComment }, buildOptions()).then((res) => res.data)
  },
  fetchOpenTimestops() {
    let url = buildUrl("timestopform/open")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTimestopsAdmin(status) {
    let url = buildUrl("fetch/timestops/admin/" + status)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveTimestopManual(steps) {
    let url = buildUrl("timestopform/manual")
    return axios.post(url, { steps }, buildOptions()).then((res) => res.data)
  },

  fetchTimestopManual() {
    let url = buildUrl("timestopform/manual")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchRecentTimestops() {
    let url = buildUrl("timestopform/recent")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllTimestopsForUser() {
    let url = buildUrl("timestopform/for/user")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllTimestopsForUserEmailAdmin(email) {
    let url = buildUrl("timestopform/for/user/email/admin/" + email)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllTimestopsForUserEmail() {
    let url = buildUrl("timestopform/for/user/email")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  deleteTimestopById(id) {
    let url = buildUrl("timestopform/delete/by/id/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  setTimestopToLate(id) {
    let url = buildUrl("timestopform/set/to/late/by/id/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateTimeStopFormEntry(data) {
    let url = buildUrl("timestopform/update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateTimeStopFormEntryNoNotification(data) {
    let url = buildUrl("timestopform/update/no/notification")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchDeclinedTimestops() {
    let url = buildUrl("timestopform/declined")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTimestopTextStudio() {
    let url = buildUrl("timestopform/text/fetch/studio")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateTimestopFormText(data) {
    let url = buildUrl("timestopform/text")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  sendReminderEmail(timestop) {
    let url = buildUrl("timestops/send/reminder/email")
    return axios.post(url, timestop, buildOptions()).then((res) => res.data)
  },

  getTimestops() {
    let url = buildUrl("timestops")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  submitTimestop(fd) {
    let url = buildUrl("timestop")
    return axios.post(url, fd).then((res) => res.data)
  },
  saveTimestopEntry(data) {
    let url = buildUrl("timestopform")
    return axios.post(url, { entry: data }, buildOptions()).then((res) => res.data)
  },
}
