import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  subscribeEmailSubscription() {
    let url = buildUrl("mails/subscribe")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  unsubscribeEmailSubscription() {
    let url = buildUrl("mails/unsubscribe")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchMembercardNewsletterInfo() {
    let url = buildUrl("membercard/mailchimp/info")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  subscribeMembercardNewsletter(data) {
    let url = buildUrl("membercard/mailchimp/signup")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  unsubscribeMembercardNewsletter() {
    let url = buildUrl("membercard/mailchimp/signoff")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  subscribeNewsletter(data) {
    let url = buildUrl("mailchimp/subscribe")
    return axios.post(url, { data }, buildOptions()).then((res) => res.data)
  },
  unsubscribeNewsletter() {
    let url = buildUrl("mailchimp/unsubscribe")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  fetchNewsletterInfo() {
    let url = buildUrl("mailchimp/info")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  signupMailCampaign(signup) {
    let url = buildUrl("mails/campaign/signup")
    return axios.post(url, { signup }, buildOptions()).then((res) => res.data)
  },
  fetchMailCampaigns() {
    let url = buildUrl("mails/campaign")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createMailCampaign(campaign) {
    let url = buildUrl("mails/campaign")
    return axios.post(url, { campaign }, buildOptions()).then((res) => res.data)
  },
}
