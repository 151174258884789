import shopMainpage from "@/views/shop/shopMainpage.vue"
import shopRoute from "@/views/shop/shopRoute.vue"
import shopCheckout from "@/views/shop/shopCheckout.vue"
import shopCheckPayment from "@/views/shop/shopCheckPayment.vue"
import shopPurchaseInfos from "@/views/shop/shopPurchaseInfos.vue"
import shopCheckPaymentFailed from "@/views/shop/shopCheckPaymentFailed.vue"

export default [
  {
    path: "/shop",
    name: "shopMainpage",
    component: shopRoute,
    children: [
      {
        path: "",
        name: "shopMainPage",
        component: shopMainpage,
        meta: {
          hideBottomNavigation: true,
        },
      },
      {
        path: "checkout",
        name: "shopCheckout",
        component: shopCheckout,
        meta: {
          hideBottomNavigation: true,
        },
      },
      {
        path: "payment/failed",
        name: "shopPaymentFailed",
        component: shopCheckPaymentFailed,
        meta: {
          hideBottomNavigation: true,
        },
      },
      {
        path: "check/payment",
        name: "shopCheckPayment",
        component: shopCheckPayment,
        meta: {
          hideBottomNavigation: true,
        },
      },
      {
        path: "purchase/infos/:id",
        name: "shopPurchaseInfos",
        component: shopPurchaseInfos,
        meta: {
          hideBottomNavigation: true,
        },
      },
      {
        path: ":id",
        name: "shopMainPageProduct",
        component: shopMainpage,
        meta: {
          hideBottomNavigation: true,
        },
      },
    ],
    meta: {
      hideBottomNavigation: true,
    },
  },
]
