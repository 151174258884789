import { render, staticRenderFns } from "./schoolHolidaysDialog.vue?vue&type=template&id=368085e7&scoped=true"
import script from "./schoolHolidaysDialog.vue?vue&type=script&lang=js"
export * from "./schoolHolidaysDialog.vue?vue&type=script&lang=js"
import style0 from "./schoolHolidaysDialog.vue?vue&type=style&index=0&id=368085e7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368085e7",
  null
  
)

export default component.exports