<template>
  <v-dialog dark v-model="internalDialog" :origin="origin" transition="scale-transition" fullscreen>
    <v-btn @click="closeDialog" x-large style="z-index: 1000" icon absolute top right color="white">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <shift-management-main-dialog-create></shift-management-main-dialog-create>
  </v-dialog>
</template>

<script>
import shiftManagementMainDialogCreate from "./shiftManagementMainDialogCreate.vue"

export default {
  components: {
    shiftManagementMainDialogCreate,
  },
  name: "ActionDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    // Mode: 'create' oder 'edit'
    mode: {
      type: String,
      default: "create",
    },
    // Origin für die Transition, z. B. "250px 300px"
    origin: {
      type: String,
      default: "center center",
    },
  },
  data() {
    return {
      internalDialog: this.value,
    }
  },
  watch: {
    value(val) {
      this.internalDialog = val
    },
    internalDialog(val) {
      this.$emit("input", val)
    },
  },
  methods: {
    closeDialog() {
      this.internalDialog = false
    },
  },
}
</script>

<style scoped>
/* Optional: weitere Styles für den Dialog */
</style>
