<template>
  <v-card color="secondary darken-3" class="mb-3">
    <v-card-title>
      <div class="d-flex justify-space-between align-center" style="width: 100%">
        <span>{{ index + 1 }}. {{ course.name }}</span>
        <v-btn color="red" icon @click="$emit('delete-course', course)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6" v-for="(value, key) in localCategories" :key="key">
          <div class="mb-2 text-center pr-2 font-weight-bold">{{ capitalize(key) }}</div>
          <v-slider
            thumb-color="#5a5a64"
            dark
            v-model="localCategories[key]"
            :min="1"
            :max="4"
            step="1"
            ticks="always"
            thumb-label="always"
            @change="save"></v-slider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CourseCategories",
  props: {
    course: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      names: {
        endurance: "Ausdauer",
        strength: "Kraft",
        mobility: "Beweglichkeit",
        resilience: "Resilienz",
        coordination: "Koordination",
      },
      // Lokale Kopie der Kategorien, damit Änderungen unabhängig erfolgen
      localCategories: { ...this.course.categories },
      debounceTimeout: null,
    }
  },
  watch: {
    // Aktualisiere die lokale Kopie, wenn sich der Kurs (und seine Kategorien) ändert
    course: {
      handler(newVal) {
        this.localCategories = { ...newVal.categories }
      },
      deep: true,
    },
  },
  methods: {
    capitalize(key) {
      return this.names[key]
    },
    save() {
      // Debounce, um zu häufige API-Aufrufe bei schnellen Änderungen zu vermeiden
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.$emit("save-categories", {
          id: this.course._id,
          categories: this.localCategories,
        })
      }, 300)
    },
  },
}
</script>
