<template>
  <v-row no-gutters justify="center">
    <v-dialog
      max-width="500"
      persistent
      id="nutritionMealDialog"
      content-class="nutritionMealDialog"
      :value="!!meal"
      transition="dialog-bottom-transition"
      origin="bottom center">
      <div>
        <div style="z-index: 1000; position: sticky; top: 0; background: #e0e0e0">
          <v-card-title class="h4 mb-0 font-weight-bold" @click="test()">
            <span class="mr-3">
              <v-icon class="" @click="closeDialog()">mdi-arrow-left-bold</v-icon>
            </span>
            {{ selectedRecipe ? selectedRecipe.recipe.title : meal ? meal.text : "" }}
          </v-card-title>
        </div>

        <v-row
          class="justify-space-around px-7 pt-0 pb-7"
          style="background: rgb(242, 241, 246)"
          v-if="selectionActive && meal && meal.substanceDistributions && !selectedRecipe"
          no-gutters>
          <v-col cols="12" class="mt-2"><span class="font-weight-bold">Optimale Verteilung</span></v-col>
          <v-row no-gutters class="pa-1 rounded-xl white">
            <v-col cols="4" class="text-center pa-3 rounded-xl d-flex flex-column align-items-center justify-space-around">
              <v-progress-circular :value="meal.substanceDistributions.carbs.percentage" size="60" color="orange">
                {{ meal.substanceDistributions.carbs.value + "g" }}
              </v-progress-circular>
              <span class="orange--text">Carbs</span>
            </v-col>
            <v-col cols="4" class="text-center d-flex flex-column align-items-center justify-space-around">
              <v-progress-circular :value="meal.substanceDistributions.fat.percentage" size="60" color="primary">
                {{ meal.substanceDistributions.fat.value + "g" }}
              </v-progress-circular>
              <span class="primary--text">Fett</span>
            </v-col>
            <v-col cols="4" class="text-center d-flex flex-column align-items-center justify-space-around">
              <v-progress-circular :value="meal.substanceDistributions.protein.percentage" size="60" color="blue">
                {{ meal.substanceDistributions.protein.value + "g" }}
              </v-progress-circular>
              <span class="blue--text">Protein</span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-2 flex-column" v-if="meal.note">
            <span class="font-weight-bold">Anmerkung</span>
            <v-textarea class="rounded-xl elevation-0" solo flat hide-details rows="3" :value="meal.note"></v-textarea>
          </v-row>
          <v-col cols="12">
            <span class="font-weight-bold" v-if="meal.recipes && meal.recipes.length">Rezepte</span>
          </v-col>
          <v-row no-gutters class="pb-3 fill-height" style="flex-wrap: nowrap; overflow-x: auto" v-if="meal.recipes && meal.recipes.length">
            <v-col cols="11" color="transparent" class="mr-2 fill-height" v-for="(recipe, index) of meal.recipes" :key="'recipe' + index">
              <v-card class="rounded-xl fill-height" @click="selectedRecipe = recipe">
                <v-img class="rounded-xl" :src="recipe.media.preview[0]" height="150"></v-img>
                <v-card-title class="black--text" v-if="recipe.recipe">
                  <v-col cols="10" class="pa-0">
                    <span style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden; display: block; width: 100%">
                      {{ recipe.recipe.title }}
                    </span>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <v-rating disabled :length="getRecipeStars(recipe.recipe)" :value="getRecipeStars(recipe.recipe)"></v-rating>
                  </v-col>
                </v-card-title>
                <v-card-subtitle>
                  <span v-if="recipe && recipe.time && recipe.time.length">
                    <v-icon>mdi-clock-outline</v-icon>
                    {{ recipe.time[0].value }} Minuten
                  </span>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-col cols="12" class="mt-4">
            <span class="font-weight-bold">Nährwerte selbst eintragen</span>
          </v-col>
          <v-col cols="12" v-if="false">
            <v-textarea filled hide-details rounded label="Was hast du gegessen?" v-model="nutritionAiUserMessage"></v-textarea>

            <v-btn @click="askNutrionAi()" background="white" absolute :loading="aiLoading" style="bottom: 0; right: 0" icon x-large color="grey">
              <v-icon>mdi-forum-plus-outline</v-icon>
            </v-btn>
          </v-col>

          <v-data-table
            hide-default-footer
            :headers="headers"
            style="width: 100%"
            :items="nutritionAiData"
            disable-sort
            disable-pagination
            disable-filtering
            no-data-text="Füge dein erstes Produkt hinzu"
            no-results-text="Keine Einträge vorhanden"
            class="rounded elevation-1 mt-2 full-width">
            <template v-slot:item.product="{ item }">
              {{ item.product + " (" + item.amount + item.unit + ")" }}
            </template>
            <template v-slot:foot>
              <div>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" right bottom v-bind="attrs" icon v-on="on">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Produkt erfassen</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="editedItem.title" label="Produkt"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field type="number" v-model="editedItem.nutritions.carbohydrates" label="Carbs (g)"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field type="number" v-model="editedItem.nutritions.fat" label="Fett (g)"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field type="number" v-model="editedItem.nutritions.proteins" label="Protein (g)"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary darken-1" text @click="close">Abbrechen</v-btn>
                      <v-btn color="primary darken-1" text @click="save">Speichern</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="databaseDialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" right bottom v-bind="attrs" icon v-on="on">
                      <v-icon>mdi-database-search</v-icon>
                    </v-btn>
                  </template>
                  <v-card v-if="!selectedProduct">
                    <v-card-title>
                      <span class="text-h5 font-weight-bold">
                        {{ barCodeScan ? "Barcode scannen" : newProduct ? "Neues Produkt" : "Produkte durchsuchen" }}
                      </span>
                    </v-card-title>

                    <v-row no-gutters class="px-5">
                      <v-col cols="10">
                        <v-text-field
                          v-show="!barCodeScan && !newProduct"
                          append-icon="mdi-magnify"
                          @change="resetSearchOpenDatabase()"
                          @click:append="searchOpenDatabase()"
                          @keyup.enter="searchOpenDatabase()"
                          v-model="databaseSearch"
                          label="Suchen"></v-text-field>
                      </v-col>
                      <v-col cols="1" class="align-self-center">
                        <v-btn
                          icon
                          @click="
                            () => {
                              barCodeScan = !barCodeScan
                              newProduct = false
                              openDatabaseData = null
                              databaseSearch = null
                            }
                          ">
                          <v-icon v-if="!barCodeScan">mdi-barcode-scan</v-icon>
                          <v-icon color="red" v-else>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" v-if="false" class="align-self-center">
                        <v-btn
                          icon
                          @click="
                            () => {
                              newProduct = !newProduct
                              barCodeScan = false
                            }
                          ">
                          <v-icon v-if="!newProduct">mdi-plus</v-icon>
                          <v-icon color="red" v-else>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <div style="height: 400px" v-if="barCodeScan && false">
                      <v-quagga :onDetected="onDecode" :readerSize="readerSize" :readerTypes="['ean_reader']"></v-quagga>
                    </div>
                    <div style="height: 400px" v-if="barCodeScan">
                      <nutrition-meal-dialog-barcodereader @found="onDecode"></nutrition-meal-dialog-barcodereader>
                    </div>
                    <div style="height: 400px" class="text-center" v-if="fetchingFromBarcode">
                      <v-progress-circular :size="100" indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div style="height: 400px" v-if="newProduct">
                      <nutrition-meal-dialog-new-product @found="onDecode"></nutrition-meal-dialog-new-product>
                    </div>
                    <div v-if="loadingFirst" style="max-height: 400px; overflow: scroll">
                      <v-skeleton-loader
                        v-for="n in 5"
                        :key="'skeleton' + n"
                        height="124"
                        class="mx-auto"
                        type="list-item-avatar-two-line"></v-skeleton-loader>
                    </div>
                    <v-virtual-scroll
                      v-if="!loadingFirst && openDatabaseData && openDatabaseData.products.length"
                      :items="openDatabaseData.products"
                      height="400"
                      item-height="124">
                      <template v-slot:default="{ item, index }">
                        <v-divider></v-divider>

                        <v-list-item :key="'produkt' + index" @click="selectProduct(item)">
                          <v-list-item-action v-if="item && item.image_small_url">
                            <v-img height="85" width="85" contain :src="item.image_small_url"></v-img>
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>
                              <strong>
                                {{
                                  item.product_name_de
                                    ? item.product_name_de
                                    : item.product_name
                                    ? item.product_name
                                    : item.generic_name_de
                                    ? item.generic_name_de
                                    : item.generic_name
                                    ? item.generic_name
                                    : item.generic_name_en
                                }}
                              </strong>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.brands }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <div v-intersect="searchOpenDatabase()" v-if="index === openDatabaseData.products.length - 1">
                          <v-progress-circular
                            indeterminate
                            color="grey"
                            v-if="fetchingOpenDatabaseData && index === openDatabaseData.products.length - 1"></v-progress-circular>
                        </div>
                      </template>
                    </v-virtual-scroll>
                    <div v-if="!loadingFirst && !openDatabaseData && !barCodeScan" style="height: 400px"></div>
                  </v-card>
                  <v-card v-else>
                    <v-card-title>
                      <v-btn icon @click="selectedProduct = null">
                        <v-icon>mdi-arrow-left-bold</v-icon>
                      </v-btn>
                      <span class="text-h5 font-weight-bold">
                        {{
                          selectedProduct.product_name_de
                            ? selectedProduct.product_name_de
                            : selectedProduct.product_name
                            ? selectedProduct.product_name
                            : selectedProduct.generic_name_de
                            ? selectedProduct.generic_name_de
                            : selectedProduct.generic_name
                            ? selectedProduct.generic_name
                            : selectedProduct.generic_name_en
                        }}
                        {{ selectedProduct.quantity ? "(" + selectedProduct.quantity + ")" : "" }}
                      </span>
                    </v-card-title>
                    <v-card-subtitle>
                      <span style="margin-left: 36px">
                        {{ selectedProduct.brands }}
                      </span>
                    </v-card-subtitle>
                    <v-carousel
                      v-if="
                        selectedProduct.image_front_url ||
                        selectedProduct.image_packaging_url ||
                        selectedProduct.image_ingredients_url ||
                        selectedProduct.image_nutrition_url
                      "
                      :continuous="true"
                      :show-arrows="false"
                      delimiter-icon="mdi-minus"
                      height="150">
                      <v-carousel-item cols="auto" v-if="selectedProduct.image_front_url">
                        <v-img height="150" contain :src="selectedProduct.image_front_url"></v-img>
                      </v-carousel-item>
                      <v-carousel-item cols="auto" v-if="selectedProduct.image_ingredients_url">
                        <v-img height="150" contain :src="selectedProduct.image_ingredients_url"></v-img>
                      </v-carousel-item>
                      <v-carousel-item cols="auto" v-if="selectedProduct.image_nutrition_url">
                        <v-img height="150" contain :src="selectedProduct.image_nutrition_url"></v-img>
                      </v-carousel-item>

                      <v-carousel-item cols="auto" v-if="selectedProduct.image_packaging_url">
                        <v-img height="150" contain :src="selectedProduct.image_packaging_url"></v-img>
                      </v-carousel-item>
                    </v-carousel>
                    <v-row
                      no-gutters
                      v-if="
                        selectedProduct.nutriscore_grade &&
                        selectedProduct.nutriscore_grade !== 'not-applicable' &&
                        selectedProduct.nutriscore_grade !== 'unknown'
                      ">
                      <v-card-title>NutriScore</v-card-title>
                      <nutri-score class="ml-3" :selected="selectedProduct.nutriscore_grade.toUpperCase()"></nutri-score>
                    </v-row>
                    <v-row no-gutters v-if="selectedProduct.categories && false" class="mt-1">
                      <v-chip small class="ml-1" v-for="(cat, index) of selectedProduct.categories.split(',')" :key="'cat' + index">
                        {{ cat }}
                      </v-chip>
                    </v-row>
                    <div>
                      <v-card-title v-if="selectedProduct.ingredients_text_de"> Inhalt </v-card-title>
                      <v-card-text>
                        {{ selectedProduct.ingredients_text_de }}
                      </v-card-text>
                    </div>
                    <div v-if="selectedProduct.stores">
                      <v-card-title>Erhältlich bei:</v-card-title>
                      <v-chip class="ml-2" v-for="(tag, index) in selectedProduct.stores_tags" :key="'store' + index">
                        {{ tag }}
                      </v-chip>
                    </div>

                    <div v-if="selectedProduct.nutriments">
                      <v-card-title>Nährwerte</v-card-title>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Information</th>
                              <th class="text-left" v-if="selectedProduct.nutrition_data_per">
                                {{ selectedProduct.nutrition_data_per }}
                              </th>
                              <th class="text-left" v-if="selectedProduct.serving_size">
                                Portion {{ quantity }}
                                {{
                                  selectedProduct.serving_quantity_unit
                                    ? selectedProduct.serving_quantity_unit
                                    : selectedProduct.serving_size
                                    ? selectedProduct.serving_size.split(" ")[1]
                                    : ""
                                }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="selectedProduct.nutriments.energy_100g">
                              <td>Energie</td>
                              <td v-if="selectedProduct.nutrition_data_per">
                                <p>
                                  {{ selectedProduct.nutriments.energy + "kj" }}
                                </p>
                                <p>
                                  {{ selectedProduct.nutriments["energy-kcal"] + "kcal" }}
                                </p>
                              </td>
                              <td v-if="selectedProduct.serving_size">
                                <p>
                                  {{
                                    Math.round((selectedProduct.nutriments["energy_serving"] / selectedProduct.serving_quantity) * quantity * 10) /
                                      10 +
                                    "kj"
                                  }}
                                </p>
                                <p>
                                  {{
                                    Math.round(
                                      (selectedProduct.nutriments["energy-kcal_serving"] / selectedProduct.serving_quantity) * quantity * 10
                                    ) /
                                      10 +
                                    "kcal"
                                  }}
                                </p>
                              </td>
                            </tr>
                            <tr v-if="selectedProduct.nutriments.fat">
                              <td>Fett</td>
                              <td v-if="selectedProduct.nutrition_data_per">
                                {{ selectedProduct.nutriments.fat + selectedProduct.nutriments.fat_unit }}
                              </td>
                              <td v-if="selectedProduct.serving_size">
                                {{
                                  Math.round((selectedProduct.nutriments.fat_serving / selectedProduct.serving_quantity) * quantity * 10) / 10 +
                                  selectedProduct.nutriments.fat_unit
                                }}
                              </td>
                            </tr>
                            <tr v-if="selectedProduct.nutriments.carbohydrates">
                              <td>Kohlenhydrate</td>
                              <td v-if="selectedProduct.nutrition_data_per">
                                {{ selectedProduct.nutriments.carbohydrates + selectedProduct.nutriments.carbohydrates_unit }}
                              </td>
                              <td v-if="selectedProduct.serving_size">
                                {{
                                  Math.round((selectedProduct.nutriments.carbohydrates_serving / selectedProduct.serving_quantity) * quantity * 10) /
                                    10 +
                                  selectedProduct.nutriments.carbohydrates_unit
                                }}
                              </td>
                            </tr>
                            <tr v-if="selectedProduct.nutriments.proteins">
                              <td>Proteine</td>
                              <td v-if="selectedProduct.nutrition_data_per">
                                {{ selectedProduct.nutriments.proteins + selectedProduct.nutriments.proteins_unit }}
                              </td>
                              <td v-if="selectedProduct.serving_size">
                                {{
                                  Math.round((selectedProduct.nutriments.proteins_serving / selectedProduct.serving_quantity) * quantity * 10) / 10 +
                                  selectedProduct.nutriments.proteins_unit
                                }}
                              </td>
                            </tr>
                            <tr v-if="selectedProduct.nutriments.salt">
                              <td>Salz</td>
                              <td v-if="selectedProduct.nutrition_data_per">
                                {{ selectedProduct.nutriments.salt + selectedProduct.nutriments.salt_unit }}
                              </td>
                              <td v-if="selectedProduct.serving_size">
                                {{
                                  Math.round((selectedProduct.nutriments.salt_serving / selectedProduct.serving_quantity) * quantity * 10) / 10 +
                                  selectedProduct.nutriments.salt_unit
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-card-actions class="justify-content-center d-flex flex-column">
                        <v-text-field
                          label="Menge"
                          filled
                          type="number"
                          rounded
                          v-model="quantity"
                          :suffix="selectedProductQuantityUnit"></v-text-field>
                        <v-btn x-large class="rounded-xl" @click="addProduct" color="primary"> Hinzufügen </v-btn>
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-dialog>
              </div>
            </template>
          </v-data-table>
          <div style="height: 50px"></div>
        </v-row>
        <nutrition-meal-dialog-selected-recipe
          @close="closeDialog"
          v-else-if="meal && meal.substanceDistributions"
          :recipe="selectedRecipe"
          :mealValue="meal.value"
          :date="date"
          :optimalSubstances="meal.substanceDistributions"></nutrition-meal-dialog-selected-recipe>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import api from "../../api"
import nutritionMealDialogSelectedRecipe from "./nutritionMealDialogSelectedRecipe.vue"
import goTo from "vuetify/lib/services/goto"
import nutritionMealDialogBarcodereader from "./nutritionMealDialogBarcodereader"
import nutritionMealDialogNewProduct from "./nutritionMealDialogNewProduct"
import NutriScore from "./nutriScore.vue"

export default {
  props: {
    meal: {
      default: {},
    },
    dailyCalories: {
      required: false,
    },
    date: {},
  },
  name: "habitCreateButton",
  components: {
    nutritionMealDialogSelectedRecipe,
    NutriScore,
    nutritionMealDialogBarcodereader,
    nutritionMealDialogNewProduct,
  },
  data() {
    return {
      newProduct: false,
      barCodeScan: false,
      databaseDialog: false,
      fetchingOpenDatabaseData: false,
      databaseSearch: "",
      readerSize: {
        width: 640,
        height: 480,
      },
      units: ["ml", "g", "Stück"],
      headers: [
        {
          text: "Produkt",
          sortable: false,
          value: "product",
        },
        { text: "Kcal", value: "cal" },
        { text: "Carbs", value: "carbs" },
        { text: "Fette", value: "fat" },
        { text: "Protein", value: "protein" },
      ],
      editedItem: {
        title: "",
        nutritions: {},
      },
      nutritionAiUserMessage: "",
      enterOwnNumbers: false,
      ownNumbers: {
        protein: 0,
        carbs: 0,
        fat: 0,
        description: "",
      },
      dialog: false,
      aiLoading: false,
      selectionActive: true,
      ouchIsmaFirstTime: true,
      selectedRecipe: null,
      nutritionAiData: [],
      editedIndex: -1,
      defaultItem: {
        title: "",
        nutritions: {},
      },
      openDatabaseData: null,
      databaseSearchPage: 1,
      finishedLoading: false,
      loadingFirst: false,
      selectedProduct: null,
      quantity: null,
      fetchingFromBarcode: false,
    }
  },
  computed: {
    selectedProductQuantityUnit() {
      return this.selectedProduct.serving_quantity_unit
        ? this.selectedProduct.serving_quantity_unit
        : this.selectedProduct.serving_size && this.selectedProduct.serving_size.split(" ")[1]
        ? this.selectedProduct.serving_size.split(" ")[1]
        : this.selectedProduct.serving_size_imported && this.selectedProduct.serving_size_imported.split(" ")[1]
        ? this.selectedProduct.serving_size_imported.split(" ")[1]
        : ""
    },
  },
  methods: {
    async addProduct() {
      let productToSave = {}
      productToSave.meal = this.meal.value
      productToSave.title = this.selectedProduct.product_name_de
        ? this.selectedProduct.product_name_de
        : this.selectedProduct.product_name
        ? this.selectedProduct.product_name
        : this.selectedProduct.generic_name_de
        ? this.selectedProduct.generic_name_de
        : this.selectedProduct.generic_name
        ? this.selectedProduct.generic_name
        : this.selectedProduct.generic_name_en

      productToSave.img = this.selectedProduct.image_small_url
      productToSave.quantity = this.quantity

      productToSave.nutritions = {
        carbohydrates: 0,
      }

      productToSave.quantity_unit = this.selectedProductQuantityUnit

      if (this.selectedProduct.nutriments.carbohydrates_serving) {
        productToSave.nutritions.carbohydrates =
          Math.round((this.selectedProduct.nutriments.carbohydrates_serving / this.selectedProduct.serving_quantity) * this.quantity * 100) / 100
      }

      if (this.selectedProduct.nutriments.fat_serving) {
        productToSave.nutritions.fat =
          Math.round((this.selectedProduct.nutriments.fat_serving / this.selectedProduct.serving_quantity) * this.quantity * 100) / 100
      }
      if (this.selectedProduct.nutriments.proteins_serving) {
        productToSave.nutritions.proteins =
          Math.round((this.selectedProduct.nutriments.proteins_serving / this.selectedProduct.serving_quantity) * this.quantity * 100) / 100
      }
      productToSave.date = this.date
      let data = await api.addNewNutritionEntry(productToSave)
      this.selectedProduct = null
      this.$toast.success("Produkt wurde hinzugefügt")
      this.$emit("addEntry", data)
    },
    async onDecode(result) {
      this.barCodeScan = false
      if (!this.selectedProduct && !this.fetchingFromBarcode) {
        this.fetchingFromBarcode = true
        try {
          let prod = await api.fetchOpenDatabaseBarcode(result)
          this.selectedProduct = prod
        } catch (e) {
          this.$toast.error("Produkt wurde nicht gefunden")
        }
        this.fetchingFromBarcode = false
      }
    },
    resetSearchOpenDatabase() {
      console.log("REEESSET")
      this.finishedLoading = false
      this.databaseSearchPage = 1
    },
    onIntersect(entries) {
      console.log(entries)
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async save() {
      /*    if (this.editedIndex > -1) {
                Object.assign(this.nutritionAiData[this.editedIndex], this.editedItem)
            } else {
                this.nutritionAiData.push(this.editedItem)
            }
            this.close()
            */
      try {
        this.editedItem.nutritions.fat = Number(this.editedItem.nutritions.fat)
        this.editedItem.nutritions.carbohydrates = Number(this.editedItem.nutritions.carbohydrates)
        this.editedItem.nutritions.proteins = Number(this.editedItem.nutritions.proteins)
        this.editedItem.meal = this.meal.value

        this.editedItem.date = this.date
        let data = await api.addNewNutritionEntry(this.editedItem)
        this.$toast.success("Produkt wurde hinzugefügt")
        this.$emit("addEntry", data)

        this.dialog = false
      } catch (e) {
        this.$toast.error("Fehler beim hinzufügen")
      }
    },
    extractNumberFromString(str) {
      // Regulärer Ausdruck, um nur Zahlen zu extrahieren
      const regex = /\d+(\.\d+)?/

      // Extrahiere die Zahl aus dem String
      const match = str.match(regex)

      // Überprüfe, ob eine Übereinstimmung gefunden wurde
      if (match) {
        // Gebe die extrahierte Zahl als Zahl zurück
        return parseFloat(match[0])
      } else {
        // Wenn keine Übereinstimmung gefunden wurde, gebe null zurück
        return null
      }
    },
    selectProduct(item) {
      if (!item.serving_size || (!item.serving_quantity && item.nutrition_data_per)) {
        item.serving_quantity = this.extractNumberFromString(item.nutrition_data_per)
        item.serving_size = item.nutrition_data_per
        if (
          item.nutriments.energy &&
          item.nutriments["energy-kcal"] &&
          item.nutriments.fat &&
          item.nutriments.proteins &&
          item.nutriments.carbohydrates
        ) {
          item.nutriments.carbohydrates_serving = item.nutriments.carbohydrates
          item.nutriments.energy_serving = item.nutriments.energy
          item.nutriments.fat_serving = item.nutriments.fat
          item.nutriments.proteins_serving = item.nutriments.proteins
          item.nutriments["energy-kcal_serving"] = item.nutriments["energy-kcal"]
          if (item.nutriments.salt) {
            item.nutriments.salt_serving = item.nutriments.salt
          }
        } else {
          return alert("Produkt leider nicht vollständig hinterlegt. (1)")
        }
      }
      this.selectedProduct = item
      this.quantity = JSON.parse(JSON.stringify(item.serving_quantity))
    },
    async selectOpenDatabaseProduct() {},
    async searchOpenDatabase() {
      if (this.fetchingOpenDatabaseData || !this.databaseSearch || !this.databaseSearch.length) return
      if (this.finishedLoading) return this.$toast.error("Keine weiteren Produkte gefunden")
      if (!this.finishedLoading && this.databaseSearchPage !== 1 && this.databaseSearchPage > Math.ceil(this.openDatabaseData.count / 24)) {
        this.finishedLoading = true
        this.$toast.error("Keine weiteren Produkte gefunden")
      } else {
        try {
          if (this.databaseSearchPage === 1) this.loadingFirst = true
          this.fetchingOpenDatabaseData = true
          let data = await api.searchOpenDatabase(this.databaseSearch, this.databaseSearchPage)
          if (this.databaseSearchPage === 1) {
            this.openDatabaseData = data
          } else {
            this.openDatabaseData.products = this.openDatabaseData.products.concat(data.products)
          }
          this.databaseSearchPage++
          this.loadingFirst = false
          this.fetchingOpenDatabaseData = false
        } catch (e) {
          this.fetchingOpenDatabaseData = false
          console.log(e)
          this.$toast.error("Fehler beim Suchen")
        }
      }
    },
    async askNutrionAi() {
      this.aiLoading = true
      let data = await api.askNutritionAi(this.nutritionAiUserMessage)

      let array = []
      /* if (!Array.isArray(data) && typeof data !== 'object') {
                this.aiLoading = false
                return this.$toast.error('Fehler beim Suchen der Nährwerte.')
            } */
      if (Array.isArray(data)) {
        array = data
      } else {
        array = data.products
        array.push(data)
      }
      this.nutritionAiData = array
      this.expandDiv()
      this.aiLoading = false
    },
    test() {
      console.log(this.nutritionAiData)
    },
    getRecipeStars(recipe) {
      if (recipe.difficulty) {
        return recipe.difficulty === "einfach" ? 1 : recipe.difficulty === "schwierig" ? 3 : 2
      }
      return 0
    },
    setOwnNumbers() {
      this.enterOwnNumbers = true
      setTimeout(() => {
        let element = document.querySelector(".carbsField")
        this.expandDiv(element)
      }, 5)
    },
    closeDialog(recipeToSave = null) {
      if (recipeToSave) {
        this.$emit("addEntry", recipeToSave)
      }
      if (this.selectedRecipe) return (this.selectedRecipe = null)
      this.ownNumbers = {
        protein: 0,
        carbs: 0,
        fat: 0,
        description: "",
      }
      this.enterOwnNumbers = false
      document.querySelector(".nutritionMealDialog").style.height = "auto"
      this.$emit("closeDialog")
    },
    expandDiv(scrollElement = null) {
      console.log(scrollElement)
      let element = document.querySelector(".nutritionMealDialog")
      if (this.ouchIsmaFirstTime) {
        this.ouchIsmaFirstTime = false
        element.setAttribute("data-collapsed", "true")
      }
      setTimeout(() => {
        var sectionHeight = element.scrollHeight

        element.style.height = sectionHeight + "px"

        element.addEventListener("transitionend", function (e) {
          if (arguments[0].propertyName === "height") {
            element.removeEventListener("transitionend", arguments[0].callee)
          }
        })
      }, 50)

      element.setAttribute("data-collapsed", "false")
      setTimeout(() => {
        if (scrollElement) scrollElement.scrollIntoView({ behavior: "smooth" })
      }, 200)
    },
    shrinkDiv() {
      this.selectedHabit = null

      setTimeout(() => {
        let element = document.querySelector(".nutritionMealDialog")

        var sectionHeight = element.scrollHeight

        var elementTransition = element.style.transition
        element.style.transition = ""

        requestAnimationFrame(function () {
          element.style.height = sectionHeight + "px"
          element.style.transition = elementTransition

          requestAnimationFrame(function () {
            element.style.height = 50 + "vh"
          })
        })
        element.setAttribute("data-collapsed", "true")
      }, 50)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  overflow: scroll;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 100%;
  margin: 0;
  max-height: 80vh;
  position: absolute;
  //min-height: 20vh;
  height: auto;
  bottom: 0;
  min-height: none;
  background: white;
  //max-height: 40vh;
}
::v-deep .extended-dialog {
  //max-height: 90vh !important;
}

.option-button {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background: lightgray;
    &-bulb {
      ::v-deep .v-image__image {
        width: 80px;
      }
      flex-direction: row;
    }
  }
  &__wrapper:hover {
    background: gray;
  }
}
</style>
