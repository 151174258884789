<template>
  <div class="text-center align-items-center" style="overflow: hidden" :style="{ height: height - 20 + 'px' }">
    <div v-if="height > 50 && substanceDistribution" style="height: 250">
      <v-window v-model="windowItem" :style="windowItem === '2' ? 'height: 250px' : 'height: 220px'">
        <v-window-item value="1">
          <apexchart type="radialBar" :height="250" :options="chartOptions" :series="dragging ? [0, 0, 0] : series"></apexchart>
        </v-window-item>
        <v-window-item value="2" style="width: 110vw; max-width: 550px">
          <div v-if="loadingWeekChart">
            <v-progress-circular class="mt-3" size="65" indeterminate color="white"></v-progress-circular>
          </div>
          <apexchart :height="250" v-else-if="fullWeekData" :options="fullWeekChartOptions" :series="fullWeekChartSeries"></apexchart>
        </v-window-item>
      </v-window>
      <v-card-actions class="pa-0 justify-space-around">
        <v-item-group class="text-center" mandatory>
          <v-item>
            <v-btn x-small icon @click="windowItem = '1'" :color="windowItem === '1' ? 'white' : ''">
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </v-item>
          <v-item>
            <v-btn x-small icon @click="windowItem = '2'" :color="windowItem === '2' ? 'white' : ''">
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
      </v-card-actions>
    </div>
    <v-card-title v-if="height > 250 && userEntries.length" class="white--text">Alle Einträge</v-card-title>
    <v-row :style="`height: ${height - 314}px`" style="overflow-y: auto; overflow-x: hidden" no-gutters v-if="height > 250 && userEntries.length">
      <v-row
        class="white--text not-animated"
        style="max-height: 80px"
        no-gutters
        v-for="(entry, index) of userEntries"
        :key="'eintrag' + entry.time"
        v-intersect.once="intersectingEntry"
        :options="{ threshold: 0.5 }">
        <v-col cols="3" class="align-self-center">
          {{ entry.time.substr(0, 5) }}
        </v-col>
        <v-col cols="9">
          <div @click="toggle(index)">
            <transition name="fade" mode="out-in">
              <div v-if="!toggledIndexes.includes(index)" key="1" class="content">
                <v-btn style="z-index: 100000" icon x-small color="white" @click.stop="removeEntry(entry)" absolute right>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <div class="d-flex">
                  <v-col cols="3" style="overflow: hidden" class="pa-1 white rounded-xl" v-if="entry.img">
                    <v-img class="rounded-xl" contain height="65" width="65" :src="entry.img"></v-img>
                  </v-col>

                  <v-col cols="9" class="d-flex flex-column">
                    <span class="text-left font-weight-bold" style="text-wrap: nowrap; overflow: hidden">
                      {{ entry.title }}
                    </span>
                    <span class="text-left" v-if="entry.quantity">
                      {{ entry.quantity + entry.quantity_unit }}
                    </span>
                    <span class="text-left" v-else-if="entry.img">Rezept Portion</span>
                    <span class="text-left" v-else>Eigenes Produkt</span>
                  </v-col>
                </div>
              </div>
              <div v-else key="2" class="content">
                <div class="d-flex">
                  <v-col cols="4">
                    <span class="font-weight-bold">Protein</span>
                    <p>{{ entry.nutritions.proteins + "g" }}</p>
                  </v-col>
                  <v-col cols="4">
                    <span class="font-weight-bold">Fett</span>
                    <p>{{ entry.nutritions.fat + "g" }}</p>
                  </v-col>
                  <v-col cols="4">
                    <span class="font-weight-bold">Carbs</span>
                    <p>{{ entry.nutritions.carbohydrates + "g" }}</p>
                  </v-col>
                </div>
              </div>
            </transition>
          </div>
        </v-col>
        <v-col cols="3" v-if="index !== userEntries.length - 1">
          <v-divider dark vertical></v-divider>
        </v-col>
        <div v-else height="100px"></div>
      </v-row>
    </v-row>
    <v-row v-if="height > 400 && false" class="white--text justify-content-center">
      <v-col cols="3">
        <span>Protein</span>
        <v-progress-linear height="14" :color="'#0096DD'" :value="series[0]">
          <template v-slot:default="{ value }">
            <strong class="white--text" style="font-size: 12px">{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col cols="3">
        <span>Fett</span>
        <v-progress-linear height="14" :color="'#FFA500'" :value="series[1]">
          <template v-slot:default="{ value }">
            <strong class="white--text" style="font-size: 12px">{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col cols="3">
        <span>Carbs</span>
        <v-progress-linear height="14" :color="'#F62F49'" :value="series[2]">
          <template v-slot:default="{ value }">
            <strong class="white--text" style="font-size: 12px">{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import api from "../../api"

export default {
  props: {
    entries: {},
    date: {},
    height: {},
    dragging: {},
    totalCalories: {},
    dailyCalories: {},
    nutritions: {},
    substanceDistribution: {},
  },
  computed: {
    userEntries() {
      let entries = this.entries.map((entry) => {
        entry.toggle = false
        return entry
      })
      return entries.sort((a, b) => {
        // Zeit in Stunden, Minuten und Sekunden aufteilen
        let timeA = a.time.split(":")
        let timeB = b.time.split(":")

        // Zeit in Sekunden umwandeln, um eine einfache numerische Vergleichsbasis zu haben
        let secondsA = parseInt(timeA[0]) * 3600 + parseInt(timeA[1]) * 60 + parseInt(timeA[2])
        let secondsB = parseInt(timeB[0]) * 3600 + parseInt(timeB[1]) * 60 + parseInt(timeB[2])

        // Objekte basierend auf der Gesamtzahl der Sekunden vergleichen
        return secondsA - secondsB
      })
    },
    fullWeekChartSeries() {
      return [
        {
          name: "Proteine",
          data: this.fullWeekData.map((item) => Math.round(item.nutritions.proteins)),
        },
        {
          name: "Fett",
          data: this.fullWeekData.map((item) => Math.round(item.nutritions.fat)),
        },
        {
          name: "Kohlenhydrate",
          data: this.fullWeekData.map((item) => Math.round(item.nutritions.carbohydrates)),
        },
      ]
    },
    fullWeekChartOptions() {
      return {
        chart: {
          offsetX: -30,
          type: "bar",
          height: 350,
          stacked: true,
          width: 700,
          toolbar: {
            show: false,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 7.55,

            stacked: true,
            horizontal: false,
            columnWidth: "70%",
            endingShape: "rounded",
            borderRadiusWhenStacked: "all",
          },
        },
        colors: ["#0096DD", "#FFA500", "#F62F49"],
        legend: {
          labels: {
            colors: "white",
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " g"
            },
          },
        },
      }
    },
    chartOptions() {
      let totalCalories = this.totalCalories
      let dailyCalories = this.dailyCalories
      let series = this.series
      let nutritions = this.nutritions
      let substanceDistribution = this.substanceDistribution
      return {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "12px",
                color: "#FFFFFF",
              },
              value: {
                fontSize: "15px",
                fontWeight: "600",
                color: "#FFFFFF",
                formatter: function (value) {
                  let index = series.indexOf(Number(value))
                  if (index !== -1) {
                    if (index === 0) {
                      return Math.round(nutritions.protein) + " / " + substanceDistribution.protein.value
                    }
                    if (index === 1) {
                      return Math.round(nutritions.fat) + " / " + substanceDistribution.fat.value
                    }
                    if (index === 2) {
                      return Math.round(nutritions.carbs) + " / " + substanceDistribution.carbs.value
                    }
                  }
                  return value
                },
              },
              total: {
                fontSize: "15px",
                show: true,
                color: "#FFFFFF",
                label: "kcal",
                formatter: function (w) {
                  return totalCalories ? Math.round(totalCalories) + " / " + dailyCalories : 0 + " / " + dailyCalories
                },
              },
            },
          },
        },
        labels: ["Protein (g)", "Fett (g)", "Carbs (g)"],
        colors: ["#0096DD", "#FFA500", "#F62F49"], // Farben für Protein, Fett, Kohlenhydrate
      }
    },
    series() {
      if (this.substanceDistribution) {
        return [
          (100 / this.substanceDistribution.protein.value) * this.nutritions.protein || 0,
          (100 / this.substanceDistribution.fat.value) * this.nutritions.fat || 0,
          (100 / this.substanceDistribution.carbs.value) * this.nutritions.carbs || 0,
        ]
      } else {
        return [0, 0, 0]
      }
    },
  },
  methods: {
    toggle(index) {
      console.log("toggle")
      if (this.toggledIndexes.includes(index)) {
        this.toggledIndexes = this.toggledIndexes.filter((indexes) => indexes !== index)
      } else {
        this.toggledIndexes.push(index)
      }
    },
    async removeEntry(entry) {
      if (confirm("Willst du diesen Eintrag löschen?")) {
        try {
          let response = await api.deleteNutritionEntry(entry)
          if (response === "updated") {
            this.$toast.success("Eintrag gelöscht")
            this.$emit("deletedEntry", entry)
          }
        } catch (e) {
          this.$toast.error("Der Eintrag konnte nicht gelöscht werden")
          console.error("error deleting entry: ", e)
        }
      }
    },
    intersectingEntry(element) {
      element[0].target.classList = "row white--text animate-in no-gutters"
    },
  },
  watch: {
    date() {
      this.windowItem = 1
    },
    async windowItem(val) {
      console.log(val)
      if (val === "2") {
        this.loadingWeekChart = true
        this.fullWeekData = await api.fetchWeekNutritionStatistics(this.date)
        this.loadingWeekChart = false
      }
    },
  },
  data() {
    return {
      fullWeekData: null,
      loadingWeekChart: false,
      toggledIndexes: [],
      nutritionUser: null,
      windowItem: 1,
    }
  },
}
</script>
<style lang="scss" scoped>
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0 !important;
  }
  100% {
    transform: translateY(0);
    opacity: 1 !important;
  }
}

.not-animated {
  opacity: 0;
}

.animate-in {
  animation: slideInFromBottom 0.5s ease-out forwards;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateX(-100%);
}
.content {
  height: 72px;
  transition: transform 0.05s;
}
/* Spezifische Anpassungen für das Hoch- und Herunterschieben */
.fade-enter-active {
  transform: translateX(100%);
}
.fade-leave-active {
  transform: translateX(0%);
}
</style>
