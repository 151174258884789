import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchAllPurchases() {
    let url = buildUrl("shop/fetch/purchases")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllPayedPurchasesAdmin() {
    let url = buildUrl("shop/fetch/purchases/admin")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllPurchasesDetails() {
    let url = buildUrl("shop/fetch/purchases/details")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  checkPaymentStatus(id) {
    let url = buildUrl("shop/check/payment/status/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  itemSavedInTAC(data) {
    let url = buildUrl("shop/saved/code/to/tac")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  getPaymentInfos(id) {
    let url = buildUrl("shop/get/payment/infos/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  createOrUpdateProduct(data) {
    let url = buildUrl("shop/product/create")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchProductsManagement() {
    let url = buildUrl("shop/fetch/products/management")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchProductsPublicStore(data) {
    let url = buildUrl("shop/fetch/products/public/store")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  createProductPayment(data) {
    let url = buildUrl("shop/product/buy/and/create/payment")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  downloadRealVoucher(data) {
    let url = buildUrl("shop/product/real/printversion")
    return axios
      .post(url, data, {
        responseType: "blob",
        headers: {
          Authorization: localStorage.getItem("token"),
        }, // important
      })
      .then((response) => {
        if (window.flutter_inappwebview) {
          console.log("FLUTTER IOS DOWNLOAD PDF START")
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]

            window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "pdf", "wellComeFitGutschein")
          }
        } else {
          saveAs(response.data, "gutscheinAusdruck")
        }
      })
  },
  productExamplePrintVersion(data) {
    let url = buildUrl("shop/product/example/printversion")
    return axios
      .post(url, data, {
        responseType: "blob",
        headers: {
          Authorization: localStorage.getItem("token"),
        }, // important
      })
      .then((response) => {
        if (window.flutter_inappwebview) {
          console.log("FLUTTER IOS DOWNLOAD PDF START")
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]

            window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "pdf", "wellComeFitGutschein")
          }
        } else {
          saveAs(response.data, "beispielGutschein")
        }
      })
  },
}
