<template>
  <div style="max-width: 800px" dark class="mx-auto py-3 white--text">
    <v-row class="my-5" v-if="!studioConfirmed">
      <v-col cols="12" class="d-flex justify-center mb-0">
        <v-alert style="max-width: 400px" type="info" class="mx-5">
          Stelle bitte sicher, dass du dich
          <strong>im richtigen Studio</strong>
          befindest und mit
          <strong>keinem Centeraccount</strong>
          angemeldet bist
        </v-alert>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-card-text class="text-center">
          <strong>{{ username }}</strong>
          im Studio
          <strong>{{ userStudio }}</strong>
        </v-card-text>
      </v-col>
      <v-col cols="12" class="d-flex justify-center mt-0">
        <v-btn @click="showStudioSelection" color="primary">Studio wechseln</v-btn>
      </v-col>
      <v-col cols="12" class="d-flex justify-center mt-0">
        <v-btn @click="signOut" color="red" class="white--text">Account wechseln</v-btn>
      </v-col>
      <v-col cols="12" class="d-flex justify-center mt-0">
        <v-btn color="white" text @click="studioConfirmed = true">
          <v-icon class="pr-2">mdi-check</v-icon>
          Stimmt so
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="studioConfirmed" class="mt-5">
      <v-col cols="8" sm="5" class="justify-center mx-auto d-flex px-5">
        <v-select v-model="calendarType" :items="calendarTypes" dense dark outlined hide-details class="mx-5" label="Ansicht"></v-select>
      </v-col>
      <v-col v-if="calendarType == 'day'" cols="1" class="justify-center d-flex mx-auto">
        <break-rules></break-rules>
      </v-col>
      <v-col v-if="calendarType == 'day'" cols="1" class="justify-center d-flex mx-auto">
        <enter-holidays @fetchEntries="fetchEntries" :holidayEntries="holidayEntries"></enter-holidays>
      </v-col>
      <v-col v-if="calendarType == 'day' && false" cols="1" class="justify-center d-flex mx-auto">
        <enter-free-wishes @fetchEntries="fetchEntries" :freeWishes="freeWishes"></enter-free-wishes>
      </v-col>
      <v-col v-if="calendarType == 'day' && showButtons" sm="8" cols="12" class="justify-center d-flex mx-auto">
        <edit-time-entry
          @fetchEntries="fetchEntries"
          :day="$moment(selectedDate).format('YYYY-MM-DD')"
          :recentEntries="recentEntries"></edit-time-entry>
      </v-col>
      <v-col v-if="calendarType == 'day' && !showButtons" sm="8" cols="12" class="justify-center d-flex mx-auto">
        <demand-time-entry-edit
          v-if="!submissionState || (submissionState && submissionState.state === 'rejected')"
          @fetchEntries="fetchEntries"
          :selectedDate="selectedDate"></demand-time-entry-edit>
      </v-col>
      <v-col v-if="calendarType == 'day' && !showButtons" sm="8" cols="12" class="justify-center d-flex mx-auto">
        <v-btn v-if="!submissionState || (submissionState && submissionState.state === 'rejected')" @click="fetchEntries(!showCorrection)">
          {{ showCorrection ? "unbestätigte Korrektur anzeigen" : "unbestätigte Korrektur verbergen" }}
        </v-btn>
      </v-col>
      <v-alert v-if="submissionState" :type="submissionState.state === 'accepted' ? 'success' : 'info'">
        {{
          submissionState.state === "accepted"
            ? "Die Zeiterfassung für diesen Monat wurde bereits durch deine Centerleitung weitergeleitet"
            : "Die Zeiterfassung für diesen Monat wurde bereits Übetragen und ist abgeschlossen."
        }}
      </v-alert>

      <v-col sm="8" cols="12" class="justify-center d-flex mx-auto">
        <v-simple-table dark>
          <template v-slot:default>
            <thead>
              <tr>
                <th v-if="calendarType == 'day'" class="text-center">Start</th>
                <th v-if="calendarType == 'day'" class="text-center">Ende</th>
                <th v-if="calendarType == 'day'" class="text-center">Pause</th>
                <th class="text-left">Arbeitszeit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center" v-if="calendarType == 'day'">
                  <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
                  <span v-else>{{ dayWorkStart }}</span>
                </td>
                <td class="text-center" v-if="calendarType == 'day'">
                  <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
                  <span v-else>{{ dayWorkEnd }}</span>
                </td>
                <td class="text-center" v-if="calendarType == 'day'">
                  <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
                  <span v-else>{{ dayPauseTime }}</span>
                </td>
                <td class="text-center">
                  <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
                  <span v-else>{{ dayWorkTime.formattedTime }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col
        v-if="showButtons && calendarType == 'day' && $moment(selectedDate).format('DD-MM-YYYY') === $moment().format('DD-MM-YYYY')"
        sm="8"
        cols="12"
        class="justify-center d-flex mx-auto">
        <v-btn
          :loading="loading"
          v-if="currentType !== 'checkin' && currentType !== 'pause' && currentType !== 'pauseend'"
          @click="createTimeEntry('checkin')"
          color="primary">
          <v-icon color="white" class="pr-2">mdi-timer</v-icon>
          Check-In
        </v-btn>
        <v-btn :loading="loading" v-if="currentType === 'checkin'" @click="createTimeEntry('checkout')" class="white--text" color="orange">
          <v-icon color="white" class="pr-2">mdi-timer-off</v-icon>
          Check-Out
        </v-btn>
        <v-btn :loading="loading" v-if="currentType === 'checkin'" class="white--text" @click="createTimeEntry('pause')" color="red">
          <v-icon color="white" class="pr-2">mdi-coffee</v-icon>
          Pause
        </v-btn>

        <v-btn :loading="loading" v-if="currentType === 'checkin'" class="white--text" @click="createTimeEntry('smoke')" color="red">
          <v-icon color="white" class="pr-2">mdi-smoking</v-icon>
          Rauchen
        </v-btn>

        <v-btn :loading="loading" v-if="currentType === 'pause'" @click="createTimeEntry('pauseend')" color="red">
          <v-icon color="white" class="pr-2">mdi-coffee-to-go</v-icon>
          Pause beenden
        </v-btn>
      </v-col>

      <v-col
        v-if="
          userService.hasRight('tt_tripControlEntry') &&
          calendarType == 'day' &&
          $moment(selectedDate).format('DD-MM-YYYY') === $moment().format('DD-MM-YYYY')
        "
        sm="8"
        cols="12"
        class="justify-center d-flex mx-auto">
        <trip-control-history @fetchEntries="fetchEntries" :tripControlHistoryEntries="tripControlHistoryEntries"></trip-control-history>
      </v-col>

      <v-col v-else-if="calendarType !== 'day' && calendarType !== 'month'" sm="8" cols="12" class="justify-center d-flex mx-auto">
        <v-btn
          color="primary"
          @click="
            () => {
              selectedDate = new Date()
              calendarType = 'day'
              fetchEntries()
            }
          ">
          zum aktuellen Tag
        </v-btn>
      </v-col>
      <v-col v-if="userService.hasRight('tt_daily_note') && calendarType == 'day'" sm="8" cols="12" class="justify-center d-flex mx-auto rounded">
        <v-textarea
          @blur="saveDailyNote()"
          class="rounded"
          background-color="rgba(255,255,255,0.2)"
          autogrow
          dark
          label="Tagesnotiz"
          v-model="dailyNote"></v-textarea>
      </v-col>
    </v-row>
    <div v-if="studioConfirmed && calendarType === 'month'" class="my-3 text-center">
      <v-btn
        v-if="
          !selfSubmittedTimetrackerMonths.find((month) => month === $moment(selectedDate).format('YYYY-MM')) ||
          selfSubmittedTimetrackerMonths.find((month) => month === $moment(selectedDate).format('YYYY-MM')).state === 'onlyNotes'
        "
        color="orange"
        @click="submitForSelectedMonth()"
        class="white--text">
        Zeiterfassung für {{ $moment(selectedDate).format("MMMM") }} einreichen
      </v-btn>
      <v-btn v-else text color="orange"> Zeiterfassung für {{ $moment(selectedDate).format("MMMM") }} wurde bereits eingereicht. </v-btn>
    </div>
    <div v-if="calendarType !== 'month'" class="my-3 text-center">
      <span
        v-if="!selfSubmittedTimetrackerMonths.find((month) => month === $moment(selectedDate).format('YYYY-MM'))"
        class="orange--text"
        style="word-break: break-word">
        Wechsle auf die Monatsansicht um die Zeiterfassung für
        {{ $moment(selectedDate).format("MMMM") }} einzureichen.
      </span>
      <span class="orange--text" style="word-break: break-word" v-else></span>
    </div>
    <v-row v-if="studioConfirmed" class="mb-10 justify-center white--text">
      <v-col cols="12" sm="8" class="d-flex justify-space-between mx-auto align-center">
        <v-btn plain elevation="0" class="ma-2" @click="previousDate" color="white">
          <v-icon>mdi-chevron-left</v-icon>
          Zurück
        </v-btn>
        <span v-if="calendarType === 'month'">
          {{ $moment(selectedDate).startOf("month").format("DD.MM.YYYY") }} -
          {{ $moment(selectedDate).endOf("month").format("DD.MM.YYYY") }}
        </span>
        <span v-if="calendarType === 'week'">
          {{ $moment(selectedDate).startOf("week").format("DD.MM.YYYY") }} -
          {{ $moment(selectedDate).endOf("week").format("DD.MM.YYYY") }}
        </span>
        <!--- <span v-if="calendarType == 'day'">{{ $moment(selectedDate).format('DD.MM.YYYY') }}</span> -->
        <span v-if="calendarType == 'day'">
          <v-menu offset-y nudge-left="80" v-model="showDatePicker" :close-on-content-click="false" transition="scale-transition" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dark
                :value="$moment(selectedDateFormatted).format('DD.MM.YYYY')"
                label="Datum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"></v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              locale="de-CH"
              :value="selectedDateFormatted"
              @input="updateSelectedDate"
              @change="showDatePicker = false"></v-date-picker>
          </v-menu>
        </span>
        <v-btn plain elevation="0" class="ma-2" @click="nextDate" color="white">
          Weiter
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12" sm="10">
        <v-calendar
          locale="de-CH"
          ref="calendar"
          dark
          v-model="selectedDate"
          @click:event="handleEventClick"
          @click:date="handleDateClick"
          :now="$moment().format('YYYY-MM-DD')"
          :weekdays="weekdays"
          :type="calendarType"
          :events="events"
          :event-color="(event) => event.color"
          :event-text-color="'white'">
          <template #event="{ event }">
            <div v-if="calendarType == 'day'" style="height: 100%" class="justify-center d-flex flex-column align-center">
              <span v-if="event.details !== 'Ferien'">
                {{ $moment(event.start).format("HH:mm") }} - {{ event.end ? $moment(event.end).format("HH:mm") : "" }} Uhr
              </span>
              <span v-if="event.details !== 'Arbeit'">{{ event.details }}</span>
              <span v-if="event.details === 'Arbeit' && event.studio">
                {{ storeStudios.find((stud) => stud._id === event.studio) ? storeStudios.find((stud) => stud._id === event.studio).name : "" }}
              </span>
            </div>
            <div v-if="calendarType == 'week'" style="height: 100%" class="justify-center d-flex flex-row align-center">
              {{ event.details }}
              {{
                event.details === "Arbeit" && event.studio && storeStudios.find((stud) => stud._id === event.studio)
                  ? storeStudios.find((stud) => stud._id === event.studio).name
                  : ""
              }}
            </div>
          </template>
        </v-calendar>
        <div v-if="calendarType === 'month' && !showButtons">
          <v-card-title>Monats Informationen</v-card-title>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="auto">Sollstunden diesen Monat</v-col>

            <v-col cols="auto">
              {{
                Math.round((calcBusinessDays - vacationDaysForMonth(employeeStudio._id).length) * ((8.4 * employeeStudio.percentage) / 100) * 1000) /
                  1000 +
                " Stunden (" +
                employeeStudio.percentage +
                "%)"
              }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="auto">Arbeitstage diesen Monat</v-col>
            <v-col cols="auto">
              {{ calcBusinessDays - vacationDaysForMonth(employeeStudio._id).length }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="auto">Geleistete Stunden</v-col>
            <v-col cols="auto">{{ dayWorkTime.formattedTime }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="auto">Differenz</v-col>
            <v-col cols="auto">{{ Math.round(parseFloat(timeDifference) * 1000) / 1000 }} Stunden</v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from "@/api"
import userService from "@/services/userService"
import BreakRules from "./BreakRules"
import EnterHolidays from "./EnterHolidays"
import EnterFreeWishes from "./EnterFreeWishes"
import EditTimeEntry from "./EditTimeEntry"
import DemandTimeEntryEdit from "./demandTimeEntryEdit"
import TripControlHistory from "./TripControlHistory"
import { mapState } from "vuex"
export default {
  name: "EmployeeTimeTracker",
  components: { BreakRules, EnterHolidays, EnterFreeWishes, EditTimeEntry, TripControlHistory, DemandTimeEntryEdit },
  props: {
    employeeName: String,
    shiftTime: String,
  },
  data() {
    return {
      mappedAbsenceTypes: {
        sick: "Krank",
        school: "Schule",
        pregnancy: "Schwangerschaft",
        holidayFix: "Ferien (nachtrag)",
        other: "Anderes",
        military: "Militär",
        holiday: "Ferien",
      },
      studioConfirmed: false,
      userService,
      userStudio: null,

      showDatePicker: false,
      calendarType: "day",
      calendarTypes: [
        { text: "Monat", value: "month" },
        { text: "Woche", value: "week" },
        { text: "Tag", value: "day" },
      ],
      showButtons: false,
      loading: true,
      selectedDate: new Date(),
      checkInTime: null,
      checkOutTime: null,
      dailyNote: "",
      pauseStartTime: null,
      pauseEndTime: null,
      showCorrection: true,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      events: [],
      recentEntries: [],
      tripControlHistoryEntries: [],
      holidayEntries: [],
      currentType: null,
      currentIndex: null,
      interval: null,
      timeTrackerMonths: [],
      selfSubmittedTimetrackerMonths: [],
      timeTrackerDailyNotes: [],
    }
  },
  created() {
    this.fetchUserStudio()
    this.fetchEntries()
  },
  beforeDestroy() {
    this.clearTimer()
  },
  watch: {
    calendarType() {
      this.fetchEntries()
    },
  },
  methods: {
    async saveDailyNote() {
      try {
        if (this.timeTrackerDailyNotes.find((noteEntry) => noteEntry.day === this.$moment(this.selectedDate).format("YYYY-MM-DD"))) {
          this.timeTrackerDailyNotes.find((noteEntry) => noteEntry.day === this.$moment(this.selectedDate).format("YYYY-MM-DD")).note = JSON.parse(
            JSON.stringify(this.dailyNote)
          )
        } else {
          this.timeTrackerDailyNotes.push({
            day: this.$moment(this.selectedDate).format("YYYY-MM-DD"),
            note: JSON.parse(JSON.stringify(this.dailyNote)),
          })
        }
        await api.submitTimeTrackerDailyNote(this.timeTrackerDailyNotes)
        this.$toast.success("Notiz wurde gespeichert")
      } catch (e) {
        console.log(e)
      }
    },
    async submitForSelectedMonth() {
      if (
        confirm(
          "Willst du deine Zeiterfassung einreichen? \nSie wird für deine Centerleitung als überprüft markiert. \nDu hast diesen Monat " +
            this.dayWorkTime.formattedTime +
            " Stunden gearbeitet \n" +
            "Entspricht einer Differenz zu Sollstunden von " +
            Math.round(parseFloat(this.timeDifference) * 1000) / 1000 +
            " Stunden"
        )
      ) {
        await api.selfSubmitTimetrackerMonth({ month: this.selectedDate })
        this.fetchEntries()
      }
    },
    vacationDaysForMonth(studio) {
      let fullStudio = this.storeStudios.find((std) => std._id === studio)
      if (!fullStudio || !fullStudio.vacationDays) return []
      return fullStudio.vacationDays.filter((day) => this.$moment(this.selectedDate).format("YYYY-MM") === this.$moment(day.date).format("YYYY-MM"))
    },
    async fetchUserStudio() {
      try {
        let studio = await api.getUserStudio()
        this.userStudio = studio.studio.name
      } catch (e) {
        this.$toast.error("Es gab ein Fehler beim Studio laden. Bitte versuche es erneut")
      }
    },
    signOut() {
      this.$toast.success("Erfolgreich ausgeloggt")
      localStorage.removeItem("token")
      localStorage.removeItem("user")

      location.reload()
    },
    showStudioSelection() {
      window.app.$root.$children[0].showStudioSelection = true
    },
    downloadTimetrackingExcel() {
      this.$toast.success("Excel wird generiert")
    },
    previousDate() {
      this.$refs.calendar.prev()
      this.fetchEntries()
    },
    nextDate() {
      this.$refs.calendar.next()
      this.fetchEntries()
    },
    clearTimer() {
      console.log("timer cleared")
      this.currentIndex = null
      clearInterval(this.interval)
    },
    startTimer() {
      console.log("timer started")
      if (this.currentIndex !== null)
        this.interval = setInterval(() => {
          this.renewCurrent()
        }, 15000)
    },
    renewCurrent() {
      if (this.currentIndex !== null) this.events[this.currentIndex].end = this.$moment().format("YYYY-MM-DD HH:mm")
    },
    getStartEndDate() {
      switch (this.calendarType) {
        case "month":
          return {
            start: this.$moment(this.selectedDate).startOf("month").format("YYYY-MM-DD"),
            end: this.$moment(this.selectedDate).endOf("month").format("YYYY-MM-DD"),
          }
        case "week":
          return {
            start: this.$moment(this.selectedDate).startOf("week").format("YYYY-MM-DD"),
            end: this.$moment(this.selectedDate).endOf("week").format("YYYY-MM-DD"),
          }
        case "day":
          return {
            start: this.$moment(this.selectedDate).format("YYYY-MM-DD"),
            end: this.$moment(this.selectedDate).format("YYYY-MM-DD"),
          }

        default:
          return {
            start: this.$moment().format("YYYY-MM-DD"),
            end: this.$moment().format("YYYY-MM-DD"),
          }
      }
    },
    async fetchEntries(showCorrection = true) {
      this.showCorrection = showCorrection
      let dates = this.getStartEndDate()

      this.loading = true
      this.clearTimer()

      let currentFound = false
      // Laden Sie alle Time Entries vom Backend
      // let allTimeEntries = await api.fetchAllTimetrackerCalendarEntries()
      let entries = await api.fetchTimetrackerCalendarEntries(dates, this.showCorrection)
      if (
        entries.timeTrackerDailyNotes &&
        entries.timeTrackerDailyNotes.find((noteEntry) => noteEntry.day === this.$moment(this.selectedDate).format("YYYY-MM-DD"))
      ) {
        this.dailyNote = JSON.parse(
          JSON.stringify(
            entries.timeTrackerDailyNotes.find((noteEntry) => noteEntry.day === this.$moment(this.selectedDate).format("YYYY-MM-DD")).note
          )
        )
      } else {
        this.dailyNote = ""
      }
      this.timeTrackerDailyNotes = JSON.parse(JSON.stringify(entries.timeTrackerDailyNotes))
      let allTimeEntries = entries.mappedTimeEntries
      this.employeeStudio = entries.studio
      this.recentEntries = entries.recentTimeEntries
      this.holidayEntries = entries.holidayEntries
      this.tripControlHistoryEntries = entries.tripControlHistoryEntries
      this.showButtons = entries.showButtons
      this.timeTrackerMonths = entries.timeTrackerMonths
      this.selfSubmittedTimetrackerMonths = entries.selfSubmittedTimetrackerMonths
      allTimeEntries = allTimeEntries.map((e, index) => {
        if (e.end === "current") {
          currentFound = true
          this.currentType = e.type
          this.currentIndex = index
          e.end = this.$moment().format("YYYY-MM-DD HH:mm")
        }
        delete e.type
        return e
      })

      this.startTimer()
      let holidayEntriesMapped = []
      let sickEntriesMapped = []
      if (this.holidayEntries[0] !== undefined) {
        holidayEntriesMapped = this.holidayEntries.filter((entry) => entry.status !== "rejected")
        holidayEntriesMapped = holidayEntriesMapped.map((e) => {
          if (e && e.dates && e.dates.length) {
            let isSame = this.$moment(e.dates[0].date).isSame(e.dates[e.dates.length - 1].date, "day")
            return {
              start: this.$moment(e.dates[0].date)
                .set({
                  hours: isSame ? 8 : 17 - e.dates[0].duration,
                  minutes: 0,
                  seconds: 0,
                  milliseconds: 0,
                })
                .format("YYYY-MM-DD HH:mm"),
              end: this.$moment(e.dates[e.dates.length - 1].date)
                .set({
                  hours: 8 + e.dates[e.dates.length - 1].duration,
                  minutes: 0,
                  seconds: 0,
                  milliseconds: 0,
                })
                .format("YYYY-MM-DD HH:mm"),
              color: "#FFC107",
              details: "Ferien",
            }
          }
        })
      }
      if (entries.sickEntries && entries.sickEntries.length) {
        entries.sickEntries.forEach((e) => {
          if (e && e.sickDays && e.sickDays.length) {
            e.sickDays.forEach((day) => {
              sickEntriesMapped.push({
                start: this.$moment(day.date)
                  .set({
                    hours: 1,
                    minutes: 0,
                    seconds: 0,
                    milliseconds: 0,
                  })
                  .format("YYYY-MM-DD HH:mm"),
                end: this.$moment(day.date)
                  .set({
                    hours: 1 + Number(day.hours),
                    minutes: 0,
                    seconds: 0,
                    milliseconds: 0,
                  })
                  .format("YYYY-MM-DD HH:mm"),
                color: "black",
                details: this.mappedAbsenceTypes[e.absenceReason] || "",
              })
            })
          }
        })
      }
      allTimeEntries = allTimeEntries.concat(holidayEntriesMapped, sickEntriesMapped)
      this.events = allTimeEntries
      if (!currentFound) {
        this.currentType = null
        this.currentIndex = null
      }
      this.loading = false
    },

    async createTimeEntry(type, reason = null) {
      let smoke = type === "smoke"
      let entry = {
        date: this.$moment().format("YYYY-MM-DD HH:mm"),
        type: smoke ? "pause" : type,
        reason,
        smoke,
      }
      let status = await api.createTimetrackerEntryEmployee(entry)
      if (status) {
        // this.$toast.success('Pause erfolgreich beendet')
        this.fetchEntries()
      }
    },

    handleEventClick({ event }) {
      // Handle event click
      // ...
    },
    handleDateClick({ date }) {
      this.selectedDate = date
    },
    updateSelectedDate(newValue) {
      // Update the selectedDate when the date picker value changes
      this.selectedDate = newValue
      this.fetchEntries()
    },
  },
  computed: {
    ...mapState("studioStore", ["storeStudios"]),
    submissionState() {
      if (!this.timeTrackerMonths) return null
      if (this.timeTrackerMonths && this.timeTrackerMonths.find((ttm) => ttm.month === this.$moment(this.selectedDate).format("YYYY-MM"))) {
        if (this.timeTrackerMonths.find((ttm) => ttm.month === this.$moment(this.selectedDate).format("YYYY-MM")).state === "onlyNotes") return false
        return this.timeTrackerMonths.find((ttm) => ttm.month === this.$moment(this.selectedDate).format("YYYY-MM"))
      }
    },
    username() {
      let user = this.userService.getUser()
      if (user && user.vorname && user.nachname) return user.vorname + " " + user.nachname
      else return "Problem bei Benutzerekennung, bitte melde ab und wieder an"
    },
    calcBusinessDays() {
      var day = this.$moment(this.selectedDate).startOf("month")
      var businessDays = 0

      while (day.isSameOrBefore(this.$moment(this.selectedDate).endOf("month"), "day")) {
        if (day.day() != 0 && day.day() != 6) businessDays++
        day.add(1, "d")
      }
      return businessDays
    },
    selectedDateFormatted: {
      get() {
        // Convert the selectedDate to a formatted string
        return this.$moment(this.selectedDate).format("YYYY-MM-DD")
      },
      set(newValue) {
        // Convert the formatted string to a Date object
        this.selectedDate = new Date(newValue)
      },
    },
    dayWorkStart() {
      if (!this.events || !this.events.length || this.events[0] === null) return "-"
      let firstWorkEvent = this.events.find((e) => e.details === "Arbeit")
      if (firstWorkEvent) return this.$moment(firstWorkEvent.start).format("HH:mm")
      else return "00:00"
    },
    dayWorkEnd() {
      if (!this.events || !this.events.length || this.events[0] === null) return "-"

      if (this.currentType == "checkin" || this.currentType === "pause") return "-"
      let lastWorkEvent = this.events
        .slice()
        .reverse()
        .find((e) => e.details === "Arbeit")
      if (lastWorkEvent) return this.$moment(lastWorkEvent.end).format("HH:mm")
      else return "00:00"
    },
    dayPauseTime() {
      if (!this.events || !this.events.length || this.events[0] === null) return "-"

      let pauseEvents = this.events.filter((e) => e && e.details === "Pause")
      if (!pauseEvents) return "-"
      pauseEvents = pauseEvents.reduce((acc, cur) => {
        let duration = this.$moment.duration(this.$moment(cur.end).diff(this.$moment(cur.start)))
        return acc + duration.asMinutes()
      }, 0)
      pauseEvents = Math.round(pauseEvents)
      // minuten in 00:00 format umwandeln
      let hours = Math.floor(pauseEvents / 60) // Stunden berechnen
      let minutes = pauseEvents % 60 // Minuten berechnen

      let formattedTime = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0")
      return formattedTime
    },
    timeDifference() {
      let vacationDaysLength = this.vacationDaysForMonth(this.employeeStudio._id).length || 0

      return (
        (this.dayWorkTime.minutes - (this.calcBusinessDays - vacationDaysLength) * ((8.4 * 60 * this.employeeStudio.percentage) / 100)) / 60 +
        " Stunden"
      )
    },
    dayWorkTime() {
      if (!this.events || !this.events.length || this.events[0] === null) return { formattedTime: " - ", minutes: 0 }

      let workEvents = this.events.filter(
        (e) =>
          e.details === "Arbeit" ||
          e.details === "Krank" ||
          e.details === "Schule" ||
          e.details === "Schwangerschaft" ||
          e.details === "Ferien (nachtrag)" ||
          e.details === "Anderes" ||
          e.details === "Militär"
      )
      if (!workEvents) return { formattedTime: " - ", minutes: 0 }
      workEvents = workEvents.reduce((acc, cur) => {
        let duration = this.$moment.duration(this.$moment(cur.end).diff(this.$moment(cur.start)))
        return acc + duration.asMinutes()
      }, 0)
      let start = this.$moment(this.selectedDate).startOf(this.calendarType)
      let end = this.$moment(this.selectedDate).endOf(this.calendarType)
      this.holidayEntries
        .filter(
          (entry) =>
            entry.status !== "rejected" && entry.dates.find((date) => this.$moment(date.date, "YYYY-MM-DD").isBetween(start, end, null, "[]"))
        )
        .forEach((entry) => {
          entry.dates.forEach((date) => {
            if (this.$moment(date.date, "YYYY-MM-DD").isBetween(start, end, null, "[]")) {
              workEvents += date.duration * 60
            }
          })
        })
      // minuten in 00:00 format umwandeln
      workEvents = Math.round(workEvents)
      let hours = Math.floor(workEvents / 60) // Stunden berechnen
      let minutes = workEvents % 60 // Minuten berechnen

      let formattedTime = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0")
      let totalMinutes = hours * 60 + minutes
      return { formattedTime: formattedTime, minutes: totalMinutes }
    },
  },
}
</script>

<style scoped>
.header {
  margin-top: 20px;
  text-align: center;
}

.calendar {
  margin-top: 20px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

button {
  margin-right: 10px;
}
</style>
