import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

export default {
  fetchChangelogEvents() {
    let url = buildUrl("changelog")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  deleteChangelogEvent(id) {
    let url = buildUrl("changelog/" + id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  submitChangelogEvent(entry) {
    let url = buildUrl("changelog")
    return axios.post(url, { entry }, buildOptions()).then((res) => res.data)
  },
  getRatings(id) {
    let url = buildUrl(`ratings/get/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getAllRatings(ts) {
    let url = buildUrl(`ratings/getall/${ts}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  newRating(data) {
    let url = buildUrl("ratings/new")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  checkRatingStatus(cgid) {
    let url = buildUrl(`ratings/check/${cgid}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
