import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchCompanyFiguresAccountPlan(studioId) {
    let url = buildUrl(`figures/plan/${studioId}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchCompanyFiguresIncomeStatementByDate(date) {
    let url = buildUrl(`figures/statement/${date}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  importCompanyFiguresIncomeStatement(data) {
    let url = buildUrl("figures/statement")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  importCompanyFiguresAccountPlan(data) {
    let url = buildUrl("figures/import")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  uploadCompanyFiguresIncomeStatement(formData, studioId) {
    let url = buildUrl("figures/statement/" + studioId)
    return axios
      .post(
        url,
        formData,
        buildOptions({
          headers: {
            "Content-Type": formData.type,
            "Original-File-Name": formData.name,
          },
        })
      )
      .then((res) => res.data)
  },
  uploadCompanyFiguresAccountPlan(formData, studioId) {
    let url = buildUrl("figures/plan/" + studioId)
    return axios
      .post(
        url,
        formData,
        buildOptions({
          headers: {
            "Content-Type": formData.type,
            "Original-File-Name": formData.name,
          },
        })
      )
      .then((res) => res.data)
  },
}
