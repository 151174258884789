import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchStudioSignature() {
    let url = buildUrl("studio/signature")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  saveStudioSignature(signature) {
    let url = buildUrl("studio/signatures")
    return axios.post(url, { signature }, buildOptions()).then((res) => res.data)
  },

  fetchTerminationsForStudio(data) {
    let url = buildUrl("studio/fetch/terminations")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  updateStudioTerminations(studio) {
    let url = buildUrl("studio/update/terminations")
    return axios.put(url, { studio }, buildOptions()).then((res) => res.data)
  },

  updateStudioVacationDays(studio) {
    let url = buildUrl("studio/update/vacaction-days")
    return axios.put(url, { studio }, buildOptions()).then((res) => res.data)
  },

  updateTeamVacationDays(team) {
    let url = buildUrl("team/update/vacaction-days")
    return axios.put(url, { team }, buildOptions()).then((res) => res.data)
  },

  updateStudioGrowth(studio) {
    let url = buildUrl("studio/update/growth")
    return axios.put(url, { studio }, buildOptions()).then((res) => res.data)
  },
  updateStudioCorrection(studio) {
    let url = buildUrl("studio/update/correction")
    return axios.put(url, { studio }, buildOptions()).then((res) => res.data)
  },
  updateStudio(studio) {
    let url = buildUrl("studio")
    return axios.put(url, { studio }, buildOptions()).then((res) => res.data)
  },

  fetchPublicStudios() {
    let url = buildUrl(`studios/fetch/public`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  fetchStudios() {
    let url = buildUrl(`studios/fetch`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchOpeningHours() {
    let url = buildUrl(`studios/opening-hours`)

    return axios.post(url, buildOptions()).then((res) => res.data)
  },
  saveOpeningHours(studio) {
    let url = buildUrl(`studios/save/opening-hours`)

    return axios.post(url, studio, buildOptions()).then((res) => res.data)
  },
  fetchTeamsForTitle() {
    let url = buildUrl(`teams/fetch/for/title`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getStudios() {
    let url = buildUrl(`studios/get`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
