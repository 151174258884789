import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  templateLog() {
    console.log("templateLog")
  },

  loadSortedProblems() {
    let url = buildUrl("intern/forms/problem/sorted")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  AWdownload() {
    let url = buildUrl(`wallet/generate`)
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then((res) => res.data)
  },
  getEBTImage(email) {
    let url = buildUrl(`userebt`)
    return axios.post(url, { email: email }, buildOptions()).then((res) => res.data)
  },
  PhotoEditorUpload(data) {
    let url = buildUrl(`gseditor/upload`)
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  getZeitgutschriften() {
    let url = buildUrl("zeitgutschrift")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  submitZeitgutschrift(fd) {
    let url = buildUrl("zeitgutschrift")
    return axios.post(url, fd).then((res) => res.data)
  },

  getAllSurveys() {
    let url = buildUrl("survey")

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  submitUmfrage(data) {
    let url = buildUrl("survey")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  getVersion() {
    let url = buildUrl("version")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchHostnames() {
    let url = buildUrl(`hosts/fetch`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // EINSTEIN CONTEST

  // Einstein Sauna Contest
  getEinsteinSaunaContestAdminList() {
    let url = buildUrl("einstein/contest/admin/list")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getEinsteinContestFreeSpaceCount() {
    let url = buildUrl("einstein/contest/availableentries")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getEinsteinContestAvailableDates() {
    let url = buildUrl("einstein/contest/availabledates")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  reserveEinsteinContestSpace(data) {
    let url = buildUrl("einstein/contest/reservespace")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateEinsteinContestEntry(data) {
    let url = buildUrl("/einstein/contest/updateentry")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  findAllEinsteinContestEntries() {
    let url = buildUrl("einstein/contest/allentries")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getEinsteinContestOwnEntry() {
    let url = buildUrl("einstein/contest/own/entry")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
