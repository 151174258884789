import Vue from "vue"
import VueRouter from "vue-router"
import { store } from "@/store/stores"
import userService from "@/services/userService"

Vue.use(VueRouter)

const routeFiles = require.context("./routes", false, /.*Routes\.js$/)

let routes = []

routeFiles.keys().forEach((filePath) => {
  const routeModule = routeFiles(filePath)
  routes = [...routes, ...routeModule.default]
})

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  await store.restored

  if (to.meta && to.meta.rights) {
    if (!userService.hasRightArray(to.meta.rights)) {
      console.log("Keine Berechtigung -> Weiterleitung nach / (Home).")
      return next({ name: "Home" })
    }
  }
  next()
})

export default router
