import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchUserAttendanceStats(userId) {
    let url = buildUrl("fetch/userattendancestats/" + userId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchCheckinPool(filter = false, lastUpdatedAt = null) {
    let url = `https://app.wellcomefit.ch/api/checkin/pool/entries?filter=${filter}`

    // Füge den lastUpdatedAt-Parameter nur hinzu, wenn er definiert ist
    if (lastUpdatedAt) {
      url += `&lastUpdatedAt=${encodeURIComponent(lastUpdatedAt)}`
    }

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAttendanceCustomers(studioID = 0) {
    let url = buildUrl("attendance/customers/" + studioID)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAttendanceTrainers(studioId = null) {
    let url = buildUrl("attendance/trainers")
    if (studioId) url = buildUrl("attendance/trainers/" + studioId)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  createNewAttendanceCorrection(data) {
    let url = buildUrl("attendance/correction/create")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateExistingAttendanceEntry(data) {
    let url = buildUrl("attendance/correction/update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  deleteAttendanceEntry(data) {
    let url = buildUrl("attendance/correction/delete")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  acceptAttendanceCorrection(data) {
    let url = buildUrl("attendance/correction/accept")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  rejectAttendanceCorrection(data) {
    let url = buildUrl("attendance/correction/reject")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
}
