import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  downloadContractImageData(src, title = "") {
    let url = buildUrl("contractpicturesnew/" + src.replace("contractpicturesnew/", ""))
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then(async (response) => {
      if (window.flutter_inappwebview) {
        console.log("FLUTTER IOS DOWNLOAD PDF START")
        var reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onloadend = function () {
          var dataUrl = reader.result
          var base64 = dataUrl.split(",")[1]
          console.log(base64)
          window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "png", title.replaceAll(" ", "_").replaceAll(".", "_"))
        }
      } else {
        saveAs(response.data, title.replaceAll(" ", "_").replaceAll(".", "_"))
      }
    })
  },
  downloadContractImage(imgUrl) {
    let title = imgUrl.replace(".", "_")
    let url = buildUrl("upload/contract/view/" + imgUrl)
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then(async (response) => {
      if (window.flutter_inappwebview) {
        console.log("FLUTTER IOS DOWNLOAD PDF START")
        var reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onloadend = function () {
          var dataUrl = reader.result
          var base64 = dataUrl.split(",")[1]
          console.log(base64)
          window.flutter_inappwebview.callHandler("blobToBase64Handler", base64.toString(), "png", title.replaceAll(" ", "_").replaceAll(".", "_"))
        }
      } else {
        saveAs(response.data, title.replaceAll(" ", "_").replaceAll(".", "_"))
      }
    })
  },
  getContractImage(path) {
    let url = buildUrl("upload/contract/view/" + path)
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then(async (res) => await blobToBase64(res.data))
  },
  getTimestopImage(path) {
    let url = buildUrl("upload/timestop/view/" + path)
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then(async (res) => await blobToBase64(res.data))
  },
  uploadContractPicture(formData) {
    let url = buildUrl("upload/contractpictures")
    return axios.post(url, formData, buildOptions()).then((res) => res.data)
  },

  fetchContractImageData(src) {
    let url = buildUrl("contractpicturesnew/" + src.replace("contractpicturesnew/", ""))
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then(async (res) => await blobToBase64(res.data))
  },
  fetchFormUpdateImage(src) {
    let url = buildUrl("formupdatepictures/" + src.replace("formupdatepictures/", ""))
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then(async (res) => await blobToBase64(res.data))
  },
  uploadContractImage(formData) {
    let url = buildUrl("upload/contractpicturesnew")
    return axios.post(url, formData, buildOptions()).then((res) => res.data)
  },
  uploadFormUpdateImage(formData) {
    let url = buildUrl("upload/formupdatepictures")
    return axios.post(url, formData, buildOptions()).then((res) => res.data)
  },
  uploadTimestopImage(formData) {
    let url = buildUrl("upload/timestoppictures")
    return axios.post(url, formData, buildOptions()).then((res) => res.data)
  },
  uploadImage(data) {
    if (data.art == "lead") {
      let url = buildUrl("upload/lead")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }

    if (data.art == "product") {
      let url = buildUrl("upload/product")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "slide") {
      let url = buildUrl("upload/slide")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "popup") {
      let url = buildUrl("upload/popup")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art === "timestop") {
      let url = buildUrl("upload/timestop")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "contract") {
      let url = buildUrl("upload/contract")
      console.log("upload contract")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "kurs") {
      let url = buildUrl("upload/kurs")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "news") {
      let url = buildUrl("upload/news")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }

    if (data.art == "uebung") {
      let url = buildUrl("upload/uebung")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "pfp") {
      let url = data._id ? buildUrl("upload/employee/" + data._id) : buildUrl("upload/pfp")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "gs") {
      let url = buildUrl("upload/gs")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "event") {
      let url = buildUrl("upload/event")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "workout") {
      let url = buildUrl("upload/workout")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "device") {
      let url = buildUrl("upload/device")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "workout") {
      let url = buildUrl("upload/workout")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "device_exercise") {
      let url = buildUrl("upload/device_exercise")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "device_exercise_manual") {
      let url = buildUrl("upload/device_exercise_manual")

      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
    if (data.art == "groupFitness_course") {
      let url = buildUrl("upload/group_fitness_course")
      return axios.post(url, data.formData, buildOptions()).then((res) => res.data)
    }
  },
  uploadWebsiteImage(formData) {
    let url = buildUrl("website/upload/image")
    let options = buildOptions()
    options.headers["Content-Type"] = "multipart/form-data"
    return axios.post(url, formData, buildOptions()).then((res) => res.data)
  },
}
