<template>
  <div class="openingHours">
    <v-expansion-panels dark>
      <v-expansion-panel v-for="(studio, index) in openingHoursStudios" :key="index">
        <v-expansion-panel-header>
          {{ studio.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox label="24H Checkin erlaubt?" v-model="studio.alwaysCheckin"></v-checkbox>
          <v-row v-for="(openingHours, index) of studio.openingHours" :key="'openingHours' + index" no-gutters>
            <v-col cols="auto">
              <div class="mb-3" style="display: flex; align-items: center">
                <v-text-field hide-details v-model="openingHours.title" label="Übertitel"></v-text-field>
                <v-btn icon @click="removeOpeningHourTable(index, studio.openingHours)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </div>
              <v-row no-gutters v-for="(hours, key) of openingHours.hours" :key="'stunden' + key">
                <v-col cols="auto">
                  <v-text-field v-model="hours.text" label="Text"></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <v-menu
                    ref="menu"
                    v-model="hours.fromPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="hours.from"
                        label="Von"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="hours.fromPicker" v-model="hours.from" full-width format="24hr"></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="auto">
                  <v-menu
                    ref="menu"
                    v-model="hours.toPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="hours.to"
                        label="Bis"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="hours.toPicker" v-model="hours.to" full-width format="24hr"></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="auto">
                  <v-menu
                    ref="menu"
                    v-model="hours.fromPicker2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="hours.from2"
                        label="Von (2)"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="hours.fromPicker2" v-model="hours.from2" full-width format="24hr"></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="auto">
                  <v-menu
                    ref="menu"
                    v-model="hours.toPicker2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="hours.to2"
                        label="Bis (2)"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="hours.toPicker2" v-model="hours.to2" full-width format="24hr"></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-btn @click="removeHour(key, openingHours.hours)" icon><v-icon>mdi-close</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-btn v-if="openingHours.title" @click="addNewOpeningHours(openingHours)" icon color="primary">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider dark></v-divider>
          <v-btn
            @click="addNewSection(studio)"
            v-if="
              !studio.openingHours ||
              !studio.openingHours.length ||
              (studio.openingHours[studio.openingHours.length - 1].title && studio.openingHours[studio.openingHours.length - 1].hours.length)
            ">
            Neuen Abschnitt hinzufügen
          </v-btn>
          <v-card-actions class="justify-content-center">
            <v-btn color="primary" @click="saveStudio(studio)">Speichern</v-btn>
          </v-card-actions>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import api from "../../../api"
export default {
  props: {
    studios: {},
  },
  data() {
    return {
      tab: null,
      days: null,
      active: false,
      notes: "",
      openingHoursStudios: [],
    }
  },
  async created() {
    await this.fetchOpeningHours()
    this.setStudios()
  },
  methods: {
    removeHour(index, hoursArray) {
      if (confirm("Willst du diese Uhrzeit wirklich löschen?")) {
        hoursArray.splice(index, 1)
      }
    },
    async saveStudio(studio) {
      try {
        await api.saveOpeningHours(studio)
        this.$toast.success("Öffnungszeiten erfolgreich bearbeitet")
      } catch (e) {
        this.$toast.error("Fehler beim speichern")
      }
    },
    removeOpeningHourTable(index, openingHours) {
      if (confirm("Willst du den Abschnitt wirklich löschen?")) {
        openingHours.splice(index, 1)
        this.$forceUpdate()
      }
    },
    addNewOpeningHours(openingHours) {
      openingHours.hours.push({
        text: "",
        from: null,
        fromPicker: false,
        to: null,
        toPicker: false,
        from2: null,
        fromPicker2: false,
        to2: null,
        toPicker2: false,
      })
    },
    addNewSection(studio) {
      studio.openingHours.push({
        title: "",
        hours: [],
      })
    },
    setStudios() {
      this.openingHoursStudios = JSON.parse(
        JSON.stringify(
          this.studios.map((stud) => {
            return {
              _id: stud._id,
              name: stud.name,
              openingHours: stud.openingHours || [],
              alwaysCheckin: stud.alwaysCheckin || false,
            }
          })
        )
      )
    },
    async fetchOpeningHours() {
      try {
        let days = await api.fetchOpeningHours()
      } catch (e) {
        console.log(e)
      }
      this.$emit("finishedloading")
    },
  },
  watch: {
    studios() {
      this.setStudios()
    },
  },
}
</script>
<style lang="scss" scoped></style>
