import Vue from "vue"
import Vuelidate from "vuelidate"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import vuetify from "./plugins/vuetify"

//import VueMeta from 'vue-meta'
import VueSignature from "vue-signature-pad"

import VMdDateRangePicker from "v-md-date-range-picker"
import VueGtag from "vue-gtag"
import moment from "moment-timezone"
import "moment/locale/de-ch"
import Vuex from "vuex"
import LottieVuePlayer from "@lottiefiles/vue-lottie-player"
import VueConfetti from "vue-confetti"
import VueNumber from "vue-number-animation"
//import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'
import VueFileAgent from "vue-file-agent"
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css"
import VueTheMask from "vue-the-mask"
import ICS from "vue-ics"
import VueToast from "vue-toast-notification"
import "vue-toast-notification/dist/theme-sugar.css"
import * as VueGoogleMaps from "vue2-google-maps"
import VueTour from "vue-tour"
require("vue-tour/dist/vue-tour.css")
// Import Slicksort for fitnessCenterDisplay Gallery sort
import { SlickList, SlickItem } from "vue-slicksort"
import BusinessHours from "vue-business-hours"

import ErrorHandlerPlugin from "./plugins/errorHandlerPlugin"

import VueApexCharts from "vue-apexcharts"
Vue.use(VueApexCharts)

Vue.component("BusinessHours", BusinessHours)

Vue.component("apexchart", VueApexCharts)

Vue.use(ErrorHandlerPlugin)
import VueQuagga from "vue-quaggajs"

Vue.use(VueQuagga)

// Import stores from store folder
import { store } from "./store/stores"

Vue.use(VueToast, {
  // One of the options
  position: "bottom",
})
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAlUc5nrcb39HYV-hBfkZYqf17wYtMw814",
    installComponents: true,
  },
})

//import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

Vue.use(ICS)
Vue.use(VueTour)
Vue.component("vfa-sortable-list", SlickList)
Vue.component("vfa-sortable-item", SlickItem)

//import heatmap from "vue-heatmapjs";
//import heatmapjsVue from 'heatmapjs-vue'
//Vue.use(heatmapjsVue)
Vue.use(VueSignature)
Vue.use(VueFileAgent)
/*
Sentry.init({
  Vue,
  dsn: 'https://ddf7bcc2cb074ab8980eb911dc1a1350@o1024908.ingest.sentry.io/5990795',
  integrations: [
	new Integrations.BrowserTracing({
	  routingInstrumentation: Sentry.vueRouterInstrumentation(router),
	  tracingOrigins: ['app.wellcomefit.ch', 'app.wintifit.ch', 'dev.roody.ch', /^\//],
	}),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
*/

Vue.use(Vuelidate)
Vue.use(VMdDateRangePicker)

Vue.use(Vuex)

//Vue.use(autoAnimatePlugin)
//Vue.use(VueMeta)
Vue.use(VueTheMask)

// Animations
Vue.use(VueConfetti)
Vue.use(VueNumber)

Vue.use(LottieVuePlayer)
moment.tz.setDefault("Europe/Zurich")
import VueMoment from "vue-moment"
Vue.use(VueMoment, { moment })

if (!localStorage.getItem("disableTracking")) {
  Vue.config.productionTip = false

  let gtag_id = ""
  if (location.hostname.includes("wellcomefit") && !location.pathname.includes("schnupperwoche")) {
    gtag_id = "G-MCXR31TYHP"
  }
  if (location.hostname.includes("wintifit") && !location.pathname.includes("schnupperwoche")) {
    gtag_id = "G-NCHSB9KFCM"
  }

  if (!gtag_id == "") {
    Vue.use(
      VueGtag,
      {
        config: { id: gtag_id },
      },
      router
    )
  }
}

// Enable Push

//Vue.use(heatmap);

Vue.prototype.$clone = function (value) {
  return JSON.parse(JSON.stringify(value))
}

window.app = new Vue({
  router,
  vuetify,
  store,
  VueSignature,

  render: (h) => h(App),
  validations: {},
}).$mount("#app")
