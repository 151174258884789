<template>
  <div>
    <v-row no-gutters>
      <v-btn style="z-index: 100000" absolute top right icon color="red" @click="openCancelConfirm()"><v-icon>mdi-close</v-icon></v-btn>

      <v-col cols="auto">
        <v-avatar size="80"><v-img cover :src="getImageForExercise(exercise)" position="top center"></v-img></v-avatar>
      </v-col>
      <v-col class="align-center">
        <v-card-title class="pb-0"> {{ exercise.title }}</v-card-title>
        <v-tooltip bottom v-if="analyzeExercise && analyzeExercise.text">
          <template v-slot:activator="{ on }">
            <div v-show="analyzeExercise && analyzeExercise.color" style="display: inline-block">
              <v-chip v-on="on" class="ml-3" :color="analyzeExercise && analyzeExercise.color ? analyzeExercise.color : ''" small>
                {{ analyzeExercise && analyzeExercise.text ? analyzeExercise.text : "" }}
              </v-chip>
            </div>
          </template>
          {{ analyzeExercise && analyzeExercise.description ? analyzeExercise.description : "" }}
        </v-tooltip>
      </v-col>
      <v-col cols="12">
        <v-divider color="white"></v-divider>
      </v-col>
      <v-col cols="12" v-if="exercise.general_exercise_settings.length">
        <v-row no-gutters>
          <v-col cols="auto">
            <v-card-title class="px-1 py-1 font-weight-bold">Geräteeinstellungen</v-card-title>
          </v-col>
          <v-col class="align-content-center" v-if="savedGeneralExerciseSettings" cols="grow">
            <v-btn class="ma-1" x-small fab color="blue" @click="savedGeneralExerciseSettings = false"><v-icon>mdi-pencil</v-icon></v-btn>

            <v-chip
              color="grey"
              class="mr-2"
              v-for="(setting, index) in exercise.general_exercise_settings.filter((setting) => setting.value)"
              :key="'setting' + index">
              <span>{{ shorten(setting.text) + " | " + setting.value }}</span>
            </v-chip>
          </v-col>
        </v-row>
        <v-expand-transition mode="out-in" v-if="!savedGeneralExerciseSettings">
          <v-row no-gutters>
            <v-col
              cols="auto"
              class="pa-1 d-flex align-center fill-height"
              :key="index + 'deviceSetting'"
              v-for="(deviceSetting, index) in exercise.general_exercise_settings">
              <!-- :color="deviceSetting.value ? 'listitemcolor' : 'grey'" -->
              <v-card
                class="d-flex flex-column align-center justify-space-between"
                elevation="2"
                style="width: auto; min-width: 0px; max-width: none; transition: width 0.3s">
                <span class="truncate py-1 px-2 font-weight-bold">
                  {{ shorten(deviceSetting.text, 10) }}
                </span>
                <div class="input-wrapper pb-1">
                  <v-select
                    dense
                    solo
                    height="20px"
                    style="line-height: 1"
                    hide-details
                    class="pa-0 ma-0"
                    v-model="deviceSetting.value"
                    :items="deviceSetting.selections"
                    v-if="deviceSetting && deviceSetting.input === 'selection'"
                    @keydown.enter.prevent="closeSettingDialog()"></v-select>
                  <span
                    v-else
                    contenteditable="true"
                    :class="{ 'text-center': !deviceSetting.value || true }"
                    @input="updatingSetting($event, deviceSetting)"
                    :type="deviceSetting.input"
                    placeholder="+"
                    class="compact-input flex-grow-1 pb-2"
                    v-text="deviceSetting.value"></span>
                </div>
              </v-card>
            </v-col>
            <v-col cols="auto" class="ml-2 align-content-center">
              <v-btn x-small fab color="primary" @click="saveGeneralExerciseSettings()"><v-icon>mdi-check</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-divider color="white"></v-divider>
      </v-col>

      <v-expand-transition>
        <v-row no-gutters v-if="exercise.sets.length">
          <v-col class="mb-2" :key="'exerciseSet' + index" cols="auto" v-for="(set, index) in exercise.sets">
            <v-slide-x-transition>
              <v-card class="fill-height rounded-xl mr-2">
                <v-row no-gutters>
                  <v-col cols="grow" class="flex-grow">
                    <v-card-title class="font-weight-bold py-1">{{ "Satz " + (index + 1) }}</v-card-title>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn class="ma-1" small icon color="blue" @click="editSet(index)"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn class="ma-1" small icon color="red" @click="removeSetByIndex(index)"><v-icon>mdi-delete</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-chip
                      class="my-2 mx-1"
                      small
                      :key="'setsetting' + index"
                      v-for="(setting, index) in set.filter((setting) => setting.value && setting.text !== 'Notiz')">
                      <span>{{ shorten(setting.text) + " | " + getSettingText(setting) }}</span>
                    </v-chip>
                    <br />
                    <span class="px-2 pb-1" v-if="set.find((setting) => setting.text === 'Notiz' && setting.value)">
                      {{ "Notiz: " + set.find((setting) => setting.text === "Notiz").value }}
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-col cols="12">
        <v-card-title class="px-1 py-1 mt-1 font-weight-bold">
          {{ editingSetIndex !== null ? 'Satz ' + (editingSetIndex + 1) + ' bearbeiten' : (exercise.sets.length ? 'Satz
          '
            +
            (exercise.sets.length + 1)
            + '
          hinzufügen' : 'ersten Satz hinzufügen') }}
        </v-card-title>
        <v-row no-gutters>
          <v-col
            cols="auto"
            class="pa-1 d-flex align-center fill-height"
            :key="index + 'deviceSetting'"
            v-for="(setSetting, index) in exercise.set_exercise_settings">
            <v-card color="" class="d-flex flex-column align-center justify-space-between" elevation="2">
              <span class="truncate py-1 px-2 font-weight-bold">
                {{ shorten(setSetting.text, 10) }}
              </span>
              <div class="input-wrapper pb-1">
                <v-select
                  dense
                  solo
                  height="20px"
                  style="line-height: 1"
                  hide-details
                  class="pa-0 ma-0"
                  v-model="setSetting.value"
                  :items="setSetting.selections"
                  v-if="setSetting && setSetting.input === 'selection'"
                  @keydown.enter.prevent="closeSettingDialog()"></v-select>
                <span
                  v-else
                  contenteditable="true"
                  :class="{ 'text-center': !setSetting.value || true }"
                  :type="setSetting.input"
                  placeholder="+"
                  class="compact-input flex-grow-1 pb-2"
                  v-text="setSetting.value"
                  @input="updatingSetting($event, setSetting)"></span>
              </div>
            </v-card>
          </v-col>
          <v-col cols="auto" class="ml-2 align-content-center">
            <v-btn x-small fab color="primary" @click="saveSet">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn v-if="editingSetIndex !== null" x-small fab color="grey" @click="cancelEditSet">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card-actions class="justify-content-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on" style="display: inline-block">
            <v-btn class="rounded-xl" :class="{ 'disabled-button': isButtonDisabled }" color="primary" @click="onButtonClick">
              Änderungen speichern
            </v-btn>
          </div>
        </template>
        <span>{{ tooltipMessage }}</span>
      </v-tooltip>
    </v-card-actions>
  </div>
</template>

<script>
import api from "@/api"
import { mapActions, mapGetters } from "vuex"
export default {
  name: "WorkoutAddExercise",
  props: {
    exerciseProp: {
      type: Object,
    },

    trainingMethods: {
      type: Object,
    },
  },
  data() {
    return {
      exercise: {
        method: "",
      },

      savedGeneralExerciseSettings: false,
      editingSetIndex: null,
    }
  },
  created() {
    console.log(exerciseProp)
  },
  computed: {
    analyzeExercise() {
      const sets = this.exercise.sets
      let method = ""
      if (!sets || sets.length < 3) return // Weniger als 2 Sätze → Keine Methode erkennbar

      // Werte aus den Sätzen extrahieren
      const weights = sets.map((set) => this.getSettingValue(set, "Gewicht"))
      const pauses = sets.map((set) => this.getSettingValue(set, "Pause"))
      const repetitions = sets.map((set) => this.getSettingValue(set, "Max Wiederholungen") || this.getSettingValue(set, "Min Wiederholungen"))

      // Analyse der Trainingsmethoden
      const isDescendingWeight = weights.every((w, i, arr) => !i || w < arr[i - 1])
      const isAscendingWeight = weights.every((w, i, arr) => !i || w > arr[i - 1])

      const hasPauses = pauses.length && pauses.some((p) => p > 0)

      console.log("pausen sind", pauses)
      console.log("descending", isDescendingWeight, "ascending", isAscendingWeight, "pauses", hasPauses)

      if (isDescendingWeight && !hasPauses) {
        this.$set(this.exercise, "method", "dropSet")
        method = "dropSet"
      } else if (isAscendingWeight && !hasPauses) {
        this.$set(this.exercise, "method", "reverseDropSet")
        method = "reverseDropSet"
      } else if ((isAscendingWeight || isDescendingWeight) && hasPauses) {
        this.$set(this.exercise, "method", "pyramidSet")
        method = "pyramidSet"
      } else if (hasPauses) {
        this.$set(this.exercise, "method", "restPause")
        method = "restPause"
      } else {
        this.exercise.method = "" // Keine Methode erkannt
      }
      console.log(this.exercise.method, method)
      return this.trainingMethods[method]
      return this.trainingMethods[this.exercise.method] || {}
    },
    isButtonDisabled() {
      return !this.exercise.sets.length
    },
    tooltipMessage() {
      const messages = []
      if (!this.exercise.sets.length) {
        messages.push("Mindestens einen Satz hinzufügen")
      }
      return messages.join(" ")
    },
  },
  watch: {},
  async created() {
    this.exercise = JSON.parse(JSON.stringify(this.exerciseProp))
    this.$set(this.exercise, "method", "")
    if (!this.exercise.sets) this.$set(this.exercise, "sets", [])
  },
  mounted() {},
  methods: {
    ...mapActions("deviceStore", ["setDeviceList"]),
    editSet(index) {
      this.editingSetIndex = index
      this.exercise.set_exercise_settings = JSON.parse(JSON.stringify(this.exercise.sets[index]))
    },
    getSettingValue(set, settingText) {
      const setting = set.find((s) => s.text === settingText)
      return setting ? parseFloat(setting.value) || setting.value : null
    },

    onButtonClick() {
      if (!this.isButtonDisabled) {
        let weights = this.exercise.sets.map((set) => parseFloat(set.find((item) => item.text === "Gewicht").value))
        let minReps = this.exercise.sets.map((set) => parseInt(set.find((item) => item.text === "Min Wiederholungen").value))
        let maxReps = this.exercise.sets.map((set) => parseInt(set.find((item) => item.text === "Max Wiederholungen").value))

        this.exercise.weightSum = { min: Math.min(...weights), max: Math.max(...weights) }
        this.exercise.repSum = { min: Math.min(...minReps), max: Math.max(...maxReps) }
        this.$emit("savedExercise", this.exercise)
      }
    },
    removeSetByIndex(index) {
      this.exercise.sets.splice(index, 1)
    },
    saveGeneralExerciseSettings() {
      if (!this.exercise.general_exercise_settings.find((setting) => setting.value)) {
        if (confirm("Ohne Geräteeinstellungen fortfahren?")) {
          this.savedGeneralExerciseSettings = true
        }
      } else {
        this.savedGeneralExerciseSettings = true
      }
    },
    saveSet() {
      if (this.exercise.set_exercise_settings.find((setting) => setting.value)) {
        const newSet = JSON.parse(JSON.stringify(this.exercise.set_exercise_settings))

        if (this.editingSetIndex !== null) {
          // Bearbeiten eines existierenden Satzes
          this.exercise.sets.splice(this.editingSetIndex, 1, newSet)
          this.editingSetIndex = null // Zurücksetzen
        } else {
          // Hinzufügen eines neuen Satzes
          this.exercise.sets.push(newSet)
        }
      } else {
        alert("Füge mindestens eine Satzeinstellung hinzu")
      }
    },
    cancelEditSet() {
      this.editingSetIndex = null // Zurücksetzen
      this.exercise.set_exercise_settings = JSON.parse(JSON.stringify(this.exerciseProp.set_exercise_settings)) // Zurücksetzen
    },
    openCancelConfirm() {
      if (this.exercise.sets.length || this.savedGeneralExerciseSettings) {
        if (confirm("ungespeicherte Änderungen gehen verloren")) return this.$emit("cancel-edit")
      } else {
        this.$emit("cancel-edit")
      }
    },
    updatingSetting(val, item) {
      item.value = event.target.innerText.trim()
    },
    getImageForExercise(exercise) {
      if (exercise.picture) return this.getImageUrl(exercise.picture)
      if (exercise.manual && exercise.manual[0] && exercise.manual[0].picture) return this.getImageUrl(exercise.manual[0].picture)
      return "/img/uebungen/einmalig/imageUploadPlaceholder.jpg"
    },
    getImageUrl(image) {
      return "/img/uebungen/" + image.replace(/\.[^/.]+$/, "") + ".webp"
    },
    shorten(text, length = 25) {
      if (text === "Min Wiederholungen") return "Min WH"
      if (text === "Max Wiederholungen") return "Max WH"
      if (text === "Umdrehungen pro Minute") return "UPM"
      if (text.length > length) text = text.substr(0, length) + "..."
      return text
    },
    getSettingText(setting) {
      let text = setting.value
      if (setting.text === "Gewicht") text = text + " KG"
      if (setting.text === "Dauer") text = text + ""
      return text
    },
  },
}
</script>

<style lang="scss" scoped>
.compact-input {
  width: auto;
  min-width: 50px;
  border: none;
  background: transparent;
  padding: 5px;
  font-size: 14px;
  transition: width 0.3s, border-color 0.3s;
  outline: none;
}

.compact-input:empty:before {
  content: "+";
  color: white;
}

.compact-input:focus:empty:before {
  border: 1px solid white;
}

.input-wrapper {
  flex-grow: 1;
  height: 38px;
}

.v-card {
  transition: width 0.3s, box-shadow 0.3s;
}

.v-card:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled-button {
  opacity: 0.5;
  pointer-events: none;
}
</style>
