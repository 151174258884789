import Videoarchiv from "@/views/gf/Videoarchiv.vue"
import courseListWeekly from "@/views/gf/courseListWeekly.vue"
import courseList from "@/views/gf/courseList.vue"
import courseListView from "@/views/gf/courseListView.vue"
import courseListEdit from "@/views/gf/courseListEdit.vue"
import courseEdit from "@/views/gf/courseEdit.vue"
import courseSettings from "@/views/gf/courseSettings.vue"
import courseEditTimeline from "@/views/gf/courseEditTimeline.vue"
import courseCreateTimeline from "@/views/gf/courseCreateTimeline.vue"
import Kurse_extern from "@/views/gf/Kurse_extern.vue"
import gfRecommendations from "@/views/gf/gfRecommendations.vue"

// DEPRECATED?

export default [
  { path: "/gfrecommendations/:userId", name: "gfrecommendations", component: gfRecommendations },
  { path: "/kursliste/:studiocode", name: "courseListWeekly", component: courseListWeekly },
  { path: "/kursliste/:studiocode/clean", name: "courseListWeeklyClean", component: courseListWeekly },
  {
    path: "/kurslisten",
    name: "courseEdit",
    component: courseEdit,
    children: [
      { path: "add", name: "courseCreateTimeline", component: courseCreateTimeline },
      { path: "edit/:id", name: "courseEditTimeline", component: courseEditTimeline },
    ],
  },
  {
    path: "/kurse",
    name: "courseList",
    meta: { navbarTitle: "Group Fitness" },
    component: courseList,
    children: [
      { path: ":id", name: "courseListView", component: courseListView },
      { path: ":id/edit", name: "courseListEdit", component: courseListEdit },
    ],
  },
  {
    path: "/kursliste/:id",
    name: "Kurse_extern",
    component: Kurse_extern,
  },

  // VIDEOARCHIV
  {
    path: "/videos",
    meta: { navbarTitle: "Videoarchiv" },
    name: "Videoarchiv",
    component: Videoarchiv,
  },
]
