import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  getRecentWeiterempfehlungen() {
    let url = buildUrl(`recentweiterempfehlungexcel`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getWeiterempfehlungen() {
    let url = buildUrl(`weiterempfehlungexcel`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getSensordaten() {
    let url = buildUrl(`sensordaten`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getTrainer(trainer_id) {
    let url = buildUrl(`bewertungen/kreuztabelle/trainer`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getTrainervergleich(infos) {
    let url = buildUrl(`bewertungen/kreuztabelle/studio/` + infos.studio_id + "/" + infos.von + "/" + infos.bis)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getTrainervergleich_gesamt(infos) {
    let url = buildUrl(`bewertungen/kreuztabelle/gesamt` + "/" + infos.von + "/" + infos.bis)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getKreuztabelle() {
    let url = buildUrl(`bewertungen/kreuztabelle/gesamt`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getStudiovergleich() {
    let url = buildUrl(`bewertungen/studiovergleich`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getBewertungen() {
    let url = buildUrl(`bewertungen`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  hideRating() {
    let url = buildUrl(`open/ratings/hide`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  openRatingAnamnese() {
    let url = buildUrl(`open/ratings/anamnese`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  openRatingTP() {
    let url = buildUrl(`open/ratings/trainingsplan`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  submitWorkoutRating(rating) {
    let url = buildUrl(`rating/trainingsplan`)
    return axios.post(url, rating, buildOptions()).then((res) => res.data)
  },
  submitAnamneseRating(rating) {
    let url = buildUrl(`rating/anamnese`)
    return axios.post(url, rating, buildOptions()).then((res) => res.data)
  },
}
