import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  fetchGalleries() {
    let url = buildUrl("galleries")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchImagesForGallery(galleryId) {
    let url = buildUrl("gallery/images/" + galleryId)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createGallery(formData) {
    let url = buildUrl("galleries")
    return axios.post(url, formData, buildOptions()).then((res) => res.data)
  },
  updateGallery(formData) {
    let url = buildUrl("galleries")
    return axios.put(url, formData, buildOptions()).then((res) => res.data)
  },
  deleteGallery(id) {
    let url = buildUrl("galleries/" + id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  deleteGalleryImage(image) {
    let url = buildUrl("gallery/images")
    return axios.delete(url, { data: image, headers: buildOptions() }).then((res) => res.data)
  },
  updateGalleryImageSortOrder(id, sortorder) {
    let url = buildUrl("gallery/images/" + id + "/" + sortorder)
    return axios.put(url, buildOptions()).then((res) => res.data)
  },
  fetchDisplays() {
    let url = buildUrl("display")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createDisplay(formData) {
    let url = buildUrl("display")
    return axios.post(url, formData, buildOptions()).then((res) => res.data)
  },
  updateDisplay(formData) {
    let url = buildUrl("display")
    return axios.put(url, formData, buildOptions()).then((res) => res.data)
  },
  deleteDisplay(id) {
    let url = buildUrl("display/" + id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
}
