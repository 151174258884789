import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }
  if (signal) options.signal = signal
  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export default {
  invoiceIssuers: {
    fetch() {
      const url = buildUrl("invoice-issuers")
      return axios.get(url, buildOptions()).then((res) => res.data)
    },
    create(data) {
      const url = buildUrl("invoice-issuers")
      return axios.post(url, data, buildOptions()).then((res) => res.data)
    },
  },
  documents: {
    decideDocument(documentId, data) {
      const url = buildUrl(`documents/decision/${documentId}`)
      return axios.post(url, data, buildOptions()).then((res) => res.data)
    },
    // Dokumente abrufen
    fetchDocuments(params = {}) {
      const url = buildUrl("documents")
      return axios.get(url, buildOptions({ params })).then((res) => res.data)
    },
    searchDocuments(params = {}) {
      const url = buildUrl("documents/search")
      return axios.get(url, buildOptions({ params })).then((res) => res.data)
    },
    assignDocument(documentId, payload) {
      const url = buildUrl(`documents/assign/${documentId}`)
      return axios.put(url, payload, buildOptions()).then((res) => res.data)
    },

    unassignDocument(documentId, usersToRemove) {
      const url = buildUrl(`documents/unassign/${documentId}`)
      return axios.post(url, { usersToRemove }, buildOptions()).then((res) => res.data)
    },

    fetchDocumentMail(documentId) {
      const url = buildUrl(`documents/mail/${documentId}`)
      return axios.get(url, buildOptions()).then((res) => res.data)
    },

    // Dokument-Emails abrufen
    fetchDocumentEmails() {
      const url = buildUrl("documents/email")
      return axios.get(url, buildOptions()).then((res) => res.data)
    },
    uploadDocument(formData) {
      const url = buildUrl("documents/upload")
      const options = buildOptions()
      // Damit der Browser den richtigen Content-Type mitsendet, loesche ggf. den Wert
      if (options.headers["Content-Type"]) {
        delete options.headers["Content-Type"]
      }
      return axios.post(url, formData, options).then((res) => res.data)
    },
    // Alle Ordner abrufen
    fetchFolders() {
      const url = buildUrl("folders")
      return axios.get(url, buildOptions()).then((res) => res.data)
    },

    // Neuen Ordner erstellen (data enthaelt z. B. { name, parent, ... })
    createFolder(data) {
      const url = buildUrl("folders")
      return axios.post(url, data, buildOptions()).then((res) => res.data)
    },

    // Existierenden Ordner aktualisieren
    updateFolder(folderId, data) {
      const url = buildUrl(`folders/${folderId}`)
      return axios.put(url, data, buildOptions()).then((res) => res.data)
    },

    // Ordner loeschen
    deleteFolder(folderId) {
      const url = buildUrl(`folders/${folderId}`)
      return axios.delete(url, buildOptions()).then((res) => res.data)
    },

    // Dokument in einen anderen Ordner verschieben
    moveDocument(documentId, folderId) {
      const url = buildUrl(`folders/move-document/${documentId}`)
      return axios.put(url, { folderId }, buildOptions()).then((res) => res.data)
    },

    // Dokument-Vorschau abrufen (Blob wird zurueckgegeben)
    previewDocument(documentId) {
      const url = buildUrl(`documents/attachment/${documentId}?preview=1`)
      return axios.get(url, { ...buildOptions(), responseType: "blob" }).then((res) => res.data)
    },

    // Dokument als Blob herunterladen und via file-saver speichern
    downloadDocument(documentId, fileName = "download.pdf") {
      let url = buildUrl(`documents/attachment/${documentId}`)

      axios
        .get(url, {
          responseType: "arraybuffer",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // Blob manuell erstellen – passe den MIME-Type ggf. an

          const blob = new Blob([response.data], { type: "application/pdf" })
          if (window.flutter_inappwebview) {
            var reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = function () {
              var dataUrl = reader.result
              var base64 = dataUrl.split(",")[1]
              window.flutter_inappwebview.callHandler(
                "blobToBase64Handler",
                base64.toString(),
                "pdf",
                fileName.replaceAll(" ", "_").replaceAll(".", "_")
              )
            }
          } else {
            saveAs(blob, fileName.replaceAll(" ", "_").replaceAll(".", "_"))
          }
        })
    },

    // Dokument aktualisieren
    updateDocument(documentId, data) {
      const url = buildUrl(`documents/update/${documentId}`)
      return axios.put(url, data, buildOptions()).then((res) => res.data)
    },
  },
}
