import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  // Login and Registration
  getEmailAlreadyUsed(mail) {
    let url = buildUrl("user/check/mail/in/use")
    return axios.post(url, mail, buildOptions()).then((res) => res.data)
  },
  updatePushToken(token) {
    let url = buildUrl("push/token")
    return axios.post(url, token, buildOptions()).then((res) => res.data)
  },
  generateAccount(data) {
    let url = buildUrl("register/generate")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  changePassword(data) {
    let url = buildUrl(`forgot/${data.key}`)

    return axios.post(url, data).then((res) => res.data)
  },
  // Verification
  resetPWAdmin(email) {
    let url = buildUrl(`forgotadmin/${email}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  resetPassword(data) {
    let url = buildUrl("forgot")

    return axios.post(url, data).then((res) => res.data)
  },
  sendMail(data) {
    let url = buildUrl("mail")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  authenticate(credentials) {
    let url = buildUrl("login")

    return axios.post(url, credentials).then((res) => res.data)
  },

  registerUser(credentials) {
    let url = buildUrl("register")

    return axios.post(url, credentials).then((res) => res.data)
  },
  saveConsent(customerSignature) {
    let url = buildUrl("user/consent")

    return axios.post(url, { customerSignature: customerSignature }, buildOptions()).then((res) => res.data)
  },
  saveDisclosureAggreement(customerSignature) {
    let url = buildUrl("user/non-disclosure-aggreement")

    return axios.post(url, { customerSignature: customerSignature }, buildOptions()).then((res) => res.data)
  },
  renewToken(signal = null) {
    let url = buildUrl("renew/token")

    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
  verifyEmailVerificationCode(data) {
    let url = buildUrl("user/email/verification/verify")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  startEmailVerification() {
    let url = buildUrl("user/email/verification/start")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchUserEmailVerificationStatus() {
    let url = buildUrl("user/email/verification/status")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Rights
  saveUserRoles(users) {
    let url = buildUrl("user/edit/role")

    return axios.post(url, users).then((res) => res.data)
  },
  fetchUserRightList() {
    let url = buildUrl("user/rightlist/all")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  deleteRight(data) {
    let url = buildUrl("rights/delete")
    return axios.put(url, { right: JSON.stringify(data) }, buildOptions()).then((res) => res.data)
  },
  upsertRight(data) {
    let url = buildUrl("rights")
    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },
  fetchRightsAndTopics() {
    let url = buildUrl(`rightsandtopics`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  createNewRightsTopic(data) {
    let url = buildUrl(`rightstopic`)
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchAllRights() {
    let url = buildUrl(`rights`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllUserRoles() {
    let url = buildUrl(`user/roles`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllRoles() {
    let url = buildUrl(`roles`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateExistingUserRole(data) {
    let url = buildUrl(`roles`)
    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },
  createUserRole(data) {
    let url = buildUrl(`roles`)
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  deleteUserRole(role_id) {
    let url = buildUrl(`roles/${role_id}`)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },

  fetchRolesForUser(userId) {
    let url = buildUrl(`user/roles/${userId}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getUserRights() {
    let url = buildUrl("user/rights")

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getUserRolle() {
    let url = buildUrl("rolle")

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  // Notifications
  markAllNotificationsAsRead() {
    let url = buildUrl("notifications/mark/all/as/read")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Slim-ME
  fetchSlimMeUsers() {
    let url = buildUrl("user/slim-me")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // User CRUD

  getUserforSignups(signups, id) {
    let data = {
      signups,
      id,
    }
    let url = buildUrl(`user/getlist/signups`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  getUserById(id) {
    let url = buildUrl(`user/getbyid/` + id)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getAllUserList(signal = null) {
    let url = buildUrl(`user/getlist/all`)

    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
  getUserList() {
    let url = buildUrl(`user/getlist`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getUser() {
    let url = buildUrl(`user`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  pickStudio(studio) {
    let url = buildUrl("pickstudio")

    return axios.put(url, studio, buildOptions()).then((res) => res.data)
  },
  editUserProfileFromContract(data) {
    let url = buildUrl("user/edit/profile/contract")

    return axios.put(url, data, buildOptions()).then((res) => res.data)
  },
  editUserProfile(user) {
    let url = buildUrl("user/edit/profile")

    return axios.put(url, user, buildOptions()).then((res) => res.data)
  },
  editUser(user) {
    user = JSON.parse(user)

    let url = buildUrl(`user/${JSON.parse(user.oldUser).email}`)

    return axios.put(url, JSON.parse(user.newUser), buildOptions()).then((res) => res.data)
  },

  deleteUser(userid) {
    let url = buildUrl(`user/delete`)

    return axios.post(url, userid, buildOptions()).then((res) => res.data)
  },
  deleteOwnAccount(userid) {
    let url = buildUrl(`user/delete/self`)

    return axios.post(url, userid, buildOptions()).then((res) => res.data)
  },
  editRolle(data) {
    let url = buildUrl(`rolle`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },

  searchUserforRolle(data) {
    let url = buildUrl(`rolle/list`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchAllUsers() {
    //let url = buildUrl(`fetch/users/all`)
    let url = "https://app.wellcomefit.ch/api/fetch/users/all"

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  lookupUsers(search, signal = null) {
    let url = buildUrl(`fetch/users/all`)
    //let url = "https://app.wellcomefit.ch/api/fetch/users/all"

    return axios.post(url, { search }, buildOptions({}, signal)).then((res) => res.data)
  },
  getUserForManager(id) {
    let url = buildUrl(`fetch/users/usersearch/` + id)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getUserForManagerByEmail(email) {
    let url = buildUrl(`fetch/users/usersearchemail/` + email)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getOwnUserForManager() {
    let url = buildUrl(`fetch/users/ownUser`)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getCustomerDashboard(id) {
    let url = buildUrl(`fetch/customer/dashboard/` + id)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getCustomerDashboardTraining(id) {
    let url = buildUrl(`fetch/customer/dashboard/training/` + id)

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateUserGuestEntries(data) {
    let url = buildUrl(`user/guest-entry`)

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  setUserBirthday(date) {
    let url = buildUrl("user/birthday")
    return axios.post(url, { date }, buildOptions()).then((res) => res.data)
  },

  updateUserInfo(data) {
    let url = buildUrl("user/update/info")
    return axios.put(url, { data }, buildOptions()).then((res) => res.data)
  },
  addTargetWeightToUser(weight) {
    let url = buildUrl("user/add/targetWeight")

    return axios.put(url, { weight: weight }, buildOptions()).then((res) => res.data)
  },
  addNewWeightToUser(weight) {
    let url = buildUrl("user/add/weight")

    return axios.put(url, { weight: weight }, buildOptions()).then((res) => res.data)
  },

  // Membercard

  createMembercardLead(userId) {
    let url = buildUrl(`membercard/createlead/${userId}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchMembercardStatistic(days = 30) {
    let url = buildUrl("membercard/leadsstats/" + days)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchMembercardStatisticAdmin(days = 30) {
    let url = buildUrl("membercard/leadsstatsadmin/" + days)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  fetchMembercardLeads() {
    let url = buildUrl("membercard/leads")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchPastMembercardLeads(days) {
    let url = buildUrl("membercard/leads/" + days)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchMembercardLead(userId) {
    let url = buildUrl(`membercard/fetchlead/${userId}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updateMembercard(data) {
    let url = buildUrl(`membercard/user`)
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  startMembercard(data) {
    let url = buildUrl(`membercard/user/start`)
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  createMembercard(id) {
    let url = buildUrl(`tendays/create/membercard/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  aboLoesen(id) {
    let url = buildUrl(`tendays/abos/loesen/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getMembercards() {
    let url = buildUrl("tendays/membercard/download")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getKampagneInvites() {
    let url = buildUrl(`tendays/membercard/invites/download`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  createFeedbackTendays(feedback) {
    let url = buildUrl(`tendays/feedback`)
    return axios.post(url, feedback, buildOptions()).then((res) => res.data)
  },

  subscribe(subscription) {
    let url = buildUrl(`subscription`)
    return axios.post(url, { subscription: subscription }, buildOptions()).then((res) => res.data)
  },
  td_anmelden(id) {
    let url = buildUrl(`tendays/anmelden/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  td_aboAnfragen() {
    let url = buildUrl(`tendays/abos`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  td_buyAbo() {
    let url = buildUrl(`tendays/buy`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  td_cancelAbo() {
    let url = buildUrl(`tendays/cancel`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  // Studio
  getUserStudio() {
    let url = buildUrl("user/standort")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  fetchUserMescanData() {
    let url = buildUrl("mescan/user/all")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
