import contractList from "@/views/dashboard/contract/contractList.vue"
import contractStats from "@/views/dashboard/contract/contractStats.vue"
import contractManager from "@/views/dashboard/contract/contractManager.vue"
import mapContractTAC from "@/views/dashboard/tac/components/contract/mapContract.vue"
import contractSettings from "@/views/dashboard/contract/contractSettings.vue"
import contractRejected from "@/views/dashboard/contract/contractRejected.vue"
import contractTemporary from "@/views/dashboard/contract/contractTemporary.vue"
import contractFieldsManager from "@/views/dashboard/contract/contractFieldsManager.vue"
import contractLayoutManager from "@/views/dashboard/contract/contractLayoutManager.vue"
import workoutsFeedback from "@/views/dashboard/workouts/workoutsFeedback.vue"
import workoutsDashboard from "@/views/dashboard/workouts/workoutsDashboard.vue"
import workoutsDashboardSearchAndCreate from "@/views/dashboard/workouts/workoutsDashboardSearchAndCreate.vue"
import studioDashboard from "@/views/dashboard/studio/studioDashboard.vue"
import studioDashboardEmployee from "@/views/dashboard/studio/studioDashboardEmployee.vue"
import studioDashboardDaycare from "@/views/dashboard/studio/studioDashboardDaycare.vue"
import studioDashboardSlimMe from "@/views/dashboard/studio/studioDashboardSlimMe.vue"
import studioDashboardOpeningHours from "@/views/dashboard/studio/studioDashboardOpeningHours.vue"
import studioDashboardWebsiteContent from "@/views/dashboard/studio/studioDashboardWebsiteContent.vue"
import WebsiteJobTypes from "@/views/dashboard/studio/components/WebsiteJobTypes.vue"
import WebsiteOffersManager from "@/views/dashboard/studio/components/WebsiteOffersManager.vue"
import communicationDashboard from "@/views/dashboard/communication/communicationDashboard.vue"
import communicationDashboardNews from "@/views/dashboard/communication/communicationDashboardNews.vue"
import communicationDashboardPopups from "@/views/dashboard/communication/communicationDashboardPopups.vue"
import communicationDashboardPopupsNew from "@/views/dashboard/communication/communicationDashboardPopupsNew.vue"
import communicationDashboardQRCode from "@/views/dashboard/communication/communicationDashboardQRCode.vue"
import communicationDashboardCampaign from "@/views/dashboard/communication/communicationDashboardCampaign.vue"
import communicationDashboardPush from "@/views/dashboard/communication/communicationDashboardPush.vue"
import devicesDashboard from "@/views/dashboard/devices/devicesDashboard.vue"
import devicesDashboardExerciseForm from "@/views/dashboard/devices/devicesDashboardExerciseForm.vue"
import devicesDashboardForm from "@/views/dashboard/devices/devicesDashboardForm.vue"
import devicesDashboardList from "@/views/dashboard/devices/devicesDashboardList.vue"
import userDashboard from "@/views/dashboard/user/userDashboard.vue"
//import userDashboardSearch from '@/views/dashboard/user/userDashboardSearch.vue'
import userDashboardCustomer from "@/views/dashboard/user/userDashboardCustomer.vue"
import userDashboardSearch from "@/views/dashboard/user/userDashboardSearch.vue"
import userDashboardRightList from "@/views/dashboard/user/userDashboardRightList.vue"
import communicationDashboardSlideManager from "@/views/dashboard/communication/communicationDashboardSlideManager.vue"
import membercardDashboard from "@/views/dashboard/membercard/membercardDashboard.vue"
import membercardDashboardAdmin from "@/views/dashboard/membercard/membercardDashboardAdmin.vue"
import leadsDashboard from "@/views/dashboard/leads/leadsDashboard.vue"
import leadsCostManager from "@/views/dashboard/leads/leadsCostManager.vue"
import leadsDashboardLeadsDeprecated from "@/views/dashboard/leads/leadsDashboardLeadsDeprecated.vue"
import leadsDashboardLeadsList from "@/views/dashboard/leads/leadsDashboardLeadsList.vue"
import leadsDashboardReferralList from "@/views/dashboard/leads/leadsDashboardReferralList.vue"
import leadsDashboardReferralSettings from "@/views/dashboard/leads/leadsDashboardReferralSettings.vue"
import leadsDashboardSettings from "@/views/dashboard/leads/leadsDashboardSettings.vue"
import leadsDashboardCategorySettings from "@/views/dashboard/leads/leadsDashboardCategorySettings.vue"
import leadsDashboardLeadSettings from "@/views/dashboard/leads/leadsDashboardLeadSettings.vue"
import anamneseDashboard from "@/views/dashboard/anamnese/anamneseDashboard.vue"
import anamneseDashboardSearch from "@/views/dashboard/anamnese/anamneseDashboardSearch.vue"
import anamneseDashboardSettings from "@/views/dashboard/anamnese/anamneseDashboardSettings.vue"
import shopDashboard from "@/views/dashboard/shop/shopDashboard.vue"
import nutritionDashboard from "@/views/dashboard/nutrition/nutritionDashboard.vue"
import nutritionSearch from "@/views/dashboard/nutrition/nutritionSearch.vue"
import nutritionCustomers from "@/views/dashboard/nutrition/nutritionCustomers.vue"
import shopProducts from "@/views/dashboard/shop/shopProducts.vue"
import shopPurchases from "@/views/dashboard/shop/shopPurchases.vue"
import groupFitnessDashboard from "@/views/dashboard/groupFitness/groupFitnessDashboard.vue"
import groupFitnessDashboardRatings from "@/views/dashboard/groupFitness/groupFitnessDashboardRatings.vue"
import groupFitnessDashboardCourses from "@/views/dashboard/groupFitness/groupFitnessDashboardCourses.vue"
import groupFitnessDashboardRooms from "@/views/dashboard/groupFitness/groupFitnessDashboardRooms.vue"
import groupFitnessDashboardSettings from "@/views/dashboard/groupFitness/groupFitnessDashboardSettings.vue"
import groupFitnessDashboardDownloads from "@/views/dashboard/groupFitness/groupFitnessDashboardDownloads.vue"
import groupFitnessDashboardLabels from "@/views/dashboard/groupFitness/groupFitnessDashboardLabels.vue"
import groupFitnessDashboardEventHome from "@/views/dashboard/groupFitness/groupFitnessDashboardEventHome.vue"
import groupFitnessDashboardEventForm from "@/views/dashboard/groupFitness/groupFitnessDashboardEventForm.vue"
import groupFitnessDashboardVideos from "@/views/dashboard/groupFitness/groupFitnessDashboardVideos.vue"
import homeDashboard from "@/views/dashboard/home/homeDashboard.vue"
import homeDashboardCheckinStatistics from "@/views/dashboard/home/homeDashboardCheckinStatistics.vue"
import homeDashboardMembercardStatistics from "@/views/dashboard/home/homeDashboardMembercardStatistics.vue"
import homeDashboardUserStatistics from "@/views/dashboard/home/homeDashboardUserStatistics.vue"
import homeDashboardFigures from "@/views/dashboard/home/homeDashboardFigures.vue"
import homeDashboardGroupFitnessStatistics from "@/views/dashboard/home/homeDashboardGroupFitnessStatistics.vue"
import homeDashboardTrainerStatistics from "@/views/dashboard/home/homeDashboardTrainerStatistics.vue"
import homeDashboardEmployeeStatistics from "@/views/dashboard/employee/employeeStatistic.vue"
import homeDashboardAnamneseStatistics from "@/views/dashboard/home/homeDashboardAnamneseStatistics.vue"
import documentsDashboard from "@/views/dashboard/documents/documentsDashboard.vue"
import documentsMain from "@/views/dashboard/documents/documentsMain.vue"
import toDoListDashboard from "@/views/dashboard/toDoList/toDoListDashboard.vue"
import toDoListMain from "@/views/dashboard/toDoList/toDoListMain.vue"
import shiftManagementDashboard from "@/views/dashboard/shiftManagement/shiftManagementDashboard.vue"
import shiftManagementMain from "@/views/dashboard/shiftManagement/shiftManagementMain.vue"
import shiftManagementSettings from "@/views/dashboard/shiftManagement/shiftManagementSettings.vue"
import MonthlyReport from "@/views/dashboard/monthlyReport/MonthlyReport.vue"
import contractCreator from "@/views/dashboard/contract/contractCreator.vue"
import upgradeContractView from "@/views/dashboard/contract/components/upgradeContractView.vue"
import tacContractsRoodyMapper from "@/views/dashboard/contract/components/tacContractsRoodyMapper.vue"
import contractCreatorExtern from "@/views/dashboard/contract/contractCreatorExtern.vue"
import EmployeeTimeTracker from "@/views/dashboard/timetracker/EmployeeTimeTracker.vue"
import ManagementTimeTracker from "@/views/dashboard/timetracker/ManagementTimeTracker.vue"
import OfficeTimeTracker from "@/views/dashboard/timetracker/OfficeTimeTracker.vue"
import payment from "@/views/dashboard/payment/Payment.vue"
import paymentSettings from "@/views/dashboard/payment/PaymentSettings.vue"
import paymentOverview from "@/views/dashboard/payment/PaymentOverview.vue"
import homeDashboardContractStatistics from "@/views/dashboard/home/homeDashboardContractStatistics.vue"
import homeDashboardContractPackagesStatistics from "@/views/dashboard/home/homeDashboardContractPackagesStatistics.vue"
import homeDashboardContractDevelopment from "@/views/dashboard/home/homeDashboardContractDevelopment.vue"
import homeDashboardLeadStatistics from "@/views/dashboard/home/homeDashboardLeadStatistics.vue"
import liveCheckins from "@/views/dashboard/attendance/liveCheckins.vue"
import dashboard from "@/views/dashboard/dashboard.vue"
import studioEditor from "@/views/dashboard/studioEditor.vue"
import tacAccessDevices from "@/views/dashboard/tac/tacAccessDevices.vue"
import studioSignatures from "@/views/dashboard/studio/studioSignatures.vue"

export default [
  {
    path: "/dashboard",
    name: "dashboardHome",
    component: dashboard,
    children: [
      {
        name: "toDoListDashboard",
        path: "todo",
        component: toDoListDashboard,
        children: [
          {
            name: "toDoListMain",
            path: "/",
            component: toDoListMain,
            meta: { displayedTitle: "ToDo's", rights: ["d_fetchAllDocs", "d_fetchDocs"] },
          },
        ],
      },
      {
        name: "documentsDashboard",
        path: "documents",
        component: documentsDashboard,
        children: [
          {
            name: "documentsMain",
            path: "/",
            component: documentsMain,
            meta: { displayedTitle: "Dokumente", rights: ["d_fetchAllDocs", "d_fetchDocs"] },
          },
        ],
      },
      {
        name: "shiftManagementDashboard",
        path: "shift-management",
        component: shiftManagementDashboard,
        children: [
          {
            name: "shiftManagementPlan",
            path: "/",
            component: shiftManagementMain,
            meta: { displayedTitle: "Arbeitseinsatz Planung | Erstellen und Bearbeiten", rights: ["tt_office"] },
          },
          {
            name: "shiftManagementSettings",
            path: "settings",
            component: shiftManagementSettings,
            meta: { displayedTitle: "Arbeitseinsatz Planung | Einstellungen", rights: ["tt_office"] },
          },
        ],
      },
      {
        name: "tacAccessDevices",
        path: "tac/access-devices",
        component: tacAccessDevices,
        meta: { displayedTitle: "TAC Zugangsgeräte" },
      },
      {
        name: "healthcareView",
        path: "healthcare",
        component: () => import("@/views/dashboard/healthcareConfirmation/healthcareView.vue"),
        meta: { displayedTitle: "KK-Bestätigung Anfragen" },
      },
      {
        name: "timestopView",
        path: "timestop",
        component: () => import("@/views/dashboard/timestop/timestopView.vue"),
        meta: { displayedTitle: "Timestops Anfragen" },
      },
      {
        name: "timestopCalculator",
        path: "timestop-calc",
        component: () => import("@/views/dashboard/timestop/timestopCalculator.vue"),
        meta: { displayedTitle: "Timestop Rechner" },
      },
      {
        name: "homeDashboardCheckinStatistics",
        path: "checkin-stats",
        component: homeDashboardCheckinStatistics,
        meta: { displayedTitle: "Check-IN Statistiken", statistics: true, multiStudio: true, rights: ["stat_viewstudio", "stat_admin"] },
      },
      {
        name: "homeDashboardUserStatistics",
        path: "user-stats",
        component: homeDashboardUserStatistics,
        meta: {
          hideRange: true,
          displayedTitle: "Kunden Statistiken",
          statistics: true,
          multiStudio: true,
          rights: ["stat_viewstudio", "stat_admin"],
        },
      },
      {
        name: "homeDashboardGroupFitnessStatistics",
        path: "gf-stats",
        component: homeDashboardGroupFitnessStatistics,
        meta: { displayedTitle: "Group Fitness Statistiken", statistics: true, multiStudio: true, rights: ["stat_admin", "stat_gf", "stat_gf_stud"] },
      },
      {
        name: "homeDashboardTrainerStatistics",
        path: "trainer-stats",
        component: homeDashboardTrainerStatistics,
        meta: { displayedTitle: "Trainer Statistiken", statistics: true, multiStudio: true, rights: ["stat_admin", "stat_viewstudio"] },
      },
      {
        name: "homeDashboardEmployeeStatistics",
        path: "employee-stats",
        component: homeDashboardEmployeeStatistics,
        meta: { displayedTitle: "Mitarbeiter zahlen", rights: ["stat_employee", "stat_viewstudio"] },
      },
      {
        name: "homeDashboardMembercardStatistics",
        path: "membercard-stats",
        component: homeDashboardMembercardStatistics,
        meta: { displayedTitle: "11 Tage Kampagne", rights: ["stat_admin"] },
      },
      {
        name: "homeDashboardAnamneseStatistics",
        path: "anamnese-stats",
        component: homeDashboardAnamneseStatistics,
        meta: { displayedTitle: "Anamnese Statistiken", statistics: true, multiStudio: true, rights: ["stat_admin", "stat_viewstudio"] },
      },
      {
        name: "homeDashboardContractStatistics",
        path: "contract-stats",
        component: homeDashboardContractStatistics,
        meta: { displayedTitle: "Vertrags Statistiken", statistics: true, multiStudio: true, rights: ["stat_admin", "stat_viewstudio"] },
      },
      {
        name: "homeDashboardContractPackagesStatistics",
        path: "packages-stats",
        component: homeDashboardContractPackagesStatistics,
        meta: { displayedTitle: "Vertrags Statistiken", statistics: true, multiStudio: true, rights: ["stat_admin", "stat_viewstudio"] },
      },
      {
        name: "homeDashboardContractDevelopmentStatistics",
        path: "contract-development",
        component: homeDashboardContractDevelopment,
        meta: {
          displayedTitle: "Aboentwicklung (letzte 12 Monate)",
          statistics: true,
          hideRange: true,
          multiStudio: true,
          rights: ["stat_admin", "stat_viewstudio"],
        },
      },
      {
        name: "homeDashboardLeadStatistics",
        path: "lead-stats",
        component: homeDashboardLeadStatistics,
        meta: { displayedTitle: "Leads Statistiken", statistics: true, multiStudio: true, rights: ["stat_admin", "stat_viewstudio"] },
      },
      {
        name: "homeDashboardFigures",
        path: "figures",
        component: homeDashboardFigures,
        meta: { monthsOnly: true, displayedTitle: "Kennzahlen", statistics: true, multiStudio: false, rights: ["stat_admin", "stat_viewstudio"] },
      },
      {
        name: "liveCheckins",
        path: "live-checkins",
        component: liveCheckins,
        meta: { studioOnly: true, displayedTitle: "Live Checkins", rights: ["g_studioastat_admin", "stat_viewstudio", "live_checkins"] },
      },
      {
        name: "studioEditor",
        path: "studios",
        component: studioEditor,
        meta: { displayedTitle: "Studio Admin", rights: ["g_studioadmin"] },
        children: [],
      },
      {
        name: "studioSignatures",
        path: "studios/signatures",
        component: studioSignatures,
        meta: { displayedTitle: "Studio Unterschriften", rights: ["g_studioadmin"] },
      },
      {
        name: "timeTracker",
        path: "timetracker",
        component: EmployeeTimeTracker,
        meta: { displayedTitle: "Zeiterfassung", rights: ["g_studioadmin", "tt_employeeManage"] },
      },
      {
        name: "timeTrackerManagement",
        path: "timetracker/management",
        component: ManagementTimeTracker,
        meta: {
          includeTeams: true,
          monthsOnly: true,
          statistics: true,
          displayedTitle: "Zeiterfassung Management",
          rights: ["g_studioadmin", "tt_management"],
        },
      },
      {
        name: "timeTrackerOffice",
        path: "timetracker/office",
        component: OfficeTimeTracker,
        meta: {
          includeTeams: true,
          monthsOnly: true,
          displayedTitle: "Zeiterfassung Büro",
          statistics: true,
          rights: ["g_studioadmin", "tt_office"],
        },
      },
      {
        name: "nutritionSales",
        path: "contract/nutrition",
        component: () => import("@/views/dashboard/contract/nutritionSales.vue"),
        meta: { displayedTitle: "Ernährungsberatungen" },
      },
      { name: "contractSettings", path: "contract/settings", component: contractSettings, meta: { displayedTitle: "Vertrags Einstellungen" } },
      { name: "contractList", path: "contract", component: contractList, meta: { displayedTitle: "Verträge" } },
      { name: "contractManager", path: "contract/manager", component: contractManager, meta: { displayedTitle: "offene Verträge" } },
      {
        name: "contractStats",
        path: "contract/stats",
        component: contractStats,
        meta: { statistics: true, multiStudio: true, displayedTitle: "Statistik abgelehnte Verträge" },
      },
      {
        name: "contractManager",
        path: "contract/manager/map/:userId/:index",
        component: mapContractTAC,
        meta: { displayedTitle: "Map TAC Contract" },
      },
      { name: "contractSettings", path: "contract/settings", component: contractSettings, meta: { displayedTitle: "Einstellungen" } },
      { name: "contractRejected", path: "contract/rejected", component: contractRejected, meta: { displayedTitle: "abgelehnte Verträge" } },
      {
        name: "contractTemporary",
        path: "contract/temporary",
        component: contractTemporary,
        meta: { displayedTitle: "zwischengespeicherte Verträge" },
      },
      { name: "contractFieldsManager", path: "contract/create", component: contractFieldsManager, meta: { displayedTitle: "Vertrag erstellen" } },
      {
        name: "contractFieldsManagerEdit",
        path: "contract/settings/:id",
        component: contractFieldsManager,
        meta: { displayedTitle: "Vertrag bearbeiten" },
      },
      {
        name: "contractCreator",
        path: "contract/create/:id",
        component: contractCreator,
        meta: { displayedTitle: "Vertrag Bearbeiten" },
      },
      {
        name: "tacContractsRoodyMapper",
        path: "contract/upgrade/mapping",
        component: tacContractsRoodyMapper,
        meta: { displayedTitle: "Vertrag Infos Mappen" },
      },

      {
        name: "contractUpgradesOpen",
        path: "contract/upgrade/created",
        component: upgradeContractView,
        meta: { displayedTitle: "Vertrag upgraden" },
      },
      {
        name: "contractUpgradesClosed",
        path: "contract/upgrade/rejected",
        component: upgradeContractView,
        meta: { displayedTitle: "Vertrag upgraden" },
      },
      {
        name: "contractUpgradesAccepted",
        path: "contract/upgrade/accepted",
        component: upgradeContractView,
        meta: { displayedTitle: "Vertrag upgraden" },
      },
      {
        name: "upgradeContractView",
        path: "contract/upgrade/deleted",
        component: upgradeContractView,
        meta: { displayedTitle: "Vertrag upgraden" },
      },

      {
        name: "monthlyReport",
        path: "monthly/report",
        component: MonthlyReport,
        meta: { displayedTitle: "Monats Übersicht" },
      },
      {
        name: "contractViewer",
        path: "contract/view/:userId/:index",
        component: contractCreator,
        meta: { displayedTitle: "Dein Vertrag" },
      },
      {
        name: "contractEditor",
        path: "contract/edit/:userId/:index",
        component: contractCreator,
        meta: { displayedTitle: "Vertrag bearbeiten" },
      },
      { name: "contractLayoutManager", path: "contract/layout", component: contractLayoutManager },
      {
        name: "payment",
        path: "payment",
        component: payment,
        meta: { displayedTitle: "Zahlungsabwicklung" },
      },
      {
        name: "paymentOverview",
        path: "payment/overview",
        component: paymentOverview,
        meta: { displayedTitle: "Zahlungsübersicht" },
      },
      { name: "paymentSettings", path: "payment/settings", component: paymentSettings, meta: { displayedTitle: "Einstellungen" } },
      {
        name: "workouts",
        path: "workouts",
        component: workoutsDashboard,
        children: [
          {
            name: "workoutsDashboardFeedback",
            path: "feedback",
            component: workoutsFeedback,
            meta: { displayedTitle: "Trainingsplan Bewertungen", needsLoader: true, rights: ["tp_feedback"] },
          },
          {
            name: "workoutsDashboardSearchAndCreate",
            path: "search-and-create",
            component: workoutsDashboardSearchAndCreate,
            meta: { displayedTitle: "Trainingsplan suchen und erstellen", rights: ["tp_create", "tp_viewAll"] },
          },
        ],
      },
      {
        name: "studio",
        path: "studio",
        component: studioDashboard,
        children: [
          {
            name: "studioEmployee",
            path: "employee",
            component: studioDashboardEmployee,
            meta: {
              studioOnly: true,
              multiStudio: true,
              includeTeams: true,
              displayedTitle: "Mitarbeiter",
              needsLoader: true,
              rights: ["g_employeemanager"],
            },
          },
          {
            name: "studioDaycare",
            path: "daycare",
            component: studioDashboardDaycare,
            meta: { displayedTitle: "Kinderhort", needsLoader: true, rights: ["g_daycare"] },
          },
          {
            name: "openingHours",
            path: "opening-hours",
            component: studioDashboardOpeningHours,
            meta: { studioOnly: true, multiStudio: true, displayedTitle: "Öffnungszeiten", needsLoader: true, rights: ["g_employeemanager"] },
          },
          {
            name: "websiteContent",
            path: "website",
            component: studioDashboardWebsiteContent,
            meta: { displayedTitle: "Webseite", rights: ["g_websitecontent", "g_websitecontentstudioadmin"] },
          },
          {
            name: "websiteJobTypes",
            path: "website/jobs",
            component: WebsiteJobTypes,
            meta: { displayedTitle: "Job Typen", rights: ["g_websitecontent"] },
          },
          {
            name: "websiteJobTypes",
            path: "website/offers",
            component: WebsiteOffersManager,
            meta: { displayedTitle: "Angebote und Services", rights: ["g_websitecontent"] },
          },
          {
            name: "studioSlimMe",
            path: "slim-me",
            component: studioDashboardSlimMe,
            meta: { displayedTitle: "Slim ME", needsLoader: true, rights: ["u_search"] },
          },
        ],
      },
      {
        name: "communication",
        path: "communication",
        component: communicationDashboard,
        children: [
          {
            name: "communicationNews",
            path: "news",
            component: communicationDashboardNews,
            meta: { displayedTitle: "News", needsLoader: true, rights: ["mk_news"] },
          },
          {
            name: "communicationPopups",
            path: "popups",
            component: communicationDashboardPopups,
            meta: { displayedTitle: "Popups", needsLoader: true, rights: ["mk_popup"] },
          },
          {
            name: "communicationPopupsNew",
            path: "popupsnew",
            component: communicationDashboardPopupsNew,
            meta: { displayedTitle: "Popups", needsLoader: false, rights: ["mk_popup"] },
          },
          {
            name: "communicationQRCode",
            path: "qrcode",
            component: communicationDashboardQRCode,
            meta: { displayedTitle: "QR-Code", rights: ["mk_qrcode"] },
          },
          {
            name: "communicationDashboardCampaign",
            path: "campaign",
            component: communicationDashboardCampaign,
            meta: { displayedTitle: "Kampagne", needsLoader: true, rights: ["mk_campaigns"] },
          },
          {
            name: "communicationDashboardPush",
            path: "push",
            component: communicationDashboardPush,
            meta: { displayedTitle: "Push", rights: ["mk_push"] },
          },
          {
            name: "communicationDashboardSlideManager",
            path: "slides",
            component: communicationDashboardSlideManager,
            meta: { displayedTitle: "Slides", needsLoader: true, rights: ["mk_slider"] },
          },
        ],
      },
      /*
			path: '/devices',
		name: 'DeviceList',
		component: DeviceList,
		children: [
			{
				path: 'create',
				name: 'DeviceCreate',
				component: DeviceForm,
				children: [
					{
						path: 'exercise/create',
						name: 'DeviceExerciseCreate',
						component: DeviceExerciseForm,
					},
				],
			},
			
			*/
      {
        name: "devices",
        path: "devices",
        component: devicesDashboard,
        children: [
          {
            name: "devicesDashboardList",
            path: "list",
            component: devicesDashboardList,
            meta: { displayedTitle: "Geräte Verwaltung", needsLoader: true, rights: ["ex_create", "ex_edit", "ex_delete", "ex_transfer"] },
          },
          {
            name: "devicesDashboardForm",
            path: "form",
            component: devicesDashboardForm,
            meta: { rights: ["ex_create", "ex_edit", "ex_delete", "ex_transfer"] },
          },
          {
            name: "devicesDashboardExerciseForm",
            path: "exerciseform",
            component: devicesDashboardExerciseForm,
            meta: { rights: ["ex_create", "ex_edit", "ex_delete", "ex_transfer"] },
          },
        ],
      },
      {
        name: "shop",
        path: "shop",
        component: shopDashboard,
        children: [
          { name: "shopProducts", path: "products", component: shopProducts, meta: { displayedTitle: "Produkte", rights: ["shop_manage"] } },
          {
            name: "shopPurchases",
            path: "purchases",
            component: shopPurchases,
            meta: { displayedTitle: "Einkäufe", rights: ["shop_purchase_view", "shop_manage"] },
          },
        ],
      },
      {
        name: "nutrition",
        path: "nutrition",
        component: nutritionDashboard,
        children: [
          {
            name: "nutritionSearch",
            path: "search",
            component: nutritionSearch,
            meta: { displayedTitle: "Ernährung Suche", rights: ["stat_admin", "nutrition_admin"] },
          },
          {
            name: "nutritionCustomers",
            path: "customers",
            component: nutritionCustomers,
            meta: { displayedTitle: "Kunden", rights: ["stat_admin", "nutrition_admin"] },
          },
        ],
      },
      {
        name: "anamnese",
        path: "anamnese",
        component: anamneseDashboard,
        children: [
          { name: "anamneseDashboardSearch", path: "search", component: anamneseDashboardSearch, meta: { displayedTitle: "Anamnesebögen" } },
          {
            name: "anamneseDashboardSettings",
            path: "settings",
            component: anamneseDashboardSettings,
            meta: { displayedTitle: "Anamnesebogen Einstellungen" },
          },
        ],
      },
      {
        name: "membercard",
        path: "membercard",
        component: membercardDashboard,
        children: [
          {
            name: "membercardDashboardAdmin",
            path: "admin",
            component: membercardDashboardAdmin,
            meta: {
              displayedTitle: "Membercards",
              needsLoader: true,
              rights: ["m_downloadList", "m_signupUser", "m_viewSubs", "m_createSubs", "m_createMembercard"],
            },
          },
        ],
      },

      {
        name: "timestop",
        path: "timestop",
        meta: {},
        component: () => import("@/views/dashboard/timestop/timestopView.vue"),
        childen: [],
      },

      {
        name: "timestopSettings",
        path: "timestop/settings",
        meta: {},
        component: () => import("@/views/dashboard/timestop/timestopSettings.vue"),
        childen: [],
      },

      {
        name: "leads",
        path: "leads",
        component: leadsDashboard,
        children: [
          {
            name: "leadsDashboardLeadsDeprecated",
            path: "referral-deprecated",
            component: leadsDashboardLeadsDeprecated,
            meta: {
              displayedTitle: "Weiterempfehlungen (ALT)",
              needsLoader: true,
              rights: ["af_downloadList", "af_view", "af_edit", "af_settingsEdit"],
            },
          },
          {
            name: "leadsDashboardLeadsList",
            path: "leads-list",
            component: leadsDashboardLeadsList,
            meta: { displayedTitle: "Anfragen Liste", needsLoader: true, rights: ["af_downloadList", "af_view", "af_edit", "af_settingsEdit"] },
          },
          {
            name: "leadsDashboardReferralList",
            path: "referral-list",
            component: leadsDashboardReferralList,
            meta: {
              displayedTitle: "Weiterempfehlungen Liste",
              needsLoader: true,
              rights: ["af_downloadList", "af_view", "af_edit", "af_settingsEdit"],
            },
          },
          {
            path: "cost",
            name: "leadsCostManager",
            meta: { displayedTitle: "Lead Kosten", rights: ["af_cost"] },
            component: leadsCostManager,
          },
          {
            path: "all",
            name: "leadsDashboardAllLeads",
            meta: { displayedTitle: "Alle Anfragen", rights: ["af_downloadList", "af_view", "af_edit", "af_settingsEdit"] },
            component: () => import("@/views/dashboard/leads/leadsDashboardAllLeads.vue"),
          },
          {
            name: "leadsDashboardReferralSettings",
            path: "referral-settings",
            component: leadsDashboardReferralSettings,
            meta: { displayedTitle: "Anfragen Einstellungen", needsLoader: true, rights: ["af_settingsEdit"] },
          },
          {
            name: "leadsDashboardSettings",
            path: "leads-settings",
            component: leadsDashboardSettings,
            meta: { displayedTitle: "Weiterempfehlungen Einstellungen", needsLoader: true, rights: ["af_leadSettings"] },
          },
          {
            name: "leadsDashboardCategorySettings",
            path: "category-settings",
            component: leadsDashboardCategorySettings,
            meta: { displayedTitle: "Lead Einstellungen", rights: ["af_leadSettings"] },
            children: [
              {
                name: "leadsDashboardLeadSettings",
                path: ":id",
                component: leadsDashboardLeadSettings,
                meta: { displayedTitle: "Lead Einstellungen", rights: ["af_leadSettings"] },
              },
            ],
          },
        ],
      },
      {
        name: "groupFitness",
        path: "gf",
        component: groupFitnessDashboard,
        children: [
          {
            name: "groupFitnessDashboardRatings",
            path: "ratings",
            component: groupFitnessDashboardRatings,
            meta: { displayedTitle: "Group Fitness Bewertungen", needsLoader: true, rights: ["gf_r_viewAll", "gf_r_threestars"] },
          },
          {
            name: "groupFitnessDashboardCourses",
            path: "courses",
            component: groupFitnessDashboardCourses,
            meta: { displayedTitle: "Group Fitness Kurse", needsLoader: true, rights: ["gf_c_create", "gf_c_edit", "gf_c_delete", "gf_c_plan"] },
          },
          {
            name: "groupFitnessDashboardLabels",
            path: "labels",
            component: groupFitnessDashboardLabels,
            meta: { displayedTitle: "Group Fitness Labels", needsLoader: true, rights: ["l_edit"] },
          },
          {
            name: "groupFitnessDashboardRooms",
            path: "rooms",
            component: groupFitnessDashboardRooms,
            meta: { displayedTitle: "Group Fitness Räume", needsLoader: true, rights: ["r_edit"] },
          },
          {
            name: "groupFitnessDashboardVideos",
            path: "videos",
            component: groupFitnessDashboardVideos,
            meta: { displayedTitle: "Group Fitness Videos", needsLoader: true, rights: ["a_add", "a_delete", "a_edit"] },
          },
          {
            name: "groupFitnessDashboardSettings",
            path: "settings",
            component: groupFitnessDashboardSettings,
            meta: { displayedTitle: "Group Fitness Einstellungen", needsLoader: true, rights: ["gf_c_signupRange"] },
          },
          {
            name: "groupFitnessDashboardDownloads",
            path: "downloads",
            component: groupFitnessDashboardDownloads,
            meta: { displayedTitle: "Group Fitness Downloads", needsLoader: true, rights: ["gf_c_plan", "gf_cp_instructor"] },
          },
          {
            name: "groupFitnessDashboardEventHome",
            path: "event",
            rights: ["gf_c_create", "gf_c_edit", "gf_c_delete", "gf_c_plan"],
            component: groupFitnessDashboardEventHome,
            children: [
              {
                name: "groupFitnessDashboardEventForm",
                path: "create",
                component: groupFitnessDashboardEventForm,
                meta: { displayedTitle: "Group Fitness Event erstellen", rights: ["gf_c_create", "gf_c_edit", "gf_c_delete", "gf_c_plan"] },
              },
              {
                name: "groupFitnessDashboardEventForm",
                path: "edit/:id",
                component: groupFitnessDashboardEventForm,
                meta: { displayedTitle: "Group Fitness Event bearbeiten", rights: ["gf_c_create", "gf_c_edit", "gf_c_delete", "gf_c_plan"] },
              },
            ],
          },
        ],
      },
      {
        name: "user",
        path: "user",
        component: userDashboard,
        children: [
          {
            name: "userDashboardSearch",
            path: "search",
            component: userDashboardSearch,
            meta: { displayedTitle: "Kundensuche", rights: ["u_search"] },
          },
          {
            name: "userDashboardCustomerSelected",
            path: "search/:id",
            component: userDashboardCustomer,
            meta: { displayedTitle: "Kundensuche", rights: ["u_search"] },
          },
          {
            name: "userDashboardRightList",
            path: "rightlist",
            component: userDashboardRightList,
            meta: { displayedTitle: "Rechte Liste", rights: ["u_rightList"] },
          },
        ],
      },
    ],
  },
]
