import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  PTSignOff() {
    let url = buildUrl(`terminplanerpt/signoff`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  PTcheck() {
    let url = buildUrl(`terminplanerpt/check`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  PTdeleteDate(id) {
    let url = buildUrl(`terminplanerpt/delete/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  PTsignUp(id) {
    let url = buildUrl(`terminplanerpt/signup/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  PTgetDates(studioid) {
    let url = buildUrl(`terminplanerpt/termine/${studioid}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  PTAddDate(date) {
    let url = buildUrl(`terminplanerpt/new`)
    return axios.post(url, date, buildOptions()).then((res) => res.data)
  },
  PTgetIntro() {
    let url = buildUrl(`terminplanerpt/introtext`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  PTEditIntro(data) {
    let url = buildUrl(`terminplanerpt/introtext`)
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  TPSignOff() {
    let url = buildUrl(`terminplaner/signoff`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  TPcheck() {
    let url = buildUrl(`terminplaner/check`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  TPdeleteDate(id) {
    let url = buildUrl(`terminplaner/delete/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  TPsignUp(id) {
    let url = buildUrl(`terminplaner/signup/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  TPgetDates(studioid) {
    let url = buildUrl(`terminplaner/termine/${studioid}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  TPAddDate(date) {
    let url = buildUrl(`terminplaner/new`)
    return axios.post(url, date, buildOptions()).then((res) => res.data)
  },
  TPgetIntro() {
    let url = buildUrl(`terminplaner/introtext`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  TPEditIntro(data) {
    let url = buildUrl(`terminplaner/introtext`)
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
}
