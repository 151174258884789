<template>
  <div style="width: 100%">
    <user-dashboard-nutrition-form
      :user="user"
      :wrongCoach="wrongCoach"
      @saved="reFetchDashboard"
      :allergenFilters="allergenFilters"
      v-if="!userDashboard"></user-dashboard-nutrition-form>
    <v-row v-else no-gutters>
      <v-col v-if="!anythingEnlarged" cols="4" class="justify-content-center pa-2">
        <user-dashboard-nutrition-weight-chart :user="user"></user-dashboard-nutrition-weight-chart>
      </v-col>

      <v-col
        v-if="!anythingEnlarged || enlargedNotesOverwiew"
        :cols="anythingEnlarged ? 12 : 4"
        class="justify-content-center pa-2"
        :class="{ restrictMaxHeight: !enlargedNotesOverwiew }">
        <user-dashboard-nutrition-notes
          :enlarge="enlargedNotesOverwiew"
          :userId="user._id"
          @toggleEnlarge="enlargedNotesOverwiew = !enlargedNotesOverwiew"
          :notes="userDashboard.notes"></user-dashboard-nutrition-notes>
      </v-col>

      <v-col
        v-if="!anythingEnlarged || enlargedImageOverwiew"
        :cols="anythingEnlarged ? 12 : 4"
        :class="{ restrictMaxHeight: !enlargedImageOverwiew }"
        class="justify-content-center pa-2">
        <user-dashboard-nutrition-images
          @toggleEnlarge="enlargedImageOverwiew = !enlargedImageOverwiew"
          :user="user"
          :enlargedImageOverwiew="enlargedImageOverwiew"
          :images="userDashboard && userDashboard.portionSizes ? userDashboard.portionSizes.images : []"
          :text="userDashboard && userDashboard.portionSizes ? userDashboard.portionSizes.text : ''"></user-dashboard-nutrition-images>
      </v-col>
      <v-col v-if="!anythingEnlarged" cols="4" class="justify-content-center pa-2 restrictMaxHeight">
        <user-dashboard-nutrition-allergens
          :allergens="userDashboard.allergies"
          :diet="userDashboard.diet"
          :possibilities="allergenFilters"
          :user="user"
          @updated="(val) => (userDashboard.diet = val)"></user-dashboard-nutrition-allergens>
      </v-col>
      <v-col v-if="!anythingEnlarged" cols="4" class="justify-content-center pa-2 restrictMaxHeight">
        <user-dashboard-nutrition-anamnese :user="user" :creator="userDashboard.coach"></user-dashboard-nutrition-anamnese>
      </v-col>
      <v-col v-if="!anythingEnlarged" cols="4" class="justify-content-center pa-2 restrictMaxHeight">
        <user-dashboard-nutrition-meal-plan
          :user="user"
          @deleted="userDashboard.mealPlan = null"
          @mealPlanCreated="(mealPlan) => (userDashboard.mealPlan = mealPlan)"
          :mealPlan="userDashboard.mealPlan"
          :difficultyFilters="difficultyFilers"
          :maxTimeFilters="max_timeFilters"
          :allergens="userDashboard.allergies"
          :diet="userDashboard.diet"></user-dashboard-nutrition-meal-plan>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import api from "../../../api"
import userService from "../../../services/userService"
import userSearch from "../../../components/userSearch.vue"

import userDashboardNutritionForm from "./userDashboardNutritionForm.vue"
import userDashboardNutritionWeightChart from "./userDashboardNutritionWeightChart.vue"
import userDashboardNutritionTargets from "./userDashboardNutritionTargets.vue"
import userDashboardNutritionNotes from "./userDashboardNutritionNotes.vue"
import userDashboardNutritionAllergens from "./userDashboardNutritionAllergens.vue"
import userDashboardNutritionImages from "./userDashboardNutritionImages.vue"
import userDashboardNutritionMealPlan from "./userDashboardNutritionMealPlan.vue"
import userDashboardNutritionAnamnese from "./userDashboardNutritionAnamnese.vue"
import userDashboardNutritionCalendar from "./userDashboardNutritionCalendar.vue"

export default {
  components: {
    userSearch,
    userDashboardNutritionForm,
    userDashboardNutritionWeightChart,
    userDashboardNutritionTargets,
    userDashboardNutritionImages,
    userDashboardNutritionAllergens,
    userDashboardNutritionMealPlan,
    userDashboardNutritionAnamnese,
    userDashboardNutritionNotes,
    userDashboardNutritionCalendar,
  },
  props: {
    user: {
      required: true,
    },
  },
  data() {
    return {
      data: null,
      noNutritionDashboard: true,
      allergenFilters: [],
      difficultyFilers: [],
      wrongCoach: null,
      max_timeFilters: [],
      userDashboard: null,
      enlargedImageOverwiew: false,
      enlargedNotesOverwiew: false,
    }
  },
  async created() {
    try {
      let allFilters = await api.getNutritionSearchFilters()
      let data = await api.loadCustomerDashboardCoach(this.user._id)
      if (data.email) {
        this.wrongCoach = data
        this.$toast.error("Nicht der zugewiesene Coach.")
      } else {
        this.userDashboard = data
      }
      this.loading = false
      if (allFilters.data && allFilters.data.filter) {
        this.allergenFilters = Object.entries(allFilters.data.filter.allergen.value).map((entry) => {
          return { text: entry[1], value: entry[0] }
        })
        this.difficultyFilers = Object.entries(allFilters.data.filter.difficulty.value).map((entry) => {
          return { text: entry[1], value: entry[0] }
        })
        this.max_timeFilters = Object.entries(allFilters.data.filter.max_time.value).map((entry) => {
          return { text: entry[1], value: entry[0] }
        })
      }
      console.log(this.allergenFilters)
      this.$emit("loaded")
    } catch (e) {
      console.log(e.message)
      this.$toast.error("Fehler beim laden des Ernährungs Dashboards")
    }
  },
  methods: {
    async reFetchDashboard() {
      this.$emit("reFetchUser")
      this.userDashboard = await api.loadCustomerDashboardCoach(this.user._id)
    },
  },
  computed: {
    anythingEnlarged() {
      return this.enlargedImageOverwiew || this.enlargedNotesOverwiew
    },
  },
}
</script>
<style scoped>
.restrictMaxHeight {
  max-height: 300px;
}
</style>
