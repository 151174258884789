<template>
  <v-card dark elevation="0">
    <v-toolbar flat dark>
      <v-toolbar-title>
        <span>Zusatzpakete Statistik</span>
        <v-btn class="ml-5" @click="downloadCSV" color="primary" dark> CSV </v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <!-- Auswahl der angezeigten Pakete -->
    <v-select v-model="selectedPackages" :items="allPackageNames" label="Angezeigte Pakete" multiple clearable class="ma-4" dark></v-select>

    <!-- Suchfeld -->
    <v-text-field class="ma-4" dark v-model="search" label="Suche nach Studios" clearable></v-text-field>

    <!-- Data Table -->
    <v-data-table
      dark
      :headers="headers"
      :items="filteredTableItems"
      :loading="loading"
      loading-text="Lade Daten..."
      class="elevation-1"
      :disable-pagination="true">
      <!-- Totalzeile am Ende der Tabelle -->
      <template v-slot:body.append>
        <tr class="total-row">
          <td><strong>Total</strong></td>
          <td v-for="pkg in allPackageNames" :key="pkg">
            <strong>{{ totals[pkg] }}</strong>
          </td>
          <td>
            <strong>{{ overallTotal }}</strong>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from "../../../api"

export default {
  props: {
    datePickerRange: {
      type: Array,
      default: () => [],
    },
    studios: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      firstLoad: true,
      data: null,
      selectedPackages: null, // Standard: Alle Pakete anzeigen
      selectedDisplay: "both", // Standard: Beide Werte anzeigen
      displayOptions: [
        { text: "Alle", value: "both" },
        { text: "Neu Abschlüsse", value: "new" },
        { text: "Erneuerungen", value: "renewal" },
      ],
      search: "", // Suchfeld für Studios
    }
  },
  computed: {
    // Ermittelt alle vorkommenden Pakete aus allen Studios und sortiert sie alphabetisch
    allPackageNames() {
      if (!this.data) return []
      const packageSet = new Set()
      this.data.forEach((studioEntry) => {
        studioEntry.Packages.forEach((pkg) => {
          packageSet.add(pkg.NAME)
        })
      })
      const sortedPackages = Array.from(packageSet).sort((a, b) => a.localeCompare(b))
      // selectedPackages immer an den aktuellen Stand anpassen
      this.selectedPackages = sortedPackages
      return sortedPackages
    },
    /* allPackageNames() {
      if (!this.data) return []
      const packageSet = new Set()
      this.data.forEach((studioEntry) => {
        studioEntry.Packages.forEach((pkg) => {
          packageSet.add(pkg.NAME)
        })
      })

      if (this.firstLoad) {
        this.selectedPackages = Array.from(packageSet)
        this.firstLoad = false
      }

      return Array.from(packageSet).sort((a, b) => a.localeCompare(b))
    },*/

    // Dynamische Spalten basierend auf den Paket-Namen
    headers() {
      /* const baseHeaders = [{ text: 'Studio', value: 'Studio' }];
            const packages = (this.selectedPackages || this.allPackageNames).map((pkgName) => ({
                text: pkgName,
                value: pkgName,
            }));
            baseHeaders.push({ text: 'Total', value: 'Total' });
            return [...baseHeaders, ...packages];*/

      const baseHeaders = [{ text: "Studio", value: "Studio" }]
      const packages = (this.selectedPackages || this.allPackageNames).map((pkgName) => ({
        text: pkgName,
        value: pkgName,
      }))
      return [...baseHeaders, ...packages, { text: "Total", value: "Total" }]
    },

    // Aufbereitung der Zeilen für das Data-Table
    tableItems() {
      if (!this.data) return []

      return this.data.map((studioEntry) => {
        const row = {
          Studio: studioEntry.Studio,
        }

        const pkgMap = {}
        studioEntry.Packages.forEach((pkg) => {
          pkgMap[pkg.NAME] = {
            NewCount: pkg.NewCount,
            RenewalCount: pkg.RenewalCount,
          }
        })

        let studioTotal = 0

        ;(this.selectedPackages || this.allPackageNames).forEach((pkgName) => {
          const pkgData = pkgMap[pkgName]
          let displayValue

          if (!pkgData) {
            displayValue = 0
          } else {
            switch (this.selectedDisplay) {
              case "new":
                displayValue = pkgData.NewCount
                break
              case "renewal":
                displayValue = pkgData.RenewalCount
                break
              default:
                displayValue = pkgData.NewCount + pkgData.RenewalCount
                break
            }
          }

          row[pkgName] = displayValue
          studioTotal += displayValue
        })

        row.Total = studioTotal
        return row
      })
    },

    // Gefilterte Zeilen basierend auf der Suche
    filteredTableItems() {
      if (!this.data) return []

      let items = this.tableItems

      if (this.search.trim() !== "") {
        const terms = this.search.trim().toLowerCase().split(/\s+/)
        items = items.filter((item) => {
          const studioName = item.Studio.toLowerCase()
          return terms.some((term) => studioName.includes(term))
        })
      }

      return items
    },

    // Berechnet die Totalwerte basierend auf den gefilterten Zeilen
    totals() {
      const totals = {}
      ;(this.selectedPackages || this.allPackageNames).forEach((pkgName) => {
        let total = 0
        this.filteredTableItems.forEach((item) => {
          const value = item[pkgName]
          total += Number(value) || 0
        })
        totals[pkgName] = total
      })
      return totals
    },

    // Berechnet die Gesamtsumme aller Pakete
    overallTotal() {
      return Object.values(this.totals).reduce((acc, val) => acc + val, 0)
    },
  },
  async created() {
    await this.fetchContractsByStatus()
  },
  methods: {
    async fetchContractsByStatus() {
      if (this.loading) {
        return this.$toast("Paket Statistiken werden noch geladen. Bitte kurz warten.")
      }
      if (this.datePickerRange[0] && this.datePickerRange[1]) {
        this.loading = true
        try {
          this.data = await api.fetchContractPackagesStats({
            range: this.datePickerRange,
            studios: this.studios.map((s) => s._id),
          })
        } catch (err) {
          console.error(err)
          this.$toast("Fehler beim Laden der Vertragsdaten.")
        } finally {
          this.loading = false
        }
      }
    },

    downloadCSV() {
      if (!this.data) {
        this.$toast("Keine Daten zum Herunterladen vorhanden.")
        return
      }

      const packages = this.allPackageNames

      const headers = ["Studio"]
      packages.forEach((pkg) => {
        headers.push(pkg)
      })
      headers.push("Total")

      const rows = this.data.map((studioEntry) => {
        const row = [studioEntry.Studio]
        let studioTotal = 0
        packages.forEach((pkg) => {
          const pkgData = studioEntry.Packages.find((p) => p.NAME === pkg)
          const value = pkgData ? pkgData.NewCount + pkgData.RenewalCount : 0
          row.push(value)
          studioTotal += value
        })
        row.push(studioTotal)
        return row
      })

      const totalRow = ["Total"]
      let overallTotal = 0
      packages.forEach((pkg) => {
        let pkgTotal = 0
        this.data.forEach((studioEntry) => {
          const pkgData = studioEntry.Packages.find((p) => p.NAME === pkg)
          if (pkgData) {
            pkgTotal += pkgData.NewCount + pkgData.RenewalCount
          }
        })
        totalRow.push(pkgTotal)
        overallTotal += pkgTotal
      })
      totalRow.push(overallTotal)

      const csvContent = [headers, ...rows, totalRow].map((row) => row.join(",")).join("\n")

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
      const url = URL.createObjectURL(blob)

      const link = document.createElement("a")
      link.setAttribute("href", url)
      link.setAttribute("download", "zusatzpakete_statistik.csv")
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
  watch: {
    async datePickerRange() {
      await this.fetchContractsByStatus()
    },
    async studios() {
      await this.fetchContractsByStatus()
    },
  },
}
</script>

<style scoped>
tr.total-row {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
