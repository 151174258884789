<template>
  <v-card color="transparent" elevation="0">
    <v-row>
      <!--    <v-col cols="12">
                <v-card
                    dark
                    hover
                    class="rounded pa-2 d-flex fill-height"
                    style="background: linear-gradient(90deg, #b7f8db 0%, #50a7c2 100%)">
                    <v-col class="h4 d-flex flex-column font-weight-bold align-self-center mb-0"
                        ><number from="0" :to="dashboardContract.length"></number> Erneuerungen</v-col
                    >
                    <v-col v-if="!finishedLoading" class="d-flex justify-center flex-grow">
                        <v-progress-circular indeterminate color="white"></v-progress-circular>
                    </v-col>
                    <apexchart v-else height="80" :series="categorySeries" :options="contractSparkCharts"></apexchart>
                </v-card>
            </v-col> -->
      <v-col :cols="$vuetify.breakpoint.mobile ? '12' : 'auto'">
        <v-card
          dark
          @click="
            () => {
              showTerminationsDetails = true
            }
          "
          class="rounded pa-2 d-flex fill-height"
          style="background: linear-gradient(90deg, #f44336 0%, #e91e63 100%)">
          <v-col class="h4 d-flex flex-column font-weight-bold align-self-center mb-0">
            <number v-if="data && data.totalTerminations" from="0" :to="data.totalTerminations"></number>
            <span v-else>Keine</span>
            Kündigungen
          </v-col>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? '12' : 'auto'">
        <v-card
          dark
          @click="
            () => {
              if (userService.hasRight('stat_admin')) {
                showTerminationsDialog = true
                dialogType = 'growth'
              }
            }
          "
          class="rounded pa-2 d-flex fill-height"
          style="background: linear-gradient(90deg, #ffd500 0%, #ff5700 100%)">
          <v-col class="h4 d-flex flex-column font-weight-bold align-self-center mb-0">
            <number v-if="data && data.totalGrowth" from="0" :to="data.totalGrowth"></number>
            <span v-else>Kein gewünschtes</span>
            Wachstumsziel
          </v-col>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? '12' : 'auto'">
        <!-- @click="
                        () => {
                            if (userService.hasRight('stat_admin')) {
                                showTerminationsDialog = true
                                dialogType = 'correction'
                            }
                        }
                    " -->
        <v-card dark class="rounded pa-2 d-flex fill-height" style="background: linear-gradient(90deg, #ffd500 0%, #ff5700 100%)">
          <v-col class="h4 d-flex flex-column font-weight-bold align-self-center mb-0">
            <number v-if="data && data.contractsCount" from="0" :to="data.contractsCount"></number>
            Neu Verträge
          </v-col>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? '12' : 'auto'">
        <v-card
          dark
          @click="
            () => {
              if (userService.hasRight('stat_admin')) {
                showTerminationsDialog = true
                dialogType = 'correction'
              }
            }
          "
          class="rounded pa-2 d-flex fill-height"
          style="background: linear-gradient(90deg, #ffd500 0%, #ff5700 100%)">
          <v-col class="h4 d-flex flex-column font-weight-bold align-self-center mb-0">
            <number v-if="data && data.publicContractsTotal" from="0" :to="data.publicContractsTotal"></number>
            <span v-else>Keine</span>
            Online Verträge
          </v-col>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? '12' : 'auto'">
        <v-card
          dark
          class="rounded pa-2 d-flex fill-height"
          :style="
            contractsNettoCount < 0
              ? 'background: linear-gradient(90deg, #e52d27 0%, #b31217 100%)'
              : 'background: linear-gradient(90deg, #0cd653 0%, #07b31b 100%)'
          ">
          <v-col class="h4 d-flex flex-column font-weight-bold align-self-center mb-0">
            <number v-if="data && contractsNettoCount" from="0" :to="contractsNettoCount"></number>
            <span v-else>Kein berechneter</span>
            Veträge Ist-Zustand
          </v-col>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? '12' : 'auto'">
        <v-card dark class="rounded pa-2 d-flex fill-height" style="background: linear-gradient(90deg, #0cd653 0%, #07b31b 100%)">
          <v-col class="h4 d-flex flex-column font-weight-bold align-self-center mb-0">
            <span v-if="data && data.averagePrice">
              <number from="0" :to="data.averagePrice"></number>
              CHF
            </span>
            <span v-else>Kein berechneter</span>
            Ø Pro Vertrag
          </v-col>
        </v-card>
      </v-col>
      <v-card-title class="white--text">ROODY VERTRAGSSTATISTIKEN</v-card-title>
      <v-col v-if="finishedLoading" cols="12">
        <apexchart
          height="500"
          v-if="data.packagesMapped"
          :series="[{ name: 'Pakete', data: data.packagesMapped.map((pack) => pack.y) }]"
          :options="packagesPieChart"></apexchart>
      </v-col>

      <v-col v-if="finishedLoading" cols="12">
        <apexchart
          height="500"
          v-if="data.membershipsMapped"
          :series="[{ name: 'Pakete', data: data.membershipsMapped.map((ship) => ship.y) }]"
          :options="membershipPackagesPieChart"></apexchart>
      </v-col>

      <v-col v-if="finishedLoading" :cols="$vuetify.breakpoint.mobile ? '12' : '6'">
        <apexchart
          height="500"
          v-if="data.categoriesMapped"
          :series="data.categoriesMapped.map((ship) => ship.y)"
          :options="categoryPackagesPieChart"></apexchart>
      </v-col>

      <v-col v-if="finishedLoading" :cols="$vuetify.breakpoint.mobile ? '12' : '6'">
        <apexchart
          height="500"
          v-if="data.membershipsMapped"
          :series="data.membershipsMapped.map((ship) => ship.y)"
          :options="studioSalesPieChart"></apexchart>
      </v-col>
      <v-col v-if="finishedLoading" cols="12">
        <apexchart
          height="500"
          v-if="data.packagesMapped"
          :series="data.packagesMapped.map((ship) => ship.y)"
          :options="packagesMappedPieChart"></apexchart>
      </v-col>

      <v-col v-if="finishedLoading && data.completeChartRoody" cols="12">
        <apexchart height="500" :series="data.completeChartRoody" :options="contractMultipleLineChart"></apexchart>
      </v-col>
      <v-row v-if="false">
        <v-col cols="12">
          <v-card-title class="white--text">TAC VERTRAGSSTATISTIKEN</v-card-title>
        </v-col>

        <v-col v-if="false && finishedLoading && data.completeChart" cols="12">
          <apexchart height="500" :series="data.completeChart" :options="contractMultipleLineChart"></apexchart>
        </v-col>
        <v-col v-if="finishedLoading && data.relationToTACCustomers" cols="12">
          <apexchart height="500" :series="[data.relationToTACCustomers[0]]" :options="chartInRelationToCustomersOptionsTerminations"></apexchart>
        </v-col>
        <v-col v-if="finishedLoading && data.relationToTACCustomers" cols="12">
          <apexchart height="500" :series="[data.relationToTACCustomers[1]]" :options="chartInRelationToCustomersOptionsGrowth"></apexchart>
        </v-col>
      </v-row>
      <v-col v-if="!finishedLoading" class="d-flex justify-center flex-grow">
        <v-progress-circular indeterminate color="white"></v-progress-circular>
      </v-col>
    </v-row>
    <home-dashboard-contract-terminations-dialog
      :dialogActive="showTerminationsDialog"
      :studios="studios"
      :dialogType="dialogType"
      @closeDialog="showTerminationsDialog = false"></home-dashboard-contract-terminations-dialog>
    <home-dashboard-contract-termination-details-dialog
      v-if="data"
      :dialogActive="showTerminationsDetails"
      :studios="studios"
      :terminationAmounts="data.terminations.amount"
      :reasons="data.terminations.mappedDetails"
      @closeDialog="showTerminationsDetails = false"></home-dashboard-contract-termination-details-dialog>
  </v-card>
</template>
<script>
import api from "../../../api"

import homeDashboardContractTerminationsDialog from "./homeDashboardContractTerminationsDialog.vue"
import homeDashboardContractTerminationDetailsDialog from "./homeDashboardContractTerminationDetailsDialog.vue"
import userService from "../../../services/userService"

export default {
  components: { homeDashboardContractTerminationsDialog, homeDashboardContractTerminationDetailsDialog },
  props: {
    datePickerRange: {
      type: Array,
      default: () => [],
    },
    studios: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartColors: [
        "#004400", // Dunkles Grün
        "#1d791d",
        "#3aae3a",
        "#66ff66", // Helles Grün
        "#57dae4",
        "#2b6d94",
        "#0e245e",
        "#000044", // Dunkles Blau
        "#09003a",
        "#130030",
        "#26001d",
        "#300013",
        "#440000", // Dunkles Rot
      ],
      data: null,
      showTerminationsDetails: false,
      userService: userService,
      showDialog: { active: false, infos: { userId: null, index: null, text: "" } },
      allContracts: [],
      pieChartlabels: [],
      categoryPieChartlabels: [],
      membershipPieChartlabels: [],
      studioSalesPieChartLabels: [],
      finishedLoading: false,
      showTerminationsDialog: false,
      terminationsCount: 0,
      growCount: 0,
      correctionCount: 0,
      newContractsCount: 0,
      dashboardContract: [],
      dialogType: "terminations",
      tacCustomerCount: null,
      realtionToCustomersExtraInfos: [],
      terminations: null,
    }
  },
  async created() {
    this.fetchContractsByStatus("accepted")
  },
  methods: {
    async fetchContractsByStatus(status) {
      if (this.datePickerRange[0] && this.datePickerRange[1]) {
        this.finishedLoading = false
        this.data = await api.fetchContractUserInfos({
          range: this.datePickerRange,
          studios: this.studios.map((s) => s._id),
        })
        this.terminations = this.data.terminations
        this.finishedLoading = true
      }
    },
  },
  computed: {
    packagesPieChart() {
      return {
        chart: {
          foreColor: "#ccc",

          type: "bar",
        },
        colors: this.chartColors,
        labels: this.data.packagesMapped.map((data) => data.x),
      }
    },
    categoryPackagesPieChart() {
      return {
        chart: {
          foreColor: "#ccc",

          type: "pie",
        },
        fill: {
          opacity: 0.7,
        },
        labels: this.data.categoriesMapped.map((data) => data.name),
      }
    },
    packagesMappedPieChart() {
      return {
        chart: {
          foreColor: "#ccc",

          type: "pie",
        },
        fill: {
          opacity: 0.7,
        },
        labels: this.data.packagesMapped.map((data) => data.x),
      }
    },
    membershipPackagesPieChart() {
      return {
        chart: {
          foreColor: "#ccc",

          type: "bar",
        },
        colors: this.chartColors,
        labels: this.data.membershipsMapped.map((data) => data.x),
      }
    },
    studioSalesPieChart() {
      return {
        chart: {
          foreColor: "#ccc",

          type: "pie",
        },
        fill: {
          opacity: 0.7,
        },
        labels: this.data.membershipsMapped.map((data) => data.x),
      }
    },
    chartInRelationToCustomersOptionsTerminations() {
      const self = this
      return {
        xaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: 700,
              colors: this.studios.map((studio) => {
                return "#fff"
              }),
            },
          },
        },
        colors: ["#ff0004"],
        grid: {
          borderColor: "#535A6C",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%"
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 7.55,
          },
        },
        chart: {
          type: "bar",
          foreColor: "#ccc",
        },
        fill: {
          opacity: 0.7,
        },
        tooltip: {
          x: {
            show: false,
          },
          marker: {
            show: false,
          },

          y: {
            title: {
              formatter: function () {
                return ""
              },
            },
            formatter: function (val, { seriesIndex, dataPointIndex, w }) {
              if (self.data.returnDataContracsNewCountExtraInfos) {
                let values = []
                if (seriesIndex === 0) {
                  values = self.data.returnDataTerminationsExtraInfos[dataPointIndex]
                } else {
                  values = self.data.returnDataContracsNewCountExtraInfos[dataPointIndex]
                }
                if (seriesIndex === 1) {
                  return `<br> Kunden Insgesammt: ${values.allUserCount} <br> neu Abschlüsse: ${
                    values.count + values.correctionCount
                  } <br> Kündigungen ${values.terminationsCount}`
                } else {
                  return `<br> Kunden Enddatum in ausgewählter Zeitspanne: ${values.userCount} <br> Kündigungen: ${values.terminationsCount}`
                }
              } else {
                return `${val}%`
              }
            },
          },
          theme: "dark",
        },
      }
    },
    chartInRelationToCustomersOptionsGrowth() {
      const self = this
      return {
        xaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: 700,
              colors: this.studios.map((studio) => {
                return "#fff"
              }),
            },
          },
        },
        colors: ["#ffae00"],
        grid: {
          borderColor: "#535A6C",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%"
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 7.55,
          },
        },
        chart: {
          type: "bar",
          foreColor: "#ccc",
        },
        fill: {
          opacity: 0.7,
        },
        tooltip: {
          x: {
            show: false,
          },
          marker: {
            show: false,
          },

          y: {
            title: {
              formatter: function () {
                return ""
              },
            },
            formatter: function (val, { seriesIndex, dataPointIndex, w }) {
              if (self.data.returnDataContracsNewCountExtraInfos) {
                let values = []
                if (seriesIndex === 1) {
                  values = self.data.returnDataTerminationsExtraInfos[dataPointIndex]
                } else {
                  values = self.data.returnDataContracsNewCountExtraInfos[dataPointIndex]
                }
                if (seriesIndex === 0) {
                  return `<br> Kunden Insgesammt: ${values.allUserCount} <br> neu Abschlüsse: ${
                    values.count + values.correctionCount
                  } <br> Kündigungen ${values.terminationsCount}`
                } else {
                  return `<br> Kunden Enddatum in ausgewählter Zeitspanne: ${values.userCount} <br> Kündigungen: ${values.terminationsCount}`
                }
              } else {
                return `${val}%`
              }
            },
          },
          theme: "dark",
        },
      }
    },
    contractMultipleLineChart() {
      return {
        colors: ["#ff0004", "#ffae00", this.$vuetify.theme.themes.light.primary],
        grid: {
          borderColor: "#535A6C",
        },
        plotOptions: {
          bar: {
            borderRadius: 7.55,
            dataLabels: {
              total: {
                enabled: false,
                formatter: (val, item) => {
                  return val
                },
              },
            },
            borderRadiusWhenStacked: "all",
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 2],
        },
        fill: {
          opacity: 0.7,
        },
        chart: {
          foreColor: "#ccc",
          stacked: true,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: 700,
              colors: this.studios.map((studio) => {
                return "#fff"
              }),
            },
          },
        },
        tooltip: {
          theme: "dark",
        },
      }
    },
    contractsNettoCount() {
      if (!this.data) return 0
      return this.data.contractsCount + this.data.totalCorrections - this.data.totalGrowth - this.data.totalTerminations
    },
  },
  watch: {
    async datePickerRange() {
      await this.fetchContractsByStatus()
    },
    async studios() {
      await this.fetchContractsByStatus()
    },
  },
}
</script>
<style lang="scss" scoped>
.fivecolscustom {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}
</style>
