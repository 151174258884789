import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  loadCovidQueueAll(course_ids) {
    let url = buildUrl("kurse/covidlistall")
    return axios.post(url, { course_generate_ids: course_ids }, buildOptions()).then((res) => res.data)
  },
  loadCovidQueue() {
    let url = buildUrl("kurse/covidlist")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  removeUserFromCovidGroup(timeline_id, user_id) {
    let url = buildUrl(`covidtimelines/` + timeline_id + `/` + user_id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  moveFromQueueToCovidGroup(timeline_id, user_id, generate_id) {
    let url = buildUrl(`covidtimelines/` + timeline_id + `/add/` + user_id + `/` + generate_id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  signoffCovidQueue(timeline_id) {
    let url = buildUrl(`covidtimelines/leave/` + timeline_id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  fetchCovidQueueForCourse(course_id) {
    let url = buildUrl(`kurse/covidlist/` + course_id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchFrequentlyVisitingUsers(course_id) {
    let url = buildUrl(`covidtimelines/` + course_id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  loadCertStatus() {
    let url = buildUrl("user/cert/status")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  viewedCertCheck() {
    let url = buildUrl("user/cert/viewed/check")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fakeCert(userid) {
    let url = buildUrl("user/cert/create/" + userid)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  invalidUserCert(userid) {
    let url = buildUrl("user/cert/invalid/" + userid)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  checkUserCert(userid) {
    let url = buildUrl("user/cert/check/" + userid)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getUserCertsArchive() {
    let url = buildUrl(`user/certs/archive`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getUserCerts() {
    let url = buildUrl(`user/certs`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  uploadUserCert(formData) {
    let url = buildUrl(`user/cert`)
    return axios.post(url, formData, buildOptions({ headers: { "Content-Type": "multipart/form-data" } })).then((res) => res.data)
  },
  downloadBLSAEDZip() {
    return new Promise(async (resolve, reject) => {
      let url = buildUrl("blsaedzip")
      try {
        const response = await axios({
          url,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })

        if (window.flutter_inappwebview && localStorage.getItem("isIOS")) {
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]
            resolve(base64.toString())
          }
        } else {
          saveAs(response.data, "BLS_AED")
          resolve() // Resolve without a value since the download is not async
        }
      } catch (error) {
        console.log(error, "FEHLER")
        reject(error)
      }
    })
  },
  uploadBLSAEDFile(formData) {
    let url = buildUrl(`upload/blsaed`)
    return axios.post(url, formData, buildOptions({ headers: { "Content-Type": "multipart/form-data" } })).then((res) => res.data)
  },
  downloadBLSAEDFile(fileName) {
    return new Promise(async (resolve, reject) => {
      let url = buildUrl(fileName)
      try {
        const response = await axios({
          url,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })

        if (window.flutter_inappwebview && localStorage.getItem("isIOS")) {
          var reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onloadend = function () {
            var dataUrl = reader.result
            var base64 = dataUrl.split(",")[1]
            resolve(base64.toString())
          }
        } else {
          saveAs(response.data, "BLS_AED")
          resolve() // Resolve without a value since the download is not async
        }
      } catch (error) {
        console.log(error, "FEHLER")
        reject(error)
      }
    })
  },
  deleteCert() {
    let url = buildUrl("user/cert/delete")

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  downloadCertForUser(userid) {
    let url = buildUrl("user/cert/download/" + userid)
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then((res) => res.data)
  },
  downloadCert() {
    let url = buildUrl("user/cert/download")
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then((res) => res.data)
  },
  getUserCert() {
    let url = buildUrl("user/cert")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
