<template>
  <div class="darkbackground">
    <div class="dashboard" v-if="$route.meta.statistics || $route.meta.studioOnly || $route.meta.datePickerOnly">
      <v-row>
        <v-col class="d-flex">
          <v-btn large color="white" style="align-self: center" v-if="$vuetify.breakpoint.mobile" icon @click="toggleNavbar()">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <lottie-player
            v-else
            mode="normal"
            @click="$router.push('/dashboard')"
            style="height: 100px; width: 120px"
            name="roody_icon"
            autoplay
            src="/img/lottiefiles/roody_animated.json"></lottie-player>
          <v-card-title
            class="white--text h4"
            :class="{ h1: !$vuetify.breakpoint.mobile }"
            style="word-break: break-word; font-weight: bold; letter-spacing: 1px ma-0">
            {{ $route.meta.displayedTitle }}
          </v-card-title>
        </v-col>
        <v-col cols="auto">
          <v-menu
            v-if="$route.meta.datePickerOnly || ($route.meta.statistics && !$route.meta.hideRange)"
            ref="menu"
            dark
            v-model="datePickerMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dark filled :value="formattedDatePickerRange" label="Zeitraum" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker
              locale="de-CH"
              ref="datePicker"
              range
              :type="$route.meta.monthsOnly ? 'month' : 'date'"
              v-model="datePickerRange"
              @update:active-picker="activePickerChanged"
              @change="$refs.menu.save(datePickerRange)"
              no-title
              scrollable></v-date-picker>
          </v-menu>
          <v-select
            small-chips
            dark
            filled
            chips
            label="Studios"
            multiple
            item-text="name"
            item-value="_id"
            v-model="selectedStudios"
            :items="selectStudioItems"
            v-if="
              (studios.length > 1 || (teams.length && $route.meta.includeTeams)) &&
              $route.meta.multiStudio &&
              ($route.meta.statistics || $route.meta.studioOnly)
            ">
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>
                  {{ item.name }}
                </span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption"> (+{{ selectedStudios.length - 1 }} weitere...) </span>
            </template>
          </v-select>
          <v-select
            small-chips
            dark
            filled
            chips
            label="Studio"
            item-text="name"
            item-value="_id"
            v-model="selectedStudioSingle"
            :items="selectStudioItems"
            v-if="
              (studios.length > 1 || (teams.length && $route.meta.includeTeams)) &&
              !$route.meta.multiStudio &&
              ($route.meta.statistics || $route.meta.studioOnly)
            ">
            <template v-slot:item="{ item }">
              <span>
                {{ item.name }}
                <span class="orange--text" v-if="item.studio"> (Center {{ studios.find((std) => std._id === item.studio).name }}) </span>
              </span>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>
                  {{ item.name }}
                  <span class="orange--text" v-if="item.studio"> (Center {{ studios.find((std) => std._id === item.studio).name }}) </span>
                </span>
              </v-chip>
            </template>
          </v-select>
          <v-btn
            v-if="studios.length > 1 && $route.meta.multiStudio && ($route.meta.statistics || $route.meta.studioOnly)"
            @click="toggleStudioSelection"
            class="white--text mt-0 pt-0 mb-5"
            text>
            {{ selectedStudios.length === studios.length ? "alle Studios abwählen" : "alle Studios anwählen" }}
          </v-btn>
        </v-col>
      </v-row>
      <router-view :datePickerRange="datePickerRange" :studios="filteredStudios" :selectedStudioSingle="selectedStudioSingle"></router-view>
    </div>
    <div v-else>
      <v-row v-if="!loading">
        <v-col cols="12" class="d-flex" :style="$route.name === 'dashboardHome' && !$vuetify.breakpoint.mobile ? 'place-content: center;' : ''">
          <v-btn large color="white" style="align-self: center" v-if="$vuetify.breakpoint.mobile" icon @click="toggleNavbar()">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <lottie-player
            v-else
            mode="normal"
            :style="$route.name === 'dashboardHome' && !$vuetify.breakpoint.mobile ? 'height: 140px; width: 180px' : 'height: 80px; width: 100px'"
            name="roody_icon"
            autoplay
            src="/img/lottiefiles/roody_animated.json"></lottie-player>
          <v-card-title
            v-if="$route.meta.displayedTitle"
            class="white--text font-weight-bold h4 mb-0"
            style="word-break: break-word"
            :class="{ h1: !$vuetify.breakpoint.mobile }">
            {{ $route.meta.displayedTitle }}
          </v-card-title>
        </v-col>
      </v-row>
      <v-col
        :key="loading"
        v-if="loading"
        class="d-flex flex-column"
        style="margin-top: 150px; align-items: center; position: absolute; top: 40%"
        cols="12">
        <lottie-player
          @click="$router.push('/dashboard')"
          mode="normal"
          style="height: 130px; width: 150px"
          name="roody_icon"
          autoplay
          loop
          src="/img/lottiefiles/roody_animated.json"></lottie-player>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
      <div v-if="$route.name === 'dashboardHome'" class="white--text">
        <v-card-title v-if="false" :class="{ h4: $vuetify.breakpoint.mobile }" class="h1 font-weight-bold white--text" style="word-break: break-word">
          Roody Dashboard
        </v-card-title>
        <v-card-text v-if="false" :class="{ h5: $vuetify.breakpoint.mobile }" class="h4" style="word-break: break-word">
          Hier findest du alles was früher unter Einstellungen war und noch mehr.
        </v-card-text>

        <v-row>
          <v-col cols="12">
            <v-tabs centered dark v-model="tab">
              <v-tab v-if="userService.hasRight('todo_fetchAndEdit')">ToDo</v-tab>
              <v-tab v-if="userService.hasRight('u_search')">Changelog</v-tab>
              <v-tab>Downloads</v-tab>
            </v-tabs>
            <v-tabs-items dark v-model="tab">
              <v-tab-item v-if="userService.hasRight('todo_fetchAndEdit')">
                <to-do-list-main></to-do-list-main>
              </v-tab-item>
              <v-tab-item v-if="userService.hasRight('u_search')">
                <v-card dark flat><changelog></changelog></v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card dark flat class="pb-5"><intern-deprecated></intern-deprecated></v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
      <router-view v-show="!loading" :studios="studios" @finishedloading="finishedLoading()"></router-view>
    </div>
    <left-sidebar :drawerstate="drawerState"></left-sidebar>
  </div>
</template>

<script>
import api from "../../api"
import leftSidebar from "./sidebar/leftSidebar.vue"
import homeDashboardUserStatistics from "./home/homeDashboardUserStatistics.vue"
import internDeprecated from "./home/InternDeprecated.vue"
import changelog from "./home/changelog.vue"
import toDoListMain from "./toDoList/toDoListMain.vue"
import { mapState, mapActions, mapGetters } from "vuex"
import userService from "../../services/userService"
export default {
  name: "dashboard",
  components: { leftSidebar, homeDashboardUserStatistics, internDeprecated, changelog, toDoListMain },
  data() {
    return {
      tab: null,
      drawerState: true,
      selectedStudios: [],
      userService,
      selectedStudioSingle: "",
      studios: [],
      teams: [],
      loading: false,
      datePickerRange: [this.$moment().startOf("month").format("YYYY-MM-DD"), this.$moment().endOf("month").format("YYYY-MM-DD")],
      datePickerMenu: false,
    }
  },
  async created() {
    if (this.$route.meta.needsLoader) this.loading = true
    this.fetchStudios()
    this.fetchTeams()

    let studios = await api.fetchStudios().catch((e) => (this.error = true))
    let teams = await api.fetchTeamsForTitle()
    this.setNavigationbarVisibility(false)

    if (studios && teams && Array.isArray(studios) && Array.isArray(teams)) {
      this.setStudios(
        studios.concat(teams).map((stud) => {
          return {
            _id: stud._id,
            name: stud.name,
            vacationDays: stud.vacationDays,
          }
        })
      )
    }
  },
  computed: {
    formattedDatePickerRange() {
      if (!this.datePickerRange.length) return "Ganzer Zeitraum"
      if (this.datePickerRange[0] && !this.datePickerRange[1] && this.$route.meta.monthsOnly) {
        let pickedMonth = this.$moment(this.datePickerRange[0], "YYYY-MM")
        this.$set(this.datePickerRange, 0, pickedMonth.startOf("month").format("YYYY-MM-DD"))
        this.$set(this.datePickerRange, 1, pickedMonth.endOf("month").format("YYYY-MM-DD"))
      }
      if (!this.datePickerRange[1]) return this.$moment(this.datePickerRange[0], "YYYY-MM-DD").format("DD.MM.YYYY") + " - Datum auswählen"
      if (this.$moment(this.datePickerRange[0], "YYYY-MM-DD").isAfter(this.$moment(this.datePickerRange[1]))) {
        let olddatePickerRange0 = this.datePickerRange[0]
        this.datePickerRange[0] = this.datePickerRange[1]
        this.datePickerRange[1] = olddatePickerRange0
      }
      if (
        this.$moment(this.datePickerRange[0], "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD") === this.datePickerRange[0] &&
        this.$moment(this.datePickerRange[1], "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD") === this.datePickerRange[1] &&
        this.$moment(this.datePickerRange[0], "YYYY-MM-DD").format("YYYY-MM") ===
          this.$moment(this.datePickerRange[1], "YYYY-MM-DD").format("YYYY-MM")
      ) {
        return this.$moment(this.datePickerRange[0], "YYYY-MM-DD").format("MMMM YYYY")
      } else if (
        this.$moment().isSame(this.$moment(this.datePickerRange[0], "YYYY-MM-DD"), "day") &&
        this.$moment().isSame(this.$moment(this.datePickerRange[1], "YYYY-MM-DD"), "day")
      ) {
        return "Heute"
      } else if (
        this.$moment().subtract(1, "days").isSame(this.$moment(this.datePickerRange[0], "YYYY-MM-DD"), "day") &&
        this.$moment().subtract(1, "days").isSame(this.$moment(this.datePickerRange[1], "YYYY-MM-DD"), "day")
      ) {
        return "Gestern"
      } else if (this.datePickerRange[0] === this.datePickerRange[1]) {
        return this.$moment(this.datePickerRange[0], "YYYY-MM-DD").format("DD.MM.YYYY")
      } else {
        let enddatePickerRange = this.$moment().isSame(this.$moment(this.datePickerRange[1], "YYYY-MM-DD"), "day")
          ? "Heute"
          : this.$moment(this.datePickerRange[1], "YYYY-MM-DD").format("DD.MM.YYYY")
        return this.$moment(this.datePickerRange[0], "YYYY-MM-DD").format("DD.MM.YYYY") + " - " + enddatePickerRange
      }
      return (
        this.$moment(this.datePickerRange[0], "YYYY-MM-DD").format("DD.MM.YYYY") +
        " - " +
        this.$moment(this.datePickerRange[1], "YYYY-MM-DD").format("DD.MM.YYYY")
      )
    },
    selectStudioItems() {
      let studs
      if (this.$route.meta.includeTeams) {
        let result = []

        // Loop through each studio
        this.studios.forEach((studio) => {
          // Add the current studio to the result
          result.push(studio)

          // Find its teams and add them to the result
          this.teams.forEach((team) => {
            if (team.studio === studio._id) {
              result.push(team)
            }
          })
        })

        // Add remaining teams which don't have a studio property to the result
        this.teams.forEach((team) => {
          if (!team.studio) {
            result.push(team)
          }
        })

        studs = result
      } else {
        studs = this.studios
      }
      return studs
    },
    filteredStudios() {
      let newStudioArray = this.$route.meta.includeTeams ? this.studios.concat(this.teams) : this.studios

      return newStudioArray.filter((studio) => this.selectedStudios.includes(studio._id))
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 5000) // 5000 Millisekunden = 5 Sekunden
  },
  watch: {
    $route(to, from) {
      if (to.meta.needsLoader) this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 5000) // 5000 Millisekunden = 5 Sekunden
    },
  },
  methods: {
    ...mapActions("studioStore", ["setStudios"]),
    ...mapActions("navigationStoreNotPersisted", ["setNavigationbarVisibility"]),
    toggleStudioSelection() {
      if (this.selectedStudios.length === this.studios.length) {
        this.selectedStudios = []
      } else {
        this.selectedStudios = this.studios.map((studio) => studio._id)
      }
    },
    activePickerChanged(value) {
      if (value === "YEAR" && this.$refs.datePicker && this.$refs.datePicker.tableDate) {
        let pickedYear = this.$moment(this.$refs.datePicker.tableDate, "YYYY")
        this.$set(this.datePickerRange, 0, pickedYear.startOf("year").format("YYYY-MM-DD"))
        this.$set(this.datePickerRange, 1, pickedYear.endOf("year").format("YYYY-MM-DD"))
      }
      if (value === "DATE" && this.$refs.datePicker && this.$refs.datePicker.tableDate) {
        if (this.$route.meta.monthsOnly) return
        let pickedMonth = this.$moment(this.$refs.datePicker.tableDate, "YYYY-MM")
        this.$set(this.datePickerRange, 0, pickedMonth.startOf("month").format("YYYY-MM-DD"))
        this.$set(this.datePickerRange, 1, pickedMonth.endOf("month").format("YYYY-MM-DD"))
      }
    },
    toggleNavbar() {
      this.drawerState = !this.drawerState
    },
    finishedLoading() {
      this.loading = false
    },
    async fetchStudios() {
      let studios = await api.fetchAllowedAdminStudios()
      this.selectedStudioSingle = studios[0]._id
      studios.forEach((studio) => {
        if (!studio.location) studio.location = { lat: 0, long: 0 }
        if (!studio.vacationDays) studio.vacationDays = []
        this.selectedStudios.push(studio._id)
        this.studios.push(studio)
      })
    },
    async fetchTeams() {
      if (this.userService.hasRight("tt_teams")) this.teams = await api.fetchAllowedTeams()
      else this.teams = await api.fetchAllowedTeamStudios()

      if (this.$route.meta.includeTeams) {
        this.teams.forEach((team) => {
          if (!team.vacationDays) team.vacationDays = []

          this.selectedStudios.push(team._id)
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.dashboard {
  background-color: #343541;
  min-height: 80vh;
}
</style>
