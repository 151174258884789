<template>
  <div class="courseSettings">
    <v-tabs centered v-model="tab" dark>
      <v-tab>Kurse</v-tab>
      <v-tab v-if="false">Bilder</v-tab>
      <v-tab>Kategorisieren</v-tab>
    </v-tabs>
    <v-tabs-items dark v-model="tab">
      <!-- Tab 1: Kurse verwalten -->
      <v-tab-item>
        <v-row no-gutters class="courseSettings__course my-5 px-5">
          <!-- Neuer Kurs erstellen -->
          <v-col cols="12">
            <v-text-field dark filled v-model="courseListFilter" label="Suche" placeholder=""></v-text-field>
          </v-col>
          <v-col class="d-flex justify-center" cols="12">
            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="courseSettings_action-add-btn" large v-bind="attrs" v-on="on"> hinzufügen </v-btn>
              </template>
              <template v-slot:default="dialogAdd">
                <v-card dark>
                  <v-toolbar color="primary" dark>
                    <div class="d-block">
                      <strong>Neuen Kurs hinzufuegen</strong>
                    </div>
                  </v-toolbar>
                  <div class="px-5 courseSettings_action-dialog d-flex flex-column">
                    <v-text-field filled class="mt-5" label="Titel" v-model="addCourse.name"></v-text-field>
                    <v-textarea filled label="Beschreibung" v-model="addCourse.beschreibung"></v-textarea>
                    <slim-cropper style="height: 200px" :options="slimOptionsExercise">
                      <img v-if="addCourse.bild" :src="'/img/kurse/' + addCourse.bild" alt="" />
                      <input type="file" accept="image/*" />
                    </slim-cropper>
                    <!-- Kategorien anzeigen -->
                    <v-row class="mt-4">
                      <v-col cols="12" md="6" v-for="(value, key) in addCourse.categories" :key="key">
                        <v-select v-model="addCourse.categories[key]" :items="[1, 2, 3, 4]" :label="capitalize(key)" dense></v-select>
                      </v-col>
                    </v-row>
                  </div>

                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialogAdd.value = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="createCourse(dialogAdd)">Speichern</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <!-- Kursliste -->

          <v-col
            v-for="(course, index) in courseList"
            :key="course._id"
            cols="12"
            class="courseSettings__course-list flex-row justify-space-between py-2"
            :class="{
              'd-flex': !courseListFilter.length || course.name.toLowerCase().indexOf(courseListFilter.toLowerCase()) !== -1,
              'd-none': courseListFilter.length && course.name.toLowerCase().indexOf(courseListFilter.toLowerCase()) === -1,
            }">
            <div class="courseSettings__course-list-title white--text d-flex">
              <span class="my-auto">{{ shorten(course.name) }}</span>
            </div>
            <div class="courseSettings__course-list-action">
              <v-dialog @input="setEditIndex($event, index)" transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="red" icon @click="deleteEntry(course, 'course', 0)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn icon class="courseSettings__course-list-action-btn" color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="primary" dark>
                      <div class="d-block">
                        <strong>{{ shorten(course.name) }}</strong>
                      </div>
                    </v-toolbar>
                    <div class="px-5 courseSettings__course-list-action-wrapper d-flex flex-column">
                      <v-text-field class="mt-5" label="Titel" v-model="course.name"></v-text-field>
                      <v-textarea label="Beschreibung" v-model="course.beschreibung"></v-textarea>
                      <slim-cropper style="height: 300px" :options="slimOptionsExercise">
                        <img v-if="course.bild" :src="'/img/kurse/' + course.bild" alt="" />
                        <input type="file" accept="image/*" />
                      </slim-cropper>
                      <!-- Kategorien anzeigen -->
                      <v-row class="mt-4">
                        <v-col cols="12" md="6" v-for="(value, key) in course.categories" :key="key">
                          <v-select v-model="course.categories[key]" :items="[1, 2, 3, 4]" :label="capitalize(key)" dense></v-select>
                        </v-col>
                      </v-row>
                    </div>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false">Abbrechen</v-btn>
                      <v-btn color="primary" text @click="updateCourse(course, dialog)">Speichern</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

      <!-- Tab 2: Bilder -->
      <v-tab-item v-if="false">
        <v-row class="course_picture" v-for="(course, index) in courseList" :key="'bild-' + course._id">
          <v-col cols="6">
            <v-img height="200px" width="100%" contain v-if="course.bild" :src="'/img/kurse/' + course.bild"></v-img>
          </v-col>
          <v-col cols="6" class="d-flex">
            <strong class="my-auto">{{ course.name }}</strong>
            <div class="courseSettings__course-list-action action2">
              <v-dialog @input="setEditIndex($event, index)" transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="red" icon @click="deleteEntry(course, 'course', 0)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn icon class="courseSettings__course-list-action-btn" color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card dark>
                    <v-toolbar color="primary" dark>
                      <div class="d-block">
                        <strong>{{ shorten(course.name) }}</strong>
                      </div>
                    </v-toolbar>
                    <div class="px-5 courseSettings__course-list-action-wrapper d-flex flex-column">
                      <v-text-field filled class="mt-5" label="Titel" v-model="course.name"></v-text-field>
                      <v-textarea filled label="Beschreibung" v-model="course.beschreibung"></v-textarea>
                      <slim-cropper style="height: 200px" :options="slimOptionsExercise">
                        <img v-if="course.bild" :src="'/img/kurse/' + course.bild" alt="" />
                        <input type="file" accept="image/*" />
                      </slim-cropper>
                      <!-- Hier können Sie auch Kategorien einfügen, falls erwünscht -->
                    </div>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false">Abbrechen</v-btn>
                      <v-btn color="primary" text @click="updateCourse(course, dialog)">Speichern</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

      <!-- Tab 3: Kategorisieren -->
      <v-tab-item>
        <v-row no-gutters class="courseSettings__course my-5 px-5">
          <v-col cols="12">
            <v-text-field dark filled v-model="courseListFilter" label="Suche" placeholder=""></v-text-field>
          </v-col>
          <v-col cols="12" v-for="(course, index) in filteredCourses" :key="'cat-' + course._id">
            <!-- Verwende hier die ausgelagerte Komponente -->
            <course-categories
              :index="index"
              :course="course"
              @save-categories="handleSaveCategories"
              @delete-course="handleDeleteEntry"
              @edit-course="handleEditCourse" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ shorten(deleteDialogTitle, 25) }}
        </v-card-title>
        <v-card-text v-if="deleteDialogPreText" class="red--text"> Kurs wird in folgenden Studios verwendet: {{ deleteDialogPreText }} </v-card-text>
        <v-card-text>
          Möchtest du {{ getTextbyType(deleteDialogType) }}
          <strong>{{ shorten(deleteDialogTitle, 25) }}</strong>
          wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">Abbrechen</v-btn>
          <v-btn color="green darken-1" text @click="deleteEntry(dialogDeleteObj, deleteDialogType, 1)"> Löschen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../../api"
import userService from "../../../services/userService"
import CourseCategories from "./components/courseCategories.vue"
import Slim from "../../../plugins/slimImage/slim/slim.vue"
import EventHome from "../../event/eventHome.vue"

export default {
  name: "courseSettings",
  components: {
    CourseCategories,
    "slim-cropper": Slim,
    EventHome,
  },
  data() {
    return {
      userService,
      activeCourseIds: [],
      tab: 0,
      editIndex: null,
      courseList: [],
      courseListFilter: "",
      names: {
        endurance: "Ausdauer",
        strength: "Kraft",
        mobility: "Beweglichkeit",
        resilience: "Resilienz",
        coordination: "Koordination",
      },
      addCourse: {
        name: "",
        beschreibung: "",
        bild: "",
        slimMe: false,
        categories: {
          strength: 1,
          endurance: 1,
          mobility: 1,
          resilience: 1,
          coordination: 1,
        },
      },
      deleteDialog: false,
      deleteDialogText: "",
      deleteDialogTitle: "",
      dialogDeleteObj: {},
      deleteDialogType: "",
      deleteDialogPreText: "",
      slimOptionsExercise: {
        ratio: "16:9",
        initialImage: "/img/einmalig/imageUploadPlaceholder.jpg",
        buttonCancelLabel: "Abbrechen",
        buttonConfirmLabel: "Bestätigen",
        label: "Bild hochladen",
        labelLoading: "Bild wird hochgeladen",
        statusUploadSuccess: "gespeichert",
        service: this.uploadPicture,
        serviceFormat: "file",
        instantEdit: true,
        push: true,
      },
    }
  },
  async created() {
    await this.fetchCourseList()

    this.$emit("finishedloading")
  },
  computed: {
    filteredCourses() {
      console.log("length pre filtered", this.courseList.length)

      let tempCourseList = this.courseList.filter((e) => this.activeCourseIds.includes(e._id))
      console.log("length past filtered", tempCourseList.length)
      if (!this.courseListFilter) return tempCourseList
      return tempCourseList.filter((course) => course.name.toLowerCase().includes(this.courseListFilter.toLowerCase()))
    },
  },
  methods: {
    capitalize(str) {
      return this.names[str]
    },
    setEditIndex(active, index) {
      if (active) {
        this.editIndex = index
      } else {
        this.editIndex = null
      }
    },
    uploadPicture(formdata, progress, success, failure, slim) {
      const formData = new FormData()
      formData.append("file", formdata[0])

      let uploadimg = {}
      uploadimg.art = "groupFitness_course"
      uploadimg.formData = formData

      api
        .uploadImage(uploadimg)
        .then((data) => {
          if (data.status) {
            if (this.editIndex !== null) {
              this.courseList[this.editIndex].bild = data.name
            } else {
              this.addCourse.bild = data.name
            }
            success(data)
          }
        })
        .catch((error) => {
          failure(error)
        })
    },
    async createCourse(dialog) {
      // Neuen Kurs erstellen und zur Liste hinzufügen
      const newCourse = await api.createGFCourse(this.addCourse)
      this.fetchCourseList()
      dialog.value = false
    },
    async updateCourse(course, dialog) {
      // API-Aufruf zur Aktualisierung; erwarte den aktualisierten Kurs zurück
      const updatedCourse = await api.updateGFCourse(course)
      const idx = this.courseList.findIndex((c) => c._id === course._id)
      if (idx !== -1) {
        this.$set(this.courseList, idx, course)
      }
      if (dialog) dialog.value = false
    },
    async saveCategories(course) {
      const updatedCourse = await api.updateGFCourse(course)
      const idx = this.courseList.findIndex((c) => c._id === course._id)
      if (idx !== -1) {
        this.$set(this.courseList, idx, course)
      }
      this.$toast.success("gespeichert")
    },
    async handleSaveCategories({ id, categories }) {
      const course = this.courseList.find((c) => c._id === id)
      if (course) {
        course.categories = { ...categories }
        await this.saveCategories(course)
      }
    },
    handleEditCourse(course) {
      // Zusätzliche Logik für den Editier-Button (optional)
    },
    async handleDeleteEntry(course) {
      this.deleteEntry(course, "course", 0)
    },
    getTextbyType(type) {
      if (type === "label") return "das Label"
      if (type === "room") return "den Raum"
      if (type === "course") return "den Kurs"
    },
    async deleteEntry(entry, type, status = 0) {
      this.dialogDeleteObj = entry
      this.deleteDialogType = type
      this.deleteDialogPreText = ""
      let error = false
      if (type === "label") this.deleteDialogTitle = entry.text
      if (type === "label" && status) await api.deleteGFLabel(entry._id).catch((e) => (error = true))
      if (type === "room") this.deleteDialogTitle = entry.name
      if (type === "room" && status) await api.deleteGFRoom(entry._id).catch((e) => (error = true))
      if (type === "course") this.deleteDialogTitle = entry.name
      if (type === "course") {
        let usage = await api.checkGFCourseUsage(entry._id)
        if (usage) this.deleteDialogPreText = usage
      }
      if (type === "course" && status) {
        await api.deleteGFCourse(entry._id)
        this.courseList = this.courseList.filter((c) => c._id !== entry._id)
      }
      if (!status) this.deleteDialog = true
      if (status && !error) this.deleteDialog = false
    },
    shorten(text, length = 25) {
      if (text && text.length > length) text = text.substr(0, length) + "..."
      return text
    },
    async fetchCourseList() {
      try {
        let activeCoureseids = await api.fetchActiveCourseIds()
        this.activeCourseIds = activeCoureseids
      } catch (e) {}
      let courses = await api.fetchGFCourses()
      courses = courses.map((course) => {
        if (!course.categories) {
          course.categories = {
            strength: 1,
            endurance: 1,
            mobility: 1,
            resilience: 1,
            coordination: 1,
          }
        }
        return course
      })
      this.courseList = courses
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-slide-group__prev {
  display: none !important;
}

.action2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
}

.course_picture {
  border-bottom: 1px solid white;
  cursor: pointer;

  &:hover {
    background-color: rgb(218, 218, 218);
  }
}

.courseSettings {
  &__course {
    &-list {
      font-weight: bold;
      border-bottom: 1px solid white;
    }
  }
}
</style>
