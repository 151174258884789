import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  submitAnamnese(formData) {
    let url = buildUrl("anamnesebogen/create-for-user")
    return axios.post(url, formData, buildOptions()).then((res) => res.data)
  },
  deleteMedicalHistory(id) {
    let url = buildUrl("anamnesebogen/new/delete")
    return axios.post(url, { id: id }, buildOptions()).then((res) => res.data)
  },

  getAnamneseForStudio() {
    let url = buildUrl("anamnesebogen/for/studio")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getAnamneseForUser(id) {
    let url = buildUrl("anamnesebogen/for/user")
    return axios.post(url, { id: id }, buildOptions()).then((res) => res.data)
  },
  getAnamneseNutrition() {
    let url = buildUrl("anamnesebogen/nutrition")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getAnamneseNutritionForUser(id) {
    let url = buildUrl("anamnesebogen/for/user/nutrition")
    return axios.post(url, { id: id }, buildOptions()).then((res) => res.data)
  },
  getAnamneseVariant(variant) {
    let url = buildUrl("anamnesebogen/variant/" + variant)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getAnamneseVariantForUser(id, variant) {
    let url = buildUrl("anamnesebogen/for/user/variant")
    return axios.post(url, { id: id, variant }, buildOptions()).then((res) => res.data)
  },
  createOrUpdateAnamneseQuestion(data) {
    let url = buildUrl("anamnesebogen/question/create-update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  deleteAnamneseQuestion(id) {
    let url = buildUrl("anamnesebogen/question/delete")
    return axios.post(url, id, buildOptions()).then((res) => res.data)
  },
  createOrUpdateAnamnesePage(data) {
    let url = buildUrl("anamnesebogen/page/create-update")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  updateAnamnesePageSorting(data) {
    let url = buildUrl("anamnesebogen/page/update/sorts")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  deleteAnamnesePage(id) {
    let url = buildUrl("anamnesebogen/page/delete")
    return axios.post(url, id, buildOptions()).then((res) => res.data)
  },
  fetchAnamneseDashboardInfos() {
    let url = buildUrl("anamnese/management")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Deprecated?

  ABFilter(string) {
    let url = buildUrl(`anamnesebogen/filter`)
    return axios.post(url, { filter: string }, buildOptions()).then((res) => res.data)
  },
  ABgetFilter() {
    let url = buildUrl(`anamnesebogen/filter`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  getUserAnamnesebogen() {
    let url = buildUrl(`anamnesebogen/kunde`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  searchAnamnesebogen(search) {
    let url = buildUrl("anamnesebogen/search")
    return axios.post(url, { search: search }, buildOptions()).then((res) => res.data)
  },
  submitAnamnesebogen(bogen) {
    let url = buildUrl("anamnesebogen")
    return axios.post(url, bogen, buildOptions()).then((res) => res.data)
  },
}
