<template>
  <v-card>
    <v-card-title class="primary white--text">
      {{ item._id ? "Schulferien bearbeiten" : "Schulferien erstellen" }}
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <!-- Titel -->
          <v-col cols="12">
            <v-text-field v-model="item.name" label="Titel" required></v-text-field>
          </v-col>

          <!-- Startdatum -->
          <v-col cols="12" sm="6">
            <v-menu ref="startMenu" v-model="startMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedStartDate"
                  label="Startdatum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  required></v-text-field>
              </template>
              <v-date-picker v-model="item.startDate" no-title scrollable :first-day-of-week="1" @input="startMenu = false"></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Enddatum -->
          <v-col cols="12" sm="6">
            <v-menu ref="endMenu" v-model="endMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedEndDate"
                  label="Enddatum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  required></v-text-field>
              </template>
              <v-date-picker v-model="item.endDate" no-title scrollable :first-day-of-week="1" @input="endMenu = false"></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Anzeige der Anzahl Tage -->
          <v-col cols="12">
            <div v-if="item.startDate && item.endDate">Anzahl Tage: {{ duration }}</div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="saveItem">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "schoolHolidaysDialog",
  props: {
    existingValue: {
      type: Object,
      default: () => ({ _id: null }),
    },
  },
  data() {
    return {
      item: {
        name: "",
        shortName: "",
        startDate: "",
        endDate: "",
      },
      startMenu: false,
      endMenu: false,
    }
  },
  computed: {
    duration() {
      // Berechnet die Anzahl der Tage (inklusive Start- und Enddatum)
      if (this.item.startDate && this.item.endDate) {
        const start = new Date(this.item.startDate)
        const end = new Date(this.item.endDate)
        const diffTime = end.getTime() - start.getTime()
        if (diffTime < 0) {
          return 0
        }
        // +1, damit beide Tage gezählt werden
        return Math.round(diffTime / (1000 * 60 * 60 * 24)) + 1
      }
      return 0
    },
    formattedStartDate() {
      return this.item.startDate ? this.formatDate(this.item.startDate) : ""
    },
    formattedEndDate() {
      return this.item.endDate ? this.formatDate(this.item.endDate) : ""
    },
  },
  created() {
    // Vorhandene Werte klonen, falls vorhanden
    if (this.existingValue._id) {
      this.item = { ...this.existingValue }
    }
  },
  methods: {
    saveItem() {
      this.$emit("input", this.item)
    },
    formatDate(date) {
      // Erwartet ein ISO-Datum (YYYY-MM-DD) und formatiert es als DD.MM.YYYY
      const parts = date.split("-")
      if (parts.length === 3) {
        return parts[2] + "." + parts[1] + "." + parts[0]
      }
      return date
    },
  },
}
</script>

<style scoped>
/* Hier können bei Bedarf weitere Styles ergänzt werden */
</style>
