import PublicLeads from "@/views/admin/leads/public/LeadView.vue"
import ThankYou from "@/views/admin/leads/public/ThankYou.vue"
import ReferralView from "@/views/admin/leads/public/ReferralView.vue"
import internReferralView from "@/views/admin/leads/internReferralView.vue"

export default [
  {
    path: "/referral",
    name: "internReferralView",
    component: internReferralView,
  },

  {
    path: "/l/:link",
    name: "PublicLeads",
    component: PublicLeads,
    meta: { hideBottomNavigation: true, hideAppBar: true, fullWidth: true },
  },
  {
    path: "/danke",
    name: "thankyou",
    component: ThankYou,
    meta: { hideBottomNavigation: true, hideAppBar: true, fullWidth: true },
  },
  {
    path: "/r/:email",
    name: "ReferralView",
    component: ReferralView,
  },
]
