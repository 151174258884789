import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  getAllianceEntriesExcel() {
    let url = buildUrl("allianz/download")
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then((res) => res.data)
  },
  getAllianzListEntry() {
    let url = buildUrl("allianz/status")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  enterAllianzList() {
    let url = buildUrl("allianz/enter")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Training stats

  fetchTrainingDataForDay(day) {
    let url = buildUrl("user/training/day/" + day)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchCompleteTrainingCalendar() {
    let url = buildUrl("user/training/complete")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchTrainingCalendar() {
    let url = buildUrl("user/training")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchUserProfileStats() {
    let url = buildUrl("user/profile/stats")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },

  // Exercises and Devices

  deleteDevice(device) {
    let url = buildUrl("devices/" + device._id)
    return axios.delete(url, buildOptions()).then((res) => res.data)
  },
  updateDevice(formData) {
    let url = buildUrl("devices")
    return axios.put(url, formData, buildOptions()).then((res) => res.data)
  },
  createDevice(formData) {
    let url = buildUrl("devices")
    return axios.post(url, formData, buildOptions()).then((res) => res.data)
  },
  fetchDeviceCategories() {
    let url = buildUrl("devicecategories")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchDevice(id) {
    let url = buildUrl("device/" + id)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchDevicesPage(page) {
    let url = buildUrl("devices/" + page)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchDevices(signal = null) {
    let url = buildUrl("devices")
    return axios.get(url, buildOptions({}, signal)).then((res) => res.data)
  },
  reorderExercise(deviceId, exercise) {
    let url = buildUrl("devicereorder")
    return axios.post(url, { deviceId, exercise }, buildOptions()).then((res) => res.data)
  },

  fetchAllTags() {
    let url = buildUrl("tags")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
