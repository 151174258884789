import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  getFriendsFeed() {
    let url = buildUrl("friend/signups")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  addFriend(email) {
    let url = buildUrl(`friend/request/add/${email}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  acceptFriend(id) {
    let url = buildUrl(`friend/request/accept/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getFriendrequests() {
    let url = buildUrl(`friend/request/list`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  removeFriend(id) {
    let url = buildUrl(`friend/decline/${id}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getFriends() {
    let url = buildUrl(`friend/list`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
}
