import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  toDoList: {
    // GET
    fetchAll(params = {}) {
      let url = buildUrl("todo")
      return axios.get(url, buildOptions({ params })).then((res) => res.data)
    },
    create(data) {
      let url = buildUrl("todo")
      return axios.post(url, data, buildOptions()).then((res) => res.data)
    },
    update(id, data) {
      let url = buildUrl(`todo/${id}`)
      return axios.put(url, data, buildOptions()).then((res) => res.data)
    },
    delete(id) {
      let url = buildUrl(`todo/${id}`)
      return axios.delete(url, buildOptions()).then((res) => res.data)
    },
    addComment(id, data) {
      let url = buildUrl(`todo/${id}/comment`)
      return axios.post(url, data, buildOptions()).then((res) => res.data)
    },
    assign(id, data) {
      let url = buildUrl(`todo/${id}/assign`)
      return axios.put(url, data, buildOptions()).then((res) => res.data)
    },
    removeAssignment(id, userId) {
      let url = buildUrl(`todo/${id}/assign/${userId}`)
      return axios.delete(url, buildOptions()).then((res) => res.data)
    },
  },
}
