import checkPayment from "@/views/payment/checkPayment.vue"
import checkPaymentFailed from "@/views/payment/checkPaymentFailed.vue"
import checkPaymentSuccess from "@/views/payment/checkPaymentSuccess.vue"

export default [
  {
    path: "/adyen/payment-check/:transactionID",
    name: "adyenPaymentCheck",
    component: checkPayment,
  },
  {
    path: "/adyen/payment-success",
    name: "adyenPaymentSuccess",
    component: checkPaymentSuccess,
  },
  {
    path: "/adyen/payment-failed",
    name: "adyenPaymentError",
    component: checkPaymentFailed,
  },
]
