import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  shiftManagement: {
    fetchSettings(studioId) {
      let url = buildUrl("shift-management/settings/fetch/" + studioId)
      return axios.get(url, buildOptions()).then((res) => res.data)
    },

    createOrUpdateSettings(data) {
      let url = buildUrl("shift-management/settings/create-or-update")
      return axios.post(url, data, buildOptions()).then((res) => res.data)
    },

    fetchShiftManagementCheckinData() {
      let url = buildUrl("shift-management/checkin/data")
      return axios.get(url, buildOptions()).then((res) => res.data)
    },
    fetchShiftManagementEmployee() {
      let url = buildUrl("shift-management/employee/data")
      return axios.get(url, buildOptions()).then((res) => res.data)
    },
  },
}
