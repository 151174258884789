<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card dark>
      <v-card-title class="headline">Ordner löschen</v-card-title>
      <v-card-text>
        Möchtest Du den Ordner "{{ folder.name }}" und alle Unterordner wirklich löschen? Alle darin enthaltenen Dokumente werden in den Eingang
        verschoben.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="$emit('confirm-delete')">Löschen</v-btn>
        <v-btn color="secondary" text @click="$emit('cancel-delete')">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteFolderDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    folder: {
      type: Object,
      required: true,
    },
  },
}
</script>
