import axios from "axios"
import { saveAs } from "file-saver"

const apiUrl = "/api/"

function buildUrl(path) {
  return `${apiUrl}${path}`
}

function buildOptions(options = {}, signal = null) {
  if (!options.headers) {
    options.headers = {}
  }

  if (signal) options.signal = signal

  options.headers.Authorization = localStorage.getItem("token")
  return options
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export default {
  downloadUsersWithoutRoodyList() {
    let url = buildUrl("stats/list/noroody")
    let options = {}
    options.responseType = "blob"
    return axios.get(url, buildOptions(options)).then((res) => res.data)
  },
  fetchAllowedAdminStudios() {
    let url = buildUrl("dashboard/studios")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllowedTeams() {
    let url = buildUrl("dashboard/teams")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchAllowedTeamStudios() {
    let url = buildUrl("dashboard/teams/for/studio")

    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchGroupfitnessDashboardInfos(range) {
    let url = buildUrl("dashboard/gf/infos")
    return axios.post(url, range, buildOptions()).then((res) => res.data)
  },
  fetchTrainerDashboardInfos(range) {
    let url = buildUrl("dashboard/trainer/infos")

    return axios.post(url, range, buildOptions()).then((res) => res.data)
  },
  fetchTrainerDashboardInfosSingleTrainer(data) {
    let url = buildUrl("dashboard/trainer/infos/single/trainer")

    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchTrainerDashboardInfosStudio(data) {
    let url = buildUrl("dashboard/trainer/infos/studio")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchAnamneseDashboardQuestions(data) {
    let url = buildUrl("dashboard/anamnese/quuestions")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  reloadDashboardUserStatistics() {
    let url = "https://app.wellcomefit.ch/api/stats/daily/customers"
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchDashboardUserStatisticsNew(studios) {
    let url = buildUrl("dashboard/user/statistics/new")
    return axios.post(url, studios, buildOptions()).then((res) => res.data)
  },
  fetchDashboardUserStatistics(studios) {
    let url = buildUrl("dashboard/user/statistics")
    if (location.host.includes("local")) {
      url = buildUrl("dashboard/user/statisticsnew")
    }
    return axios.post(url, studios, buildOptions()).then((res) => res.data)
  },
  fetchContractDevelopment(months) {
    let url = buildUrl("dashboard/contracts/development")
    return axios.post(url, { months }, buildOptions()).then((res) => res.data)
  },
  fetchDashboardUserTACStatistics(studios) {
    let url = ""
    if (location.host.includes("localhost")) {
      url = buildUrl("dashboard/user/tacstatisticsnew")
    } else {
      url = buildUrl("dashboard/user/tacstatistics")
    }

    return axios.post(url, studios, buildOptions()).then((res) => res.data)
  },
  getCustomerContracts(userId) {
    let url = buildUrl(`dashboard/user/contracts/${userId}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  getCustomerDocuments(userId) {
    let url = buildUrl(`dashboard/user/documents/${userId}`)
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  fetchDashboardUserInfos() {
    let url = buildUrl("dashboard/user/infos")
    return axios.get(url, buildOptions()).then((res) => res.data)
  },
  updatePaymentOptionsContract(info) {
    let url = buildUrl("dashboard/contract/paymentoptions/edit")
    return axios.post(url, info, buildOptions()).then((res) => res.data)
  },
  addAttachmentToContract(info) {
    let url = buildUrl("dashboard/contract/attachment/add")
    return axios.post(url, info, buildOptions()).then((res) => res.data)
  },
  fetchContractUserInfos(data) {
    let url = "https://app.wellcomefit.ch/api/dashboard/contracts/infos"
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchContractPackagesStats(data) {
    let url = "https://app.wellcomefit.ch/api/dashboard/contracts/packages-and-total"
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchContractsDevelopment(data) {
    let url = buildUrl("dashboard/contracts/development")
    return axios.post(url, data, buildOptions()).then((res) => res.data)
  },
  fetchDashboardCheckinInfos(range, selectedStudios) {
    let url = buildUrl("dashboard/checkin/infos")
    return axios.post(url, { range, selectedStudios }, buildOptions()).then((res) => res.data)
  },
}
