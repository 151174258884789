<template>
  <v-container fluid>
    <!-- App-Bar -->
    <v-container dark fluid>
      <v-row no-gutters align="center">
        <v-col v-if="userService.hasRight('d_fetchAllDocs')" cols="6">
          <v-btn color="white" outlined @click="loadEmails">
            <v-icon left>mdi-email</v-icon>
            Neue Mails abrufen
          </v-btn>
        </v-col>
        <v-col :cols="userService.hasRight('d_fetchAllDocs') ? '6' : '12'" class="text-right">
          <v-btn color="white" outlined @click="uploadMedia">
            <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-upload</v-icon>
            <span v-if="$vuetify.breakpoint.mdAndUp">Datei hochladen</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <document-email-fetcher @newAttachements="reloadTreeManager" ref="documentMailFetcher"> </document-email-fetcher>

    <file-drop-upload
      :allowed="userService.hasRight('d_fetchAllDocs')"
      @hideDialog="showUploadDialog = false"
      :showUploadDialog="showUploadDialog"
      @fileUpload="reloadTreeManager">
    </file-drop-upload>

    <v-row>
      <v-col cols="12">
        <folder-tree-manager ref="treeManager"></folder-tree-manager>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userService from "@/services/userService"
import documentEmailFetcher from "./components/documentEmailFetcher.vue"
import fileDropUpload from "./components/fileDropUpload.vue"
import folderTreeManager from "./folderTreeManager.vue"
import api from "@/api" // Stelle sicher, dass hier deine API-Funktion fetchDocumentEmails verfügbar ist.

export default {
  name: "Dashboard",
  components: { documentEmailFetcher, folderTreeManager, fileDropUpload },
  data() {
    return {
      userService,
      showUploadDialog: false,
    }
  },
  methods: {
    async uploadMedia() {
      this.showUploadDialog = true
    },
    async reloadTreeManager() {
      this.showUploadDialog = false
      this.$refs.treeManager.init()
    },
    async loadEmails() {
      // Rufe die Methode der Kind-Komponente auf, die den Request startet
      await this.$refs.documentMailFetcher.fetchMails()
    },
  },
}
</script>

<style scoped>
/* Eigene Styles können hier hinzugefuegt werden */
</style>
