<template>
  <v-speed-dial v-model="fab" direction="top" style="position: fixed; bottom: 16px" right>
    <template v-slot:activator>
      <v-btn v-model="fab" color="blue" large dark fab>
        <v-img v-if="!fab" height="45" width="45" contain src="/img/habitory/fabButton.png"></v-img>
        <v-icon v-else>mdi-close</v-icon>
      </v-btn>
    </template>
    <v-btn v-if="false" fab dark color="red darken-3" @click="emitSelection('medicationHabit')">
      <v-img height="45" width="45" :src="'/img/lottiefiles/medicationReport.png'"></v-img>
    </v-btn>
    <div class="text-center d-flex flex-column">
      <v-btn class="mb-0" fab dark color="blue darken-2" @click="emitSelection('drinkHabit')">
        <v-img height="45" width="45" :src="'/img/lottiefiles/glas.png'"></v-img>
      </v-btn>
      <span class="font-weight-bold" style="font-size: 12px">Trinken</span>
    </div>
    <div class="text-center d-flex flex-column">
      <v-btn class="mb-0" fab dark color="red" @click="emitSelection('workoutHabit')">
        <v-img height="45" width="45" :src="'/img/lottiefiles/workout.png'"></v-img>
      </v-btn>
      <span class="font-weight-bold" style="font-size: 12px">Training</span>
    </div>
    <div class="text-center d-flex flex-column">
      <v-btn class="mb-0" fab dark color="red lighten-4" :loading="loading" @click="emitSelection('formUpdateHabit', true)">
        <v-img width="35px" :src="'/img/lottiefiles/formUpdate.png'"></v-img>
      </v-btn>
      <span class="font-weight-bold" style="font-size: 12px">Fortschritt</span>
    </div>
    <div class="text-center d-flex flex-column">
      <v-btn class="mb-0" fab dark color="grey lighten-2" :loading="loading" @click="emitSelection('shoppingList', true)">
        <v-img width="20px" :src="'/img/lottiefiles/shoppingList.png'"></v-img>
      </v-btn>
      <span class="font-weight-bold" style="font-size: 12px">Einkauf</span>
    </div>
    <div class="text-center d-flex flex-column">
      <v-btn class="mb-0" fab dark color="grey lighten" :loading="loading" @click="emitSelection('dayChoice')">
        <v-img width="35px" :src="'/img/lottiefiles/dayChoice.png'"></v-img>
      </v-btn>
      <span class="font-weight-bold" style="font-size: 12px">Tagesmodus</span>
    </div>
    <div class="text-center d-flex flex-column">
      <v-btn class="mb-0" fab dark color="grey darken-2" :loading="loading" @click="emitSelection('recipes')">
        <v-img width="35px" :src="'/img/lottiefiles/recipes.png'"></v-img>
      </v-btn>
      <span class="font-weight-bold" style="font-size: 12px">Rezepte</span>
    </div>
  </v-speed-dial>
</template>

<script>
import { habitService } from "@/services/habitService"
export default {
  props: {
    formUpdateHabit: {},
    date: {},
  },
  data() {
    return {
      fab: false,
      loading: false,
    }
  },
  methods: {
    async emitSelection(selection, execution = false) {
      if (selection === "shoppingList") return this.$router.push({ name: "nutritionShoppingList" })
      this.$emit("selected", null)
      this.$emit("selected-execution", null)
      if (selection === "dayChoice") return this.$emit("dayChoice")
      if (selection === "recipes") return this.$router.push({ name: "nutritionRecipes", params: { date: this.date } })
      if (this.formUpdateHabit && selection === "formUpdateHabit") {
        selection = "formUpdateHabitExecution"
      } else if (selection === "formUpdateHabit" && !this.formUpdateHabit) {
        this.loading = true
        let formUpdateHabit = habitService.getNewFormUpdateHabit()
        formUpdateHabit = await formUpdateHabit.save()
        console.log("should reload now")
        location.reload()
        this.$emit("added", formUpdateHabit)
        this.loading = false
      }
      execution ? this.$emit("selected-execution", selection) : this.$emit("selected", selection)
    },
  },
}
</script>
