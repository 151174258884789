import PopupService from "@/apiServices/popupService"

const modules = {}

const requireModule = require.context("./api/", false, /^(?!.*api\.js$).*\.js$/)

requireModule.keys().forEach((fileName) => {
  const moduleName = fileName.replace(/(\.\/|\.js)/g, "")
  modules[moduleName] = requireModule(fileName).default
})

const api = Object.assign({ PopupService }, ...Object.values(modules))
export default api
