<template>
  <div>
    <v-row no-gutters>
      <v-btn style="z-index: 100000" absolute top right icon color="red" @click="openCancelConfirm()">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <!-- Wenn mehrere Übungen vorhanden sind, zeigen wir Informationen zu allen Übungen an -->
      <v-col cols="12" class="align-center mb-2">
        <v-row no-gutters class="justify-content-center">
          <v-col
            v-for="(exercise, exIndex) in exercises"
            :key="exIndex"
            cols="auto"
            class="mx-2 d-flex align-center"
            :style="exIndex === 1 ? 'flex-flow: row-reverse' : ''">
            <v-avatar size="80">
              <v-img cover :src="getImageForExercise(exercise)" position="top center"></v-img>
            </v-avatar>
            <div class="ml-2">
              <v-card-title class="pb-0">{{ exercise.title }}</v-card-title>
              <v-tooltip bottom v-if="getAnalyzeExercise(exIndex)">
                <template v-slot:activator="{ on }">
                  <div v-show="getAnalyzeExercise(exIndex) && getAnalyzeExercise(exIndex).color" style="display: inline-block">
                    <v-chip v-on="on" class="ml-3" :color="getAnalyzeExercise(exIndex).color || ''" small>
                      {{ getAnalyzeExercise(exIndex).text || "" }}
                    </v-chip>
                  </div>
                </template>
                {{ getAnalyzeExercise(exIndex).description || "" }}
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-divider color="white"></v-divider>
      </v-col>

      <!-- Allgemeine Einstellungen pro Übung -->
      <v-col
        cols="12"
        v-for="(exercise, exIndex) in exercises"
        :key="'generalSettings' + exIndex"
        v-if="exercise.general_exercise_settings && exercise.general_exercise_settings.length">
        <v-row no-gutters>
          <v-col cols="auto">
            <v-card-title class="px-1 py-1 font-weight-bold"> Geräteeinstellungen für {{ exercise.title }} </v-card-title>
          </v-col>
          <v-col class="align-content-center" v-if="exercise.savedGeneralExerciseSettings" cols="grow">
            <v-btn class="ma-1" x-small fab color="blue" @click="exercise.savedGeneralExerciseSettings = false">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-chip
              color="grey"
              class="mr-2"
              v-for="setting in exercise.general_exercise_settings.filter((setting) => setting.value)"
              :key="setting.text">
              <span>{{ shorten(setting.text) + " | " + setting.value }}</span>
            </v-chip>
          </v-col>
        </v-row>
        <v-expand-transition mode="out-in" v-if="!exercise.savedGeneralExerciseSettings">
          <v-row no-gutters>
            <v-col
              cols="auto"
              class="pa-1 d-flex align-center fill-height"
              v-for="(deviceSetting, index) in exercise.general_exercise_settings"
              :key="index + 'deviceSetting' + exIndex">
              <v-card
                class="d-flex flex-column align-center justify-space-between"
                elevation="2"
                style="width: auto; min-width: 0px; max-width: none; transition: width 0.3s">
                <span class="truncate py-1 px-2 font-weight-bold">
                  {{ shorten(deviceSetting.text, 10) }}
                </span>
                <div class="input-wrapper pb-1">
                  <v-select
                    dense
                    solo
                    height="20px"
                    style="line-height: 1"
                    hide-details
                    class="pa-0 ma-0"
                    v-model="deviceSetting.value"
                    :items="deviceSetting.selections"
                    v-if="deviceSetting && deviceSetting.input === 'selection'"
                    @keydown.enter.prevent="closeSettingDialog()"></v-select>
                  <span
                    v-else
                    contenteditable="true"
                    :class="{ 'text-center': !deviceSetting.value || true }"
                    @input="updatingSetting($event, deviceSetting)"
                    :type="deviceSetting.input"
                    placeholder="+"
                    class="compact-input flex-grow-1 pb-2"
                    v-text="deviceSetting.value"></span>
                </div>
              </v-card>
            </v-col>
            <v-col cols="auto" class="ml-2 align-content-center">
              <v-btn x-small fab color="primary" @click="saveGeneralExerciseSettings(exIndex)">
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-divider color="white"></v-divider>
      </v-col>

      <!-- Satz hinzufügen Select -->
      <v-col cols="12" class="my-4">
        <v-select
          v-if="!savedFirstSet"
          v-model="selectedExerciseForAdd"
          :items="exercises"
          item-text="title"
          item-value="index"
          label="Wähle die Übung mit der du beginnen möchtest"
          @change="onExerciseSelect">
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-avatar left size="40">
                <v-img :src="getImageForExercise(item)"></v-img>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>

      <!-- Darstellung der Sätze für mehrere Übungen -->
      <v-col cols="12">
        <v-card-title v-if="savedFirstSet" class="px-1 py-1 mt-1 font-weight-bold"> Sätze hinzufügen / bearbeiten </v-card-title>

        <!-- Existierende Sätze in einer Tabelle -->
        <v-row no-gutters class="mb-2" v-if="savedFirstSet">
          <v-col cols="12">
            <v-row no-gutters v-for="rowIndex in maxSetCount" :key="'row' + rowIndex" class="my-1">
              <v-col v-for="(exercise, exIndex) in exercises" :key="'set' + exIndex + '-' + rowIndex" cols="auto" class="mr-2">
                <v-slide-x-transition>
                  <v-card v-if="exercise.sets && exercise.sets[rowIndex - 1]" class="fill-height rounded-xl mr-2">
                    <v-row no-gutters>
                      <v-col cols="grow" class="flex-grow">
                        <v-card-title class="font-weight-bold py-1"> {{ "Satz " + rowIndex }} - {{ exercise.title }} </v-card-title>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn class="ma-1" small icon color="blue" @click="editSet(exIndex, rowIndex - 1)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn class="ma-1" small icon color="red" @click="removeSetByIndex(exIndex, rowIndex - 1)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-chip
                          class="my-2 mx-1"
                          small
                          v-for="setting in exercise.sets[rowIndex - 1].filter((setting) => setting.value && setting.text !== 'Notiz')"
                          :key="setting.text + rowIndex">
                          <span>{{ shorten(setting.text) + " | " + getSettingText(setting) }}</span>
                        </v-chip>
                        <br />
                        <span class="px-2 pb-1" v-if="exercise.sets[rowIndex - 1].find((setting) => setting.text === 'Notiz' && setting.value)">
                          {{ "Notiz: " + exercise.sets[rowIndex - 1].find((setting) => setting.text === "Notiz").value }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card v-else class="fill-height rounded-xl mr-2 d-flex align-center justify-center" style="height: 80px">
                    <em class="grey--text">Kein Satz für diese Runde</em>
                  </v-card>
                </v-slide-x-transition>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- Nur anzeigen, wenn eine Übung zum Hinzufügen ausgewählt wurde -->
        <v-row no-gutters v-if="selectedExerciseIndex !== null">
          <v-col cols="12" class="mb-2">
            <v-card-title class="px-1 py-1 font-weight-bold">
              {{
                editingSetIndex !== null
                  ? "Satz " + (editingSetIndex + 1) + " bearbeiten für " + exercises[selectedExerciseIndex].title
                  : exercises[selectedExerciseIndex].sets && exercises[selectedExerciseIndex].sets.length
                  ? "Satz " + (exercises[selectedExerciseIndex].sets.length + 1) + " hinzufügen für " + exercises[selectedExerciseIndex].title
                  : "Ersten Satz hinzufügen für " + exercises[selectedExerciseIndex].title
              }}
            </v-card-title>

            <v-row no-gutters>
              <v-col
                cols="auto"
                class="pa-1 d-flex align-center fill-height"
                v-for="(setSetting, index) in exercises[selectedExerciseIndex].set_exercise_settings"
                :key="index + 'deviceSettingEdit'">
                <v-card
                  color=""
                  class="d-flex flex-column align-center justify-space-between"
                  @click="test(exercises[selectedExerciseIndex])"
                  elevation="2">
                  <span class="truncate py-1 px-2 font-weight-bold"> {{ shorten(setSetting.text, 10) }} {{ "huh" + selectedExerciseIndex }} </span>
                  <div class="input-wrapper pb-1">
                    <v-select
                      dense
                      solo
                      height="20px"
                      style="line-height: 1"
                      hide-details
                      class="pa-0 ma-0"
                      v-model="setSetting.value"
                      :items="setSetting.selections"
                      v-if="setSetting && setSetting.input === 'selection'"
                      @keydown.enter.prevent="closeSettingDialog()"></v-select>
                    <span
                      v-else
                      contenteditable="true"
                      :class="{ 'text-center': !setSetting.value || true }"
                      :type="setSetting.input"
                      placeholder="+"
                      class="compact-input flex-grow-1 pb-2"
                      v-text="setSetting.value"
                      @input="updatingSetting($event, setSetting)"></span>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="auto" class="ml-2 align-content-center">
                <v-btn x-small fab color="primary" @click="saveSet">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn v-if="editingSetIndex !== null" x-small fab color="grey" @click="cancelEditSet">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card-actions class="justify-content-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on" style="display: inline-block">
            <v-btn class="rounded-xl" :class="{ 'disabled-button': isButtonDisabled }" color="primary" @click="onButtonClick">
              Änderungen speichern
            </v-btn>
          </div>
        </template>
        <span>{{ tooltipMessage }}</span>
      </v-tooltip>
    </v-card-actions>
  </div>
</template>

<script>
import api from "@/api"

export default {
  name: "WorkoutAddExercise",
  props: {
    exercisesProp: {
      type: Array,
      default: () => [],
    },
    trainingMethods: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      exercises: [],
      savedFirstSet: false,
      editingSetIndex: null,
      selectedExerciseIndex: null, // Welcher Index der exercises wird gerade bearbeitet?
      selectedExerciseForAdd: null, // Für das Select zum Hinzufügen eines Satzes
      nextExerciseIndex: 0, // Um die nächste Übung für das Hinzufügen zu verfolgen
      isAddingSet: false, // Um den Select zu deaktivieren während des Hinzufügens
    }
  },
  computed: {
    maxSetCount() {
      // Die maximale Anzahl der Sätze unter allen Übungen, um Reihen darzustellen
      let maxCount = 0
      this.exercises.forEach((ex) => {
        if (ex.sets && ex.sets.length > maxCount) {
          maxCount = ex.sets.length
        }
      })
      // +1 für die Möglichkeit, einen neuen Satz hinzuzufügen (bei Bedarf)
      return maxCount > 0 ? maxCount : 1
    },
    isButtonDisabled() {
      // Button ist disabled, wenn keine Sätze angelegt wurden
      let anySets = this.exercises.some((ex) => ex.sets && ex.sets.length > 0)
      return !anySets
    },
    tooltipMessage() {
      const messages = []
      let anySets = this.exercises.some((ex) => ex.sets && ex.sets.length > 0)
      if (!anySets) {
        messages.push("Mindestens einen Satz hinzufügen")
      }
      return messages.join(" ")
    },
  },
  created() {
    this.exercises = JSON.parse(JSON.stringify(this.exercisesProp)).map((ex, index) => {
      if (!ex.sets) ex.sets = []
      ex.savedGeneralExerciseSettings = false
      ex.index = index // Füge den Index hinzu, um ihn im Select zu verwenden
      return ex
    })
  },
  methods: {
    getAnalyzeExercise(exIndex) {
      // Beispielhafte Analyse pro Übung
      const exercise = this.exercises[exIndex]
      const sets = exercise.sets
      let method = ""
      if (!sets || sets.length < 3) return
      const weights = sets.map((set) => this.getSettingValue(set, "Gewicht"))
      const pauses = sets.map((set) => this.getSettingValue(set, "Pause"))
      const isDescendingWeight = weights.every((w, i, arr) => !i || w <= arr[i - 1])
      const isAscendingWeight = weights.every((w, i, arr) => !i || w >= arr[i - 1])
      const hasPauses = pauses.every((p) => p && parseInt(p, 10) >= 30)

      if (isDescendingWeight && !hasPauses) {
        method = "dropSet"
      } else if (isAscendingWeight && !hasPauses) {
        method = "reverseDropSet"
      } else if ((isAscendingWeight || isDescendingWeight) && !hasPauses) {
        method = "pyramidSet"
      } else if (hasPauses) {
        method = "restPause"
      } else {
        method = ""
      }
      return this.trainingMethods[method]
    },
    getSettingValue(set, settingText) {
      const setting = set.find((s) => s.text === settingText)
      return setting ? parseFloat(setting.value) || setting.value : null
    },
    onButtonClick() {
      if (!this.isButtonDisabled) {
        // Gewichte und Wiederholungen zusammenfassen
        this.exercises.forEach((ex) => {
          if (ex.sets && ex.sets.length) {
            let weights = ex.sets.map((set) => parseFloat(set.find((item) => item.text === "Gewicht")?.value || 0))
            let minReps = ex.sets.map((set) => parseInt(set.find((item) => item.text === "Min Wiederholungen")?.value || 0))
            let maxReps = ex.sets.map((set) => parseInt(set.find((item) => item.text === "Max Wiederholungen")?.value || 0))
            ex.weightSum = { min: Math.min(...weights), max: Math.max(...weights) }
            ex.repSum = { min: Math.min(...minReps), max: Math.max(...maxReps) }
          }
        })
        this.$emit("savedExercises", this.exercises)
      }
    },
    removeSetByIndex(exIndex, index) {
      this.exercises[exIndex].sets.splice(index, 1)
    },
    saveGeneralExerciseSettings(exIndex) {
      const exercise = this.exercises[exIndex]
      if (!exercise.general_exercise_settings.find((setting) => setting.value)) {
        if (confirm("Ohne Geräteeinstellungen fortfahren?")) {
          exercise.savedGeneralExerciseSettings = true
        }
      } else {
        exercise.savedGeneralExerciseSettings = true
      }
    },
    saveSet() {
      const exercise = this.exercises[this.selectedExerciseIndex]
      if (exercise.set_exercise_settings.find((setting) => setting.value)) {
        const newSet = JSON.parse(JSON.stringify(exercise.set_exercise_settings))

        if (this.editingSetIndex !== null) {
          // Bearbeiten eines existierenden Satzes
          exercise.sets.splice(this.editingSetIndex, 1, newSet)
          this.editingSetIndex = null // Zurücksetzen
        } else {
          // Hinzufügen eines neuen Satzes
          exercise.sets.push(newSet)
          // Initialisiere set_exercise_settings für die nächste Übung
          this.exercises[this.selectedExerciseIndex].set_exercise_settings = JSON.parse(
            JSON.stringify(this.exercisesProp[this.selectedExerciseIndex].set_exercise_settings)
          )
        }
        // Setze den nächsten Übungsindex für die Alternation
        this.selectedExerciseIndex = this.nextExerciseIndex

        // Aktualisiere den nextExerciseIndex für die nächste Runde
        this.nextExerciseIndex = (this.nextExerciseIndex + 1) % this.exercises.length
        this.savedFirstSet = true
      } else {
        alert("Füge mindestens eine Satzeinstellung hinzu")
      }
    },
    editSet(exIndex, index) {
      this.selectedExerciseIndex = exIndex
      this.editingSetIndex = index
      this.exercises[exIndex].set_exercise_settings = JSON.parse(JSON.stringify(this.exercises[exIndex].sets[index]))
    },
    cancelEditSet() {
      if (this.selectedExerciseIndex !== null) {
        this.editingSetIndex = null
        this.exercises[this.selectedExerciseIndex].set_exercise_settings = JSON.parse(
          JSON.stringify(this.exercisesProp[this.selectedExerciseIndex].set_exercise_settings)
        )
        this.selectedExerciseIndex = null
      }
    },
    openCancelConfirm() {
      let anyUnsaved = this.exercises.some((ex) => ex.sets.length || ex.savedGeneralExerciseSettings)
      if (anyUnsaved) {
        if (confirm("Ungelespeicherte Änderungen gehen verloren")) return this.$emit("cancel-edit")
      } else {
        this.$emit("cancel-edit")
      }
    },
    onExerciseSelect(selectedIndex) {
      if (selectedIndex === null) return

      this.selectedExerciseIndex = selectedIndex
      this.editingSetIndex = null

      // Initialisiere set_exercise_settings mit einer Kopie der ursprünglichen Einstellungen
      this.exercises[selectedIndex].set_exercise_settings = JSON.parse(JSON.stringify(this.exercisesProp[selectedIndex].set_exercise_settings))

      // Aktualisiere den nextExerciseIndex für die Alternation
      this.nextExerciseIndex = (selectedIndex + 1) % this.exercises.length

      // Optional: Setze das selectedExerciseForAdd auf den nächsten Index, um die Auswahl zu resetten
      this.selectedExerciseForAdd = null
    },
    test(test) {
      console.log(test)
    },
    updatingSetting(event, item) {
      item.value = event.target.innerText.trim()
    },
    getImageForExercise(exercise) {
      if (exercise.picture) return this.getImageUrl(exercise.picture)
      if (exercise.manual && exercise.manual[0] && exercise.manual[0].picture) return this.getImageUrl(exercise.manual[0].picture)
      return "/img/uebungen/einmalig/imageUploadPlaceholder.jpg"
    },
    getImageUrl(image) {
      return "/img/uebungen/" + image.replace(/\.[^/.]+$/, "") + ".webp"
    },
    shorten(text, length = 25) {
      if (text === "Min Wiederholungen") return "Min WH"
      if (text === "Max Wiederholungen") return "Max WH"
      if (text === "Umdrehungen pro Minute") return "UPM"
      if (text.length > length) text = text.substr(0, length) + "..."
      return text
    },
    getSettingText(setting) {
      let text = setting.value
      if (setting.text === "Gewicht") text = text + " KG"
      return text
    },
  },
}
</script>

<style lang="scss" scoped>
.compact-input {
  width: auto;
  min-width: 50px;
  border: none;
  background: transparent;
  padding: 5px;
  font-size: 14px;
  transition: width 0.3s, border-color 0.3s;
  outline: none;
}

.compact-input:empty:before {
  content: "+";
  color: white;
}

.compact-input:focus:empty:before {
  border: 1px solid white;
}

.input-wrapper {
  flex-grow: 1;
  height: 38px;
}

.v-card {
  transition: width 0.3s, box-shadow 0.3s;
}

.v-card:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled-button {
  opacity: 0.5;
  pointer-events: none;
}
</style>
