<template>
  <v-stepper class="fill-height" v-model="step">
    <!-- Steppers Header -->
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1"> Arbeitsplan erstellen </v-stepper-step>
      <v-stepper-step step="3"> Mitarbeiter erstellen </v-stepper-step>
      <v-stepper-step :complete="step > 2" step="2"> Spezialtage bearbeiten </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items class="fill-height" style="overflow: auto; padding-bottom: 50px">
      <!-- Schritt 1: Arbeitsplan erstellen -->
      <v-stepper-content step="1">
        <v-container fluid style="max-width: 90vw !important">
          <v-row>
            <v-col cols="3">
              <v-row class="mb-1">
                <v-card outlined style="width: 100%" class="rounded-lg pa-1">
                  <v-card-title>Ansicht</v-card-title>
                  <v-select
                    class="mx-3"
                    filled
                    :items="viewOptions"
                    label="Ansicht wählen"
                    v-model="viewMode"
                    item-text="label"
                    item-value="value"></v-select>
                  <v-select
                    class="mx-3"
                    filled
                    :items="dayOptions"
                    label="Tag wählen"
                    v-model="selectedDay"
                    item-text="label"
                    item-value="value"></v-select>
                </v-card>
              </v-row>
              <v-row class="mb-1">
                <v-card outlined style="width: 100%" class="rounded-lg pa-1">
                  <v-card-title>Verfügbare Schichten</v-card-title>
                  <v-list>
                    <v-list-item
                      v-for="shift in settings.shifts"
                      :key="shift._id"
                      class="draggable-shift"
                      draggable="true"
                      @dragstart="onDragStart(shift, $event)">
                      <v-list-item-content :style="'background: ' + shift.color" class="white--text mb-2 rounded-lg pa-1">
                        <v-list-item-title>{{ shift.name }}</v-list-item-title>
                        <v-list-item-subtitle class="font-weight-bold">
                          {{ shift.shortName }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-row>
              <v-row v-if="viewMode === 'category'">
                <v-card outlined style="width: 100%" class="rounded-lg pa-1">
                  <v-card-title> Ø Checkins {{ $moment(selectedDay).format("dddd") }} </v-card-title>
                  <apexchart type="bar" height="350" :options="chartOptions" :series="series" />
                </v-card>
              </v-row>
            </v-col>

            <v-col cols="9">
              <!-- Kalender mit Drag &amp; Drop -->
              <div class="calendar-drop-zone" @dragover.prevent @drop="onDrop">
                <v-calendar
                  @click:event="removeEvent"
                  ref="calendar"
                  first-interval="5"
                  interval-count="18"
                  v-model="selectedDay"
                  :events="calendarEvents"
                  :categories="calendarCats"
                  category-for-invalid="huhh"
                  category-show-all
                  event-overlap-mode="stack"
                  @dragenter:time="mouseEnter"
                  :type="viewMode"
                  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  color="primary">
                  <!-- Angepasster Day-Slot: Zusammenfassung -->
                  <template v-slot:day-header="{ date }">
                    <div class="v-calendar-day-content" style="text-align: center">
                      <div class="v-date-subtitle" style="font-size: 0.8rem">
                        {{ getDaySummary(date) }}
                      </div>
                    </div>
                  </template>
                  <template v-slot:day-label-header="{ date }">
                    <span @click="selectedDay = date">
                      {{ $moment(date).format("dddd") }}
                    </span>
                  </template>
                </v-calendar>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-stepper-content step="2">
        <shift-management-main-dialog-create-employee class="fill-height"></shift-management-main-dialog-create-employee>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-container fluid style="max-width: 90vw !important">
          <v-row>
            <v-col cols="3">
              <v-row class="mb-1">
                <v-card outlined style="width: 100%" class="rounded-lg pa-1">
                  <v-card-title>Event oder Tag mit Sonderschichten hinzufügen</v-card-title>
                  <v-menu v-model="specialDayMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="specialDay"
                        label="Spezialtag auswählen"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        class="mx-3"></v-text-field>
                    </template>
                    <v-date-picker v-model="specialDay" @input="specialDayMenu = false" locale="de" :events="specialDatePickerEvents"></v-date-picker>
                  </v-menu>
                </v-card>
              </v-row>
              <v-row class="mb-1">
                <v-card outlined style="width: 100%" class="rounded-lg pa-1">
                  <v-card-title>Verfügbare Schichten</v-card-title>
                  <v-list>
                    <v-list-item
                      v-for="shift in settings.shifts"
                      :key="shift._id"
                      class="draggable-shift"
                      draggable="true"
                      @dragstart="onDragStart(shift, $event)">
                      <v-list-item-content :style="'background: ' + shift.color" class="white--text mb-2 rounded-lg pa-1">
                        <v-list-item-title>{{ shift.name }}</v-list-item-title>
                        <v-list-item-subtitle class="font-weight-bold">
                          {{ shift.shortName }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-row>
              <!-- Optional: Chart integrieren -->
            </v-col>

            <v-col cols="9">
              <!-- Kalender-Drag & Drop für Spezialtage (nur Category-View) -->
              <div class="calendar-drop-zone" @dragover.prevent @drop="onSpecialDrop">
                <v-calendar
                  @click:event="removeSpecialEvent"
                  ref="specialCalendar"
                  first-interval="5"
                  interval-count="18"
                  v-model="specialDay"
                  category-show-all
                  :categories="specialCalendarCats"
                  :events="specialCalendarEvents"
                  event-overlap-mode="stack"
                  :type="'category'"
                  color="primary">
                  <template v-slot:day-header="{ date }">
                    <div class="v-calendar-day-content" style="text-align: center">
                      <div class="v-date-subtitle" style="font-size: 0.8rem">
                        {{ getDaySummarySpecial(date) }}
                      </div>
                    </div>
                  </template>
                </v-calendar>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>

      <!-- Schritt 3: Mitarbeiter erstellen -->
    </v-stepper-items>
    <v-card-actions style="position: fixed; bottom: 0; width: 100vw; justify-content: right">
      <v-btn color="secondary" @click="prevStep">Zurück</v-btn>
      <v-btn color="primary" @click="nextStep" class="mr-7">Weiter</v-btn>
    </v-card-actions>
  </v-stepper>
</template>

<script>
import api from "@/api"
import shiftManagementMainDialogCreateEmployee from "./shiftManagementMainDialogCreateEmployee.vue"

export default {
  name: "ArbeitsplanStepper",
  components: {
    shiftManagementMainDialogCreateEmployee,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          stacked: true,
          toolbar: {
            show: true,
          },
        },
        colors: [this.$vuetify.theme.currentTheme.primary],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
          },
        },
        tooltip: {
          theme: "dark",
        },
        xaxis: {
          categories: ["9-12 Uhr", "12-15 Uhr", "15-18 Uhr", "18-21 Uhr"],
          labels: {
            style: {
              colors: "#fff",
            },
          },
          axisBorder: {
            show: true,
            color: "#fff",
          },
          axisTicks: {
            show: true,
            color: "#fff",
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#fff",
            },
          },
        },
        legend: {
          position: "top",
          labels: {
            colors: "#fff",
          },
        },
      },
      step: 1,
      viewMode: "week", // 'day' oder 'week' oder 'category'
      selectedDay: this.$moment().startOf("week").format("YYYY-MM-DD"),
      settings: {
        shifts: [],
      },
      calendarEvents: [],
      specialCalendarEvents: [],
      checkinData: null,
      draggedShift: null,
      // Neuer Zustand für den Spezialtag
      specialDay: this.$moment().format("YYYY-MM-DD"),
      specialDayMenu: false,
    }
  },
  async created() {
    this.checkinData = await api.shiftManagement.fetchShiftManagementCheckinData()
    this.settings = await api.shiftManagement.fetchSettings("local")
  },
  computed: {
    specialDatePickerEvents() {
      const uniqueDates = new Set()
      this.specialCalendarEvents.forEach((event) => {
        const date = event.start.substring(0, 10)
        uniqueDates.add(date)
      })
      return Array.from(uniqueDates)
    },

    calendarCats() {
      let cats = []
      this.calendarEvents.forEach((event) => {
        if (event.name === "Pause") return
        // Extrahiere den Datumsanteil von event.start (z. B. "YYYY-MM-DD")
        const eventDate = event.start.substring(0, 10)
        if (eventDate !== this.selectedDay) return
        const cat = event.name.split(" ")[0]
        if (!cats.includes(cat)) cats.push(cat)
      })
      return cats
    },
    specialCalendarCats() {
      let cats = []
      this.specialCalendarEvents.forEach((event) => {
        if (event.name === "Pause") return
        // Extrahiere das Datum von event.start (Format "YYYY-MM-DD")
        const eventDate = event.start.substring(0, 10)
        if (eventDate !== this.specialDay) return
        const cat = event.name.split(" ")[0]
        if (!cats.includes(cat)) cats.push(cat)
      })
      return cats
    },

    series() {
      if (this.viewMode !== "category" || !this.checkinData) return []
      const selectedAbbr = this.$moment(this.selectedDay).format("dd").toUpperCase()
      const hours = this.checkinData[selectedAbbr]
      if (!hours) return []
      const bucket0 = hours.slice(9, 12).reduce((sum, val) => sum + val, 0)
      const bucket1 = hours.slice(12, 15).reduce((sum, val) => sum + val, 0)
      const bucket2 = hours.slice(15, 18).reduce((sum, val) => sum + val, 0)
      const bucket3 = hours.slice(18, 21).reduce((sum, val) => sum + val, 0)
      return [
        {
          name: "Checkins",
          data: [bucket0, bucket1, bucket2, bucket3],
        },
      ]
    },
    viewOptions() {
      return [
        { value: "category", label: "Tagesansicht" },
        { value: "week", label: "Wochenansicht" },
      ]
    },
    dayOptions() {
      const options = []
      const monday = this.$moment().startOf("isoWeek")
      for (let i = 0; i < 7; i++) {
        const day = monday.clone().add(i, "days")
        options.push({
          value: day.format("YYYY-MM-DD"),
          label: day.format("dddd"),
        })
      }
      return options
    },
  },
  methods: {
    removeEvent(event) {
      this.calendarEvents = this.calendarEvents.filter((ev) => ev.removeTimestamp !== event.event.removeTimestamp)
    },
    removeSpecialEvent(event) {
      this.specialCalendarEvents = this.specialCalendarEvents.filter((ev) => ev.removeTimestamp !== event.event.removeTimestamp)
    },
    mouseEnter(day) {
      this.selectedDay = day.date
    },
    onDragStart(shift, event) {
      this.draggedShift = shift
      event.dataTransfer.setData("text/plain", JSON.stringify(shift))
    },
    onDrop(event) {
      const dropDate = this.selectedDay
      if (!this.draggedShift) return
      let removeTimestamp = this.$moment().unix()
      this.draggedShift.times.forEach((time) => {
        if (!time.start || !time.end) {
          console.warn("Ungültiger Zeitabschnitt:", time, "in Shift:", this.draggedShift)
          return
        }
        const eventStart = `${dropDate} ${time.start}`
        const eventEnd = `${dropDate} ${time.end}`
        const newEvent = {
          name: time.type === "Pause" ? "Pause" : this.draggedShift.name,
          start: eventStart,
          end: eventEnd,
          color: time.type === "Pause" ? "#9E9E9E" : this.draggedShift.color,
          shiftId: this.draggedShift._id,
          type: time.type,
          category: this.draggedShift.name.split(" ")[0],
          removeTimestamp,
        }
        this.calendarEvents.push(newEvent)
      })
      this.draggedShift = null
    },
    onSpecialDrop(event) {
      const dropDate = this.specialDay
      if (!this.draggedShift) return
      let removeTimestamp = this.$moment().unix()
      console.log(event, this.draggedShift)
      this.draggedShift.times.forEach((time) => {
        if (!time.start || !time.end) {
          console.warn("Ungültiger Zeitabschnitt:", time, "in Shift:", this.draggedShift)
          return
        }
        const eventStart = `${dropDate} ${time.start}`
        const eventEnd = `${dropDate} ${time.end}`
        const newEvent = {
          name: time.type === "Pause" ? "Pause" : this.draggedShift.name,
          start: eventStart,
          end: eventEnd,
          color: time.type === "Pause" ? "#9E9E9E" : this.draggedShift.color,
          shiftId: this.draggedShift._id,
          type: time.type,
          category: this.draggedShift.name.split(" ")[0],
          removeTimestamp,
        }
        this.specialCalendarEvents.push(newEvent)
      })
      console.log(this.specialCalendarEvents)
      this.draggedShift = null
    },
    nextStep() {
      this.step++
    },
    prevStep() {
      if (this.step > 1) this.step--
    },
    formatTime(timestamp) {
      if (typeof timestamp === "string" && timestamp.length >= 16) {
        return timestamp.substring(11, 16)
      }
      return timestamp
    },
    // Zusammenfassung für den allgemeinen Kalender (Step 1)
    getDaySummary(date) {
      const dayStr = this.$moment(date).format("YYYY-MM-DD")
      const morningThreshold = this.$moment(date).hour(12).minute(0).second(0)
      const afternoonThreshold = this.$moment(date).hour(18).minute(0).second(0)
      const groups = {}
      this.calendarEvents.forEach((event) => {
        if (event.type === "Pause") return
        const eventDay = this.$moment(event.start).format("YYYY-MM-DD")
        if (eventDay !== dayStr) return
        if (!groups[event.shiftId]) {
          groups[event.shiftId] = []
        }
        groups[event.shiftId].push(event)
      })
      let morningCount = 0
      let afternoonCount = 0
      let eveningCount = 0
      Object.values(groups).forEach((events) => {
        let hasMorning = false
        let hasAfternoon = false
        let hasEvening = false
        events.forEach((event) => {
          const start = this.$moment(event.start)
          const end = this.$moment(event.end)
          if (start.isBefore(morningThreshold)) {
            hasMorning = true
          }
          if (start.isBefore(afternoonThreshold) && end.isAfter(morningThreshold)) {
            hasAfternoon = true
          }
          if (end.isAfter(afternoonThreshold)) {
            hasEvening = true
          }
        })
        if (hasMorning) morningCount++
        if (hasAfternoon) afternoonCount++
        if (hasEvening) eveningCount++
      })
      return `M: ${morningCount} | N: ${afternoonCount} | A: ${eveningCount}`
    },
    // Zusammenfassung für den Spezialtag (Step 2)
    getDaySummarySpecial(date) {
      const dayStr = this.$moment(date).format("YYYY-MM-DD")
      const morningThreshold = this.$moment(date).hour(12).minute(0).second(0)
      const afternoonThreshold = this.$moment(date).hour(18).minute(0).second(0)
      const groups = {}
      this.specialCalendarEvents.forEach((event) => {
        if (event.type === "Pause") return
        const eventDay = this.$moment(event.start).format("YYYY-MM-DD")
        if (eventDay !== dayStr) return
        if (!groups[event.shiftId]) {
          groups[event.shiftId] = []
        }
        groups[event.shiftId].push(event)
      })
      let morningCount = 0
      let afternoonCount = 0
      let eveningCount = 0
      Object.values(groups).forEach((events) => {
        let hasMorning = false
        let hasAfternoon = false
        let hasEvening = false
        events.forEach((event) => {
          const start = this.$moment(event.start)
          const end = this.$moment(event.end)
          if (start.isBefore(morningThreshold)) {
            hasMorning = true
          }
          if (start.isBefore(afternoonThreshold) && end.isAfter(morningThreshold)) {
            hasAfternoon = true
          }
          if (end.isAfter(afternoonThreshold)) {
            hasEvening = true
          }
        })
        if (hasMorning) morningCount++
        if (hasAfternoon) afternoonCount++
        if (hasEvening) eveningCount++
      })
      return `M: ${morningCount} | N: ${afternoonCount} | A: ${eveningCount}`
    },
  },
}
</script>

<style scoped>
::v-deep .v-calendar-daily_head-day-label span {
  word-break: keep-all !important;
}
.draggable-shift {
  cursor: move;
  user-select: none;
}
.calendar-drop-zone {
  min-height: 500px;
}
</style>
