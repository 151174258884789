<template>
  <v-row justify="center">
    <v-dialog style="z-index: 2000" content-class="white" persistent v-model="dialog" transition="dialog-bottom-transition" origin="bottom center">
      <div>
        <v-card-title class="drink-execution__title justify-content-center white--text primary" style="position: fixed; z-index: 2000">
          Dein Fortschritt
          <v-btn icon color="white" @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-timeline dense style="padding-top: 72px">
          <v-timeline-item class="mb-12" dot-color="orange" small>
            <template v-slot:opposite>
              <v-chip>{{ $moment().format("DD.MM.YYYY") }}</v-chip>
            </template>
            <v-card class="rounded-xl mr-2">
              <v-card-title class="primary white--text mb-0 py-2">Neuer Eintrag</v-card-title>
              <v-card-text class="mt-1">
                <v-row class="mb-2">
                  <v-col cols="4">
                    <label for="images" class="drop-container" id="dropcontainer">
                      <div class="image-upload">
                        <v-icon class="ma-auto" x-large>mdi-image-plus</v-icon>
                      </div>
                      <v-file-input multiple @change="uploadImages($event)" style="display: none" type="file" id="images" accept="image/*" required />
                    </label>
                  </v-col>
                  <v-col cols="4" v-for="(image, index) in newItem.images" :key="'image' + index">
                    <v-img contain class="mx-auto" v-if="image.dataUrl" height="65px" width="65px" :src="image.dataUrl" alt="Uploaded Image"></v-img>
                  </v-col>
                </v-row>
                <v-textarea filled rounded hide-details v-model="newItem.text" label="Anmerkungen"></v-textarea>
              </v-card-text>
              <v-row no-gutters>
                <v-col class="text-right" cols="8">
                  <span class="pr-2">Ersichtlich für Coach</span>
                </v-col>
                <v-col cols="4">
                  <v-switch class="mt-0 pt-0" hide-details inset v-model="newItem.coach"></v-switch>
                </v-col>
              </v-row>
              <v-card-actions class="justify-content-center">
                <v-btn class="rounded primary" @click="saveExecution()">Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </v-timeline-item>
          <v-timeline-item v-for="(item, i) of habit.executions" :key="i" small>
            <v-card class="rounded-xl mr-2" v-if="item.images || item.text">
              <v-card-title class="primary white--text mb-0 py-2">
                {{ $moment(i).format("DD.MM.YYYY") }}
              </v-card-title>
              <v-card-text class="mt-1">
                <v-row class="mb-2">
                  <v-col cols="4" v-for="(image, index) in item.images" :key="'image' + index">
                    <v-img @click="viewBigImage(i, index)" contain height="100px" class="mx-auto" :src="image.dataUrl" alt="Uploaded Image"></v-img>
                  </v-col>
                </v-row>
                <v-textarea filled disabled hide-details rounded v-model="item.text" label="Anmerkungen"></v-textarea>
                <v-row no-gutters>
                  <v-col class="text-right" cols="8">
                    <span class="pr-2">Ersichtlich für Coach</span>
                  </v-col>
                  <v-col cols="4">
                    <v-switch class="mt-0 pt-0" @change="updateCoachVisibility(item, i)" hide-details inset v-model="item.coach"></v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-dialog>
    <habit-form-update-execution-image
      :bigImagesArray="bigImagesArray"
      v-if="bigImagesArray"
      @close="bigImagesArray = null"></habit-form-update-execution-image>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { habitService } from "@/services/habitService.js"
import habitFormUpdateExecutionImage from "./habitFormUpdateExecutionImage.vue"
import api from "@/api.js"
export default {
  name: "habitFormUpdateExecution",
  props: {
    date: {
      type: Object,
      required: true,
    },
    habit: {
      type: Object,
      required: true,
    },
  },
  components: {
    habitFormUpdateExecutionImage,
  },
  data() {
    return {
      newItem: {
        images: [],
        text: "",
        coach: true,
      },
      imageViewDialog: false,
      selectedHabit: null,
      dialog: true,
      templateSelection: false,
      templatesList: [],
      halfGlas: false,
      bigImagesArray: [],
      todayExists: false,
      bigImagesIndex: 0,
      bigImagesArrayDay: null,
    }
  },
  async mounted() {
    console.log("mounted")
    console.log(this.habit.executions)

    for (const [key, ex] of Object.entries(this.habit.executions)) {
      if (this.$moment(key, "YYYY-MM-DD").isSame(this.$moment(), "day")) this.todayExists = true
      console.log("hoi", ex, key)
      for await (let image of ex.images) {
        const response = await api.fetchFormUpdateImage(image.src)
        this.$set(image, "dataUrl", response)
      }
    }
  },
  methods: {
    updateCoachVisibility(item, index) {
      try {
        this.habit.updateExecution(index, item)
        this.$toast.success("Sichtbarkeit wurde aktualisiert")
      } catch (error) {
        this.$toast.error("Fehler beim aktualisieren")
      }
    },
    viewBigImage(i, index) {
      this.imageViewDialog = true
      this.bigImagesArrayDay = this.$moment(i).format("DD.MM.YYYY")
      this.bigImagesArray = JSON.parse(JSON.stringify(this.habit.executions[i].images))
      this.bigImagesIndex = index
    },
    saveExecution() {
      if (this.todayExists && !confirm("Du hast heute bereits einen Eintrag erstellt, willst du diesen Überschreiben?")) {
        return
      }

      if (this.newItem.images.length || confirm("Willst du den Eintrag erstellen ohne ein Bild hinzuzufügen?")) {
        this.newItem.images = this.newItem.images.map((image) => {
          return { src: image.src }
        })
        this.habit.execute(this.newItem)
        this.closeDialog()
      }
    },
    async fetchImageDataSource(image) {
      try {
        const response = await api.fetchFormUpdateImage(image.src)
        console.log(response)
        return response
      } catch (error) {
        this.$toast.error("Fehler beim laden des Bildes")
      }
    },
    uploadImages(files) {
      for (let file of files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const formData = new FormData()
          formData.append("file", file)

          api
            .uploadFormUpdateImage(formData)
            .then((response) => {
              const uploadedImageName = response.name

              this.newItem.images.push({
                title: "",
                src: uploadedImageName,
                dataUrl: e.target.result,
              })
              this.$forceUpdate()
            })
            .catch((error) => {
              console.log("Upload failed", error)
            })
        }
        reader.readAsDataURL(file)
      }
    },
    closeDialog() {
      this.dialog = false
      setTimeout(() => {
        this.$emit("closeExecutionForm")
      }, 500)
    },
  },
  computed: {},
}
</script>

<style lang="scss" scoped>
.image-upload {
  height: 65px;
  width: 65px;
  display: flex;
  border: dashed;
  border-color: grey;
  border-radius: 6px;
}

.drink-execution {
  &__title {
    position: sticky;
    min-height: 68px;
    width: 100%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }

  &__add-button {
    background-color: #4bb2fd;
    color: white;
  }
}

::v-deep .v-dialog {
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 100%;
  margin: 0;
  position: absolute;
  min-height: 40vh;
  bottom: 0;
  background: white;
  max-height: 90vh;
}

.option-button {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background: lightgray;

    &-bulb {
      ::v-deep .v-image__image {
        width: 80px;
      }

      flex-direction: row;
    }
  }

  &__wrapper:hover {
    background: gray;
  }
}
</style>
