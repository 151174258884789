export default [
  {
    path: "/user",
    name: "userProfile",
    component: () => import("@/views/user/UserProfile.vue"),
  },
  {
    path: "/user/edit",
    name: "userProfile",
    component: () => import("@/views/user/views/UserEditProfile.vue"),
  },

  {
    path: "/user/payments",
    name: "userPayments",
    meta: {
      hideBottomNavigation: true,
    },
    component: () => import("@/views/user/views/UserPayments.vue"),
  },

  {
    path: "/user/healthcare",
    meta: { navbarTitle: "Krankenkasse" },
    name: "userHealthcare",
    component: () => import("@/views/user/views/UserHealthcare.vue"),
  },
  {
    path: "/user/purchases",
    name: "userPurchases",
    component: () => import("@/views/shop/PurchasesProfile.vue"),
  },
  {
    path: "/user/payments",
    name: "userPayments",
    component: () => import("@/views/user/views/UserPayments.vue"),
  },
]
