<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile" class="overflow-hidden" width="700" :value="true" persistent>
    <v-card class="aktuelles-reading__card fill-height">
      <v-row no-gutters class="justify-space-between mb-0">
        <v-col class="pt-0" cols="12" v-show="news.foto">
          <v-img contain min-height="150" class="aktuelles-reading__card-image" :src="'/img/news/' + news.foto" />
        </v-col>
        <v-col v-show="news.titel">
          <v-card-title style="word-break: break-word" v-show="news.titel"> {{ format(news.titel) }} </v-card-title>
          <div v-show="news.inhalt" class="aktuelles-reading__card-content px-5 overflow-auto" style="max-height: 40vh" ref="aktuellesReadingView">
            <div v-html="format(news.inhalt)"></div>
          </div>
          <div v-show="news.link" class="ml-5 news-link">
            <a target="_blank" :style="'background-color: ' + news.link_color" :href="news.link">{{ news.link_text }}</a>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "../../api"
import { mapState, mapActions, mapGetters } from "vuex"
export default {
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.setNavigationBarText({ text: "zurück", state: false, endPoint: this.news._id })
    api.markNewsAsRead(this.news._id)
  },
  beforeDestroy() {
    this.setNavigationBarText(null)
  },

  methods: {
    ...mapActions("navigationStoreNotPersisted", ["setNavigationBarText"]),
    format(i) {
      return i.split("{{studio}}").join(this.getArt())
    },
    getArt() {
      let ret = false
      if (location.host.includes("localhost")) {
        ret = "well come FIT"
      }
      if (location.host.includes("wellcomefit")) {
        ret = "well come FIT"
      }
      if (location.host.includes("wintifit")) {
        ret = "winti FIT"
      } else if (location.host.includes("fzw")) {
        ret = "FZW"
      }
      return ret
    },
  },
  computed: {
    ...mapState("navigationStoreNotPersisted", ["navigationBarText"]),
    ...mapGetters("navigationStoreNotPersisted", ["navigationBarTextState"]),
  },
  watch: {
    navigationBarTextState(value) {
      if (value === true && this.navigationBarText.endPoint === this.news._id) return this.$emit("resetActiveNews")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'md-and-up')} {
  ::v-deep .v-dialog--active {
    border-radius: 24px;
  }
}
::v-deep .v-dialog--active {
  overflow-x: hidden;
  background: white;
}

.aktuelles-reading__card {
  box-shadow: unset !important;
  &-content-text {
    scroll-behavior: smooth;
  }
}
</style>
